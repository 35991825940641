import { Center, Heading } from "@chakra-ui/react"
import React from "react"

const RequestPage = ({ type }) => {
	if (type === "accepted") {
		return (
			<Center>
				<Heading>Zahtev za odsustvo prihvaćen.</Heading>
			</Center>
		)
	} else {
		return (
			<Center>
				<Heading>Zahtev za odsustvo odbijen.</Heading>
			</Center>
		)
	}
}

export default RequestPage
