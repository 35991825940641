import { Button, Flex, FormControl, FormLabel, Heading, Input, Stack, useToast } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useGeneric from "../../redux/hooks/useGeneric"
import { forms } from "../../utils/constants"
import { formatDateWithoutTime, handleToast } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { DatePickerWrapper } from "../DatePickerComponent"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const FormularForm = ({ onClose, defaultValues, selectData }) => {
	const [selectedFormular, setSelectedFormular] = useState(null)

	const [selectedVerificationDoc, setSelectedVerificationDoc] = useState(null)
	const [selectedCvDoc, setSelectedCvDoc] = useState(null)
	const [selectedInitiativeDoc, setSelectedInitiativeDoc] = useState(null)
	const [selectedStatementDoc, setSelectedStatementDoc] = useState(null)
	const [selectedAbsenceDoc, setSelectedAbsenceDoc] = useState(null)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const { handleSubmit, control, watch } = useForm({})

	const hookG = useGeneric()
	const toast = useToast()

	useEffect(() => {
		if (hookG.message) {
			handleToast(toast, hookG.message, "1")
		}
	}, [hookG.message])

	const submit = (data) => {
		if (selectedFormular) {
			let formData = new FormData()
			formData.append("form_id", selectedFormular)
			console.log(data, "Daat")
			Object.keys(data).map((key) => {
				if (typeof data[key] === "object" && data[key]) {
					formData.append(key, data[key].value)
				} else if (key === "mpo") {
					// console.log(selectData.sectors.find((ele) => ele.label === data[key]).value)
					formData.append("mpo", selectData.sectors.find((ele) => ele.label === data[key]).value)
				} else if (data[key]) {
					formData.append(key, data[key])
				} else {
					formData.append(key, "")
				}
			})
			if (selectedFormular === 1) {
				if (selectedInitiativeDoc) {
					formData.append("first_attachment", selectedInitiativeDoc, selectedInitiativeDoc.name)
				} else {
					formData.append("first_attachment", "")
				}
			} else if (selectedFormular === 2) {
				if (selectedCvDoc) {
					formData.append("first_attachment", selectedCvDoc, selectedCvDoc.name)
				} else {
					formData.append("first_attachment", "")
				}
			} else if (selectedFormular === 3) {
				if (selectedAbsenceDoc) {
					formData.append("first_attachment", selectedAbsenceDoc, selectedAbsenceDoc.name)
				} else {
					formData.append("first_attachment", "")
				}

				if (startDate) {
					formData.append("date_from", formatDateWithoutTime(startDate, true))
				}

				if (endDate) {
					formData.append("date_to", formatDateWithoutTime(endDate, true))
				}
			} else if (selectedFormular === 4) {
				if (selectedVerificationDoc) {
					formData.append("first_attachment", selectedVerificationDoc, selectedVerificationDoc.name)
				} else {
					formData.append("first_attachment", "")
				}

				if (selectedStatementDoc) {
					formData.append("second_attachment", selectedStatementDoc, selectedStatementDoc.name)
				} else {
					formData.append("second_attachment", "")
				}
			}

			hookG.submitForm(formData).then((res) => {
				if (res !== undefined && res.status === 200) {
					onClose()
				}
			})
		} else {
			handleToast(toast, { text: "Selektuj formular", type: "text" }, "2")
		}
	}

	const checkFileChange = (file, name) => {
		if (name === "verification_doc") {
			setSelectedVerificationDoc(file)
		} else if (name === "cv_doc") {
			setSelectedCvDoc(file)
		} else if (name === "initiative_doc") {
			setSelectedInitiativeDoc(file)
		} else if (name === "statement_doc") {
			setSelectedStatementDoc(file)
		} else if (name === "absence_doc") {
			setSelectedAbsenceDoc(file)
		}
	}

	const checkSelectedFile = (name) => {
		if (name === "verification_doc") {
			return selectedVerificationDoc
		} else if (name === "cv_doc") {
			return selectedCvDoc
		} else if (name === "initiative_doc") {
			return selectedInitiativeDoc
		} else if (name === "statement_doc") {
			return selectedStatementDoc
		} else if (name === "absence_doc") {
			return selectedAbsenceDoc
		}
	}

	const checkDate = (name) => {
		if (name === "date_from") {
			return { value: startDate, setValue: setStartDate }
		} else if (name === "date_to") {
			return { value: endDate, setValue: setEndDate }
		}
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={4} minH="240px">
				<Heading as="h3" fontSize={["18px", "24px"]}>
					{strings.forms}
				</Heading>

				<SelectDropdown
					name="documents"
					placeholder={strings.choosePlaceholder}
					onChange={(val) => setSelectedFormular(val.value)}
					options={selectData.forms}
				/>

				{forms.map((form, i) => {
					if (selectedFormular === form.id) {
						return form.formFields.map((field, index) => {
							if (field.type === "input") {
								return (
									<FormControl mb="16px">
										<FormLabel color="mc_medium" fontSize="12px">
											{`${strings[field.label]} ${field.required ? "*" : ""}`}
										</FormLabel>
										<Controller
											as={<Input disabled={field.disabled} />}
											control={control}
											name={field.fieldName}
											defaultValue={defaultValues[field.fieldName]}
										/>
									</FormControl>
								)
							} else if (field.type === "select") {
								return (
									<FormControl mb="16px">
										<FormLabel color="mc_medium" fontSize="12px">
											{`${strings[field.label]} ${field.required ? "*" : ""}`}
										</FormLabel>
										<Controller
											as={<SelectDropdown />}
											control={control}
											name={field.fieldName}
											options={field.options ? field.options : selectData[field.selectDataName]}
											defaultValue={field.options ? null : defaultValues[field.fieldName]}
										/>
									</FormControl>
								)
							} else if (field.type === "file") {
								return (
									<FormControl mb="16px">
										<FormLabel color="mc_medium" fontSize="12px">
											{`${strings[field.label]} ${field.required ? "*" : ""}`}
										</FormLabel>
										<Flex w="100" justify="center">
											<UploadFile
												w="180px"
												h="47px"
												p="4px"
												textAlign="center"
												onChange={(file) => checkFileChange(file, field.fieldName)}
												fileUpload={checkSelectedFile(field.fieldName)}
											/>
										</Flex>
									</FormControl>
								)
							} else if (field.type === "date") {
								return (
									<FormControl mb="16px">
										<FormLabel color="mc_medium" fontSize="12px">
											{`${strings[field.label]} ${field.required ? "*" : ""}`}
										</FormLabel>
										<Flex w="100" justify="center">
											<DatePickerWrapper
												selected={checkDate(field.fieldName).value}
												onChange={(date) => checkDate(field.fieldName).setValue(date)}
												dateFormat="dd-MM-yyyy"
											/>
										</Flex>
									</FormControl>
								)
							}
						})
					}
				})}
				{watch("doc_type") && watch("doc_type").value === "ostalo" && (
					<FormControl mb="16px">
						<FormLabel color="mc_medium" fontSize="12px">
							{"Ostalo"}
						</FormLabel>
						<Controller as={<Input />} control={control} name={"doc_type_rest"} defaultValue={""} />
					</FormControl>
				)}
			</Stack>
			<Flex h="full" w="100%" justify="space-between" mt={"110px"}>
				<Button variant="red" textTransform="uppercase" onClick={onClose}>
					{strings.close}
				</Button>
				<Button type="submit" variant="medium" textTransform="uppercase">
					{strings.confirm}
				</Button>
			</Flex>
		</form>
	)
}
export default FormularForm
