import { useDisclosure } from "@chakra-ui/hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSurveyPage,
  removeSurvey,
  createSurvey,
  updateSurvey,
  addNewQuestion,
  removeOneQuestion,
  updateOneQuestion,
  setQuestionsArray,
  cleanMessage,
  changeDefaultSize
} from "../reducers/surveyReducer";

export default function useSurvey() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.surveys.data);
  const pagination = useSelector((state) => state.surveys.pagination);
  const questions = useSelector((state) => state.surveys.questions);
  const defaultSize = useSelector((state) => state.surveys.defaultSize);
  const message = useSelector((state) => state.surveys.message);
  const loading = useSelector((state) => state.surveys.loading);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetch = async (perPage, pageNumber) => {
    dispatch(fetchSurveyPage(perPage, pageNumber));
  };

  const create = async (payload) => {
    dispatch(createSurvey(payload)).then((res) => {
		if(res !== undefined && res.data.message.type === "success") {
			handleRefresh()
		}	
	})
		
  };

  const update = async (payload) => {
    dispatch(updateSurvey(payload)).then((res) => {
		if(res !== undefined && res.data.message.type === "success") {
			handleRefresh()
		}	
	})	
  };

  const remove = async (id) => {
    dispatch(removeSurvey(id)).then(() => handleRefresh());
  };

  const cleanToast = async () => {
	dispatch(cleanMessage())
  }

  const handleRefresh = () => {
    onClose();
    fetch(defaultSize, pagination.current_page);
  };

  const addQuestion = (payload) => {
	dispatch(addNewQuestion(payload))
  }

  const removeQuestion = (payload) => {
	dispatch(removeOneQuestion(payload))
  }

  const updateQuestion = (payload) => {
	dispatch(updateOneQuestion(payload))
  }

  const setQuestions = (payload) => {
	  dispatch(setQuestionsArray(payload))
  }

  const changeSize = (size) => {
	  dispatch(changeDefaultSize(size))
  }

  return {
    data,
	pagination,
	defaultSize,
    message,
    loading,
	questions,
    fetch,
    remove,
    create,
    update,
	cleanToast,
    isOpen,
    onOpen,
    onClose,
	changeSize,
	addQuestion,
	removeQuestion,
	updateQuestion,
	setQuestions
  };
}
