import { Box, Button, Flex, FormControl, FormLabel, Input, Stack } from "@chakra-ui/react"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import useApp from "../../redux/hooks/useApp"
import useUser from "../../redux/hooks/useUser"
import { strings } from "../../utils/localization"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const DocsForm = ({ updateData, submit, handleClose, selectData, setSelectedFile }) => {
	const { register, handleSubmit, control } = useForm()
	const hookUser = useUser()
	const hookApp = useApp()

	let defaultApp = null
	let defaultCompanies = null

	if (updateData) {
		defaultCompanies = updateData.defaultCompanies
		defaultApp = updateData.defaultApp
	} else if (!hookUser.admin && !updateData) {
		defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
		defaultApp = { label: hookApp.name, value: hookApp.id }
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={8}>
				<FormControl>
					<FormLabel color="mc_medium" fontSize="12px">
						{strings.title}
					</FormLabel>
					<Controller
						as={<Input />}
						name="title"
						control={control}
						color="mc_dark"
						rounded="xl"
						boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
						defaultValue={updateData && updateData.title}
					/>
				</FormControl>
				{hookUser.admin && (
					<Flex gridGap={4}>
						<FormControl w="40%">
							<FormLabel color="mc_medium" fontSize="12px">
								{strings.application}
							</FormLabel>
							<Box w="100%">
								<Controller
									as={<SelectDropdown />}
									control={control}
									name="app"
									options={selectData.apps}
									defaultValue={defaultApp}
								/>
							</Box>
						</FormControl>
						<FormControl flex={1}>
							<FormLabel color="mc_medium" fontSize="12px">
								{strings.companies}
							</FormLabel>
							<Controller
								as={<SelectDropdown isMulti />}
								control={control}
								name="companies"
								options={selectData.companies}
								defaultValue={defaultCompanies}
							/>
						</FormControl>
					</Flex>
				)}
				<FormControl>
					<FormLabel color="mc_medium" fontSize="12px" textTransform="uppercase">
						{strings.selectDocument}
					</FormLabel>
					<UploadFile onChange={setSelectedFile} fileUpload={true} />
				</FormControl>
			</Stack>
			<Flex justify="space-between" mt={14}>
				<Button onClick={handleClose} variant="red">
					{strings.close}
				</Button>
				<Button type="submit" variant="medium" color="mc_bg" ml={3}>
					{strings.save}
				</Button>
			</Flex>
		</form>
	)
}

export default DocsForm
