import { Image } from "@chakra-ui/image"
import { Heading, Stack } from "@chakra-ui/layout"
import useConfig from "../../utils/config"

const Question = ({ display, text, img }) => {
	const config = useConfig()

	return (
		<Stack display={display} spacing={6}>
			<Image w="100%" h="100%" borderRadius={["lg", "3xl"]} src={`${config.baseImageUrl}${img}`} objectFit="contain" />
			<Heading as="h3" color="mc_dark" fontSize={["16px", "18px"]} textAlign="center">
				{text}
			</Heading>
		</Stack>
	)
}

export default Question
