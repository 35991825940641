import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import React from "react"

const AddUpdateModal = ({ children, isOpen, onClose, size, overlayClick, closeButton }) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={size}
            closeOnOverlayClick={overlayClick}
            closeOnEsc={false}
            scrollBehavior={"inside"}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                {closeButton && (
                    <ModalCloseButton
                        _focus={{
                            boxShadow: "none",
                        }}
                    />
                )}
                <ModalBody bg="mc_bg" p={["4", "8"]} borderRadius="md">
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default AddUpdateModal
