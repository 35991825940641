import { Box, Flex, Heading, Link, SimpleGrid, Text } from "@chakra-ui/layout"
import React, { useEffect } from "react"
import { Link as RouterLink, useOutletContext } from "react-router-dom"
import { strings } from "../utils/localization"
import useUser from "../redux/hooks/useUser"
import { handleToast } from "../utils/functions"
import { useToast } from "@chakra-ui/toast"

const WelcomePage = ({ location }) => {

	const hook = useUser()
	const toast = useToast()
	const config = useOutletContext()

	useEffect(() => {
		const query = new URLSearchParams(location.search)
		const token = query.get("code")
		hook.confirmRegister(token)
	}, []) //eslint-disable-line

	useEffect(() => {
		if(hook.message) {
			handleToast(toast, hook.message, "1")
		}
	}, [hook.message]) //eslint-disable-line
   
    return (
        <SimpleGrid rows={3} spacingY="50px" height="100vh" textAlign="center">
            <Box></Box>
            <Box>
                
                <Flex justify="center" mb="10px">
                    {config.styledLogo}
                </Flex>
                
                <Heading color="mc_medium" fontSize="70px" as="h1" textTransform="uppercase">
                    {strings.welcomeTo} <br /> {`${config.title}!`}
                </Heading>
            </Box>
            <Box alignSelf="center">
                <Text color="mc_medium" fontSize="16px" fontWeight="700">
                    {strings.backTo}{" "}
                    <Link as={RouterLink} to="/login" textDecor="underline">
                        {strings.loginPage}
                    </Link>
                </Text>
            </Box>
        </SimpleGrid>
    )
}

export default WelcomePage
