export const themeConfig = {
    initialColorMode: "light",
    useSystemColorMode: false,
}

export const fontSizes = {
	Heading: {
		h1: "36px",
		h2: "28px",
		h3: "18px",
	},
}

export const buttonVariant = {
	Button: {
		variants: {
			medium: () => ({
				bg: "mc_medium",
				color: "mc_bg",
				_hover: {
					bg: "mc_medium",
					opacity: ".8",
				},
			}),
			dark: () => ({
				bg: "mc_dark",
				color: "mc_bg",
				_hover: {
					bg: "mc_dark",
					opacity: ".8",
				},
			}),
			red: () => ({
				bg: "mc_errorRed",
				color: "mc_bg",
				_hover: {
					bg: "mc_errorRed",
					opacity: ".8",
				},
			}),
			sideBar: () => ({
				bg: "mc_bg",
				color: "mc_dark",
				_hover: {
					bg: "mc_medium",
					color: "mc_bg",
					opacity: ".8",
				},
			}),
		},
	},
}







