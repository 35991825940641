import { Button } from "@chakra-ui/button"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input"
import { Box, Flex, Heading, Link, Stack, Text } from "@chakra-ui/layout"
import { Link as RouterLink } from "react-router-dom"
import React from "react"
import { ReactComponent as WhiteArrow } from "../../assets/icons/whiteArrow.svg"
import { useForm } from "react-hook-form"
import { strings } from "../../utils/localization"
import useConfig from "../../utils/config"

const ResetPassForm = ({ submit, disabled }) => {
 
 
	const config = useConfig()

    const { register, handleSubmit } = useForm()

    return (
        <Flex justify="center" alignItems="center" h="70vh" direction="column">
            
			<Box mb="10px">
				{config.styledLogo}
			</Box>
           
            <Heading as="h1" letterSpacing="5px" mb="55px">
                {strings.passwordReset}
            </Heading>
            <form onSubmit={handleSubmit(submit)}>
                <Stack w="530px" spacing={3}>
                    <InputGroup>
                        <Input
                            name="email"
                            ref={register({ required: true })}
                            placeholder={strings.emailAddress}
                            type="email"
                            bg="mc_bg"
                            _placeholder={{
                                color: "mc_medium",
                            }}
                            pl="25px"
                            fontWeight="700"
                            fontSize="14px"
                            rounded="xl"
                            boxShadow="0px 2px 12px 4px rgba(34, 178, 186, 0.35)"
                            h="57px"
                            letterSpacing="1px"
                        />
                        <InputRightElement h="100%" w="160px">
                            <Button
                                type="submit"
                                h="100%"
                                w="160px"
                                overflow="hidden"
                                rounded="xl"
                                variant="medium"
                                _hover={{ bg: "mc_dark" }}
								disabled={disabled}
                            >
                                {strings.confirm}
                                <Box ml="15px">
                                    <WhiteArrow />
                                </Box>
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    <Flex
                        color="mc_medium"
                        fontSize="16px"
                        fontWeight="700"
                        flexDirection="column"
                        alignItems="center"
                        gridRowGap="10px"
                    >
                        <Text>
                            {strings.noAccount}{" "}
                            <Link as={RouterLink} to="/registration" textDecor="underline">
                                {strings.clickHere}
                            </Link>
                        </Text>
                        <Text>
                            {strings.backTo}{" "}
                            <Link as={RouterLink} to="/login" textDecor="underline">
                                {strings.loginPage}
                            </Link>
                        </Text>
                    </Flex>
                </Stack>
            </form>
        </Flex>
    )
}

export default ResetPassForm
