import { useDisclosure } from "@chakra-ui/hooks";
import { useDispatch, useSelector } from "react-redux";
import { changeDefaultSize, cleanMessage, createBillboardAd, fetchBillboardPage, fetchOneJob, postJobApplication, removeBillboardAd, updateBillboardAd } from "../reducers/billboardReducer";

export default function useArticlePanel() {

	const dispatch = useDispatch();
	const data = useSelector((state) => state.billboard.data);
	const detail = useSelector((state) => state.billboard.detail);
	const pagination = useSelector((state) => state.billboard.pagination);
	const defaultSize = useSelector((state) => state.billboard.defaultSize);
	const message = useSelector((state) => state.billboard.message);
	const loading = useSelector((state) => state.billboard.loading);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const fetchPage = async (perPage, pageNumber) => {
		dispatch(fetchBillboardPage(perPage, pageNumber))
	}

	const fetchOne = async (id) => {
		return dispatch(fetchOneJob(id))
	}

	const create = async (payload) => {
		return dispatch(createBillboardAd(payload))
	}

	const update = async (payload) => {
		return dispatch(updateBillboardAd(payload))
	}

	const remove = async (id) => {
		return dispatch(removeBillboardAd(id))
	}

	const sendJobApplication = async (payload) => {
		return dispatch(postJobApplication(payload))
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}
	
	const changeSize = async (size) => {
		dispatch(changeDefaultSize(size))
	}

	return {
		data, loading, message, 
		pagination, defaultSize, 
		fetchPage, cleanToast, changeSize,
		remove, create, update, 
		isOpen, onOpen, onClose,
		sendJobApplication, fetchOne, detail 
	}
}