import { Avatar, Box, Button, Flex, Heading, Hide, HStack, Icon, Show, Stack, useDisclosure, Wrap, WrapItem } from "@chakra-ui/react"
import React from "react"
import useDashboard from "../../redux/hooks/useDashboard"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { strings } from "../../utils/localization"
import ChangePassForm from "../forms/ChangePassForm"
import DocumentsForm from "../forms/DocumentsForm"
import FormularForm from "../forms/FormularForm"
import { DocIcon, FormularIcon } from "../Icons"
import AddUpdateModal from "../modals/AddUpdateModal"
import PrivacyPolicy from "../PrivacyPolicy"
import UploadFile from "../UploadFile"
import CategoryButton from "./CategoryButton"
import VisualMainBox from "./VisualMainBox"

const Main = ({ categoriesData, handleProfilePicture, avatarSrc, results, selectData, defaultData }) => {
	const { isOpen: isOpenPolicy, onOpen: onOpenPolicy, onClose: onClosePolicy } = useDisclosure()
	const { isOpen: isOpenDocs, onOpen: onOpenDocs, onClose: onCloseDocs } = useDisclosure()
	const { isOpen: isOpenForms, onOpen: onOpenForms, onClose: onCloseForms } = useDisclosure()
	const hook = useUser()
	const hookD = useDashboard()
	const config = useConfig()

	const handleUpdateProfile = (data) => {
		const payload = {
			username: hook.username,
		}

		Object.keys(data).forEach((key) => {
			if (data[key] && typeof data[key] === "object") {
				payload[`${key}`] = data[key].value
			} else if (data[key]) {
				payload[`${key}`] = data[key]
			} else if (!data[key]) {
				payload[`${key}`] = ""
			}
		})

		hook.update(payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.onClose()
				hook.refreshAccess()
			}
		})
	}

	// console.log(selectData.sectors.find((sector) => sector.value === hook.sector))

	const defaultSector = selectData.sectors.find((sector) => sector.value === hook.sector)

	const formularDefaultValues = {
		first_name: hook.personalInfo.firstName,
		last_name: hook.personalInfo.lastName,
		mpo: defaultSector ? defaultSector.label : "",
		email: hook.personalInfo.email,
		phone_number: hook.personalInfo.phoneNumber,
	}

	return (
		<>
			<Stack spacing={12}>
				<Flex gridRowGap={4} direction={["column", "column", "row"]} align={["center"]} justify={["space-between"]}>
					<HStack spacing={[4, 2]} justify={["space-between"]}>
						<Avatar
							position="relative"
							overflow="hidden"
							display="block"
							mx="auto"
							boxSize={["130px", "150px"]}
							src={avatarSrc ? `${config.baseImageUrl}${avatarSrc}` : ""}
						>
							<Flex
								bg="mc_medium"
								h={["40px", "50px"]}
								w={["120px", "140px"]}
								bottom={["-6px", "-8px"]}
								right={["-0px", "5px"]}
								fontSize="12px"
								position="absolute"
								borderBottomRadius="full"
								pb="28px"
							>
								<UploadFile onChange={handleProfilePicture} />
							</Flex>
						</Avatar>
						<Stack>
							<Heading as="h3" fontSize="xl" fontWeight="bold" color="mc_dark">
								{`${hook.personalInfo.firstName} ${hook.personalInfo.lastName}`}
							</Heading>
							<Button
								onClick={() => hook.onOpen()}
								variant="unstyled"
								textTransform="uppercase"
								fontWeight="bold"
								bg="none"
								color="mc_medium"
								px="16px !important"
								h="28px"
							>
								{strings.updateData}
							</Button>
							<Button
								onClick={() => hook.signout()}
								variant="unstyled"
								textTransform="uppercase"
								fontWeight="bold"
								bg="none"
								color="mc_errorRed"
								px="16px !important"
								h="28px"
							>
								{strings.signOut}
							</Button>
						</Stack>
					</HStack>

					<Flex h="100%" alignSelf="center">
						<Show breakpoint="(max-width: 768px)">
							<Button
								onClick={onOpenPolicy}
								bg="mc_bg"
								h="36px"
								w="120px"
								fontSize="14px"
								boxShadow="0px 2px 12px 2px rgba(66, 146, 220, 0.25)"
							>
								{strings.privacyPolicy}
							</Button>
						</Show>
					</Flex>

					<HStack align="flex-end">
						{config.appId === 2 && (
							<Button
								variant="medium"
								h="46px"
								w="178px"
								fontSize="18px"
								color="mc_bg"
								bg="mc_medium"
								borderRadius="lg"
								leftIcon={<Icon as={FormularIcon} color="mc_bg" boxSize="28px" />}
								onClick={onOpenForms}
							>
								{strings.forms}
							</Button>
						)}
						<Button
							variant="dark"
							h="46px"
							w="178px"
							fontSize="18px"
							color="mc_bg"
							bg="mc_dark"
							borderRadius="lg"
							leftIcon={<Icon as={DocIcon} color="mc_bg" boxSize="28px" />}
							onClick={onOpenDocs}
						>
							{strings.documents}
						</Button>
					</HStack>
				</Flex>

				<Wrap spacing={[2, 5]}>
					{categoriesData.map((category, index) => {
						return (
							<WrapItem
								w={["calc(50% - 8px)", "calc(50% - 20px)", "calc(50% - 20px)", "calc(25% - 20px)"]}
								// w={{
								// 	base: "calc(50% - 8px)",
								// 	sm: "calc(50% - 20px)",
								// 	md: "calc(50% - 20px)",
								// 	lg: "calc(25% - 20px)",
								// 	xl: "calc(25% - 20px",
								// }}
								key={index}
							>
								<CategoryButton
									icon={category.icon}
									title={category.title}
									subtitle={category.subtitle}
									number={category.number}
									action={category.action}
								/>
							</WrapItem>
						)
					})}
				</Wrap>

				<Wrap spacing={5}>
					{hookD.userActivity.map((visualBox, index) => {
						return (
							<WrapItem
								key={index}
								w={["100%", "calc(50% - 20px)", "calc(50% - 20px)", "calc(50% - 20px)", "580px"]}
								// maxW={{
								// 	md: "calc(50% - 20px)",
								// 	lg: "calc(33,3% - 40px)",
								// 	xl: "580px",
								// }}
							>
								<VisualMainBox
									title={visualBox.title}
									resultNumber={visualBox.resultNumber}
									color={visualBox.color}
									resultPercent={visualBox.resultPercent}
									// tooltipLabel={`${strings.visualBox.title}_TP`}
									tooltipLabel={strings[`${visualBox.title}_TP`]}
								/>
							</WrapItem>
						)
					})}
				</Wrap>
				<Hide breakpoint="(max-width: 768px)">
					<Flex w="100%" justify="flex-end">
						<Button
							onClick={onOpenPolicy}
							bg="mc_bg"
							h="42px"
							fontSize="14px"
							// textTransform="capitalize"
							boxShadow="0px 2px 12px 2px rgba(66, 146, 220, 0.25)"
						>
							{strings.privacyPolicy}
						</Button>
					</Flex>
				</Hide>
			</Stack>
			<AddUpdateModal isOpen={isOpenPolicy} onClose={onClosePolicy} size="6xl">
				<Box h="80vh" overflowY="scroll">
					<PrivacyPolicy onClose={onClosePolicy} />
				</Box>
			</AddUpdateModal>
			<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="5xl">
				<ChangePassForm
					onClose={hook.onClose}
					handleUpdateProfile={handleUpdateProfile}
					selectData={selectData}
					defaultData={defaultData}
				/>
			</AddUpdateModal>
			<AddUpdateModal isOpen={isOpenDocs} onClose={onCloseDocs} size="lg">
				<DocumentsForm onClose={onCloseDocs} options={selectData.documents} />
			</AddUpdateModal>
			<AddUpdateModal isOpen={isOpenForms} onClose={onCloseForms} size="2xl">
				<FormularForm onClose={onCloseForms} defaultValues={formularDefaultValues} selectData={selectData} />
			</AddUpdateModal>
		</>
	)
}

export default Main
