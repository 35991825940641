import { Flex, Grid, GridItem, Heading, Icon, IconButton } from "@chakra-ui/react"
import React from "react"
import useConfig from "../../utils/config"
import { strings } from "../../utils/localization"
import {
	Finished15EduAchieveIcon,
	Finished15TestsAchieveIcon,
	Finished20EduAchieveIcon,
	LeftArrow,
	LikesAchieveIcon,
	Logged14daysAchieveIcon,
	Logged7daysAchieveIcon,
	PassedTestsAchieveIcon,
	SeenArticlesAchieveIcon,
} from "../Icons"
import VisualMainBox from "./VisualMainBox"
import VisualSmallBox from "./VisualSmallBox"
import VisualSmallBoxesContainer from "./VisualSmallBoxesContainer"

const achievementsData = [
	{
		title: "Aktivnost",
		value: "6 / 9",
		cluster: [
			{ title: "7 dana uzastopnog logovanja", value: " 7 / 7", color: "#31D888", icon: Logged7daysAchieveIcon },
			{ title: "14 dana uzastopnog logovanja", value: "12 / 14", color: "#31D888", icon: Logged14daysAchieveIcon },
		],
	},
	{
		title: "Novosti",
		value: "0/2",
		cluster: [
			{ title: "10 lajkovanih novosti", value: " 3 / 10", color: "#EC5C5C", icon: LikesAchieveIcon },
			{ title: "20 odgledanih novosti", value: "12 / 20", color: "#EFC42C", icon: SeenArticlesAchieveIcon },
		],
	},
	{
		title: "Edukacije",
		value: "2/2 ",
		cluster: [
			{ title: "15 završenih edukacija", value: "15 / 20", color: "#31D888", icon: Finished15EduAchieveIcon },
			{ title: "20 završenih edukacija", value: "20 / 20", color: "#31D888", icon: Finished20EduAchieveIcon },
		],
	},
	{
		title: "Testovi",
		value: "2/3",
		cluster: [
			{ title: "15 završenih testova", value: "7 / 7", color: "#31D888", icon: Finished15TestsAchieveIcon },
			{ title: "5 položenih testova sa svim tačnim odgovorima", value: "5 / 5", color: "#31D888", icon: PassedTestsAchieveIcon },
			{ title: "15 položenih testova sa svim tačnim odgovorima", value: "6 / 15", color: "#EFC42C", icon: PassedTestsAchieveIcon },
		],
	},
]

const Achievements = ({ data, back }) => {
	const config = useConfig()

	if (data) {
		return (
			<>
				<Flex w="100%" mb={4}>
					<IconButton
						onClick={back}
						aria-label="Back"
						icon={<Icon as={LeftArrow} color="mc_dark" boxSize={5} />}
						bg="transparent"
					/>
					<Heading as="h3" fontSize="32px" fontWeight="bold" color="mc_dark" textAlign="center" w="100%" mb={[4, 0]}>
						{strings.achievements}
					</Heading>
				</Flex>
				<VisualMainBox
					resultNumber={`${data.total_completed} / ${data.total_count}`}
					color="mc_medium"
					resultPercent={data.percent_completed}
					mb={5}
				/>
				<Grid gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))" gap={4}>
					{data.achievements_data.map((achievement, index) => {
						return (
							<GridItem colSpan={1} key={index}>
								<VisualSmallBoxesContainer
									title={strings[achievement.category_code]}
									value={`${achievement.completed} / ${achievement.total}`}
								>
									{achievement.data.map((item, index) => {
										return (
											<VisualSmallBox
												key={index}
												title={`${item.max_points} ${strings[item.group_code]}`}
												icon={config.baseImageUrl + item.image}
												value={`${item.result}/${item.max_points}`}
												color={item.color}
											/>
										)
									})}
								</VisualSmallBoxesContainer>
							</GridItem>
						)
					})}
				</Grid>
			</>
		)
	} else {
		return <></>
	}
}

export default Achievements
