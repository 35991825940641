import { Button } from "@chakra-ui/button"
import { FormLabel } from "@chakra-ui/form-control"
import { FormControl } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Flex } from "@chakra-ui/layout"
import React from "react"
import { useForm } from "react-hook-form"
import { strings } from "../../utils/localization"

const MasterdataForm = ({ updateData, submit, onClose }) => {
    const { register, handleSubmit } = useForm()

    return (
        <form onSubmit={handleSubmit(submit)}>
            <FormControl>
                <FormLabel>{strings.code}</FormLabel>
                <Input
                    name="code"
                    defaultValue={updateData && updateData.code}
                    ref={register({ required: true })}
                />
            </FormControl>
            <FormControl mt={4}>
                <FormLabel>{strings.name}</FormLabel>
                <Input
                    name="name"
                    defaultValue={updateData && updateData.name}
                    ref={register({ required: true })}
                />
            </FormControl>
            <Flex justify="flex-end" mt={4}>
                <Button onClick={onClose} variant="red">
                    {strings.close}
                </Button>
                <Button type="submit" variant="medium" ml={3}>
                    {strings.save}
                </Button>
            </Flex>
        </form>
    )
}

export default MasterdataForm
