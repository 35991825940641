import { Box } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/layout"
import { Link } from "@chakra-ui/layout"
import { Text } from "@chakra-ui/layout"
import { SimpleGrid } from "@chakra-ui/layout"
import { Link as RouterLink } from "react-router-dom"
import { strings } from "../utils/localization"
import Layout from "./layouts/Layout"

const ConfirmEmail = ({ logo }) => {

	return (
		<Layout>
			<SimpleGrid rows={3} spacingY="50px" height="100vh" textAlign="center">
                <Box></Box>
                <Box>
                 
					<Flex justify="center" mb="10px">
						{logo}
					</Flex>
					<Text
                        fontSize="26px"
                        color="mc_dark"
                        textTransform="uppercase"
                        fontWeight="700"
                    >
                        {strings.checkEmailMessage}
                    </Text>
                </Box>
                <Box alignSelf="center">

                    <Text color="mc_medium" fontSize="16px" fontWeight="700">
                        {strings.backTo}{" "}
                        <Link as={RouterLink} to="/login" textDecor="underline">
                            {strings.loginPage}
                        </Link>
                    </Text>
                </Box>
            </SimpleGrid>
		</Layout>
	)


}

export default ConfirmEmail