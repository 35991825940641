import { Button } from "@chakra-ui/button"
import React from "react"
import { strings } from "../utils/localization"
import PrivacyPolicyInEnglish from "./PrivacyPolicyInEnglish"
import PrivacyPolicyText from "./PrivacyPolicyText"

const PrivacyPolicy = ({ onClose }) => {
	return (
		<>
			{strings.getLanguage() === "RS" ? <PrivacyPolicyText /> : <PrivacyPolicyInEnglish />}

			<Button onClick={onClose} display="block" mx="auto" variant="medium" mt="20px">
				{strings.close}
			</Button>
		</>
	)
}

export default PrivacyPolicy
