import React, { useEffect } from "react"
import MasterdataTable from "../../components/tables/MasterdataTable"
import useMasterdata from "../../redux/hooks/useMasterdata"
import {
    title,
    educationCategory,
    position,
    companyType,
    country,
    city,
    tag,
} from "../../utils/constants"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import MasterdataForm from "../../components/forms/MasterdataForm"
import PlusButton from "../../components/buttons/PlusButton"
import { useToast } from "@chakra-ui/toast"
import { handleToast } from "../../utils/functions"
import { Box } from "@chakra-ui/react"

const AdminMasterdataPage = ({ type }) => {
    const hook = useMasterdata()
    const toast = useToast()

    useEffect(() => {
        hook.cleanToast()
        hook.fetch(type)
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) //eslint-disable-line

    let data = []
    if (type === title) {
        data = hook.titles
    } else if (type === educationCategory) {
        data = hook.educationCategories
    } else if (type === position) {
        data = hook.positions
    } else if (type === companyType) {
        data = hook.companyTypes
    } else if (type === country) {
        data = hook.countries
    } else if (type === city) {
        data = hook.cities
    } else if (type === tag) {
        data = hook.tags
    }

    const onSubmit = (data) => {
        hook.create(type, data)
    }

    return (
        <Box
            h="95%"
            overflow="auto"
            border={"4px"}
            boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
            borderColor="mc_black"
            borderRadius="4px"
            w="100%"
        >
            <MasterdataTable data={data} type={type} />
            <PlusButton onClick={() => hook.onOpen()} />
            <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="xl">
                <MasterdataForm submit={onSubmit} onClose={hook.onClose} />
            </AddUpdateModal>
        </Box>
    )
}

export default AdminMasterdataPage
