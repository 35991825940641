import { Box, Flex, Img, Stack, useToast } from "@chakra-ui/react"
import React, { useEffect } from "react"
import BannersForm from "../../components/forms/BannersForm"
import useDataManagement from "../../redux/hooks/useDataManagement"
import { handleToast } from "../../utils/functions"

const AdminBannersPage = () => {
    const hook = useDataManagement()
    const toast = useToast()

    useEffect(() => {
        hook.getBanners()
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.importExportMessage) {
            handleToast(toast, hook.importExportMessage, "1")
        }
    }, [hook.importExportMessage]) //eslint-disable-line

    const firstBanner = hook.banners.find((val) => val.id === 1)
    const secondBanner = hook.banners.find((val) => val.id === 2)
    const thirdBanner = hook.banners.find((val) => val.id === 3)
    const fourthBanner = hook.banners.find((val) => val.id === 4)

    return (

		<Stack w="100%" minW="520px" justify="center" align="center" spacing={6}>
			<Flex
				w={["100%", "100%", "100%", "720px"]}
				h="390px"
				border="2px solid"
				borderColor="mc_dark"
				justify="space-between"
				align="center"
				p="20px"
			>
				<Stack spacing={10}>
					<Box>
						1
						<Flex
							w="230px"
							h="144px"
							border="2px solid"
							borderColor="mc_dark"
							justify="center"
							align="center"
						>
							<Img
								src={firstBanner && firstBanner.image}
								objectFit="cover"
								w="100%"
								h="100%"
							/>
						</Flex>
					</Box>
					<Box>
						2
						<Flex
							w="230px"
							h="144px"
							border="2px solid"
							borderColor="mc_dark"
							justify="center"
							align="center"
						>
							<Img
								src={secondBanner && secondBanner.image}
								objectFit="cover"
								w="100%"
								h="100%"
							/>
						</Flex>
					</Box>
				</Stack>
				<Stack spacing={10}>
					<Box>
						3
						<Flex
							w="230px"
							h="144px"
							border="2px solid"
							borderColor="mc_dark"
							justify="center"
							align="center"
						>
							<Img
								src={thirdBanner && thirdBanner.image}
								objectFit="cover"
								w="100%"
								h="100%"
							/>
						</Flex>
					</Box>
					<Box>
						4
						<Flex
							w="230px"
							h="144px"
							border="2px solid"
							borderColor="mc_dark"
							justify="center"
							align="center"
						>
							<Img
								src={fourthBanner && fourthBanner.image}
								objectFit="cover"
								w="100%"
								h="100%"
							/>
						</Flex>
					</Box>
				</Stack>
			</Flex>
			<Flex justify="center" w={["100%", "100%", "100%", "100%", "60%"]}>
				{hook.banners.length > 0 && <BannersForm banners={hook.banners} />}
			</Flex>
		</Stack>
    )
}

export default AdminBannersPage
