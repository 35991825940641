export const slovenian = () => {
	return {
		showMore: "Pokaži več",
		edit: "Uredi",
		educationAuthorIs: "To izobraževanje je pripravilo podjetje:",
		startTest: "test za zagon",
		didTest: "Naredil si test",
		maxTimes: "največje število krat",
		endTest: "DOPOLNITE TEST",
		requiredFieldsMessage: "Polja, označena z *, so obvezna!",
		allRequiredFieldsMessage: "Vsa polja so obvezna!",
		requiredTextOrImageMessage: "Niste vnesli nobenega besedila ali slike!",
		requiredTextMessage: "Niste vnesli nobenega besedila!",
		requiredTextOfAllAnswersMessage: "Izpolnite besedilo vseh odgovorov!",
		oneCorrectAnswerMessage: "Samo en odgovor je lahko pravilen!",
		atLeastOneCorrectAnswerMessage: "Vsaj en odgovor mora biti pravilen!",
		requiredImageMessage: "Slika mora biti izbrana!",
		requiredQuestionTextMessage: "Vnesite besedilo vprašanja!",
		requiredEducationSlideNumberMessage: "Vnesite številko izobraževalnega diapozitiva!",
		requiredAllQuestions: "Izpolnite vsa vprašanja!",
		passwordDontMatchMessage: "Geslo se ne ujema!",
		noDataSelectedMessage: "Podatki niso izbrani!",
		noReportSelectedMessage: "Poročilo ni izbrano!",
		noTestSelectedMessage: "Test ni izbran!",
		noTypeSelectedMessage: "Vrsta ni izbrana!",
		noArticleSelected: "Novice ni izbran!",
		noSurveySelected: "Anketa ni izbrana!",
		noJobSelected: "Delo ni izbrano!",
		notEnoughReportDataMessage: "Za izbrano poročilo ni dovolj podatkov",
		enterCommentMessage: "Vnesite komentar!",
		leaveComment: "PUSTITE KOMENTAR",
		report: "POROČILO",
		test: "PRESKUS",
		type: "TIP",
		from: "OD",
		to: "TO",
		previous: "Prejšnja",
		next: "Naslednja",
		page: "stran",
		of: "od",
		generate: "GENERAT",
		choosePlaceholder: "Izberite ...",
		name: "NAME",
		title: "NASLOV",
		education: "Izobraževanje",
		category: "KATEGORIJA",
		application: "PRIJAVA",
		company: "PODJETJE",
		survey: "ANKETA",
		country: "DRŽAVA",
		city: "MESTO",
		jobTitle: "NASLOV",
		position: "POLOŽAJ",
		language: "JEZIK",
		phoneNumber: "TELEFONSKA ŠTEVILKA",
		licenceNumber: "ŠTEVILKA LICENCE",
		biography: "BIOGRAFIJA",
		slideText: "SLIDE BESEDILO",
		image: "IMAGE",
		question: "SLIKA",
		questions: "VPRAŠANJA",
		educationSlide: "STRAN IZOBRAŽEVANJA",
		answers: "ODGOVORI",
		summaryText: "POVZETEK BESEDILA",
		coverPhoto: "FOTOGRAFIJA OGLASA",
		content: "VSEBINA",
		status: "STANJE",
		close: "ZAPRTO",
		back: "NAZAJ",
		forward: "NAPREJ",
		save: "SHRANI",
		password: "GESLO",
		repeatPassword: "PONOVITE GESLO",
		newPassword: "NOVO GESLO",
		newPasswordRepeat: "PONOVITE NOVO GESLO",
		code: "KODA",
		companyType: "VRSTA PODJETJA",
		firstName: "IME",
		lastName: "PRIIMEK",
		username: "IME UPORABNIKA",
		email: "E-MAIL",
		supervisor: "NADZORNIK",
		users: "UPORABNIKI",
		reports: "POROČILA",
		importExport: "UVOZNO-IZVOZNIH",
		masterData: "GLAVNI PODATKI",
		countries: "DRŽAVE",
		jobTitles: "NASLOVI",
		positions: "POLOŽAJI",
		companyTypes: "VRSTE PODJETJA",
		cities: "MESTA",
		companies: "PODJETJA",
		educationCategories: "KATEGORIJE IZOBRAŽEVANJA",
		articleCategories: "KATEGORIJE NOVICE",
		languages: "JEZIKI",
		article: "NOVICE",
		articles: "NOVICE",
		surveys: "ANKETA",
		educations: "IZOBRAŽEVANJA",
		tests: "PRESKUSI",
		panel: "PANEL",
		signOut: "ODJAVA",
		signIn: "VPIS",
		controlPanel: "KONTROLNI PANEL",
		profile: "PROFIL",
		cancel: "ODPOVED",
		delete: "IZBRIŠI",
		deleteQuestion: "IZBRIŠI VPRAŠANJE",
		deleteWarningMessage: "Če izbrišete ta element, ga ne boste mogli obnoviti!",
		yes: "DA",
		no: "ŠT",
		creationDate: "DATUM NASTANKA",
		views: "POGLEDI",
		reactions: "REAKCIJE",
		comments: "PRIPOMBE",
		registrationTime: "ČAS PRIJAVE",
		lastLogin: "ZADNJA PRIJAVA",
		active: "AKTIVNO",
		congratulations: "ČESTITKE",
		completedTest: "PREIZKUS STE USPEŠNO OPRAVILI",
		monthlyReport: "Mesečno poročilo",
		fileSelected: "Datoteka je izbrana",
		chooseCsvFile: "Izberite datoteko .csv",
		photo: "Fotografija",
		selectPhoto: "Izberite fotografijo",
		noFileSelectedMessage: "Datoteka ni izbrana!",
		exportData: "IZVOZNI PODATKI",
		export: "IZVOZ",
		importData: "UVOZI PODATKE",
		import: "UVOZ",
		confirm: "POTRDITE",
		noComments: "Brez komentarja",
		loadMore: "NALOŽI VEČ",
		author: "AVTOR",
		notFoundMessage: "Strani, ki jo iščete, ni bilo mogoče najti",
		updateData: "POSODOBITE PODATKE",
		privacyPolicy: "POLITIKA ZASEBNOSTI",
		result: "REZULTAT",
		serverErrorMessage: "Napaka strežnika!",
		wrongCredentialsMessage: "Najden ni bil noben račun z danimi poverilnicami!",
		noAccount: "Nimate računa?",
		clickHere: "Klikni tukaj",
		forgotPassword: "Ste pozabili geslo?",
		backTo: "Nazaj k",
		loginPage: "stran za prijavo",
		emailAddress: "EMAIL NASLOV",
		passwordReset: "RESETIRANJE GESLA",
		registration: "PRIJAVITE SE",
		noMore: "Nič več!",
		welcomeTo: "Dobrodošli v",
		byUser: "UPORABNIK",
		byCity: "PO MESTU",
		byQuestions: "PO VPRAŠANJIH",
		loginCount: "Število prijav",
		articleViews: "Pogledi na novice",
		articleReactions: "Odzivi na novice",
		educationViews: "Pogledi na izobraževanje",
		passedTests: "Opravljeni testi",
		monthly: "MESEČNO",
		eduAll: "VSE",
		eduChoose: "IZBERITE",
		eduChooseCompleted: "IZBERITE KONČANO",
		eduChooseNotCompleted: "IZBERITE NI DOKONČANO",
		noEduSelectedMessage: "Izobrazba ni izbrana!",
		deleteSlide: "IZBRIŠI SLID",
		cantDeleteFirst: "Ne morete izbrisati prvega diapozitiva!",
		agreePrivacyPolicy: "Strinjam se s politiko zasebnosti",
		articleAll: "VSE",
		articleByUserReports: "POROČILA UPORABNIKOV",
		imageMissing: "Slika nekje manjka!",
		monthlyPoints: "TOČKE ZA MESEC:",
		annualPoints: "TOČKE ZA TO LETO:",
		notePlaceholder: "Opomba...",
		wrongAppMessage: "Poskušate dostopati do napačne platforme!",
		selectFile: "Izberite datoteko",
		searchPlaceholder: "Iskanje...",
		repeatTest: "PONOVITE TEST",
		usersUpdate: "POSODOBITEV ZA UPORABNIKE",
		total: "TOTAL",
		track: "TRACK",
		loginData: "LOGIN DATA",
		checkEmailMessage: "Odprite e-poštno povezavo za potrditev registracije (preverite neželeno pošto)",
		subquestion: "PODVPRAŠANJE",
		comment: "KOMENTAR",
		reject: "ZAVRNI",
		approve: "ODOBRITE",
		tags: "OZNAKE",
		deadline: "rok",
		applicationDeadline: "rok za prijavo",
		jobListings: "OBVESTILO ZA DELOVANJE",
		apply: "prijavite se",
		openFormToApply: "Odprite prijavni obrazec",
		uploadYourCV: "naložite svoj CV",
		uploadFile: "Naloži datoteko",
		yourCVIsUploaded: "vaš CV je naložen",
		applied: "REGISTRIRAN SI",
		jobs: "SLUŽBE",
		job: "DELO",
		note: "Opomba",
		newEmployees: "Novi zaposleni",
		newEmployee: "NOVI ZAPOSLENI",
		updateEmploymentStatus: "Posodobite zaposlitveni status",
		banner: "oglas",
		url: "url-povezava",
		expirationDate: "ROK TRAJANJA",
		expired: "POTEKEL",
		days: "DNEVI",
		hours: "URE",
		useCustomTheme: "uporabite temo po meri",
		chooseTheColorOfTheApplication: "izberite barvo aplikacije",
		selectTheApplicationLogo: "izberite logotip aplikacije",
		selectTheApplicationFont: "izberite pisavo aplikacije",
		currentLogo: "trenutni logotip",
		font: "pisava",
		black: "črna",
		background: "ozadje",
		dark: "temna",
		medium: "srednje",
		light: "svetla",
		red: "rdeča",
		assignedToUsers: "DODELJENO UPORABNIKOM",
		testAnswerQuestion: "Odgovori na vprašanje",
		testMaxRepeatNumberReached: "Test ste opravili največkrat",
		selectDocument: "Izberite dokument",
		UA_LOGIN_ACTIVITY: "Dejavnost",
		UA_NEWS_VIEWS: "Novice",
		UA_NEWS_REACTIONS: "Reakcije",
		UA_EDUCATION_VIEWS: "Izobraževanja",
		UA_TESTS: "Testi",
		UA_LOGIN_ACTIVITY_TP: "Dejavnost uporabnikov v zadnjih 100 dneh",
		UA_NEWS_VIEWS_TP: "Novice, videne v zadnjih 100 dneh",
		UA_NEWS_REACTIONS_TP: "Število reakcij v zadnjih 100 dneh",
		UA_EDUCATION_VIEWS_TP: "Uspešno opravljena izobraževanja v preteklih 100 dneh",
		UA_TESTS_TP: "Aktivnost opravljenih testov v zadnjih 100 dneh",
		ACTIVITY: "Dejavnost",
		NEWS: "Novice",
		EDUCATIONS: "Izobraževanja",
		TESTS: "Testi",
		achievements: "Dosežki",
		ACTIVITY_DAYS: "dni zaporedne prijave",
		EDUCATIONS_FINISHED: "končana izobraževanja",
		NEWS_LIKED: "všeč novice",
		NEWS_VIEWED: "ogledali novice",
		TESTS_SUCCESS_FINISHED: "opravil teste z vsemi pravilnimi odgovori",
		TESTS_FINISHED: "opravljene teste",
		numberOfAchievements: "Število dosežkov",
		currentRank: "Trenutni rang",
		numberOfUnread: "Število neprebranih",
		numberOfNotCompleted: "Število nedokončanih",
		documents: "Dokumenti",
		document: "Dokument",
		download: "Prenesi",
		completedAllEdu: "Izpolnili ste vse",
		readAllNews: "Vse ste prebrali",
		forms: "Obrazci",
		month: "Mesec",
		year: "Leto",
		absenceRequests: "ZAHTEVE ZA ODSOTNOST",
		absenceRequestType: "VRSTA ODSOTNOSTI",
		byAssignedUser: "OD DODELJENEGA UPORABNIKA",
		bySubQuestions: "PO PODVPRAŠANJU",
		lockedEduMessage:
			"Spoštovani uporabnik, dokler ste novozaposleni, morate izvajati izobraževanja za nove zaposlene, da imate dostop do drugih izobraževanj.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Zahteva za upravno prepoved",
		FORM_PZDF: "Vloga za skupno rabo letakov",
		contactPhoneNumber: "KONTAKTIRAJTE TELEFONSKO ŠTEVILKO",
		FORM_TK: "Racing Club",
		FORM_ZPOZ: "Zahteva za potrdila o zaposlitvi",
		bank: "BANKA",
		certificationPurpose: "NAMEN CERTIFICIRANJA",
		documentType: "VRSTA DOKUMENTA",
		documentVerification: "DOKUMENT ZA PREVERJANJE",
		loanStatement: "IZJAVA ZA NEODVISNO ODPLAČILO POSOJILA",
		FORM_ZZO: "Zahteve",
		leaveType: "VRSTA DOPUSTA",
		dateFrom: "DATUM OD",
		dateTo: "DATUM DO",
		absenceDocument: "DOKUMENT ZA ODSOTNOST",
		FORM_PK: "Zmagovalna kombinacija",
		commentNote: "KOMENTAR/OPOMBA",
		recommendation: "PRIPOROČILO",
		FORM_IZ: "Pobude zaposlenih",
		initiative: "POBUDA",
		choose: "Izberite",
	}
}
