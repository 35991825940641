import { useDisclosure } from "@chakra-ui/hooks"
import { Flex, Grid, Heading, Stack } from "@chakra-ui/layout"
import { Button } from "@chakra-ui/react"
import { Textarea } from "@chakra-ui/textarea"
import { useToast } from "@chakra-ui/toast"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { WhiteArrowIcon } from "../components/Icons"
import SlideModal from "../components/modals/SlideModal"
import Answer from "../components/tests/Answer"
import EducationPointer from "../components/tests/EducationPointer"
import FinishTest from "../components/tests/FinishTest"
import Question from "../components/tests/Question"
import ResultBox from "../components/tests/ResultBox"
import useEducation from "../redux/hooks/useEducation"
import useForms from "../redux/hooks/useForms"
import useTest from "../redux/hooks/useTest"
import { handleToast } from "../utils/functions"
import { strings } from "../utils/localization"

const TestPage = () => {
	const hook = useTest()
	const hookAnswers = useForms()
	const hookEdu = useEducation()
	const toast = useToast()
	let navigate = useNavigate()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [currentId, setCurrentId] = useState(0)

	const test = hook.detail

	useEffect(() => {
		if (test && test.answers) {
			test.answers.map((answer, i) => {
				const payload = {
					serial_num: answer.serial_num,
					text: answer.question,
					testResult: answer.answers,
					userResult: {},
					finalResult: false,
					id: answer.id,
					subquestion: "",
					confirmed: false,
				}
				hookAnswers.addTestAnswer(payload)
			})
		} else {
			navigate("/educations")
		}
	}, [])

	const handleClick = (payload) => {
		hookAnswers.handleUserAnswer(payload)
	}

	const handleEnd = () => {
		let payload = {
			test: test.test.id,
			serial_num: test.test.user_test_attempts,
			education: hookEdu.detail.education.id,
		}

		let answers = {}
		let answers_result = {}
		let sub_questions_result = {}
		hookAnswers.testAnswers.map((answer, i) => {
			answers[`${answer.id}`] = answer.finalResult
			sub_questions_result[`${answer.id}`] = answer.subquestion
			answers_result[`${answer.id}`] = answer.userResult
		})

		payload["answers"] = answers
		payload["answers_result"] = answers_result
		payload["sub_question_result"] = sub_questions_result

		hook.end(payload)
	}

	const handleRedirect = () => {
		navigate("/educations")
	}

	const handleReset = () => {
		hookAnswers.cleanAnswers()
		hook.fetchOne(test.test.education).then((res) => {
			if (res !== undefined && res.data.test.user_allowed_test) {
				const test = res.data.test
				hook.start({
					test: test.id,
					serial_num: test.user_test_attempts,
					education: hookEdu.detail.education.id,
				})
				res.data.answers.map((answer, i) => {
					const payload = {
						serial_num: answer.serial_num,
						text: answer.question,
						testResult: answer.answers,
						userResult: {},
						finalResult: false,
						id: answer.id,
						subquestion: "",
						confirmed: false,
					}
					hookAnswers.addTestAnswer(payload)
				})
				setCurrentId(0)
			} else {
				handleToast(toast, { text: strings.testMaxRepeatNumberReached, type: "info" }, "1")
			}
		})
	}

	const handleNext = () => {
		if (hookAnswers.testAnswers.length === currentId) {
			handleToast(toast, { text: strings.testAnswerQuestion, type: "error" }, "2")
		} else {
			setCurrentId(currentId + 1)
		}
	}

	const handleConfirm = (id) => {
		let currentQuestion = hookAnswers.testAnswers.find((answer) => answer.id === id)
		let testCorrectAnswers = {}
		Object.keys(currentQuestion.testResult).map((key, i) => {
			if (currentQuestion.testResult[key]) {
				return (testCorrectAnswers[key] = true)
			}
		})
		hookAnswers.confirmUserAnswer({
			id: id,
			finalResult: JSON.stringify(testCorrectAnswers) === JSON.stringify(currentQuestion.userResult) ? true : false,
		})
	}

	const checkEduPointer = () => {
		if (hookAnswers.testAnswers.length > 0) {
			const answer = hookAnswers.testAnswers.find((answer, i) => i === currentId)
			if (answer !== undefined && answer.confirmed && !answer.finalResult) {
				const test = hook.detail.answers.find((element, i) => element.id === answer.id)
				if (test.education_slide_image !== undefined) {
					return (
						<EducationPointer
							gridColumnStart={["1", "1", "3"]}
							my="20px"
							imgUrl={test.education_slide_image}
							onClick={() => onOpen()}
						/>
					)
				}
			}
		}
	}

	const checkModal = () => {
		if (hookAnswers.testAnswers.length > 0) {
			const answer = hookAnswers.testAnswers.find((answer, i) => i === currentId)
			if (answer !== undefined) {
				const test = hook.detail.answers.find((element, i) => element.id === answer.id)
				return (
					<SlideModal
						heading={test.education_slide_text}
						imgUrl={test.education_slide_image}
						isOpen={isOpen}
						onClose={onClose}
						size="4xl"
					/>
				)
			}
		}
	}

	const checkAnswered = (id, key) => {
		let current = hookAnswers.testAnswers.find((answer) => answer.id === id)
		if (current && current.userResult[key]) {
			return true
		} else {
			return false
		}
	}

	return (
		<>
			<>
				<Grid
					templateColumns={["1fr", "1fr", "1fr", "repeat(3,1fr)"]}
					gridTemplateRows="auto"
					// minH="90vh"
					h="auto"
					justifyItems="center"
					alignItems="center"
				>
					<Flex
						gridColumnStart={["1", "1", "1", "2"]}
						maxW="700px"
						minW={["auto", "auto", "700px"]}
						h="auto"
						rounded="2xl"
						// boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
						// bg="mc_bg"
						p={["10px", "20px"]}
						direction="column"
						justify="center"
						alignItems="center"
						gridRowGap="20px"
					>
						{test &&
							test.answers.map((question, i) => {
								return (
									<Question display={currentId === i ? "block" : "none"} text={question.question} img={question.image} />
								)
							})}
						<Stack direction="row" justify="center">
							{test &&
								!(test.answers.length === currentId) &&
								hookAnswers.testAnswers.map((answer, i) => answer.confirmed && <ResultBox result={answer.finalResult} />)}
						</Stack>

						<Stack w="100%">
							{test && test.answers.length === currentId ? (
								<FinishTest
									answers={hookAnswers.testAnswers}
									endTest={handleEnd}
									handleRedirect={() => handleRedirect()}
									handleReset={() => handleReset()}
								/>
							) : (
								test &&
								hookAnswers.testAnswers.map((question, i) =>
									question.serial_num === currentId + 1
										? Object.keys(question.testResult).map((key, i) => (
												<Answer
													text={key}
													handleClick={() =>
														handleClick({
															key: key,
															value: true,
															id: question.id,
															type: checkAnswered(question.id, key) ? "remove" : "add",
														})
													}
													answered={checkAnswered(question.id, key)}
													confirmed={question.confirmed}
													testResult={question.testResult}
													userResult={question.userResult}
													answerKey={key}
												/>
										  ))
										: null
								)
							)}
						</Stack>
						{test &&
							test.answers.map((question, i) => {
								if (
									question.subquestion &&
									hookAnswers.testAnswers[currentId] !== undefined &&
									hookAnswers.testAnswers[currentId].confirmed
								) {
									return (
										<Stack w="100%" mt="10px" display={currentId === i ? "flex" : "none"}>
											<Heading as="h4" fontSize="14px" textTransform="uppercase" color="mc_dark">
												{question.subquestion}
											</Heading>

											<Textarea
												color="mc_dark"
												rounded="xl"
												w="100%"
												size="xl"
												resize="none"
												bg="mc_bg"
												p="10px"
												boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
												onChange={(e) => hookAnswers.updateSubquestion(i, e.target.value)}
											/>
										</Stack>
									)
								} else {
									return null
								}
							})}

						{hookAnswers.testAnswers.map((question, i) =>
							question.serial_num === currentId + 1 && !question.confirmed ? (
								<Button variant="medium" onClick={() => handleConfirm(question.id)}>
									{strings.confirm}
								</Button>
							) : question.serial_num === currentId + 1 && question.confirmed ? (
								<Button variant="medium" onClick={() => handleNext()}>
									<WhiteArrowIcon fill="mc_bg" w="80px" />
								</Button>
							) : (
								<></>
							)
						)}
					</Flex>
					{checkEduPointer()}
					{checkModal()}
				</Grid>
			</>
		</>
	)
}

export default TestPage
