import { Text } from "@chakra-ui/layout"
import { NavLink, useLocation } from "react-router-dom"

const AdminPanelItem = ({ text, path }) => {
	const urlName = useLocation().pathname

	return (
		<NavLink to={path} className={urlName === path ? "selected" : ""}>
			<Text
				variant="sideBar"
				boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
				rounded="xl"
				fontSize="14px"
				h="30px"
				p="5px"
				mb="8px"
				cursor="pointer"
				bg="inherit"
				textTransform="uppercase"
				_hover={{
					bg: "mc_medium",
					color: "mc_bg",
					opacity: ".8",
				}}
			>
				{text}
			</Text>
		</NavLink>
	)
}

export default AdminPanelItem
