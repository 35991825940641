import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { strings } from "../../utils/localization"
import { educationPageAdminURL, educationPageUserURL, educationSingleURL, educationsNewEmployeeURL, educationURL } from "../api_urls"

const START_REQUEST = createAction("EDUCATION/START_REQUEST")
const FETCH_SUCCESS = createAction("EDUCATION/FETCH_SUCCESS")
const FETCH_SUCCESS_NEW_EMPLOYEE = createAction("EDUCATION/FETCH_SUCCESS_NEW_EMPLOYEE")
const FETCH_ONE = createAction("EDUCATION/FETCH_ONE")
const REQUEST_SUCCESS = createAction("EDUCATION/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("EDUCATION/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("EDUCATION/CLEAN_MESSAGE")
const CLEAN_DETAIL = createAction("EDUCATION/CLEAN_DETAIL")
const CLEAN_EDUCATIONS = createAction("EDUCATION/CLEAN_EDUCATIONS")
const CHANGE_SIZE = createAction("EDUCATION/CHANGE_SIZE")

export const fetchEducationPage = (perPage, pageNumber, role, filterData) => async (dispatch) => {
	dispatch(START_REQUEST())

	let userUrl = educationPageUserURL(perPage, pageNumber)
	if (filterData) {
		if (filterData.name) {
			userUrl = userUrl + `&name=${filterData.name}`
		}

		if (filterData.completed) {
			userUrl = userUrl + "&completed=True"
		}

		if (filterData.eduCategory) {
			userUrl = userUrl + `&category=${filterData.eduCategory}`
		}
	}

	return axios
		.get(role ? educationPageAdminURL(perPage, pageNumber) : userUrl)
		.then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchNewEmployeesEdu = () => async (dispatch) => {
	dispatch(START_REQUEST())

	return axios.get(educationsNewEmployeeURL).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS_NEW_EMPLOYEE, REQUEST_FAIL))
}

export const fetchEducation = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(educationSingleURL(id)).then((res) => handleResponse(res, dispatch, FETCH_ONE, REQUEST_FAIL))
}

export const createEducation = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios
		.post(educationURL, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateEducation = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios
		.put(educationURL, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeEducation = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(educationSingleURL(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

export const cleanEducation = () => async (dispatch) => {
	dispatch(CLEAN_DETAIL())
}

export const cleanEducations = () => async (dispatch) => {
	dispatch(CLEAN_EDUCATIONS())
}

export const changeDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	newEmployeeData: [],
	pagination: null,
	detail: null,
	defaultSizeAdmin: 25,
	defaultSizeUser: 10,
	message: null,
	loading: false,
}

export const educationReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.educations
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_SUCCESS_NEW_EMPLOYEE, (state, action) => {
			state.loading = false
			state.newEmployeeData = action.payload.educations
		})
		.addCase(FETCH_ONE, (state, action) => {
			state.loading = false
			state.detail = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CLEAN_DETAIL, (state, action) => {
			state.detail = null
		})
		.addCase(CLEAN_EDUCATIONS, (state, action) => {
			state.data = []
			state.pagination = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSizeAdmin = action.payload
		})
})
