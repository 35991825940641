import RegistrationForm from "../components/forms/RegistrationForm"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import useUser from "../redux/hooks/useUser"
import { handleToast } from "../utils/functions"
import useMasterdata from "../redux/hooks/useMasterdata"
import { makeDropdownOptions } from "../utils/functions"
import ConfirmEmail from "../components/ConfirmEmail"
import { useOutletContext } from "react-router-dom"

const RegistrationPage = () => {
    const hook = useUser()
	const hookMd = useMasterdata()
    const toast = useToast()
	const config = useOutletContext()

	const [countries, setCountries] = useState(null)
    const [positions, setPositions] = useState(null)
    const [cities, setCities] = useState(null)
	const [titles, setTitles] = useState(null)
	const [companies, setCompanies] = useState(null)
	const [confirmed, setConfirmed] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [privacyAgree, setPrivacyAgree] = useState(false)
	const [error, setError] = useState(false)

    useEffect(() => {
        hook.cleanToast()
		hookMd.fetchPublic()
		
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
			handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) //eslint-disable-line

	useEffect(() => {
		makeDropdownOptions(hookMd.countries, setCountries)
		makeDropdownOptions(hookMd.positions, setPositions)
		makeDropdownOptions(hookMd.cities, setCities)
		makeDropdownOptions(hookMd.titles, setTitles)
		makeDropdownOptions(hookMd.companies, setCompanies)
	}, [hookMd.countries, hookMd.positions, hookMd.cities, hookMd.titles, hookMd.companies])

	const selectData = {
		countries: countries,
		positions: positions,
		cities: cities,
		titles: titles,
		companies: companies
	}

	const submit = (data) => {
		if(data.password !== data.repeated_password) {
			handleToast(toast, {type: "error", text: "Passwords do not match!"}, "1")
		}else if(!privacyAgree){
			setError(true)
			handleToast(toast, {type: "error", text: "Privacy policy is required!"}, "2")
		}else{

			const payload = {}

			Object.keys(data).map((key, i) => 
			data[key] !== undefined && typeof data[key] === "object" ?
				payload[`${key}`] = data[key].value :
			data[key] ?
				payload[`${key}`] = data[key] : null
			)
			
			setButtonDisabled(true)
			hook.register(payload).then((res) => {
				if(res !== undefined) {
					if(res.status === 200) {
						setConfirmed(true)
					}else{
						setButtonDisabled(false)
					}
				}else{
					setButtonDisabled(false)
				}
			})
		}
	}

	if(confirmed) {
		return <ConfirmEmail logo={config.styledLogo} />
	}else{
		return (
			<RegistrationForm 
				submit={submit} 
				selectData={selectData} 
				disabled={buttonDisabled}
				privacyAgree={privacyAgree}
				setPrivacyAgree={setPrivacyAgree}
				error={error}
			/>
		)
	}

}

export default RegistrationPage
