import { Button } from "@chakra-ui/button"
import { FormControl } from "@chakra-ui/form-control"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input"
import { Box, Flex, Heading, Link, Stack, Text } from "@chakra-ui/layout"
import { Checkbox, FormErrorMessage, useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { Link as RouterLink } from "react-router-dom"
import * as yup from "yup"
import { ReactComponent as WhiteArrow } from "../../assets/icons/whiteArrow.svg"
import { Colors } from "../../GlobalStyledComponents"
import useConfig from "../../utils/config"
import { useYupValidationResolver } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { HidePasswordIcon, ShowPasswordIcon } from "../Icons"
import AddUpdateModal from "../modals/AddUpdateModal"
import PrivacyPolicy from "../PrivacyPolicy"
import SelectDropdown from "../SelectDropdown"

const CustomInput = ({ ...props }) => {
	return (
		<Input
			bg="mc_bg"
			_placeholder={{
				color: "mc_medium",
				fontWeight: "700",
			}}
			pl="15px"
			fontWeight="normal"
			fontSize="16px"
			rounded="xl"
			boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
			h="40px"
			letterSpacing="1px"
			{...props}
		/>
	)
}

const RegistrationForm = ({ selectData, submit, disabled, privacyAgree, setPrivacyAgree, error }) => {
	const colors = Colors()
	const config = useConfig()

	const [showNew, setShowNew] = useState(false)
	const [showRepeatNew, setShowRepeatNew] = useState(false)
	const handleClickOnNew = () => setShowNew(!showNew)
	const handleClickOnRepeatNew = () => setShowRepeatNew(!showRepeatNew)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const validationSchema = yup.object().shape({
		first_name: yup.string().required("First name is required!").min(3, "Minimum 3 letters.").max(21, "Maximum 21 letters.").nullable(),
		last_name: yup.string().required("Last name is required!").min(3, "Minimum 3 letters.").max(21, "Maximum 21 letters.").nullable(),
		username: yup.string().required("Username is required!").max(21, "Maximum 21 letters.").nullable(),
		email: yup.string().required("E-mail is required!").email("E-mail is not valid").nullable(),
		password: yup.string().required("Password is required!").min(3, "Minimum 3 letters.").max(21, "Maximum 21 letters.").nullable(),
		repeated_password: yup
			.string()
			.required("Repeated password is required!")
			.min(3, "Minimum 3 letters.")
			.max(21, "Maximum 21 letters.")
			.nullable(),
		country: yup.object().required("Country is required!"),
		city: yup.object().required("City is required!"),
		company: yup.object().required("Company is required!"),
		title: yup.object().required("Title is required!"),
		position: yup.object().required("Position is required!"),
	})

	const resolver = useYupValidationResolver(validationSchema)

	const { handleSubmit, control, errors } = useForm({
		resolver: resolver,
		mode: "onSubmit",
		reValidateMode: "onChange",
		defaultValues: {
			agreePrivacy: false,
		},
		criteriaMode: "firstError",
		shouldFocusError: true,
		shouldUnregister: true,
	})

	return (
		<Flex justify="center" alignItems="center" minH="100vh" py="30px" direction="column">
			<Box mb="10px">{config.styledLogo}</Box>

			<Heading as="h1" letterSpacing="5px" mb="40px">
				{strings.registration}
			</Heading>
			<form onSubmit={handleSubmit(submit)}>
				<Flex gridGap={["12px", "12px", "12px", "50px"]} mb="30px" direction={["column", "column", "column", "row"]} align="center">
					<Stack minW="310px" w={["90vw", "530px"]} spacing={8}>
						<FormControl isInvalid={errors.first_name}>
							<Controller as={<CustomInput />} control={control} name="first_name" placeholder={`${strings.firstName} *`} />
							{errors.first_name !== undefined && (
								<FormErrorMessage color="red">{errors.first_name.message}</FormErrorMessage>
							)}
						</FormControl>
						<FormControl isInvalid={errors.last_name}>
							<Controller as={<CustomInput />} control={control} name="last_name" placeholder={`${strings.lastName} *`} />
							{errors.last_name !== undefined && <FormErrorMessage color="red">{errors.last_name.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.username}>
							<Controller as={<CustomInput />} control={control} name="username" placeholder={`${strings.username} *`} />
							{errors.username !== undefined && <FormErrorMessage color="red">{errors.username.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.email}>
							<Controller
								as={<CustomInput />}
								control={control}
								name="email"
								placeholder={`${strings.email} *`}
								type="email"
							/>
							{errors.email !== undefined && <FormErrorMessage color="red">{errors.email.message}</FormErrorMessage>}
						</FormControl>
						<InputGroup size="md">
							<FormControl isInvalid={errors.password}>
								<Controller
									as={<CustomInput />}
									control={control}
									name="password"
									placeholder={`${strings.password} *`}
									type={showNew ? "text" : "password"}
								/>
								<InputRightElement width="4.5rem">
									<Button h="38px" size="md" variant="bg" onClick={handleClickOnNew}>
										{showNew ? <HidePasswordIcon fill="mc_dark" /> : <ShowPasswordIcon fill="mc_dark" />}
									</Button>
								</InputRightElement>
								{errors.password !== undefined && (
									<FormErrorMessage color="red">{errors.password.message}</FormErrorMessage>
								)}
							</FormControl>
						</InputGroup>
						<InputGroup size="md">
							<FormControl isInvalid={errors.repeated_password}>
								<Controller
									as={<CustomInput />}
									control={control}
									name="repeated_password"
									placeholder={`${strings.repeatPassword} *`}
									type={showRepeatNew ? "text" : "password"}
								/>
								<InputRightElement width="4.5rem">
									<Button h="38px" size="md" variant="bg" onClick={handleClickOnRepeatNew}>
										{showRepeatNew ? <HidePasswordIcon fill="mc_dark" /> : <ShowPasswordIcon fill="mc_dark" />}
									</Button>
								</InputRightElement>
								{errors.repeated_password !== undefined && (
									<FormErrorMessage color="red">{errors.repeated_password.message}</FormErrorMessage>
								)}
							</FormControl>
						</InputGroup>
						<Controller as={<CustomInput />} control={control} name="phone_number" placeholder={strings.phoneNumber} />
					</Stack>
					<Stack minW="310px" w={["90vw", "530px"]} spacing={8}>
						<FormControl isInvalid={errors.country}>
							<Controller
								as={<SelectDropdown />}
								control={control}
								name="country"
								options={selectData.countries}
								placeholder={
									<div style={{ color: colors.colors.mc_medium, fontWeight: "700" }}>{`${strings.country} *`}</div>
								}
							/>
							{errors.country !== undefined && <FormErrorMessage color="red">{errors.country.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.city}>
							<Controller
								as={<SelectDropdown />}
								control={control}
								name="city"
								options={selectData.cities}
								placeholder={<div style={{ color: colors.colors.mc_medium, fontWeight: "700" }}>{`${strings.city} *`}</div>}
							/>
							{errors.city !== undefined && <FormErrorMessage color="red">{errors.city.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.title}>
							<Controller
								as={<SelectDropdown />}
								control={control}
								name="title"
								options={selectData.titles}
								placeholder={
									<div style={{ color: colors.colors.mc_medium, fontWeight: "700" }}>{`${strings.jobTitle} *`}</div>
								}
							/>
							{errors.title !== undefined && <FormErrorMessage color="red">{errors.title.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.company}>
							<Controller
								as={<SelectDropdown />}
								control={control}
								name="company"
								options={selectData.companies}
								placeholder={
									<div style={{ color: colors.colors.mc_medium, fontWeight: "700" }}>{`${strings.company} *`}</div>
								}
							/>
							{errors.company !== undefined && <FormErrorMessage color="red">{errors.company.message}</FormErrorMessage>}
						</FormControl>
						<FormControl isInvalid={errors.position}>
							<Controller
								as={<SelectDropdown />}
								control={control}
								name="position"
								options={selectData.positions}
								placeholder={
									<div style={{ color: colors.colors.mc_medium, fontWeight: "700" }}>{`${strings.position} *`}</div>
								}
							/>
							{errors.position !== undefined && <FormErrorMessage color="red">{errors.position.message}</FormErrorMessage>}
						</FormControl>
						<Controller as={<CustomInput />} control={control} name="licence_number" placeholder={strings.licenceNumber} />
					</Stack>
				</Flex>
				<Stack mb="20px">
					<Link as="i" textDecor="underline" color="mc_medium" _hover={{ color: "mc_dark" }} onClick={onOpen}>
						{strings.privacyPolicy}
					</Link>

					<Checkbox
						colorScheme="teal"
						size="lg"
						color="mc_medium"
						isChecked={privacyAgree}
						onChange={(e) => setPrivacyAgree(e.target.checked)}
						isInvalid={error}
					>
						{strings.agreePrivacyPolicy}
						<span style={{ color: "red" }}> *</span>
					</Checkbox>
				</Stack>

				<Flex direction="column" alignItems="center" gridRowGap="10px">
					<Button
						type="submit"
						h="50px"
						w="160px"
						overflow="hidden"
						rounded="xl"
						variant="medium"
						_hover={{ bg: "mc_dark" }}
						disabled={disabled}
					>
						{strings.confirm}
						<Box ml="15px">
							<WhiteArrow />
						</Box>
					</Button>

					<Text color="mc_medium" fontSize="16px" fontWeight="700">
						{strings.backTo}{" "}
						<Link as={RouterLink} to="/login" textDecor="underline">
							{strings.loginPage}
						</Link>
					</Text>
				</Flex>
			</form>
			<AddUpdateModal isOpen={isOpen} onClose={onClose} size="6xl">
				<PrivacyPolicy onClose={onClose} />
			</AddUpdateModal>
		</Flex>
	)
}

export default RegistrationForm
