import { createAction, createReducer } from "@reduxjs/toolkit";
import axios from "axios";
import { strings } from "../../utils/localization";
import { commentsRemoveURL, commentURL } from "../api_urls";

const START_REQUEST = createAction("COMMENT/START_REQUEST")
const FETCH_SUCCESS = createAction("COMMENT/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("COMMENT/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("COMMENT/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("COMMENT/CLEAN_MESSAGE")


export const fetchUserComments = () => async (dispatch) => {  
	dispatch(START_REQUEST())
	return await axios.get(commentURL)
		.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const approveUserComment = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return await axios.put(commentURL, payload)
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const rejectUserComment = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return await axios.delete(commentsRemoveURL(id))
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

const handleResponse = (res, dispatch, success, fail) => {
	if(res !== undefined) {
		if(res.status >= 200 && res.status <= 299) {
			dispatch(success(res.data))
		}else if(res.response !== undefined && res.response.status === 400){
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		}else{
			dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
			dispatch(CLEAN_MESSAGE())
		}
		return res
	}else{
		dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
    message: null,
	loading: false
};



export const commentReducer = createReducer(initState, (builder) => 
	builder
	.addCase(START_REQUEST, (state, action) => {
		state.loading = true
		state.message = null
	})
    .addCase(FETCH_SUCCESS, (state, action) => {
		state.loading = false
        state.data = action.payload	
	})
	.addCase(REQUEST_SUCCESS, (state, action) => {
		state.loading = false
        state.message = action.payload.message	
	})
	.addCase(REQUEST_FAIL, (state, action) => {
		state.loading = false
		state.message = action.payload
	})
	.addCase(CLEAN_MESSAGE, (state, action) => {
		state.message = null
	})
	)
  