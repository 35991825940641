import { Box, Flex } from "@chakra-ui/layout"
import { CorrectIcon, PlusIcon } from "../Icons"

const ResultBox = ({ result }) => {
    if (result) {
        return (
            <Box w="14px" h="12px" bg="mc_dark" rounded="4">
                <CorrectIcon w="8px" h="8px" fill="mc_bg" transform="translate(3px, -6px)" />
            </Box>
        )
    } else {
        return (
            <Flex w="14px" h="12px" bg="mc_errorRed" rounded="4" justify="center" align="center">
                <PlusIcon transform="rotate(45deg)" w="9px" h="9px" fill="mc_bg" />
            </Flex>
        )
    }
}

export default ResultBox
