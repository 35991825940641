import { Flex, Text } from "@chakra-ui/layout"
import { CorrectIcon, PlusIcon } from "../Icons"

const Answer = ({ text, handleClick, confirmed, answered, testResult, userResult, answerKey }) => {
	const checkColor = () => {
		if (confirmed) {
			if (userResult[answerKey]) {
				if (JSON.stringify(testResult[answerKey]) === JSON.stringify(userResult[answerKey])) {
					return "mc_dark"
				} else {
					return "mc_errorRed"
				}
			} else {
				return "mc_dark"
			}
		} else {
			return "mc_dark"
		}
	}

	const checkCorrectOrWrong = () => {
		const getCorrectOrWrong = () => {
			if (userResult[answerKey]) {
				if (JSON.stringify(testResult[answerKey]) === JSON.stringify(userResult[answerKey])) {
					return <CorrectIcon fill="mc_bg" />
				} else {
					return <PlusIcon fill="mc_bg" transform="rotate(45deg)" />
				}
			} else if (testResult[answerKey]) {
				return <CorrectIcon fill="mc_bg" />
			}
		}

		if (answered || testResult[answerKey]) {
			return (
				<Flex w="80px" h="100%" align="flex-start">
					<Flex w="80px" h="38px" rounded="base" bg={checkColor()} alignItems="center" justify="center">
						{getCorrectOrWrong()}
					</Flex>
				</Flex>
			)
		} else {
			return <></>
		}
	}

	return (
		<Flex
			w="100%"
			minH="38px"
			boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
			rounded="lg"
			pl="10px"
			justify="space-between"
			bg="mc_bg"
			border={answered && "2px solid"}
			borderColor={checkColor()}
			cursor={"pointer"}
			onClick={() => !confirmed && handleClick()}
		>
			<Text color={checkColor()} fontWeight="700" cursor="pointer" fontSize={["12px", "16px"]}>
				{text}
			</Text>

			{confirmed && checkCorrectOrWrong()}
		</Flex>
	)
}
// }

export default Answer
