import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input"
import { Box, Flex, Stack } from "@chakra-ui/layout"
import { Textarea } from "@chakra-ui/textarea"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import { strings } from "../../utils/localization"
import { HidePasswordIcon, ShowPasswordIcon } from "../Icons"
import SelectDropdown from "../SelectDropdown"

const ChangePassForm = ({ onClose, handleUpdateProfile, selectData, defaultData }) => {
	const config = useOutletContext()

	const [showNew, setShowNew] = useState(false)
	const [showRepeatNew, setShowRepeatNew] = useState(false)
	const handleClickOnNew = () => setShowNew(!showNew)
	const handleClickOnRepeatNew = () => setShowRepeatNew(!showRepeatNew)

	const { register, handleSubmit, control } = useForm()

	return (
		<form onSubmit={handleSubmit(handleUpdateProfile)}>
			<Flex gridColumnGap="50px">
				<Stack spacing={4} w="50%">
					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.firstName}
						</FormLabel>
						<Controller as={<Input />} control={control} name="first_name" defaultValue={defaultData.defaultFirstName} />
					</FormControl>
					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.lastName}
						</FormLabel>
						<Controller as={<Input />} control={control} name="last_name" defaultValue={defaultData.defaultLastName} />
					</FormControl>
					<Box>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.newPassword}
						</FormLabel>
						<InputGroup size="md">
							<Input name="new_password" pr="4.5rem" type={showNew ? "text" : "password"} ref={register} />
							<InputRightElement width="4.5rem">
								<Button h="1.75rem" variant="bg" size="sm" onClick={handleClickOnNew}>
									{showNew ? <HidePasswordIcon fill="mc_dark" /> : <ShowPasswordIcon fill="mc_dark" />}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<Box>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.newPasswordRepeat}
						</FormLabel>
						<InputGroup size="md">
							<Input name="repeated_password" pr="4.5rem" type={showRepeatNew ? "text" : "password"} ref={register} />
							<InputRightElement width="4.5rem">
								<Button h="1.75rem" size="sm" variant="bg" onClick={handleClickOnRepeatNew}>
									{showRepeatNew ? <HidePasswordIcon fill="mc_dark" /> : <ShowPasswordIcon fill="mc_dark" />}
								</Button>
							</InputRightElement>
						</InputGroup>
					</Box>
					<FormControl mb="16px">
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.country}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="country"
							options={selectData.countries}
							defaultValue={defaultData.defaultCountry}
						/>
					</FormControl>
					<FormControl mb="16px">
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.city}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="city"
							options={selectData.cities}
							defaultValue={defaultData.defaultCity}
						/>
					</FormControl>
				</Stack>
				<Stack spacing={4} w="50%">
					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.language}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="language"
							options={selectData.languages}
							defaultValue={defaultData.defaultLanguage}
						/>
					</FormControl>

					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.position}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="position"
							options={selectData.positions}
							defaultValue={defaultData.defaultPosition}
						/>
					</FormControl>
					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.phoneNumber}
						</FormLabel>
						<Input name="phone_number" type={"text"} ref={register} defaultValue={defaultData.defaultPhoneNumber} />
					</FormControl>
					{config.appId !== 2 && (
						<>
							<FormControl>
								<FormLabel color="mc_medium" fontSize="12px">
									{strings.jobTitle}
								</FormLabel>
								<Controller
									as={<SelectDropdown />}
									control={control}
									name="title"
									options={selectData.titles}
									defaultValue={defaultData.defaultTitle}
								/>
							</FormControl>
							<FormControl>
								<FormLabel color="mc_medium" fontSize="12px">
									{strings.licenceNumber}
								</FormLabel>

								<Input name="licence_number" type={"text"} ref={register} defaultValue={defaultData.defaultLicenseNumber} />
							</FormControl>
						</>
					)}
					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.biography}
						</FormLabel>
						<Controller
							as={
								<Textarea
									color="mc_dark"
									rounded="xl"
									size="l"
									resize="none"
									h="200px"
									p="10px"
									boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
								/>
							}
							control={control}
							name="bio"
							defaultValue={defaultData.defaultBio}
						/>
					</FormControl>
				</Stack>
			</Flex>

			<Flex justify="flex-end" mt="30px">
				<Button onClick={onClose} variant="red">
					{strings.close}
				</Button>
				<Button type="submit" variant="medium" ml={3}>
					{strings.save}
				</Button>
			</Flex>
		</form>
	)
}

export default ChangePassForm

// drzava (dropdown), grad (Dropdown), broj telefona, broj licence, zvanje (dropdown)
