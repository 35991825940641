import ReactApexChart from "react-apexcharts"
import { checkLabel } from "../../utils/functions"


const PieChart = ({ data, user }) => {

	let localizedLabels = []
	if(user) {
		data.labels.forEach((label) => localizedLabels.push(checkLabel(label)))
	}else{
		localizedLabels = data.labels
	}
	

	const options = {
		chart: {
			width: "520",
			type: "pie",
		},
		toolbar: {
			// autoSelected: "zoom",
			show: true,
		},
		labels: localizedLabels,

		responsive: [
			{
				breakpoint: 480,
				options: {
					chart: {
						width: 400,
					},
					legend: {
						position: "bottom",
					},
				},
			},
		],
	} 

	return (

		<div id="chart">
			<ReactApexChart
				options={options}
				series={data.series}
				type="pie"
				width={520}
			/>
		</div>
	)

}

export default PieChart