import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import { useToast } from "@chakra-ui/toast"
import useGeneric from "../../redux/hooks/useGeneric"
import { city, country } from "../../utils/constants"
import useMasterdata from "../../redux/hooks/useMasterdata"
import CityTable from "../../components/tables/CityTable"
import CityForm from "../../components/forms/CityForm"
import { handleToast, makeDropdownOptions } from "../../utils/functions"
import { Box } from "@chakra-ui/react"

const AdminCitiesPage = () => {
    const hook = useGeneric()
    const hookMd = useMasterdata()
    const toast = useToast()

    const [countries, setCountries] = useState(null)

    useEffect(() => {
        hook.cleanToast()
        hook.fetch(city)
        hookMd.fetch(country)
    }, []) //eslint-disable-line

    useEffect(() => {
        makeDropdownOptions(hookMd.countries, setCountries)
    }, [hookMd.countries])

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "toast1")
        }
    }, [hook.message]) // eslint-disable-line

    const onSubmit = (data) => {
        const payload = {
            code: data.code,
            name: data.name,
            country: data.country.value,
        }
        hook.create(city, payload)
    }

    const selectData = {
        countries: countries,
    }

    return (
        <Box
            h="95%"
            overflow="auto"
            border={"4px"}
            boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
            borderColor="mc_black"
            borderRadius="4px"
            w="100%"
        >
            <CityTable data={hook.cities} selectData={selectData} />
            <PlusButton onClick={() => hook.onOpen()} />
            <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="4xl">
                <CityForm submit={onSubmit} onClose={hook.onClose} selectData={selectData} />
            </AddUpdateModal>
        </Box>
    )
}

export default AdminCitiesPage
