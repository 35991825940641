import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { strings } from "../../utils/localization"
import {
	bannersUrl,
	documentsRemoveUrl,
	documentsUrl,
	emailNotificationsRemoveUrl,
	emailNotificationsUrl,
	exportImportUrl,
	reportsUrl,
	updateEmployeeStatusURL,
	uploadFileUrl,
} from "../api_urls"

const START_REQUEST = createAction("DATA/START_REQUEST")
const REQUEST_SUCCESS = createAction("DATA/REQUEST_SUCCESS")
const FETCH_BANNERS = createAction("DATA/FETCH_BANNERS")
const FETCH_DOCUMENTS = createAction("DATA/FETCH_DOCUMENTS")
const FETCH_DOCUMENTS_DROPDOWN = createAction("DATA/FETCH_DOCUMENTS_DROPDOWN")
const REQUEST_FAIL = createAction("DATA/REQUEST_FAIL")
const REQUEST_FAIL_EXPORT_IMPORT = createAction("DATA/REQUEST_FAIL_EXPORT_IMPORT")
const CLEAN_MESSAGE = createAction("DATA/CLEAN_MESSAGE")
const FETCH_NOTIFICATION = createAction("DATA/FETCH_NOTIFICATIONS")

export const createReport = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(reportsUrl, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const fetchDocumentFiles = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(documentsUrl).then((res) => handleResponse(res, dispatch, FETCH_DOCUMENTS, REQUEST_FAIL))
}

export const fetchDocumentsFilesDropdown = () => async (dispatch) => {
	const url = documentsUrl + "?dropdown_data=True"
	dispatch(START_REQUEST())
	return axios.get(url).then((res) => handleResponse(res, dispatch, FETCH_DOCUMENTS_DROPDOWN, REQUEST_FAIL))
}

export const createDocumentFile = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(documentsUrl, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateDocumentFile = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(documentsUrl, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeDocumentFile = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(documentsRemoveUrl(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const fetchBanners = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(bannersUrl).then((res) => handleResponse(res, dispatch, FETCH_BANNERS, REQUEST_FAIL))
}

export const fetchEmailNotifications = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(emailNotificationsUrl).then((res) => handleResponse(res, dispatch, FETCH_NOTIFICATION, REQUEST_FAIL))
}

export const createEmailNotification = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(emailNotificationsUrl, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateEmailNotification = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(emailNotificationsUrl, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const deleteEmailNotification = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(emailNotificationsRemoveUrl(id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateBanner = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return await axios
		.put(bannersUrl, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL_EXPORT_IMPORT))
}

export const exportData = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(exportImportUrl, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL_EXPORT_IMPORT))
}

export const importData = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return await axios
		.post(exportImportUrl, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL_EXPORT_IMPORT))
}

export const uploadFile = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return await axios
		.post(uploadFileUrl, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL_EXPORT_IMPORT))
}

export const updateEmploymentStatus = (payload) => async (dispatch) => {
	return axios
		.post(updateEmployeeStatusURL, payload)
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL_EXPORT_IMPORT))
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			dispatch(success(res.data))
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
		return res
	} else {
		dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	reportMessage: null,
	importExportMessage: null,
	userGraphData: null,
	banners: [],
	emailNotifications: [],
	documents: [],
	documentsDropdown: [],
	pagination: null,
	loading: false,
}

export const dataManagementReducer = createReducer(initState, (builder) =>
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.reportMessage = null
			state.importExportMessage = null
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.importExportMessage = action.payload.message
			state.reportMessage = action.payload.message
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.reportMessage = action.payload
		})
		.addCase(REQUEST_FAIL_EXPORT_IMPORT, (state, action) => {
			state.loading = false
			state.importExportMessage = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.importExportMessage = null
			state.reportMessage = null
		})
		.addCase(FETCH_BANNERS, (state, action) => {
			state.loading = false
			state.banners = action.payload
		})
		.addCase(FETCH_DOCUMENTS, (state, action) => {
			state.loading = false
			state.documents = action.payload.docs
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_DOCUMENTS_DROPDOWN, (state, action) => {
			state.loading = false
			state.documentsDropdown = action.payload.docs
		})
		.addCase(FETCH_NOTIFICATION, (state, action) => {
			state.loading = false
			state.emailNotifications = action.payload.email_notifications
			state.pagination = action.payload.pagination
		})
)
