export const smarted = "SMARTED"
export const pharmanet = "PHARMANET"
export const doctornet = "DOCTORNET"

export const checkAppId = (appName) => {
	if(appName === smarted) {
		return 2
	}else if(appName === pharmanet) {
		return 1
	}else if(appName === doctornet) {
		return 3
	}
}