import React from "react"
import useConfig from "../utils/config"
import { strings } from "../utils/localization"
import { Heading, Link, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react"

const PrivacyPolicyInEnglish = () => {
    const config = useConfig()

    return (
        <Stack spacing={4} fontSize="16px" color="mc_black">
            <Heading
                as={"h1"}
                color="mc_medium"
                fontSize="20px"
                lineHeight={1.8}
                textAlign="center"
                mb="20px"
            >
                {strings.privacyPolicy} <br /> {`${config.companyName} LLC`} Kraljevo
            </Heading>
            <Text>
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    {`${config.companyName} LLC`} Kraljevo
                </Text>{" "}
                (hereinafter:{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    {config.companyName}
                </Text>
                ), based in Kraljevo, ul. Ribnička br. 26, {config.companyInfo}, in the course of
                its activities, processes personal data in compliance with all relevant laws and
                regulations.
            </Text>
            <Text color="mc_medium" fontWeight="700" textAlign="center">
                Please read the following information carefully!
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Personality data controller
            </Heading>
            <Text>
                {config.companyName} is a personal data controller in accordance with the law and
                regulations on personal data protection.{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    {config.companyName}
                </Text>
                , as the Personal Data Handler, determines the purpose and means of personal data
                processing and is responsible for storing and using personal data in written and /
                or electronic form.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Personality data processor
            </Heading>
            <Text>
                {config.companyName}, in its business, may process personal data on behalf of the
                Personal Data Manager, or in accordance with its written instructions in which case
                it has the status of Personal Data Processor.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                What personal information does {config.companyName} collect?
            </Heading>
            <Text>
                Personal data that {config.companyName} collects personal data of its clients, ie
                users of the {config.policyAppName} internet application, as well as visitors to the
                internet presentation located on the internet domain:{" "}
                <Link href={config.website} isExternal color="mc_medium" display="inline-block">
                    {config.website}
                </Link>
                . These are, first of all, personal data without which {config.companyName} cannot
                perform the service, and include the least data related to the use of the website
                located on the mentioned internet domain, as well as other personal data necessary
                to exercise the client's rights based on legal provisions, and shall include at
                least:
                <UnorderedList>
                    <ListItem>basic personal data (name and surname)</ListItem>
                    <ListItem>contact information (e-mail address, telephone number)</ListItem>
                    <ListItem>
                        data related to the use of the internet presentation located on the internet
                        domain{" "}
                        <Link
                            href={config.website}
                            isExternal
                            color="mc_medium"
                            display="inline-block"
                        >
                            {config.website}
                        </Link>{" "}
                        (IP address)
                    </ListItem>
                    <ListItem>
                        other personal data necessary for the exercise of the client's rights based
                        on legal consumer protection provisions.
                    </ListItem>
                </UnorderedList>
                Nezavisno od odabranog komunikacijskog kanala zahtev se podnosi lično i na takav
                način da je zaposlenima i/ili ovlašćenim licima {config.companyName}-a omogućena
                identifikacija podnosioca zahteva.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Prikupljanje i korišćenje podataka o ličnosti
            </Heading>
            <Text>
                {config.companyName} collects and manages personal data for the purpose of providing
                services in the field of its business, as well as in order to fulfill legal and
                regulatory obligations and for the purpose of realizing its own legitimate interests
                in lawful business. If we are required to do so by the relevant legal regulations,
                for certain reasons we will additionally ask the user for his (your) explicit
                consent.
            </Text>
            <Text>
                The reasons why we process personal data for the purpose of{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    concluding and fulfilling contractual obligations
                </Text>{" "}
                are:
                <UnorderedList>
                    <ListItem>Internet application administration {config.policyAppName}</ListItem>
                    <ListItem>
                        managing and receiving complaints, grievances and complaints;
                    </ListItem>
                    <ListItem>
                        other legal obligations arising from the operations of {config.companyName}.
                    </ListItem>
                </UnorderedList>
            </Text>
            <Text>
                The reasons why we process your personal information based on our legitimate
                business interests are:
                <UnorderedList>
                    <ListItem>notification of changes related to business conditions;</ListItem>
                    <ListItem>prevention and detection of fraud.</ListItem>
                </UnorderedList>
            </Text>
            <Text>
                For all other purposes, not listed for the purposes listed above,{" "}
                {config.companyName} will ask for your additional consent. You can withdraw your
                consent at any time.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Access to personal data
            </Heading>
            <Text>
                {config.companyName} undertakes technical and organizational measures in order to
                process the collected personal data only in a manner that is in accordance with the
                stated purpose. If necessary for the purpose of carrying out our business
                activities, access to personal data will be provided to third parties, as follows:
                <UnorderedList>
                    <ListItem>
                        tate bodies and public authorities that have the right to access personal
                        data for the purpose of enforcing legal obligations;
                    </ListItem>
                    <ListItem>lawyers, </ListItem>
                    <ListItem>
                        service departments, printing house, financial institutions and other
                        business partners.
                    </ListItem>
                </UnorderedList>
                All, except state and public authorities, process personal data exclusively
                according to the instructions of {config.companyName}-a.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Processing of personal data
            </Heading>
            <Text>
                User data will be processed by the processors listed in the chapter{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    Access to personal data
                </Text>
                , and whose responsibility and obligation to protect personal data and maintain
                their confidentiality is defined by the contractual relationship and in accordance
                with all technical and organizational measures and legal regulations regulate data
                protection. Your data, collected by {config.companyName} for the purposes stated in
                the chapter{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    Collection and use of personal data
                </Text>
                , will not be available to anyone who does not have the authorization of{" "}
                {config.companyName}
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Exercise of rights
            </Heading>
            <Text>
                When it is a legally prescribed person whose data {config.companyName} processes has
                the{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    right to information
                </Text>{" "}
                on the processing of his personal data;
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    withdraw your consent
                </Text>{" "}
                at any time if {config.companyName} processes personal data with your consent;{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    correction or supplementation of personal data
                </Text>{" "}
                in order to always be accurate;{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    deleting personal data
                </Text>{" "}
                when they are no longer needed for the above purposes;{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    filing a complaint with
                </Text>{" "}
                {config.companyName} or the competent state authority.
            </Text>
            <Text>
                Persons to whom personal data refer can exercise their rights by submitting
                requests, through the following communication channels:
                <UnorderedList>
                    <ListItem>
                        in person at the business premises of {config.companyName} – Str. Ribnička
                        No. 26, Kraljevo
                    </ListItem>
                    <ListItem>
                        by e-mail sent from the e-mail address registered in the{" "}
                        {config.companyName} system:{" "}
                        <Text color="mc_medium" display="inline-block">
                            {config.support}
                        </Text>
                    </ListItem>
                </UnorderedList>
                Regardless of the chosen communication channel, the request is submitted in person
                and in such a way that the employees and / or authorized persons of{" "}
                {config.companyName} are enabled to identify the applicant.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                The right to object to the supervisory authority
            </Heading>
            <Text>
                In addition to the objection to the processing of personal data submitted to{" "}
                {config.companyName}, the objection to the processing of personal data can also be
                submitted to the Office of the{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    Commissioner for Information of Public Importance and Personal Data Protection,
                </Text>
                tr. Bulevar kralja Aleksandra 15, 11 000 Belgrade, Republic of Serbia, mail{" "}
                <Text color="mc_medium" display="inline-block">
                    office@poverenik.rs
                </Text>
                or phone number: +381 11 3408 900.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Storing personal data
            </Heading>
            <Text>
                The time period for storing personal data of the user depends on the purpose for
                which
                {` ${config.companyName}`} processes.
                <UnorderedList>
                    <ListItem>
                        Data collected for the purpose of administering the internet application{" "}
                        {config.policyAppName} - until the moment of deleting the user account by
                        the person whose data is processed on this application (revocation of
                        consent);
                    </ListItem>
                    <ListItem>
                        Data collected for processing based on the legal basis, are stored in
                        accordance with the time limits of the provisions of the law in question
                        (for example, the Law on Accounting, etc.).
                    </ListItem>
                </UnorderedList>
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Contact
            </Heading>
            <Text>
                For any questions about how to use personal information, you can contact us by
                phone, e-mail or mail as follows:
                <UnorderedList>
                    <ListItem>
                        by sending an e-mail to{" "}
                        <Text color="mc_medium" display="inline-block">
                            {config.support}
                        </Text>
                    </ListItem>
                    <ListItem>
                        in writing via the address:{" "}
                        <Text display="inline-block" color="mc_medium" fontWeight="700">
                            {`${config.companyName} LLC`} Kraljevo
                        </Text>
                        , str. Ribnička No. 26, Kraljevo, Republic of Serbia via the form that you
                        can download{" "}
                        <Link
                            href={config.website}
                            isExternal
                            color="mc_medium"
                            display="inline-block"
                        >
                            here
                        </Link>
                        ;{" "}
                    </ListItem>
                    <ListItem>by calling the contact center on +381 60 352 5350.</ListItem>
                </UnorderedList>
                For communication due to the exercise of rights related to the protection of
                personal data, it is necessary to use the communication channels defined in the
                chapter{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    "Exercise of rights"
                </Text>{" "}
                in order for each request to be accepted and the user identified as the applicant.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Update Personal Information Processing Information
            </Heading>
            <Text>
                {config.companyName} regularly reviews and updates the content of the Privacy Policy
                and harmonizes it with changes in its own business processes, with changes in legal
                provisions and regulatory obligations.
            </Text>
            <Text>
                In the event of significant changes that significantly affect the processing of
                personal data, {config.companyName} will without delay publish such changes on its
                website and inform you directly.{" "}
            </Text>
            <Text>
                The training for personal data protection for the company {config.companyName} was
                conducted by the Law Office Cvjetićanin & Partners.
            </Text>
        </Stack>
    )
}

export default PrivacyPolicyInEnglish
