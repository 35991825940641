import { Image } from "@chakra-ui/image"
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import React from "react"
import useConfig from "../../utils/config"

const ImageModal = ({ data, isOpen, onClose, size }) => {
    const config = useConfig()

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered>
            <ModalOverlay />
            <ModalContent>
                <ModalBody bg="mc_bg" p={8}>
                    <Image
                        src={`${config.baseImageUrl}${data}`}
                        alt="eduImage"
                        objectFit="cover"
                        boxSize="100%"
                        bgPos="center"
                        rounded="xl"
                    />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default ImageModal
