import { useDisclosure } from "@chakra-ui/hooks"
import { useDispatch, useSelector } from "react-redux"
import {
	changeDefaultSize,
	cleanEducation,
	cleanEducations,
	cleanMessage,
	createEducation,
	fetchEducation,
	fetchEducationPage,
	fetchNewEmployeesEdu,
	removeEducation,
	updateEducation,
} from "../reducers/educationReducer"

export default function useEducation() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.educations.data)
	const newEmployeeData = useSelector((state) => state.educations.newEmployeeData)
	const pagination = useSelector((state) => state.educations.pagination)
	const detail = useSelector((state) => state.educations.detail)
	const defaultSizeAdmin = useSelector((state) => state.educations.defaultSizeAdmin)
	const defaultSizeUser = useSelector((state) => state.educations.defaultSizeUser)
	const message = useSelector((state) => state.educations.message)
	const loading = useSelector((state) => state.educations.loading)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const fetchPage = async (perPage, pageNumber, role, filterData) => {
		dispatch(fetchEducationPage(perPage, pageNumber, role, filterData))
	}

	const fetchNewEmp = async () => {
		dispatch(fetchNewEmployeesEdu())
	}

	const fetchOne = async (id) => {
		return dispatch(fetchEducation(id))
	}

	const create = async (payload) => {
		return dispatch(createEducation(payload))
	}

	const update = async (payload) => {
		return dispatch(updateEducation(payload))
	}

	const remove = async (id) => {
		dispatch(removeEducation(id)).then(() => handleRefresh())
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const changeSize = async (size) => {
		dispatch(changeDefaultSize(size))
	}

	const cleanDetail = async () => {
		dispatch(cleanEducation())
	}

	const clean = async () => {
		dispatch(cleanEducations())
	}

	const handleRefresh = () => {
		onClose()
		fetchPage(defaultSizeAdmin, pagination.current_page)
	}

	return {
		data,
		newEmployeeData,
		loading,
		message,
		detail,
		pagination,
		defaultSizeAdmin,
		defaultSizeUser,
		fetchPage,
		fetchNewEmp,
		remove,
		create,
		fetchOne,
		update,
		cleanToast,
		changeSize,
		cleanDetail,
		clean,
		isOpen,
		onOpen,
		onClose,
		handleRefresh,
	}
}
