import { useDispatch, useSelector } from "react-redux"
import {
	addAnsweredTestQuestion,
	addEduSlide,
	addNewAnswer,
	addNewQuestion,
	changeAnswerResult,
	changeAnswerSubquestion,
	changeAnswerText,
	changeEduSlideText,
	changeQuestionSlide,
	changeQuestionSubquestion,
	changeQuestionText,
	cleanEduSlides,
	cleanFormAnswers,
	cleanTestAnswers,
	confirmNewUserAnswer,
	deleteEduSlide,
	deleteOneQuestion,
	handleNewUserAnswer,
	removeOneAnswer,
	setAnswers,
	setEduSlides,
	updateUserSubquestion,
} from "../reducers/formReducer"

export default function useForms() {
	const dispatch = useDispatch()
	const answerForms = useSelector((state) => state.forms.answerForms)
	const eduForms = useSelector((state) => state.forms.eduForms)
	const testAnswers = useSelector((state) => state.forms.testAnswers)

	const setTestAnswers = async (payload) => {
		dispatch(setAnswers(payload))
	}

	const addQuestion = async (index) => {
		dispatch(addNewQuestion(index))
	}

	const deleteQuestion = async (index) => {
		dispatch(deleteOneQuestion(index))
	}

	const updateQuestionText = async (questionIndex, value) => {
		dispatch(changeQuestionText(questionIndex, value))
	}

	const updateQuestionSlide = async (questionIndex, value) => {
		dispatch(changeQuestionSlide(questionIndex, value))
	}

	const updateQuestionSubquestion = async (questionIndex, value) => {
		dispatch(changeQuestionSubquestion(questionIndex, value))
	}

	const addAnswer = async (index, text, result, id) => {
		dispatch(addNewAnswer(index, text, result, id))
	}

	const updateSubquestion = async (index, result) => {
		dispatch(updateUserSubquestion(index, result))
	}

	const removeAnswer = async (questionIndex, answerIndex) => {
		dispatch(removeOneAnswer(questionIndex, answerIndex))
	}

	const updateAnswerText = async (questionIndex, answerIndex, value) => {
		dispatch(changeAnswerText(questionIndex, answerIndex, value))
	}

	const updateAnswerSubquestion = async (questionIndex, answerIndex, value) => {
		dispatch(changeAnswerSubquestion(questionIndex, answerIndex, value))
	}

	const updateAnswerResult = async (questionIndex, answerIndex, value) => {
		dispatch(changeAnswerResult(questionIndex, answerIndex, value))
	}

	const addTestAnswer = async (payload) => {
		dispatch(addAnsweredTestQuestion(payload))
	}

	const handleUserAnswer = async (payload) => {
		dispatch(handleNewUserAnswer(payload))
	}

	const confirmUserAnswer = async (payload) => {
		dispatch(confirmNewUserAnswer(payload))
	}

	const cleanAnswers = async () => {
		dispatch(cleanTestAnswers())
	}

	const cleanTestForms = async () => {
		dispatch(cleanFormAnswers())
	}

	const setSlides = async (payload) => {
		dispatch(setEduSlides(payload))
	}

	const addSlide = async (index, payload) => {
		dispatch(addEduSlide(index, payload))
	}

	const deleteSlide = async (index) => {
		dispatch(deleteEduSlide(index))
	}

	const updateSlideText = async (index, value) => {
		dispatch(changeEduSlideText(index, value))
	}

	const cleanSlides = async () => {
		dispatch(cleanEduSlides())
	}

	return {
		answerForms,
		testAnswers,
		eduForms,
		addAnswer,
		addQuestion,
		updateAnswerText,
		updateAnswerResult,
		removeAnswer,
		addTestAnswer,
		cleanAnswers,
		cleanTestForms,
		setTestAnswers,
		deleteQuestion,
		updateQuestionText,
		updateQuestionSlide,
		addSlide,
		deleteSlide,
		updateSlideText,
		setSlides,
		cleanSlides,
		updateAnswerSubquestion,
		updateQuestionSubquestion,
		updateSubquestion,
		handleUserAnswer,
		confirmUserAnswer,
	}
}
