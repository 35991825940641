import { Center } from "@chakra-ui/layout"
import { useCallback } from "react"
import { ReactComponent as FalseIcon } from "../assets/icons/false-icon.svg"
import { ReactComponent as TrueIcon } from "../assets/icons/true-icon.svg"
import generateToast from "../components/ToastGenerator"
import { articleReactions, articleViews, educationViews, loginCount, passedTests } from "./constants"
import { strings } from "./localization"

//generate options for csv export
export const csvOptions = (fileName) => {
	return {
		filename: fileName,
		fieldSeparator: ",",
		quoteStrings: '"',
		decimalSeparator: ".",
		showLabels: true,
		showTitle: false,
		useTextFile: false,
		useBom: true,
		useKeysAsHeaders: true,
	}
}

//generate and display toast, no duplicates
export const handleToast = (toast, message, messageId, longer) => {
	const id = messageId
	if (!toast.isActive(id)) {
		toast(generateToast(message, id, longer))
	}
}

export const makeIdsArray = (array) => {
	const idsArray = []
	if (array !== undefined) {
		array.forEach((item) => idsArray.push(item.value))
	}

	return idsArray
}

//generate array for dropdown options and set it in local state
export const makeDropdownOptions = (array, setData, forms) => {
	if (forms && array !== undefined && array.length > 0) {
		const arrayDrop = []
		array.map((element, i) => arrayDrop.push({ label: strings[element.code], value: element.id }))
		if (setData) {
			setData(arrayDrop)
		}
		return arrayDrop
	} else if (array !== undefined && array.length > 0) {
		const arrayDrop = []
		array.map((element, i) => arrayDrop.push({ label: element.name, value: element.id }))
		if (setData) {
			setData(arrayDrop)
		}
		return arrayDrop
	}
}

export const makeDropdownOptionsUsers = (array, setData) => {
	if (array !== undefined && array.length > 0) {
		const arrayDrop = []
		array.map((ele, i) =>
			arrayDrop.push({
				label: ele.username,
				value: ele.id,
			})
		)
		if (setData) {
			setData(arrayDrop)
		}
		return arrayDrop
	}
}

export const makeDropdownOptionsValueField = (array) => {
	const arrayDrop = []
	if (array !== undefined && array.length > 0) {
		array.map((ele, i) =>
			arrayDrop.push({
				label: ele.value_field,
				value: ele.id,
			})
		)
	}

	return arrayDrop
}

export const makeDropdownOptionsDocuments = (array, setData) => {
	if (array.length > 0) {
		const arrayDrop = []
		array.map((element, i) => arrayDrop.push({ label: element.title, value: element.doc }))
		setData(arrayDrop)
	}
}

export const makeDropdownOptionsArticle = (array, setData) => {
	if (array.length > 0) {
		const arrayDrop = []
		array.map((element, i) => arrayDrop.push({ label: element.title, value: element.id }))
		setData(arrayDrop)
	}
}

export const handleSelectedDropdownOption = (val, setSelected, clean) => {
	if (clean) {
		clean()
	}
	if (val) {
		setSelected(val.value)
	} else {
		setSelected(null)
	}
}

//date formatting
Date.prototype.setTimezoneOffset = function (minutes) {
	//eslint-disable-line
	var _minutes
	if (this.timezoneOffset === _minutes) {
		_minutes = this.getTimezoneOffset()
	} else {
		_minutes = this.timezoneOffset
	}
	if (arguments.length) {
		this.timezoneOffset = minutes
	} else {
		this.timezoneOffset = minutes = this.getTimezoneOffset()
	}
	return this.setTime(this.getTime() + (_minutes - minutes) * 6e4)
}

export const fixTimezoneOffset = (date) => {
	if (!date) return ""
	return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toJSON()
}

export const fixTimezoneOffsetDate = (date) => {
	if (!date) return ""
	return new Date(date.getTime() + date.getTimezoneOffset() * 60000)
}

export const formatDateForCell = (date, alreadyDate) => {
	if (date) {
		let d = alreadyDate ? date : new Date(date)
		d.setTimezoneOffset(0)
		return d.toLocaleString("sr", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
		})
	} else {
		return ""
	}
}

export const formatDateWithoutTime = (date, alreadyDate) => {
	let d = alreadyDate ? date : new Date(date)
	d.setTimezoneOffset(0)
	return d.toLocaleDateString("sr")
}

export const getTimeRemaining = (endtime) => {
	const total = Date.parse(endtime) - Date.parse(new Date())
	const seconds = Math.floor((total / 1000) % 60)
	const minutes = Math.floor((total / 1000 / 60) % 60)
	const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
	const days = Math.floor(total / (1000 * 60 * 60 * 24))

	return {
		total,
		days,
		hours,
		minutes,
		seconds,
	}
}

export const checkLabel = (label) => {
	if (label === loginCount) {
		return strings.loginCount
	} else if (label === articleViews) {
		return strings.articleViews
	} else if (label === articleReactions) {
		return strings.articleReactions
	} else if (label === educationViews) {
		return strings.educationViews
	} else if (label === passedTests) {
		return strings.passedTests
	}
}

export const columnTableString = (array, type) => {
	let string = ""
	if (type) {
		array.map((element, i) => {
			if (i === array.length - 1) {
				return (string = string + element[type])
			} else {
				return (string = string + element[type] + ", ")
			}
		})
	} else if (array !== undefined && array.length > 0) {
		array.map((element, i) => {
			if (i === array.length - 1) {
				return (string = string + element.value_field)
			} else {
				return (string = string + element.value_field + ", ")
			}
		})
	}
	return string
}

//yup validation resolver

export const useYupValidationResolver = (validationSchema) =>
	useCallback(
		async (data) => {
			try {
				const values = await validationSchema.validate(data, {
					abortEarly: false,
				})

				return {
					values,
					errors: {},
				}
			} catch (errors) {
				return {
					values: {},
					errors: errors.inner.reduce(
						(allErrors, currentError) => ({
							...allErrors,
							[currentError.path]: {
								type: currentError.type ?? "validation",
								message: currentError.message,
							},
						}),
						{}
					),
				}
			}
		},
		[validationSchema]
	)

export const trueOrFalse = (value) => {
	return <Center>{value ? <TrueIcon /> : <FalseIcon />}</Center>
}
