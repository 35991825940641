import { Button } from "@chakra-ui/button"
import { Checkbox } from "@chakra-ui/checkbox"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Box, Flex, ListItem, OrderedList, Stack } from "@chakra-ui/layout"
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/number-input"
import { useToast } from "@chakra-ui/toast"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import useApp from "../../redux/hooks/useApp"
import useForms from "../../redux/hooks/useForms"
import useTest from "../../redux/hooks/useTest"
import useUser from "../../redux/hooks/useUser"
import { handleToast, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedPermissionsPayload, SmartedFields } from "../../utils/smartedHelper"
import AddItemButton from "../buttons/AddItemButton"
import RemoveItemButton from "../buttons/RemoveItemButton"
import { LeftArrow, RightArrow } from "../Icons"
import CustomSwitch from "../inputs/CustomSwitch"
import CustomTextInput from "../inputs/CustomTextInput"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const AddTestForm = ({ selectData, handleClose }) => {
	const [currentId, setCurrentId] = useState(0)
	const hook = useForms()
	const hookTest = useTest()
	const toast = useToast()
	const hookUser = useUser()
	const hookApp = useApp()
	const config = useOutletContext()

	let defaultApp = null
	let defaultCompanies = []

	if (!hookUser.admin) {
		defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
		defaultApp = { label: hookApp.name, value: hookApp.id }
	}

	const { handleSubmit, control, register } = useForm({})

	const [images, setImages] = useState([])

	const handleNext = () => {
		let exitLoopText
		const correctAnswers = []
		const currentAnswers = hook.answerForms.find((ele, i) => i === currentId)

		currentAnswers.answers.forEach((element) => {
			if (element.text === "") {
				exitLoopText = true
			}
			if (element.result) {
				correctAnswers.push(true)
			}
		})

		if (exitLoopText) {
			handleToast(toast, { text: strings.requiredTextOfAllAnswersMessage, type: "error" }, "1")
		} else if (correctAnswers.length === 0) {
			handleToast(toast, { text: strings.atLeastOneCorrectAnswerMessage, type: "error" }, "3")
		} else if (!currentAnswers.text || !images[currentId]) {
			handleToast(toast, { text: strings.requiredTextOrImageMessage, type: "error" }, "1")
		} else {
			if (currentId + 1 === hook.answerForms.length) {
				hook.addQuestion(currentId + 1).then(() => hook.addAnswer(currentId + 1, "", false))
			}
			setCurrentId(currentId + 1)
		}
	}

	const close = () => {
		hook.cleanTestForms()
		handleClose()
	}

	const submit = (data) => {
		var formData = new FormData()

		const questions = []

		hook.answerForms.map((question, i) => {
			const oneQuestion = {}
			oneQuestion["serial_num"] = i + 1
			oneQuestion["question"] = question.text
			oneQuestion["education_slide"] = question.education_slide
			oneQuestion["subquestion"] = question.subquestion
			if (question.subquestion) {
				oneQuestion["subquestion"] = question.subquestion
			} else {
				oneQuestion["subquestion"] = ""
			}
			oneQuestion["image"] = images[i].data

			return questions.push(oneQuestion)
		})

		let exitLoopImage = false
		let exitLoopText = false

		// console
		questions.map((question, i) => {
			if (question.image !== undefined) {
				formData.append("images[]", question.image, question.image.name)
			} else {
				exitLoopImage = true
			}

			if (question.question === undefined || question.question === "") {
				exitLoopText = true
			}

			delete question.image
			question["answers"] = {}
			return hook.answerForms.map((questionRed, index) => {
				//eslint-disable-line
				if (index === i) {
					questionRed.answers.map((answer, answerIndex) => {
						const result = answer.result.toString()
						return (question.answers[`${answer.text}`] = result.charAt(0).toUpperCase() + result.slice(1))
					})
				}
			})
		})

		const replacer = (key, value) =>
			value instanceof Object && !(value instanceof Array)
				? Object.keys(value)
						.sort()
						.reduce((sorted, key) => {
							sorted[key] = value[key]
							return sorted
						}, {})
				: value

		formData.append("questions", JSON.stringify(questions, replacer))

		let exitLoopAnswers = false
		const correctAnswers = []
		const currentAnswers = hook.answerForms.find((ele, i) => i === currentId)
		currentAnswers.answers.forEach((element) => {
			if (element.text === "") {
				exitLoopAnswers = true
			}
			if (element.result) {
				correctAnswers.push(true)
			}
		})

		if (exitLoopAnswers) {
			handleToast(toast, { text: strings.requiredTextOfAllAnswersMessage, type: "error" }, "4")
		} else if (correctAnswers.length === 0) {
			handleToast(toast, { text: strings.atLeastOneCorrectAnswerMessage, type: "error" }, "6")
		} else if (exitLoopImage) {
			handleToast(toast, { text: strings.requiredImageMessage, type: "error" }, "7")
		} else if (exitLoopText) {
			handleToast(toast, { text: strings.requiredQuestionTextMessage, type: "error" }, "8")
		} else if (!data.name || !data.app) {
			handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "10")
		} else {
			let test = {}

			if (data.education) {
				test["education"] = data.education.value
			} else {
				test["education"] = ""
			}

			test["name"] = data.name

			if (data.companies.length > 0) {
				test["companies"] = makeIdsArray(data.companies)
			} else {
				test["companies"] = []
			}

			if (config.appId === 2) {
				handleSmartedPermissionsPayload(data, test)
			}

			test["app"] = data.app.value
			if (data.track) {
				test["track"] = "True"
			} else {
				test["track"] = "False"
			}

			formData.append("test", JSON.stringify(test))

			hookTest.create(formData).then((res) => {
				if (res !== undefined && res.status === 200) {
					close()
					hookTest.handleRefresh()
				}
			})
		}
	}

	const handleDeleteQuestion = () => {
		if (currentId === 0 && hook.answerForms.length === 1) {
			handleToast(toast, { text: "ne meze", type: "error" }, "11")
		} else {
			setImages(images.filter((item, i) => i !== currentId))
			hook.deleteQuestion(currentId)
			if (currentId !== 0) {
				setCurrentId(currentId - 1)
			}
		}
	}

	const handleImage = (file, index) => {
		let newImages = [...images]
		if (newImages.length > 0) {
			newImages.map((data, i) => {
				//eslint-disable-line
				if (index === i) {
					newImages = newImages.filter((item, i) => i !== index)
				}
			})
		}

		if (currentId === 0) {
			newImages.unshift({ question: index, data: file })
		} else {
			newImages.push({ question: index, data: file })
		}
		setImages(newImages)
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={6}>
				<Flex alignItems="flex-end" gridGap="20px">
					<FormControl w="70%">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.name} *
						</FormLabel>
						<Controller as={<Input boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)" />} control={control} name="name" />
					</FormControl>
					<FormControl w="30%">
						<FormLabel fontSize="14px" color="mc_dark" textTransform={"uppercase"}>
							{strings.education}
						</FormLabel>
						<Controller as={<SelectDropdown />} control={control} name="education" options={selectData.educations} />
					</FormControl>
				</Flex>
				<Flex gridGap="20px" w="100%" display={hookUser.admin ? "flex" : "none"} direction={["column", "column", "row"]}>
					<FormControl w={["100%", "100%", "30%"]}>
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.application} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="app"
							options={selectData.apps}
							defaultValue={defaultApp}
						/>
					</FormControl>
					<Box w={["100%", "100%", "70%"]}>
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.companies}
						</FormLabel>
						<Controller
							as={<SelectDropdown isMulti />}
							control={control}
							name="companies"
							options={selectData.companies}
							defaultValue={defaultCompanies}
						/>
					</Box>
					<Checkbox size="lg" alignItems="center" color="mc_dark" fontSize="md" ref={register} name="track" defaultChecked={true}>
						{strings.track}
					</Checkbox>
				</Flex>
				{config.appId === 2 && <SmartedFields control={control} selectData={selectData} />}
				<Stack direction="row" justify="center" flexWrap="wrap">
					{hook.answerForms.map((slide, i) =>
						currentId === i ? (
							<Flex
								w="18px"
								h="16px"
								bg="mc_dark"
								rounded="2"
								fontSize="11px"
								color="mc_bg"
								justify="center"
								align="center"
								pt="4px"
								mb={1}
							>
								{i + 1}
							</Flex>
						) : (
							<Flex
								w="18px"
								h="16px"
								border="1px solid"
								borderColor="mc_medium"
								rounded="2"
								fontSize="11px"
								color="mc_dark"
								justify="center"
								align="center"
								pt="4px"
								mb={1}
							>
								{i + 1}
							</Flex>
						)
					)}
				</Stack>
				<Box w="100%">
					{hook.answerForms.map((item, index) => (
						<>
							<Flex
								w="100%"
								gridGap="20px"
								display={currentId === index ? "flex" : "none"}
								direction={["column", "column", "row"]}
								mb={["20px", "20px", 0]}
							>
								<FormControl w="100%">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.question} *
									</FormLabel>
									<Input
										name={`text${index + 1}`}
										boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
										onChange={(e) => hook.updateQuestionText(index, e.target.value)}
										value={item.text}
									/>
								</FormControl>
								<FormControl w="fit-content">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.image} *
									</FormLabel>
									{/* <Controller
                                        as={}
                                        control={control}
                                        name={`image${index + 1}`}
                                    /> */}
									<UploadFile onChange={(val) => handleImage(val, index)} />
								</FormControl>
								<FormControl w="fit-content">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.educationSlide}
									</FormLabel>

									<NumberInput
										min={0}
										precision={0}
										w="80px"
										boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
										name={`education_slide${index + 1}`}
										onChange={(string, number) => hook.updateQuestionSlide(index, number)}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Flex>

							<FormControl w={["100%", "100%", "80%"]} display={currentId === index ? "true" : "none"}>
								<FormLabel fontSize="14px" color="mc_dark">
									{strings.subquestion}
								</FormLabel>
								<Input
									name={`subquestion${index + 1}`}
									boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
									onChange={(e) => hook.updateQuestionSubquestion(index, e.target.value)}
									value={item.subquestion}
								/>
							</FormControl>
						</>
					))}
				</Box>
				<Flex w="100%" gridGap="40px" pl="10px">
					<Box w={["100%", "100%", "80%"]} maxH="380px" overflowY="auto">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.answers}
						</FormLabel>
						<OrderedList mt="0 !important">
							{hook.answerForms.map(
								(form, index) =>
									index === currentId &&
									form.answers.map((answer, i) => (
										<ListItem key={i}>
											<Flex mb="10px">
												<CustomTextInput
													onChange={(data) => hook.updateAnswerText(currentId, i, data)}
													value={answer.text}
												/>
												<RemoveItemButton onClick={() => hook.removeAnswer(currentId, i)}></RemoveItemButton>
												<CustomSwitch
													onChange={(data) => hook.updateAnswerResult(currentId, i, data)}
													value={answer.result}
												/>
											</Flex>
										</ListItem>
									))
							)}
						</OrderedList>
						<AddItemButton onClick={() => hook.addAnswer(currentId, "", false)} />
					</Box>
				</Flex>
				<Stack spacing={[4, 4, 0]}>
					<Flex gridGap="20px" justify="center" w="100%">
						<Button
							display={currentId === 0 ? "none" : "inline-block"}
							variant="dark"
							leftIcon={<LeftArrow fill="mc_bg" />}
							onClick={() => setCurrentId(currentId - 1)}
						>
							{strings.back}
						</Button>
						<Button variant="red" textTransform="uppercase" onClick={handleDeleteQuestion}>
							{strings.deleteQuestion}
						</Button>
						<Button variant="dark" rightIcon={<RightArrow fill="mc_bg" />} onClick={() => handleNext()}>
							{strings.forward}
						</Button>
					</Flex>

					<Flex w="100%" justify="space-between">
						<Button variant="red" onClick={() => close()}>
							{strings.close}
						</Button>{" "}
						<Button variant="medium" type="submit">
							{strings.save}
						</Button>
					</Flex>
				</Stack>
			</Stack>
		</form>
	)
}

export default AddTestForm
