import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useGeneric from "../../redux/hooks/useGeneric"
import { articleCategory } from "../../utils/constants"
import { makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import UpdateButton from "../buttons/UpdateButton"
import CategoryForm from "../forms/CategoryForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"

const CategoryTable = ({ data, selectData, type }) => {
	const hook = useGeneric()

	const [updateData, setUpdateData] = useState(null)

	const columns = [
		{
			dataField: "code",
			text: strings.code,
		},
		{
			dataField: "name",
			text: strings.name,
		},
		{
			dataField: "app_name",
			text: strings.application,
		},
		{
			dataField: "companies_value",
			text: strings.companies,
		},
	]

	const handleUpdateModal = (row) => {
		const defaultCompanies = []
		row.companies.map((e, i) => defaultCompanies.push(selectData.companies.find((ele) => ele.value === e)))

		const defaultValues = {
			id: row.id,
			code: row.code,
			name: row.name,
			defaultApp: selectData.applications.find((ele) => ele.value === row.app),
			defaultCompanies: defaultCompanies,
		}
		setUpdateData(defaultValues)
		hook.onOpen()
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<DeleteModal remove={() => hook.remove(articleCategory, row.id)} />
				<UpdateButton onClick={() => handleUpdateModal(row)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleSubmit = (data) => {
		const payload = {
			id: updateData.id,
			code: data.code,
			name: data.name,
			app: data.app.value,
		}

		if (data.companies && data.companies.length > 0) {
			payload["companies"] = makeIdsArray(data.companies)
		} else {
			payload["companies"] = []
		}

		hook.update(type, payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.handleRefresh(type)
			}
		})
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="4x1">
				<CategoryForm updateData={updateData} close={hook.onClose} submit={handleSubmit} selectData={selectData} />
			</AddUpdateModal>
		</>
	)
}

export default CategoryTable
