import { Button } from "@chakra-ui/button"
import { FormLabel, FormControl } from "@chakra-ui/form-control"
import { Flex } from "@chakra-ui/layout"
import { Textarea } from "@chakra-ui/react"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import { strings } from "../../utils/localization"
import SelectDropdown from "../SelectDropdown"

const EmailNotificationForm = ({ updateData, submit, close, selectData }) => {
    const { register, handleSubmit, control } = useForm()

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Flex mt={10} flexDirection={"column"} gridRowGap={"40px"}>
                <FormControl>
                    <FormLabel>{"NOTIFICATION"}</FormLabel>
                    <Controller
                        as={
                            <Textarea
                                color="mc_dark"
                                rounded="xl"
                                size="xl"
                                resize="none"
                                p="10px"
                                boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                            />
                        }
                        control={control}
                        name="notification"
                        defaultValue={updateData && updateData.defaultNotification}
                        rules={{ required: true }}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{"USERS"}</FormLabel>
                    <Controller
                        as={<SelectDropdown />}
                        isMulti
                        control={control}
                        name="users"
                        options={selectData.users}
                        defaultValue={updateData && updateData.defaultUsers}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{strings.application}</FormLabel>
                    <Controller
                        as={<SelectDropdown />}
                        control={control}
                        name="application"
                        options={selectData.applications}
                        defaultValue={updateData && updateData.defaultApp}
                        rules={{ required: true }}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>{"ARTICLE CATEGORY"}</FormLabel>
                    <Controller
                        as={<SelectDropdown />}
                        control={control}
                        name="article_category"
                        options={selectData.articleCategories}
                        defaultValue={updateData && updateData.defaultArticleCategory}
                        rules={{ required: true }}
                    />
                </FormControl>
            </Flex>

            <Flex justify="flex-end" mt={4}>
                <Button onClick={close} variant="red">
                    {strings.close}
                </Button>
                <Button type="submit" variant="medium" ml={3}>
                    {strings.save}
                </Button>
            </Flex>
        </form>
    )
}

export default EmailNotificationForm
