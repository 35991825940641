import { Button } from "@chakra-ui/button"
import { Box, Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/layout"
import { Center, Img, Link, Stack, Tooltip, useDisclosure } from "@chakra-ui/react"
import { Tag } from "@chakra-ui/tag"
import { Textarea } from "@chakra-ui/textarea"
import { useToast } from "@chakra-ui/toast"
import { motion } from "framer-motion"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import styled from "styled-components"
import { Player } from "video-react"
import "video-react/dist/video-react.css"
import Comments from "../components/article/Comments"
import Count from "../components/article/Count"
import ReactionCountBox from "../components/article/ReactionCountBox"
import Survey from "../components/article/Survey"
import { BulbIcon, HeartIcon, LikeIcon, MessageIcon, ReportIcon, WhiteArrowIcon } from "../components/Icons"
import AddUpdateModal from "../components/modals/AddUpdateModal"
import PdfRender from "../components/PdfRender"
import UploadFile from "../components/UploadFile"
import useApp from "../redux/hooks/useApp"
import useArticle from "../redux/hooks/useArticle"
import useDataManagement from "../redux/hooks/useDataManagement"
import { insightful, like, love } from "../utils/constants"
import { formatDateWithoutTime, handleToast } from "../utils/functions"
import { strings } from "../utils/localization"

const MotionBox = motion(Box)

const ArticleDetailPage = () => {
	const hook = useArticle()
	const hookBanners = useDataManagement()
	const toast = useToast()
	const config = useOutletContext()
	const [comment, setComment] = useState("")
	const [note, setNote] = useState("")
	const [file, setFile] = useState(null)
	const appName = useApp().name

	let { id } = useParams()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const handleReactions = () => {
		hook.setReactionToggle(!hook.reactionToggle)
	}

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "1")
		}
	}, [hook.message]) //eslint-disable-line

	const fetchArticle = () => {
		hook.fetchOne(id)
	}
	useEffect(() => {
		hookBanners.getBanners()
	}, []) //eslint-disable-line

	useLayoutEffect(() => {
		hook.cleanDetail()
		fetchArticle()
	}, []) //eslint-disable-line

	const article = hook.detail

	const handleComment = () => {
		if (comment) {
			hook.postComment(article.id, comment).then(() => setComment(""))
		} else {
			handleToast(toast, { text: strings.enterCommentMessage, type: "error" }, "2")
		}
	}

	const handleSurvey = (data) => {
		Object.keys(data).map((key) => {
			if (data[key] === undefined) {
				data[key] = ""
			}
		})
		const payload = {
			survey: article.survey,
			answers: data,
		}
		hook.postSurvey(payload)
	}

	const handleFileSelect = (chosenFile) => {
		setFile(chosenFile)
	}

	const handleReport = () => {
		if (!file) {
			handleToast(toast, { text: strings.requiredImageMessage, type: "error" }, "2")
		} else {
			let formData = new FormData()
			formData.append("note", note)
			formData.append("file", file, file.name)
			formData.append("article", hook.detail.id)
			hook.postArticleReport(formData).then((res) => {
				if (res !== undefined && res.status === 200) {
					onClose()
				}
			})
		}
	}
	const firstBanner = hookBanners.banners.find((val) => val.id === 1)
	const secondBanner = hookBanners.banners.find((val) => val.id === 2)
	const thirdBanner = hookBanners.banners.find((val) => val.id === 3)
	const fourthBanner = hookBanners.banners.find((val) => val.id === 4)

	return (
		<>
			{article && (
				<Grid
					templateColumns={["1fr", "1fr", "1fr", "1fr", "1fr", "1fr auto 1fr"]}
					gridAutoFlow="column"
					gridAutoRows="auto"
					// minH="100vh"
					w="100%"
					gap={["10px", "20px"]}
					mx="auto"
					bg="transparent"
				>
					<GridItem display={["none", "none", "none", "none", "none", "flex"]} width="100%" minW="300px" justifyContent="center">
						{appName === "PHARMANET" && (
							<BannerWrapper>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={firstBanner.image ? "1px solid" : null}
									borderColor={firstBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{firstBanner.image && (
										<Link
											href={firstBanner.url.length > 0 ? firstBanner.url : null}
											isExternal
											cursor={firstBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={firstBanner && firstBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={secondBanner.image ? "1px solid" : null}
									borderColor={secondBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{secondBanner.image && (
										<Link
											href={secondBanner.url.length > 0 ? secondBanner.url : null}
											isExternal
											cursor={secondBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={secondBanner && secondBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
							</BannerWrapper>
						)}
					</GridItem>

					<Box
						w="100%"
						maxW="900px"
						mx="auto"
						minH="100vh"
						bg="mc_bg"
						boxShadow="2px 2px 10px rgba(36, 180, 188, 0.25)"
						border="4x solid mc_medium"
						borderRadius="2xl"
						p={["4px", "20px"]}
					>
						<Box position="relative" w={{ xl: "860px" }}>
							<Img w="100%" borderTopRadius="3xl" src={`${config.baseImageUrl}${article.image}`} objectFit="contain" />
						</Box>
						<Flex
							alignItems="center"
							justify="space-between"
							bg="mc_light"
							w="100%"
							flex={1}
							h={["50px", "70px"]}
							borderBottomRadius="3xl"
						>
							<Flex gridColumnGap={["8px", "10px"]} p="10px" alignItems="center">
								<Flex
									onMouseEnter={() => handleReactions()}
									onMouseLeave={() => {
										setTimeout(() => handleReactions(), 800)
									}}
									alignItems="center"
									justifyContent="center"
									w="37px"
									h="37"
									rounded="xl"
									bg="mc_medium"
									position="relative"
									_hover={{ bg: "mc_dark", transition: "0.3s" }}
								>
									<LikeIcon fill={article.reacted ? null : "mc_bg"} />
									<Count count={article.reactions_count} />
								</Flex>

								<Flex
									alignItems="center"
									justifyContent="center"
									w={["90px", "103px"]}
									h="37"
									rounded="xl"
									bg="mc_medium"
									gridColumnGap={["10px", "16px"]}
									display={hook.reactionToggle ? "flex" : "none"}
									_hover={{
										display: "flex",
										animation: "fadeOut 1s",
									}}
								>
									<MotionBox
										whileHover={{ scale: 1.6, y: -5 }}
										whileTap={{ scale: 0.9 }}
										onClick={() => hook.postReaction(like, article.id)}
									>
										<LikeIcon cursor="pointer" fill={article.reacted === like ? null : "mc_bg"} />
									</MotionBox>
									<MotionBox
										whileHover={{ scale: 1.6, y: -5 }}
										whileTap={{ scale: 0.9 }}
										onClick={() => hook.postReaction(love, article.id)}
									>
										<HeartIcon
											cursor="pointer"
											fill={article.reacted === love ? null : "mc_bg"}
											_focus={{ fill: "mc_errorRed" }}
										/>
									</MotionBox>
									<MotionBox
										whileHover={{ scale: 1.6, y: -5 }}
										whileTap={{ scale: 0.9 }}
										onClick={() => hook.postReaction(insightful, article.id)}
									>
										<BulbIcon cursor="pointer" fill={article.reacted === insightful ? null : "mc_bg"} />
									</MotionBox>
								</Flex>
								<ReactionCountBox>
									<MessageIcon fill="mc_bg" />
									<Count count={article.comments_count} />
								</ReactionCountBox>
							</Flex>

							{config.appId === 2 && (
								<Tooltip label="report" fontSize="md" borderRadius="md">
									<Flex
										alignItems="center"
										justifyContent="center"
										w="37px"
										h="37px"
										rounded="xl"
										bg="mc_errorRed"
										position="relative"
										mr="10px"
										onClick={() => onOpen()}
										cursor="pointer"
										_hover={{
											bg: "#e24848",
											transition: "0.3s",
										}}
									>
										<MotionBox whileHover={{ scale: 1.2, y: -2 }} whileTap={{ scale: 0.9 }}>
											<ReportIcon fill="white" w="20px" h="20px" />
										</MotionBox>
									</Flex>
								</Tooltip>
							)}
						</Flex>
						<Flex direction="column" gridGap="40px" textAlign="left" my="20px">
							<Box>
								<Flex mb="10px" justify="space-between" align="flex-end">
									<Text color="mc_medium" fontSize={["12px", "14px"]}>
										{formatDateWithoutTime(hook.detail.date_created)}
									</Text>
									<Tag size="sm" bg="mc_medium" color="mc_bg" fontSize={["12px", "13px"]} maxW="260px">
										{hook.detail.category_name}
									</Tag>
								</Flex>
								<Heading as="h1" fontSize="26px" color="mc_dark" mb="20px" lineHeight="135%">
									{article.title}
								</Heading>
								{article.content_type === 4 ? (
									<Flex justify="center">
										<PdfRender url={`${config.baseImageUrl}${article.content_pdf}`} />
									</Flex>
								) : article.content_type === 1 ? (
									<Box
										dangerouslySetInnerHTML={{
											__html: article.content,
										}}
									/>
								) : article.content_type === 3 ? (
									<Box position="relative" w={{ xl: "860px" }}>
										<Img w="100%" src={`${config.baseImageUrl}${article.content_image}`} objectFit="contain" />
									</Box>
								) : article.content_type === 2 ? (
									<Player playsInline>
										<source src={`${config.baseImageUrl}${article.content_video}`} type="video/mp4" />
									</Player>
								) : (
									<></>
								)}
							</Box>
							{!article.answered_survey && article.survey_name && (
								<Box>
									<Heading as="h3" fontSize="16px" color="mc_dark" letterSpacing="4px" fontWeight="700" mb="10px">
										{article.survey_name}
									</Heading>
									<Box w="100%" bg="mc_dark" rounded="xl">
										<Survey questions={article.survey_questions} submit={handleSurvey} />
									</Box>
								</Box>
							)}
							<Box>
								<Heading as="h3" fontSize="14px" color="mc_dark" letterSpacing="4px" fontWeight="700" mb="10px">
									{strings.leaveComment}
								</Heading>
								<Textarea
									w="100%"
									h="170px"
									boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
									rounded="xl"
									onChange={(e) => setComment(e.target.value)}
									value={comment}
									maxLength="440"
								></Textarea>
							</Box>

							<Button
								w="160px"
								variant="dark"
								mx="auto"
								rightIcon={<WhiteArrowIcon fill="mc_bg" />}
								h="50px"
								textTransform="uppercase"
								onClick={() => handleComment()}
							>
								{strings.confirm}
							</Button>
							<Box>
								<Heading as="h3" fontSize="16px" color="mc_dark" letterSpacing="4px" fontWeight="700" mb="10px">
									{strings.comments}
								</Heading>
								<Box w="100%">
									{article.comments.length > 0 ? (
										<Comments data={article.comments} fetchArticle={fetchArticle} />
									) : (
										<Text>{strings.noComments}</Text>
									)}
								</Box>
							</Box>
						</Flex>
					</Box>
					<GridItem display={["none", "none", "none", "none", "none", "flex"]} width="100%" minW="300px" justifyContent="center">
						{appName === "PHARMANET" && (
							<BannerWrapper>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={thirdBanner.image ? "1px solid" : null}
									borderColor={thirdBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{thirdBanner.image && (
										<Link
											href={thirdBanner.url.length > 0 ? thirdBanner.url : null}
											isExternal
											cursor={thirdBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={thirdBanner && thirdBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={fourthBanner.image ? "1px solid" : null}
									borderColor={fourthBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{fourthBanner.image && (
										<Link
											href={fourthBanner.url.length > 0 ? fourthBanner.url : null}
											isExternal={fourthBanner.url.length > 0 ? true : false}
											cursor={fourthBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={fourthBanner && fourthBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
							</BannerWrapper>
						)}
					</GridItem>
				</Grid>
			)}
			<AddUpdateModal isOpen={isOpen} onClose={onClose} size="4xl" overlayClick={true}>
				<Stack spacing={6} justify="center" align="center">
					<Heading as="h3" fontSize="18px" color="mc_dark" letterSpacing="4px" fontWeight="700" mb="10px">
						{strings.report}
					</Heading>

					<Textarea
						w="100%"
						h="170px"
						boxShadow="0px 2px 10px rgba(66, 146, 220, 0.25)"
						border="none"
						rounded="xl"
						onChange={(e) => setNote(e.target.value)}
						value={note}
						maxLength="440"
						resize="none"
						placeholder={strings.notePlaceholder}
					/>

					<UploadFile onChange={handleFileSelect} fileUpload={true} />

					<Center>
						<Button
							w="160px"
							variant="dark"
							mx="auto"
							rightIcon={<WhiteArrowIcon fill="mc_bg" />}
							h="50px"
							textTransform="uppercase"
							onClick={() => handleReport()}
						>
							{strings.confirm}
						</Button>
					</Center>
				</Stack>
			</AddUpdateModal>
		</>
	)
}

const BannerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 16%;
	position: -webkit-sticky;
	position: sticky;
	top: 15vh;
	min-height: 65vh;
	height: calc(80vh - 200px);
	width: 100%;
	max-width: 380px;
	padding: 0 15px;
`
export default ArticleDetailPage
