import ResetPassForm from "../components/forms/ResetPassForm"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import useUser from "../redux/hooks/useUser"
import { handleToast } from "../utils/functions"

const ResetPassPage = () => {
    const hook = useUser()
    const toast = useToast()

	const [buttonDisabled, setButtonDisabled] = useState(false)

    useEffect(() => {
        hook.cleanToast()
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
			handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) //eslint-disable-line

	const submit = (data) => {
		setButtonDisabled(true)
		hook.resetPassword({email: data.email}).then((res) => {
			if(res !== undefined && res.status !== 200) {
				setButtonDisabled(false)
			}
		})
		
	}

    return (
        <ResetPassForm submit={submit} disabled={buttonDisabled} />
    )
}

export default ResetPassPage
