export const serbian = () => {
	return {
		showMore: "Prikaži više",
		edit: "izmeni",
		educationAuthorIs: "Ova edukacija je napravljena od strane kompanije:",
		startTest: "započni test",
		didTest: "Uradili ste test",
		maxTimes: "maksimalan broj puta",
		requiredFieldsMessage: "Polja označena sa * su obavezna!",
		allRequiredFieldsMessage: "Sva polja su obavezna!",
		requiredTextOrImageMessage: "Niste uneli tekst ili sliku!",
		requiredTextMessage: "Niste uneli tekst!",
		requiredTextOfAllAnswersMessage: "Popuni tekst svih odgovora!",
		oneCorrectAnswerMessage: "Samo jedan odgovor može biti tačan!",
		atLeastOneCorrectAnswerMessage: "Barem jedan odgovor mora biti tačan!",
		requiredImageMessage: "Slika mora biti izabrana!",
		requiredQuestionTextMessage: "Unesi tekst pitanja!",
		requiredEducationSlideNumberMessage: "Unesi broj slajda edukacije!",
		requiredAllQuestions: "Popuni sva pitanja!",
		passwordDontMatchMessage: "Lozinke se ne podudaraju!",
		noDataSelectedMessage: "Podaci nisu izabrani!",
		noReportSelectedMessage: "Izveštaj nije izabran!",
		noTestSelectedMessage: "Test nije izabran!",
		noTypeSelectedMessage: "Tip nije izabran!",
		noArticleSelected: "Vest nije izabrana!",
		noSurveySelected: "Anketa nije izabrana!",
		noJobSelected: "Posao nije izabran!",
		notEnoughReportDataMessage: "Nema dovoljno podataka za izabrani izveštaj",
		enterCommentMessage: "Unesi komentar!",
		leaveComment: "OSTAVI KOMENTAR",
		test: "TEST",
		report: "IZVEŠTAJ",
		type: "TIP",
		from: "OD",
		to: "DO",
		previous: "Prethodna",
		next: "Sledeća",
		page: "Strana",
		of: "od",
		generate: "GENERIŠI",
		choosePlaceholder: "Izaberi...",
		name: "NAZIV",
		title: "NASLOV",
		education: "Edukacija",
		category: "KATEGORIJA",
		application: "APLIKACIJA",
		company: "KOMPANIJA",
		survey: "ANKETA",
		country: "DRŽAVA",
		city: "GRAD",
		jobTitle: "ZVANJE",
		position: "POZICIJA",
		language: "JEZIK",
		phoneNumber: "BROJ TELEFONA",
		licenceNumber: "BROJ LICENCE",
		biography: "BIOGRAFIJA",
		slideText: "TEKST SLAJDA",
		image: "FOTOGRAFIJA",
		question: "PITANJE",
		questions: "PITANJA",
		educationSlide: "SLAJD EDUKACIJE",
		summaryText: "UVODNI TEKST",
		coverPhoto: "NASLOVNA FOTOGRAFIJA",
		content: "SADRŽAJ",
		status: "STATUS",
		answers: "ODGOVORI",
		close: "ZATVORI",
		back: "NAZAD",
		forward: "NAPRED",
		save: "SAČUVAJ",
		password: "LOZINKA",
		repeatPassword: "PONOVI LOZINKU",
		newPassword: "NOVA LOZINKA",
		newPasswordRepeat: "PONOVI NOVU LOZINKU",
		code: "KOD",
		companyType: "TIP KOMPANIJE",
		firstName: "IME",
		lastName: "PREZIME",
		username: "KORISNIČKO IME",
		email: "E-MAIL",
		supervisor: "SUPERVIZOR",
		users: "KORISNICI",
		reports: "IZVEŠTAJI",
		importExport: "UVOZ/IZVOZ",
		masterData: "ŠIFARNICI",
		countries: "DRŽAVE",
		jobTitles: "ZVANJA",
		positions: "POZICIJE",
		companyTypes: "TIPOVI KOMPANIJA",
		cities: "GRADOVI",
		companies: "KOMPANIJE",
		educationCategories: "KATEGORIJE EDUKACIJA",
		articleCategories: "KATEGORIJE VESTI",
		languages: "JEZICI",
		article: "VEST",
		articles: "VESTI",
		surveys: "ANKETE",
		educations: "EDUKACIJE",
		tests: "TESTOVI",
		panel: "PANEL",
		signOut: "ODJAVI SE",
		signIn: "PRIJAVI SE",
		controlPanel: "KONTROLNI PANEL",
		profile: "PROFIL",
		cancel: "ODUSTANI",
		delete: "OBRIŠI",
		deleteQuestion: "OBRIŠI PITANJE",
		deleteWarningMessage: "Ako obrišete ovu stavku, nećete moći da je povratite!",
		yes: "DA",
		no: "NE",
		creationDate: "DATUM KREIRANJA",
		views: "PREGLEDI",
		reactions: "REAKCIJE",
		comments: "KOMENTARI",
		registrationTime: "VREME REGISTRACIJE",
		lastLogin: "POSLEDNJA PRIJAVA",
		active: "AKTIVAN",
		congratulations: "ČESTITAMO",
		completedTest: "USPEŠNO STE ZAVRŠILI TEST",
		endTest: "ZAVRŠI TEST",
		monthlyReport: "Mesečni izveštaj",
		fileSelected: "Datoteka je izabrana",
		chooseCsvFile: "Izaberi .csv fajl",
		photo: "Slika",
		selectPhoto: "Izaberi sliku",
		noFileSelectedMessage: "Datoteka nije izabrana!",
		exportData: "IZVOZ PODATAKA",
		export: "IZVEZI",
		importData: "UVOZ PODATAKA",
		import: "UVEZI",
		confirm: "POTVRDI",
		noComments: "Nema komentara",
		loadMore: "UČITAJ JOŠ",
		author: "AUTOR",
		notFoundMessage: "stranica koju tražite nije pronađena",
		updateData: "IZMENI PODATKE",
		privacyPolicy: "POLITIKA PRIVATNOSTI",
		result: "REZULTAT",
		serverErrorMessage: "Serverski problem!",
		wrongCredentialsMessage: "Uneti podaci nisu ispravni!",
		noAccount: "Nemate nalog?",
		clickHere: "Kliknite ovde",
		forgotPassword: "Zaboravili ste lozinku?",
		backTo: "Nazad na",
		loginPage: "stranicu za prijavu",
		emailAddress: "E-MAIL ADRESA",
		passwordReset: "RESETOVANJE LOZINKE",
		registration: "REGISTRACIJA",
		noMore: "Nema više!",
		welcomeTo: "dobrodošli na",
		byUser: "PO KORISNIKU",
		byCity: "PO GRADU",
		byQuestions: "PO PITANJIMA",
		loginCount: "Broj logovanja",
		articleViews: "Broj pregleda vesti",
		articleReactions: "Reakcije na vesti",
		educationViews: "Broj pregleda edukacija",
		passedTests: "Broj položenih testova",
		monthly: "MESEČNI",
		eduAll: "SVE",
		eduChoose: "IZABERI",
		eduChooseCompleted: "ODABERITE ZAVRŠENO",
		eduChooseNotCompleted: "ODABERITE NIJE ZAVRŠENO",
		noEduSelectedMessage: "Edukacija nije izabrana!",
		deleteSlide: "OBRIŠI SLAJD",
		cantDeleteFirst: "Ne možete obrisati prvi slajd!",
		agreePrivacyPolicy: "Slažem se s politikom privatnosti",
		articleAll: "SVE",
		articleByUserReports: "PO IZVEŠTAJIMA KORISNIKA",
		imageMissing: "Slika negde nedostaje!",
		monthlyPoints: "BODOVI ZA OVAJ MESEC:",
		annualPoints: "BODOVI ZA OVU GODINU:",
		notePlaceholder: "Beleška...",
		wrongAppMessage: "Pokušavate da pristupite pogrešnoj platformi!",
		selectFile: "Izaberi datoteku",
		searchPlaceholder: "Pretraga...",
		repeatTest: "PONOVITE TEST",
		usersUpdate: "AŽURIRANJE KORISNIKA",
		total: "UKUPNI",
		track: "PRAĆENJE",
		loginData: "PODACI ZA LOGOVANJE",
		checkEmailMessage: "Otvorite link e-pošte da potvrdite registraciju (proverite neželjenu poštu)",
		subquestion: "PODPITANJE",
		comment: "KOMENTAR",
		reject: "ODBIJ",
		approve: "ODOBRI",
		tags: "OZNAKE",
		jobListings: "OGLASNA TABLA",
		deadline: "ROK",
		applicationDeadline: "Rok za prijavu",
		apply: "prijavite se",
		openFormToApply: "Otvorite formu za prijavu",
		uploadYourCV: "OTPREMITE VAŠ CV",
		uploadFile: "Otpremi datoteku",
		yourCVIsUploaded: "VAŠ CV je otpremljen",
		applied: "PRIJAVLJENI STE",
		jobs: "POSLOVI",
		job: "POSAO",
		note: "napomena",
		newEmployees: "Novozaposleni",
		newEmployee: "NOVI ZAPOSLENI",
		updateEmploymentStatus: "Ažurirajte status zaposlenja",
		banner: "reklama",
		url: "url-veza",
		expirationDate: "DATUM ISTEKA",
		expired: "ISTEKAO",
		days: "DANA",
		hours: "SATA",
		useCustomTheme: "koristite prilagođenu temu",
		chooseTheColorOfTheApplication: "odaberite boju aplikacije",
		selectTheApplicationLogo: "odaberite logo aplikacije",
		selectTheApplicationFont: "odaberite font aplikacije",
		currentLogo: "trenutni logo",
		font: "font",
		black: "crna",
		background: "pozadina",
		dark: "tamna",
		medium: "srednja",
		light: "svetla",
		red: "crvena",
		assignedToUsers: "DODELJENO KORISNICIMA",
		testAnswerQuestion: "Odgovorite na pitanje",
		testMaxRepeatNumberReached: "Uradili ste test maksimalan broj puta",
		UA_LOGIN_ACTIVITY: "Aktivnost",
		UA_NEWS_VIEWS: "Vesti",
		UA_NEWS_REACTIONS: "Reakcije",
		UA_EDUCATION_VIEWS: "Edukacije",
		UA_TESTS: "Testovi",
		UA_LOGIN_ACTIVITY_TP: "Aktivnost korisnika u prethodnih 100 dana",
		UA_NEWS_VIEWS_TP: "Pregled novosti u prethodnih 100 dana",
		UA_NEWS_REACTIONS_TP: " Broj reakcija u prethodnih 100 dana",
		UA_EDUCATION_VIEWS_TP: "Uspešno završene edukacije u prethodnih 100 dana",
		UA_TESTS_TP: "Aktivnost položenih testova u prethodnih 100 dana",
		ACTIVITY: "Aktivnost",
		NEWS: "Vesti",
		EDUCATIONS: "Edukacije",
		TESTS: "Testovi",
		achievements: "Dostignuća",
		ACTIVITY_DAYS: "dana uzastopnog prijavljivanja",
		EDUCATIONS_FINISHED: "završenih edukacija",
		NEWS_LIKED: "lajkovanih vesti",
		NEWS_VIEWED: "pogledanih vesti",
		TESTS_SUCCESS_FINISHED: "položenih testova sa svim tačnim odgovorima",
		TESTS_FINISHED: "položenih testova",
		numberOfAchievements: "Broj dostignuća",
		currentRank: "Trenutni rank",
		numberOfUnread: "Broj nepročitanih",
		numberOfNotCompleted: "Broj nezavršenih",
		documents: "Dokumenti",
		document: "Dokument",
		download: "preuzmi",
		selectDocument: "Odaberite dokument",
		completedAllEdu: "Završili ste sve",
		readAllNews: "Pročitali ste sve",
		forms: "Formulari",
		month: "Mesec",
		year: "Godina",
		absenceRequests: "ZAHTEVI ZA ODSUSTVO",
		absenceRequestType: "TIP ODSUSTVA",
		byAssignedUser: "PO DODELJENIM KORISNICIMA",
		bySubQuestions: "PO POTPITANJIMA",
		lockedEduMessage:
			"Poštovani korisniče, sve dok ste novozaposlen morate uraditi edukacije za novozaposlene, kako bi imali pristup ostlim edukacijama.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Zahtev za administrativnu zabranu",
		FORM_PZDF: "Prijava za deljenje flajera",
		contactPhoneNumber: "KONTAKT TELEFON",
		FORM_TK: "Trkački klub",
		FORM_ZPOZ: "Zahtev potvrda o zaposlenju",
		bank: "BANKA",
		certificationPurpose: "SVRHA POTVRDE",
		documentType: "TIP DOKUMENTA",
		documentVerification: "DOKUMENT ZA OVERU",
		loanStatement: "IZJAVA ZA SAMOSTALNU OTPLATU KREDITA",
		FORM_ZZO: "Zahtevi za odsustvo",
		leaveType: "VRSTA ODSUSTVA",
		dateFrom: "DATUM OD",
		dateTo: "DATUM DO",
		absenceDocument: "DOKUMENT ZA ODSUSTVO",
		FORM_PK: "Pobednička kombinacija",
		commentNote: "KOMENTAR/NAPOMENA",
		recommendation: "PREPORUKA",
		FORM_IZ: "Inicijative zaposlenih",
		initiative: "INICIJATIVA",
		choose: "Izaberi",
	}
}
