import { Button } from "@chakra-ui/button"
import { Checkbox } from "@chakra-ui/checkbox"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { InputGroup, InputRightElement } from "@chakra-ui/input"
import { Flex, SimpleGrid } from "@chakra-ui/layout"
import { GridItem } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import { Stack } from "@chakra-ui/layout"
import { Textarea } from "@chakra-ui/textarea"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useApp from "../../redux/hooks/useApp"
import useUser from "../../redux/hooks/useUser"
import { strings } from "../../utils/localization"
import CustomTextInput from "../inputs/CustomTextInput"
import SelectDropdown from "../SelectDropdown"

const UserForm = ({ onClose, selectData, updateData, submit }) => {
    const [showPass, setShowPass] = useState(false)
    const [showRepeatPass, setShowRepeatPass] = useState(false)

    const handleClickOnPass = () => setShowPass(!showPass)
    const handleClickOnRepeatPass = () => setShowRepeatPass(!showRepeatPass)

    const { handleSubmit, control, register } = useForm()

    const hookUser = useUser()
    const hookApp = useApp()

    let defaultApp = null
    let defaultCompany = null

    if (updateData) {
        defaultCompany = updateData.defaultCompany
        defaultApp = updateData.defaultApp
    } else if (!hookUser.admin && !updateData) {
        defaultCompany = { label: hookUser.company.name, value: hookUser.company.id }
        defaultApp = { label: hookApp.name, value: hookApp.id }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack px="5px" spacing={6} w="100%" maxH="700px" overflowY="auto">
                <SimpleGrid minChildWidth="280px" spacing="20px">
                    <Box display={hookUser.admin ? "block" : "none"}>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.company}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="company"
                            options={selectData.companies}
                            defaultValue={defaultCompany}
                        />
                    </Box>

                    <Box display={hookUser.admin ? "block" : "none"}>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.application} {!updateData && `*`}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="app"
                            options={selectData.apps}
                            defaultValue={defaultApp}
                        />
                    </Box>

                    <Box>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.position}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="position"
                            options={selectData.positions}
                            defaultValue={updateData && updateData.defaultPosition}
                        />
                    </Box>

                    <Box>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.jobTitle}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="title"
                            options={selectData.titles}
                            defaultValue={updateData && updateData.defaultTitle}
                        />
                    </Box>

                    <Box>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.city}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="city"
                            options={selectData.cities}
                            defaultValue={updateData && updateData.defaultCity}
                        />
                    </Box>
					<Box>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.country}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="country"
                            options={selectData.countries}
                            defaultValue={updateData && updateData.defaultCountry}
                        />
                    </Box>
					<Box>
                        <FormLabel fontSize="12px" color="mc_dark">
                            {strings.language}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="language"
                            options={selectData.languages}
                            defaultValue={updateData && updateData.defaultLanguage}
                        />
                    </Box>
                </SimpleGrid>

                <Flex gridGap="20px" direction={["column", "column", "row"]}>
                    <Box w={["100%", "100%", "80%"]}>
                        <FormLabel color="mc_dark" fontSize="12px">
                            {strings.biography}
                        </FormLabel>
                        <Controller
                            as={
                                <Textarea
                                    color="mc_dark"
                                    rounded="xl"
                                    size="xl"
                                    h={["200px", "150px"]}
                                    resize="none"
                                    p="10px"
                                    boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                                />
                            }
                            control={control}
                            name="bio"
                            defaultValue={updateData && updateData.bio}
                        />
                    </Box>
                </Flex>
                <SimpleGrid minChildWidth="300px" spacing="20px">
                    <FormControl>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.firstName} {!updateData && `*`}
                        </FormLabel>

                        <Controller
                            as={<CustomTextInput />}
                            control={control}
                            name="first_name"
                            defaultValue={updateData && updateData.firstName}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.lastName} {!updateData && `*`}
                        </FormLabel>
                        <Controller
                            as={<CustomTextInput />}
                            control={control}
                            name="last_name"
                            defaultValue={updateData && updateData.lastName}
                        />
                    </FormControl>
                    {!updateData && (
                        <FormControl>
                            <FormLabel fontSize="14px" color="mc_dark">
                                {strings.username} {!updateData && `*`}
                            </FormLabel>
                            <Controller
                                as={<CustomTextInput />}
                                control={control}
                                name="username"
                                defaultValue={updateData && updateData.username}
                            />
                        </FormControl>
                    )}

                    <FormControl>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.email} {!updateData && `*`}
                        </FormLabel>
                        <Controller
                            as={<CustomTextInput type="email" />}
                            control={control}
                            name="email"
                            defaultValue={updateData && updateData.email}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.phoneNumber}
                        </FormLabel>
                        <Controller
                            as={<CustomTextInput />}
                            control={control}
                            name="phone_number"
                            defaultValue={updateData && updateData.phoneNumber}
                        />
                    </FormControl>

                    <Checkbox
                        size="lg"
                        alignItems="center"
                        color="mc_dark"
                        fontSize="md"
                        ref={register}
                        name="is_supervisor"
                        defaultChecked={updateData && updateData.supervisor}
                    >
                        {strings.supervisor}
                    </Checkbox>
					
                    <Checkbox
                        size="lg"
                        alignItems="center"
                        color="mc_dark"
                        fontSize="md"
                        ref={register}
                        name="is_active"
                        defaultChecked={updateData ? updateData.isActive : true}
                    >
                        {strings.active}
                    </Checkbox>

					<Checkbox
                        size="lg"
                        alignItems="center"
                        color="mc_dark"
                        fontSize="md"
                        ref={register}
                        name="new_employee"
                        defaultChecked={updateData ? updateData.newEmployee : true}
                    >
                        {strings.newEmployee}
                    </Checkbox>
                </SimpleGrid>
                <SimpleGrid minChildWidth="300px" spacing="20px">
                    <FormControl>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.password} {!updateData && `*`}
                        </FormLabel>
                        <InputGroup>
                            <Controller
                                as={<CustomTextInput />}
                                control={control}
                                name="password"
                                type={showPass ? "text" : "password"}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={handleClickOnPass}>
                                    {showPass ? "Sakrij" : "Prikaži"}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.repeatPassword} {!updateData && `*`}
                        </FormLabel>{" "}
                        <InputGroup>
                            <Controller
                                as={<CustomTextInput />}
                                control={control}
                                name="repeated_password"
                                type={showRepeatPass ? "text" : "password"}
                            />
                            <InputRightElement width="4.5rem">
                                <Button h="1.75rem" size="sm" onClick={handleClickOnRepeatPass}>
                                    {showRepeatPass ? "Sakrij" : "Prikaži"}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <GridItem></GridItem>
                </SimpleGrid>
                <Flex justify="flex-end" mt={4}>
                    <Button onClick={onClose} variant="red">
                        {strings.close}
                    </Button>
                    <Button type="submit" variant="medium" ml={3}>
                        {strings.save}
                    </Button>
                </Flex>
            </Stack>
        </form>
    )
}

export default UserForm
