import { Heading } from "@chakra-ui/layout"

const AdminPanelHeading = ({ text }) => {
    return (
        <Heading as="h4" flex="1" color="mc_medium" fontSize="20px" textAlign="center">
            {text}
        </Heading>
    )
}

export default AdminPanelHeading
