import { useDisclosure } from "@chakra-ui/hooks"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useApp from "../../redux/hooks/useApp"
import useEducation from "../../redux/hooks/useEducation"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { formatDateForCell, makeDropdownOptions, trueOrFalse } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedDefaultValues, smartedColumns } from "../../utils/smartedHelper"
import UpdateButton from "../buttons/UpdateButton"
import UpdateEduForm from "../forms/UpdateEduForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"

const EducationTable = ({ data, selectData }) => {
	const hook = useEducation()
	const hookUser = useUser()
	const hookApp = useApp()
	const config = useConfig()

	const [updateData, setUpdateData] = useState(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const columns = [
		{
			dataField: "name",
			text: strings.name,
		},
		{
			dataField: "date_created",
			text: strings.creationDate,
			formatter: (cell) => formatDateForCell(cell),
		},
		{
			dataField: "expiration_date",
			text: strings.expirationDate,
			formatter: (cell) => formatDateForCell(cell),
		},
		{
			dataField: "status_name",
			text: strings.status,
		},
		{
			dataField: "category_name",
			text: strings.category,
		},
		{
			dataField: "views",
			text: strings.views,
		},
		{
			dataField: "new_employee_content",
			text: "NEW EMPLOYEE CONTENT",
			formatter: (cell) => trueOrFalse(cell),
		},
	]

	if (hookUser.admin) {
		columns.push(
			{
				dataField: "companies_value",
				text: strings.companies,
			},
			{
				dataField: "app_name",
				text: strings.application,
			}
		)
	}

	if (config.appId === 2) {
		smartedColumns.forEach((column) => columns.push(column))
	}

	const handleUpdateModal = (row) => {
		const defaultCategory = selectData.categories.find((e) => e.label === row.category_name)
		const defaultStatus = selectData.statuses.find((e) => e.value === row.status)

		let defaultCompanies = []
		let defaultApp

		if (!hookUser.admin) {
			defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
			defaultApp = { label: hookApp.name, value: hookApp.id }
		} else {
			defaultCompanies = makeDropdownOptions(row.companies)
			defaultApp = selectData.apps.find((e) => e.label === row.app_name)
		}

		let expirationDate = ""

		if (row.expiration_date) {
			expirationDate = row.expiration_date
		}

		const defaultValues = {
			id: row.id,
			defaultCategory: defaultCategory,
			defaultApp: defaultApp,
			defaultCompanies: defaultCompanies,
			name: row.name,
			defaultStatus: defaultStatus,
			new_employee_content: row.new_employee_content,
			expiration_date: expirationDate,
			defaultContentType: selectData.contentTypes.find((val) => val.value === row.content_type),
		}

		if (config.appId === 2) {
			handleSmartedDefaultValues(defaultValues, row, selectData)
		}

		setUpdateData(defaultValues)

		onOpen()
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<DeleteModal remove={() => hook.remove(row.id)} />
				<UpdateButton onClick={() => handleUpdateModal(row)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	return (
		<>
			<TablesStyles appId={config.appId}>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<AddUpdateModal isOpen={isOpen} onClose={onClose} size="full">
				<UpdateEduForm handleClose={onClose} updateData={updateData} selectData={selectData} />
			</AddUpdateModal>
		</>
	)
}

export default EducationTable
