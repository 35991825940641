import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import {
	article,
	articleCategory,
	city,
	company,
	education,
	educationCategory,
	form,
	jobListing,
	sector,
	survey,
	test,
} from "../../utils/constants"
import { strings } from "../../utils/localization"
import { formSubmissionURL, genericRemoveURL, genericURL } from "../api_urls"

const START_REQUEST = createAction("GENERIC/START_REQUEST")
const FETCH_SUCCESS = createAction("GENERIC/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("GENERIC/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("GENERIC/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("GENERIC/CLEAN_MESSAGE")

export const fetchGeneric = (type, dropdownData) => async (dispatch) => {
	dispatch(START_REQUEST())
	let url = genericURL(type)
	if (dropdownData) {
		url = url + "&dropdown_data=True"
	}
	return axios.get(url).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL, type))
}

export const createGeneric = (type, payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(genericURL(type), payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateGeneric = (type, payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(genericURL(type), payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeGeneric = (type, id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(genericRemoveURL(type, id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const submitFormSubmission = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(formSubmissionURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

const handleResponse = (res, dispatch, success, fail, type) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success({ data: res.data, type: type }))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	companies: [],
	cities: [],
	educations: [],
	tests: [],
	surveys: [],
	articles: [],
	jobs: [],
	articleCategories: [],
	educationCategories: [],
	forms: [],
	sectors: [],
	message: null,
	loading: false,
}

export const genericReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			if (action.payload.type === company) {
				state.companies = action.payload.data
			} else if (action.payload.type === city) {
				state.cities = action.payload.data
			} else if (action.payload.type === education) {
				state.educations = action.payload.data
			} else if (action.payload.type === survey) {
				state.surveys = action.payload.data
			} else if (action.payload.type === test) {
				state.tests = action.payload.data
			} else if (action.payload.type === article) {
				state.articles = action.payload.data
			} else if (action.payload.type === jobListing) {
				state.jobs = action.payload.data
			} else if (action.payload.type === articleCategory) {
				state.articleCategories = action.payload.data
			} else if (action.payload.type === form) {
				state.forms = action.payload.data
			} else if (action.payload.type === educationCategory) {
				state.educationCategories = action.payload.data
			} else if (action.payload.type === sector) {
				state.sectors = action.payload.data
			}
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
