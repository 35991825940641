import LocalizedStrings from "react-localization"
import { albanian } from "./languages/albanian"
import { bosnian } from "./languages/bosnian"
import { bulgarian } from "./languages/bulgarian"
import { croatian } from "./languages/croatian"
import { english } from "./languages/english"
import { greek } from "./languages/greek"
import { macedonian } from "./languages/macedonian"
import { romanian } from "./languages/romanian"
import { serbian } from "./languages/serbian"
import { slovenian } from "./languages/slovenian"

export let strings = new LocalizedStrings({
	EN: english(),
	RS: serbian(),
	HR: croatian(),
	BIH: bosnian(),
	BG: bulgarian(),
	RO: romanian(),
	GR: greek(),
	AL: albanian(),
	SL: slovenian(),
	MK: macedonian(),
})
