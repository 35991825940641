import BootstrapTable from "react-bootstrap-table-next"
import { TablesStyles } from "./TablesStyles"
import { strings } from "../../utils/localization"
import { formatDateForCell } from "../../utils/functions"
import CustomButton from "../buttons/CustomButton"
import useComment from "../../redux/hooks/useComment"

const CommentTable = ({ data }) => {

    const hook = useComment()

    const columns = [
        {
            dataField: "article_title",
            text: strings.title,
        },
        {
            dataField: "username",
            text: strings.username,
        },
        {
            dataField: "comment",
            text: strings.comment,
        },
        {
            dataField: "date_created",
            text: strings.creationDate,
            formatter: (cell) => formatDateForCell(cell)
        },
        {
            dataField: "app_name",
            text: strings.application,
        },
    ]

    const handleApprove = (id) => {
        
        const payload = {
            id: id
        }

        hook.approveComment(payload).then(res => {
            if(res !== undefined && res.status === 200) {
                hook.fetchComments()
            }   
        })
    }

    const handleReject = (id) => {
        
        hook.rejectComment(id).then(res => {
            if(res !== undefined && res.status === 200) {
                hook.fetchComments()
            }   
        })
    }

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <CustomButton 
                    onClick={() => handleApprove(row.id)}
                    text={strings.approve}
                    variant={"medium"}
                />
                <CustomButton 
                    onClick={() => handleReject(row.id)}
                    text={strings.reject}
                    variant={"red"}
                />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }

    return (
        <>
            <TablesStyles>
                <BootstrapTable
                    data={data}
                    columns={columns}
                    keyField="id"
                    classes="TablesStyles"
                    expandRow={expandRow}
                    bordered
                    bootstrap4
                />
            </TablesStyles>
        </>
    )
}

export default CommentTable
