import { Button } from "@chakra-ui/button"
import { Box, Flex, Stack, Text } from "@chakra-ui/layout"
import React from "react"
import { ReactComponent as TrashCanIcon } from "../../assets/icons/trash-can.svg"
import useArticle from "../../redux/hooks/useArticle"
import useUser from "../../redux/hooks/useUser"
import { formatDateForCell } from "../../utils/functions"

const Comments = ({ data, fetchArticle }) => {
	const hook = useArticle()
	const hookUser = useUser()

	const checkCommentCondition = (username, hr_code) => {
		if (hookUser.admin || username === hookUser.username || hr_code === hookUser.hr_code) {
			return true
		} else {
			return false
		}
	}

	const handleDeleteComment = (id) => {
		if (hookUser.admin) {
			hook.removeComment(id).then((res) => {
				if (res !== undefined && res.status === 200) {
					fetchArticle()
				}
			})
		} else {
			hook.removeComment(id, hookUser.id).then((res) => {
				if (res !== undefined && res.status === 200) {
					fetchArticle()
				}
			})
		}
	}

	return (
		<Stack p="10px" spacing={4}>
			{data.map((element, i) => (
				<Box key={i} w="100%" minH="75px" rounded="xl" bg="mc_dark" pos="relative">
					<Flex alignItems="center" justify="space-between" p={["10px", "20px"]} gridGap="8px" flexWrap="wrap">
						<Text
							color="mc_bg"
							fontSize={["12px", "14px"]}
							letterSpacing={["2px", "4px"]}
							fontWeight="700"
							maxW={["250px", "300px"]}
						>
							{element.username ? element.username : element.hr_code}
						</Text>
						<Text
							color="mc_bg"
							fontSize={["11px", "12px"]}
							letterSpacing={["2px", "4px"]}
							fontWeight="700"
							textTransform="uppercase"
							textAlign="end"
						>
							{formatDateForCell(element.date_created)}
						</Text>
					</Flex>
					<Text
						borderLeft="2px solid"
						borderLeftColor="mc_bg"
						px="10px"
						pr={["10px", "30px"]}
						pb={["16px", 0]}
						color="mc_bg"
						ml={["10px", "20px"]}
						mb="10px"
						fontSize={["14px", "16px"]}
					>
						{element.comment}
					</Text>
					{checkCommentCondition(element.username, element.hr_code) && (
						<Button
							pos="absolute"
							px="10px"
							bottom={0}
							right={-1}
							h="30px"
							w="40px"
							border="1px solid"
							borderColor="mc_dark"
							onClick={() => handleDeleteComment(element.id)}
							title="delete"
						>
							<TrashCanIcon />
						</Button>
					)}
				</Box>
			))}
		</Stack>
	)
}

export default Comments
