import { Flex } from "@chakra-ui/layout"

const ReactionCountBox = ({ children }) => {
    return (
        <Flex
            alignItems="center"
            justifyContent="center"
            w="37px"
            h="37"
            rounded="xl"
            bg="mc_medium"
            position="relative"
            _hover={{ bg: "mc_dark", transition: "0.3s" }}
        >
            {children}
        </Flex>
    )
}

export default ReactionCountBox
