import { Box } from "@chakra-ui/layout"
import { Select } from "@chakra-ui/select"
import React from "react"
import ReactPaginate from "react-paginate"
import styled from "styled-components"
import { LeftArrow, RightArrow } from "../Icons"

const Pagination = ({ paginationData, fetchPage, setSize, filterData, role }) => {
	const handlePageChange = (item) => {
		fetchPage(paginationData.pageSize, item.selected + 1, role, filterData)
	}

	const handlePageSizeChange = (e) => {
		const val = e.target.value
		setSize(val)
		fetchPage(val, paginationData.currentPage, role, filterData)
	}

	return (
		<PaginationStyle>
			<Select w="130px" onChange={handlePageSizeChange}>
				{paginationData.pageSizesArray.map((number, i) => {
					return (
						<option key={i} value={number}>
							{number} of {paginationData.totalCount}
						</option>
					)
				})}
			</Select>
			<Box mx="auto" h="40px">
				<ReactPaginate
					previousLabel={<LeftArrow />}
					nextLabel={<RightArrow />}
					breakLabel={"..."}
					pageCount={paginationData.totalPages}
					onPageChange={handlePageChange}
					containerClassName={"pagination-container"}
					activeClassName={"activePag"}
				/>
			</Box>
		</PaginationStyle>
	)
}

const PaginationStyle = styled.div`
	width: 96%;
	height: 40px;

	display: flex;

	align-items: center;
	margin-top: 10px;
	.pagination-container {
		margin: 0 auto;
		width: 400px;
		height: 40px;
	}
	ul {
		list-style-type: none;
		display: flex;
		justify-content: space-between;
		align-items: center;
		li {
			min-width: 20px;
			cursor: pointer;
		}

		a[aria-current="page"] {
			text-align: center;
			outline: none;
			padding: 0 2px;
		}

		a {
			border-radius: 5px;
			padding: 5px;
			font-size: 17px;
			outline: none;
		}

		a[aria-label="Next page"],
		a[aria-label="Previous page"] {
			:focus {
				outline: auto;
				outline-color: var(--mc_medium);
			}
		}
		.activePag a {
			display: block;
			background: var(--mc_medium);
			color: white;
			width: 100%;
		}
		li.previous,
		li.next {
			display: flex;
			justify-content: center;
		}
	}

	@media (max-width: 920px) {
		.pagination-container {
			width: 150px;
		}
	}
`
export default Pagination
