import { useOutletContext } from "react-router-dom"
import Select from "react-select"
import { strings } from "../utils/localization"

const customStyles = (smallPlaceholder, colors) => {
	return {
		control: (base, state) => ({
			...base,
			height: "max-content",
			outline: "none",
			border: `2px solid ${colors.mc_bg}`,
			borderColor: state.isFocused
				? `${colors.dropdownOutline} !important`
				: 0 || state.isActive
				? `${colors.dropdownOutline} !important`
				: 0,

			paddingLeft: "5px",
			borderRadius: "6px",
			boxShadow: "0px 2px 10px rgba(36, 180, 188, 0.25)",
			backgroundColor: `${colors.mc_bg}`,
			"&:hover": {
				borderColor: "inherit",
			},
			"@media only screen and (max-width: 768px)": {
				...base["@media only screen and (max-width: 768px)"],
				fontSize: "14px",
			},
		}),
		placeholder: (base) => ({
			...base,
			fontSize: smallPlaceholder ? "14px" : "inherit",
			"@media only screen and (max-width: 768px)": {
				...base["@media only screen and (max-width: 768px)"],
			},
		}),

		menu: (base) => ({
			...base,
			"z-index": 10,
			"@media only screen and (max-width: 768px)": {
				...base["@media only screen and (max-width: 768px)"],
				fontSize: "14px",
			},
		}),

		menuList: (base) => ({
			...base,
			maxHeight: "200px",
		}),

		multiValue: (base) => {
			return {
				...base,
				backgroundColor: `${colors.mc_dark}`,
			}
		},
		multiValueLabel: (base) => ({
			...base,
			color: `${colors.mc_bg}`,
		}),
		multiValueRemove: (base) => ({
			...base,
			color: `${colors.mc_bg}`,
			":hover": {
				backgroundColor: `${colors.mc_medium}`,
				color: `${colors.mc_dark}`,
			},
		}),
	}
}

export default function SelectDropdown({ options, placeholder, smallPlaceholder, ...rest }) {
	const config = useOutletContext()

	const dropdownTheme = (base) => {
		return {
			...base,
			colors: {
				...base.colors,
				primary25: config.colors.mc_medium,
				primary: config.colors.mc_dark,
			},
		}
	}

	return (
		<Select
			{...rest}
			styles={customStyles(smallPlaceholder, config.colors)}
			theme={dropdownTheme}
			options={options}
			placeholder={placeholder ? placeholder : strings.choosePlaceholder}
			isClearable
		></Select>
	)
}
