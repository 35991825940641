import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Box, Flex } from "@chakra-ui/layout"
import { Controller } from "react-hook-form"
import { UploadCVIcon } from "../components/Icons"
import SelectDropdown from "../components/SelectDropdown"
import UploadFile from "../components/UploadFile"
import {
    concept,
    hqRetail,
    organizationUnit,
    positionSe1,
    positionSe2,
    sector,
    superiors,
} from "./constants"
import { columnTableString, makeDropdownOptionsValueField, makeIdsArray } from "./functions"
import { strings } from "./localization"

export const handleSmartedPermissionsPayload = (data, payload, type) => {
    const all = {
        countries: data.countries,
        cities: data.cities,
        users: data.users,
        hq_retails: data.hq_retails,
        concepts: data.concepts,
        organization_units: data.organization_units,
        sectors: data.sectors,
        positions_se_1: data.positions_se_1,
        positions_se_2: data.positions_se_2,
        superiors: data.superiors,
    }

    if (type === "formData") {
        Object.keys(all).forEach((key) => {
            // if(Array.isArray(all[key])){
            if (all[key] !== undefined && all[key].length > 0) {
                return payload.append(key, JSON.stringify(makeIdsArray(all[key])))
            } else {
                return payload.append(key, JSON.stringify([]))
            }
            // }else if(all[key]) {
            // 	return payload.append(key, all[key].value)
            // }
        })
    } else {
        Object.keys(all).forEach((key) => {
            // if(Array.isArray(all[key])){
            if (all[key] !== undefined && all[key].length > 0) {
                payload[`${key}`] = makeIdsArray(all[key])
            } else {
                payload[`${key}`] = []
            }
            // }else if(all[key]) {
            // 	payload[`${key}`] = all[key].value
            // }
        })
    }
}

export const handleSmartedSelectData = (allOptions, selectData) => {
    Object.keys(allOptions).forEach((key) => (selectData[`${key}`] = allOptions[key]))
}

export const handleSmartedDefaultValues = (defaultValues, row) => {
    //selectData

    const smartedDefaultValues = {
        defaultHqRetails: makeDropdownOptionsValueField(row.hq_retails),
        defaultCities: makeDropdownOptionsValueField(row.cities),
        defaultCountries: makeDropdownOptionsValueField(row.countries),
        defaultUsers: makeDropdownOptionsValueField(row.users),
        defaultConcepts: makeDropdownOptionsValueField(row.concepts),
        defaultOrganizationUnits: makeDropdownOptionsValueField(row.organization_units),
        defaultSectors: makeDropdownOptionsValueField(row.sectors),
        defaultPositionsSe1: makeDropdownOptionsValueField(row.positions_se_1),
        defaultPositionsSe2: makeDropdownOptionsValueField(row.positions_se_2),
        defaultSuperiors: makeDropdownOptionsValueField(row.superiors),
    }

    Object.keys(smartedDefaultValues).forEach(
        (key) => (defaultValues[`${key}`] = smartedDefaultValues[key])
    )
}

export const smartedExportOptions = [
    { label: "HQ RETAIL", value: hqRetail },
    { label: "CONCEPT", value: concept },
    { label: "ORGANIZATION UNIT", value: organizationUnit },
    { label: "SECTOR", value: sector },
    { label: "POSITION SE 1", value: positionSe1 },
    { label: "POSITION SE 2", value: positionSe2 },
    { label: "SUPERIORS", value: superiors },
]

export const smartedColumns = [
    {
        dataField: "countries",
        text: strings.countries,
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "cities",
        text: strings.cities,
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "superiors",
        text: "SUPERIORS",
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "hq_retails",
        text: "HQ RETAILS",
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "concepts",
        text: "CONCEPTS",
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "organization_units",
        text: "ORGANIZATION UNITS",
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "sectors",
        text: "SECTORS",
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "positions_se_1",
        text: "POSITIONS SE 1",
        formatter: (cell) => columnTableString(cell),
    },
    {
        dataField: "positions_se_2",
        text: "POSITIONS SE 2",
        formatter: (cell) => columnTableString(cell),
    },
]

export const smartedColumnsUser = [
    {
        dataField: "hq_retail_name",
        text: "HQ RETAIL",
    },
    {
        dataField: "superiors",
        text: "SUPERIORS",
    },
    {
        dataField: "concept_name",
        text: "CONCEPTS",
    },
    {
        dataField: "organization_unit_name",
        text: "ORGANIZATION UNIT",
    },
    {
        dataField: "sector_name",
        text: "SECTOR",
    },
    {
        dataField: "position_se_1_name",
        text: "POSITION SE 1",
    },
    {
        dataField: "position_se_2_name",
        text: "POSITION SE 2",
    },
]

const FormDropdown = ({ label, control, name, options, defaultValue, isMulti }) => {
    return (
        <FormControl minW="300px" w={["100%", "32%"]}>
            <FormLabel color="mc_dark" fontSize="14px">
                {label}
            </FormLabel>
            <Box w="100%">
                <Controller
                    as={<SelectDropdown isMulti={isMulti} />}
                    control={control}
                    name={name}
                    options={options}
                    defaultValue={defaultValue}
                />
            </Box>
        </FormControl>
    )
}

export const SmartedFields = ({ control, selectData, updateData, setSelectedFile, fileImport }) => {

	const handleFileSelect = (file) => {
		setSelectedFile(file)
	}

    return (
        <Flex w="100%" flexWrap="wrap" gridGap="20px">
            <FormDropdown
                label={strings.countries}
                control={control}
                name="countries"
                options={selectData.countries}
                defaultValue={updateData && updateData.defaultCountries}
                isMulti={true}
            />
            <FormDropdown
                label={strings.cities}
                control={control}
                name="cities"
                options={selectData.cities}
                defaultValue={updateData && updateData.defaultCities}
                isMulti={true}
            />
  
            <FormDropdown
                label={"HQ RETAILS"}
                control={control}
                name="hq_retails"
                options={selectData.hqRetails}
                defaultValue={updateData && updateData.defaultHqRetails}
                isMulti={true}
            />
            <FormDropdown
                label={"CONCEPTS"}
                control={control}
                name="concepts"
                options={selectData.concepts}
                defaultValue={updateData && updateData.defaultConcepts}
                isMulti={true}
            />
            <FormDropdown
                label={"ORGANIZATION UNITS"}
                control={control}
                name="organization_units"
                options={selectData.organizationUnits}
                defaultValue={updateData && updateData.defaultOrganizationUnits}
                isMulti={true}
            />

            <FormDropdown
                label={"SECTORS"}
                control={control}
                name="sectors"
                options={selectData.sectors}
                defaultValue={updateData && updateData.defaultSectors}
                isMulti={true}
            />
            <FormDropdown
                label={"POSITIONS SE 1"}
                control={control}
                name="positions_se_1"
                options={selectData.positionsSe1}
                defaultValue={updateData && updateData.defaultPositionsSe1}
                isMulti={true}
            />
            <FormDropdown
                label={"POSITIONS SE 2"}
                control={control}
                name="positions_se_2"
                options={selectData.positionsSe2}
                defaultValue={updateData && updateData.defaultPositionsSe2}
                isMulti={true}
            />

            <FormDropdown
                label={"SUPERIORS"}
                control={control}
                name="superiors"
                options={selectData.users}
                defaultValue={updateData && updateData.defaultSuperiors}
                isMulti={true}
            />

			<FormDropdown
                label={strings.users}
                control={control}
                name="users"
                options={selectData.users}
                defaultValue={updateData && updateData.defaultUsers}
                isMulti={true}
            />

            {
                fileImport && 

                <UploadFile 
                    format={[".csv", ".xlsx"]}  
                    uploadCVIcon={<UploadCVIcon />}
                    onChange={handleFileSelect}
			    />
            }      
	
        </Flex>
    )
}
