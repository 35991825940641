import { Heading, Link, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react"
import React from "react"
import useConfig from "../utils/config"
import { strings } from "../utils/localization"

const PrivacyPolicyText = () => {
    const config = useConfig()

    return (
        <Stack spacing={4} fontSize="16px" color="mc_black">
            <Heading
                as={"h1"}
                color="mc_medium"
                fontSize="20px"
                lineHeight={1.8}
                textAlign="center"
                mb="20px"
            >
                {strings.privacyPolicy} <br /> {`${config.companyName} doo`} Kraljevo
            </Heading>
            <Text>
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    {`${config.companyName} doo`} Kraljevo
                </Text>
                (u daljem tekstu:{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    {config.companyName}
                </Text>
                ), sa sedištem u Kraljevu, ul. Ribnička br. 26, {config.companyInfo}, u obavljanju
                svoje delatnosti obrađuje podatke o ličnosti poštujući sve relevantne zakone i
                propise.
            </Text>
            <Text color="mc_medium" fontWeight="700" textAlign="center">
                Molimo Vas da sledeće Informacije pažljivo pročitate!
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Rukovalac podacima o ličnosti
            </Heading>
            <Text>
                {config.companyName} je rukovalac podacima o ličnosti u skladu sa zakonom i
                propisima o zaštiti podataka o ličnosti.{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    {config.companyName}
                </Text>
                , kao Rukovalac podacima o ličnosti,  određuje svrhu i sredstva obrade podataka o
                ličnosti i odgovoran je za čuvanje i korišćenje podataka o ličnosti u pisanoj i/ili
                elektronskoj formi.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Obrađivač podataka o ličnosti
            </Heading>
            <Text>
                {config.companyName}, u svom poslovanju, može obrađivati podatke o ličnosti u ime
                Rukovaoca podacima o ličnosti, odnosno u skladu sa njegovim pisanim uputstvima u kom
                slučaju ima svojstvo Obrađivača podataka o ličnosti.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Koje podatke o ličnosti koje {config.companyName} prikuplja
            </Heading>
            <Text>
                Podaci o ličnosti koje {config.companyName} prikuplja podatke o ličnosti svojih
                klijenata, odnosno korisnika internet aplikacije {config.policyAppName}, kao i
                posetilaca internet prezentacije koja se nalazi na internet domenu:{" "}
                <Link href={config.website} isExternal color="mc_medium" display="inline-block">
                    {config.website}
                </Link>
                . Reč je, pre svega, o podacima o ličnosti bez kojih {config.companyName} ne može da
                izvrši uslugu, a uključuju najmanje podatke vezane za korišćenje internet
                prezentacije koje se nalaze na pomenutom internet domenu, kao i ostale lične podatke
                neophodne za ostvarenje klijentovih prava baziranih na zakonskim odredbama, a
                uključuju najmanje:
                <UnorderedList>
                    <ListItem>osnovne lične podatke (ime i prezime)</ListItem>
                    <ListItem>kontakt podatke (e-mail adresa, broj telefona)</ListItem>
                    <ListItem>
                        podatke vezane za korišćenje internet prezentacije koja se nalazi na
                        internet domenu{" "}
                        <Link
                            href={config.website}
                            isExternal
                            color="mc_medium"
                            display="inline-block"
                        >
                            {config.website}
                        </Link>{" "}
                        (IP adresa)
                    </ListItem>
                    <ListItem>
                        ostale lične podatke neophodne za ostvarenje klijentovih prava baziranih na
                        zakonskim odredbama o zaštiti potrošača.
                    </ListItem>
                </UnorderedList>
                Nezavisno od odabranog komunikacijskog kanala zahtev se podnosi lično i na takav
                način da je zaposlenima i/ili ovlašćenim licima {config.companyName}-a omogućena
                identifikacija podnosioca zahteva.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Prikupljanje i korišćenje podataka o ličnosti
            </Heading>
            <Text>
                {config.companyName} prikuplja i upravlja podacima o ličnosti radi pružanja usluga
                iz oblasti svog poslovanja, kao i kako bi ispunio zakonske i regulatorne obaveze i u
                svrhu ostvarenja sopstvenih legitimnih interesa zakonitog poslovanja. Ako to od nas
                traže relevantni zakonski propisi, za određene razloge ćemo od korisnika dodatno
                tražiti njegov (Vaš) izričiti pristanak.
            </Text>
            <Text>
                Razlozi zbog kojih obrađujemo podatke o ličnosti radi 
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    sklapanja i ispunjenja ugovornih obaveza 
                </Text>
                su:
                <UnorderedList>
                    <ListItem>administriranja internet aplikacije {config.policyAppName}</ListItem>
                    <ListItem>upravljanje i primanje prigovora, pritužbi i reklamacija;</ListItem>
                    <ListItem>
                        druge zakonske obaveze koje proizlaze iz poslovanja {config.companyName}-a.
                    </ListItem>
                </UnorderedList>
            </Text>
            <Text>
                Razlozi radi kojih obrađujemo Vaše podatke o ličnosti na bazi naših poslovnih
                legitimnih interesa su:
                <UnorderedList>
                    <ListItem>obaveštavanje o promenama vezanim za uslove poslovanja;</ListItem>
                    <ListItem>sprečavanje i otkrivanje prevare.</ListItem>
                </UnorderedList>
            </Text>
            <Text>
                Za sve druge svrhe, koje nisu uvrštene u gore pobrojane svrhe, {config.companyName}{" "}
                će zatražiti Vaš dodatni pristanak. Dati pristanak možete povući u svakom trenutku.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Pristup podacima o ličnost
            </Heading>
            <Text>
                {config.companyName} preduzima tehničke i organizacione mere kako bi se prikupljeni
                podaci o ličnosti obrađivali samo na način koji je u skladu sa navedenom svrhom.
                Ukoliko je neophodno u svrhu izvršenja naših poslovnih aktivnosti, pristup podacima
                o ličnosti će biti omogućen trećim stranama i to:
                <UnorderedList>
                    <ListItem>
                        državnim organima i organima javne vlasti koja u svrhu sprovođenja zakonskih
                        obaveza imaju pravo pristupa podacima o ličnosti;
                    </ListItem>
                    <ListItem>advokatima, </ListItem>
                    <ListItem>
                        servisnim službama, štampariji, finansijskim ustanovama i drugim poslovnim
                        partnerima.
                    </ListItem>
                </UnorderedList>
                Svi, izuzev državnih organa i organa javne vlasti, podatke o ličnosti obrađuju
                isključivo prema uputstvima {config.companyName}-a.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Obrada podataka o ličnosti
            </Heading>
            <Text>
                Korisnički podaci o ličnosti će biti obrađivani od strane izvršilaca obrade koji su
                navedeni u poglavlju{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    Pristup podacima o ličnosti
                </Text>
                , a čija je odgovornost i obaveza zaštite podataka o ličnosti i čuvanja njihove
                poverljivosti definisana ugovornim odnosom i u skladu sa svim tehničkim i
                organizacijskim merama i zakonskim propisima koji uređuju zaštitu podataka. Vaši
                podaci, koje je {config.companyName} prikupio u svrhe navedene u poglavlju{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    Prikupljanje i korišćenje podataka o ličnosti
                </Text>
                , neće biti dostupni nikome ko za to nema ovlašćenje {config.companyName}
                -a.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Ostvarivanje prava
            </Heading>
            <Text>
                Kada je to zakonski predviđeno lice čije podatke {config.companyName} obrađuje ima{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    pravo na informacije
                </Text>{" "}
                o obradi njegovih podataka o ličnosti;
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    povlačenje svog pristanka
                </Text>{" "}
                u bilo koje vreme, ako podatke o ličnosti {config.companyName} obrađuje uz dat
                pristanak;{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    ispravku ili dopunu podataka
                </Text>{" "}
                o ličnosti kako bi uvek bili tačni;{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    brisanje podatakao ličnosti
                </Text>{" "}
                kada više nisu potrebni za gore navedene svrhe;{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    podnošenje prigovora
                </Text>{" "}
                {config.companyName}-u ili nadležnom državnom organu.
            </Text>
            <Text>
                Lica na koja se podaci o ličnosti odnose mogu svoja prava ostvariti postavljanjem
                zahteva, i to putem sledećih komunikacijskih kanala:
                <UnorderedList>
                    <ListItem>
                        lično u poslovnim prostorijama {config.companyName}-a – Ul. Ribnička br. 26,
                        Kraljevo
                    </ListItem>
                    <ListItem>
                        putem elektronske pošte poslate sa u sistemu {config.companyName}
                        -a registrovane e-mail adrese:{" "}
                        <Text color="mc_medium" display="inline-block">
                            {config.support}
                        </Text>
                    </ListItem>
                </UnorderedList>
                Nezavisno od odabranog komunikacijskog kanala zahtev se podnosi lično i na takav
                način da je zaposlenima i/ili ovlašćenim licima {config.companyName}-a omogućena
                identifikacija podnosioca zahteva.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Pravo na prigovor nadzornom organu
            </Heading>
            <Text>
                Osim prigovora na obradu podataka o ličnosti podnesenog {config.companyName}–u,
                prigovor na obradu ličnih podataka moguće je podneti i kancelariji {" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    Poverenika za informacije od javnog značaja i zaštitu podataka o ličnosti
                </Text>
                , Bulevar kralja Aleksandra 15, 11 000 Beograd, Republika Srbija, na e-mail {" "}
                <Text color="mc_medium" display="inline-block">
                    office@poverenik.rs
                </Text>
                 ili na broj telefona: 011 3408 900.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Čuvanje podataka o ličnosti
            </Heading>
            <Text>
                Vremenski period čuvanja podataka o ličnosti korisnika zavisi od svrhe zbog koje
                {` ${config.companyName}`} obrađuje.
                <UnorderedList>
                    <ListItem>
                        Podaci prikupljeni u svrhu administriranja internet aplikacije{" "}
                        {config.policyAppName} - do momenta brisanja korisničkog naloga od strane
                        lica čiji se podaci obrađuju na ovoj aplikaciji (opoziv saglasnosti);
                    </ListItem>
                    <ListItem>
                        Podaci prikupljeni zbog obrada baziranih na zakonskoj osnovi, čuvaju se u
                        skladu sa vremenskim ograničenjima odredbi predmetnog zakona (na primer
                        Zakon o računovodstvu i slično).
                    </ListItem>
                </UnorderedList>
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Kontakt
            </Heading>
            <Text>
                Za bilo kakva pitanja o načinu korišćenja podataka o ličnosti možete nas
                kontaktirati telefonom, e-mailom ili poštom kako sledi:
                <UnorderedList>
                    <ListItem>
                        slanjem elektronske pošte na{" "}
                        <Text color="mc_medium" display="inline-block">
                            {config.support}
                        </Text>
                    </ListItem>
                    <ListItem>
                        pisanim putem preko adrese:{" "}
                        <Text display="inline-block" color="mc_medium" fontWeight="700">
                            {`${config.companyName} doo`} Kraljevo
                        </Text>
                        , ul. Ribnička br. 26, Kraljevo, Republika Srbija putem obrasca koji možete
                        preuzeti 
                        <Link
                            href={config.website}
                            isExternal
                            color="mc_medium"
                            display="inline-block"
                        >
                            ovde
                        </Link>
                        ;{" "}
                    </ListItem>
                    <ListItem>pozivom kontakt centra na +381 60 352 5350.</ListItem>
                </UnorderedList>
                Za komunikaciju zbog ostvarivanja prava vezanih za zaštitu podataka o ličnosti
                potrebno je koristiti komunikacijske kanale definisane u poglavlju{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    „Ostvarivanje prava“
                </Text>{" "}
                kako bi svaki zahtev bio prihvaćen, a korisnik kao podnosilac zahteva identifikovan.
            </Text>
            <Heading as="h4" fontSize="18px" color="mc_medium" textAlign="left">
                Ažuriranje Informacije o obradi podataka o ličnosti
            </Heading>
            <Text>
                {config.companyName} redovno revidira i ažurira sadržaj Politike privatnosti i isti
                usklađuje sa promenama u sopstvenim poslovnim procesima, sa promenama zakonskih
                odredbi i regulatornih obaveza. Aktuelna verzija Politike privatnosti dostupna je na
                internet prezentaciji {config.companyName}-a koja se nalaze na internet domenu:{" "}
                <Link href={config.website} isExternal color="mc_medium" display="inline-block">
                    {config.website}.
                </Link>
            </Text>
            <Text>
                Ukoliko dođe do značajnih promena koje u većoj meri utiču na obradu podataka o
                ličnosti {config.companyName} će takve promene bez odlaganja objaviti na svojim
                internet prezentacijama i direktno Vas informisati.{" "}
            </Text>
            <Text>
                Obuku za{" "}
                <Text display="inline-block" color="mc_medium" fontWeight="700">
                    zaštitu podataka o ličnosti
                </Text>{" "}
                za privredno društvo {config.companyName} izvršila je Advokatska kancelarija
                Cvjetićanin&Partners.
            </Text>
        </Stack>
    )
}

export default PrivacyPolicyText
