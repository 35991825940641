import { articleURL, articleRemoveURL, articlePageAdminURL } from "../api_urls";
import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios";
import { strings } from "../../utils/localization";

const START_REQUEST = createAction("ARTICLE/PANEL/START_REQUEST")
const FETCH_SUCCESS = createAction("ARTICLE/PANEL/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("ARTICLE/PANEL/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("ARTICLE/PANEL/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("ARTICLE/PANEL/CLEAN_MESSAGE")
const CHANGE_SIZE = createAction("ARTICLE/PANEL/CHANGE_SIZE")

export const fetchArticlePage = (perPage, pageNumber) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.get(articlePageAdminURL(perPage, pageNumber))
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
};

export const createArticle = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.post(articleURL, payload, {headers: {"content-type": "multipart/form-data"}})
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const updateArticle = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.put(articleURL, payload, {headers: {"content-type": "multipart/form-data"}})
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const removeArticle = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.delete(articleRemoveURL(id))
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

export const changeDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleResponse = (res, dispatch, success, fail) => {
	if(res !== undefined) {
		if(res.status >= 200 && res.status <= 299) {
			if(success === FETCH_SUCCESS) {
				dispatch(success(res.data))
			}else{
				dispatch(success(res.data.message))
			}
			return res
		}else if(res.response !== undefined && res.response.status === 400){
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		}else{
			dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
			dispatch(CLEAN_MESSAGE())
		}
	}else{
		dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
		dispatch(CLEAN_MESSAGE())
	}

}


const initState = {
	data: [],
	pagination: null,
	defaultSize: 25,
	message: null,
	loading: false,
};

export const articlePanelReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.articles
			state.pagination = action.payload.pagination
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
	})
  