export const bulgarian = () => {
	return {
		showMore: "Покажи повече",
		edit: "редактиране",
		educationAuthorIs: "Това образование е направено от компанията:",
		startTest: "старт тест",
		didTest: "Направихте тест",
		maxTimes: "максимален брой пъти",
		endTest: "ПОПЪЛНЕТЕ ТЕСТА",
		requiredFieldsMessage: "Полетата маркирани с * са задължителни!",
		allRequiredFieldsMessage: "Всички полета са задължителни!",
		requiredTextOrImageMessage: "Не сте въвели текст или изображение!",
		requiredTextMessage: "Не сте въвели никакъв текст!",
		requiredTextOfAllAnswersMessage: "Попълнете текста на всички отговори!",
		oneCorrectAnswerMessage: "Само един отговор може да бъде верен!",
		atLeastOneCorrectAnswerMessage: "Поне един отговор трябва да е верен!",
		requiredImageMessage: "Изображението трябва да бъде избрано!",
		requiredQuestionTextMessage: "Въведете текста на въпроса!",
		requiredEducationSlideNumberMessage: "Въведете номера на образователния слайд!",
		requiredAllQuestions: "Попълнете всички въпроси!",
		passwordDontMatchMessage: "Паролите не съвпадат!",
		noDataSelectedMessage: "Данните не са избрани!",
		noReportSelectedMessage: "Докладът не е избран!",
		noTestSelectedMessage: "Тестът не е избран!",
		noTypeSelectedMessage: "Типът не е избран!",
		noArticleSelected: "Новини не е избрана!",
		noSurveySelected: "Проучването не е избрано!",
		noJobSelected: "Работата не е избрана!",
		notEnoughReportDataMessage: "Няма достатъчно данни за избрания отчет",
		enterCommentMessage: "Въведете коментар!",
		leaveComment: "ОСТАВЕТЕ КОМЕНТАР",
		report: "ДОКЛАД",
		test: "ТЕСТ",
		type: "ТИП",
		from: "ОТ",
		to: "ДА СЕ",
		previous: "Предишна",
		next: "Следваща",
		page: "Страница",
		of: "от",
		generate: "ГЕНЕРИРАЙТЕ",
		choosePlaceholder: "Избирам...",
		name: "ИМЕ",
		title: "ЗАГЛАВИЕ",
		education: "Обучение",
		category: "КАТЕГОРИЯ",
		application: "ПРИЛОЖЕНИЕ",
		company: "ТЪРГОВСКО ДРУЖЕСТВО",
		survey: "ИЗСЛЕДВАНЕ",
		country: "СТРАНА",
		city: "ГРАД",
		jobTitle: "ЗВАНИЕ",
		position: "ПОЗИЦИЯ",
		language: "ЕЗИК",
		phoneNumber: "ТЕЛЕФОНЕН НОМЕР",
		licenceNumber: "НОМЕР НА ЛИЦЕНЗИЯ",
		biography: "БИОГРАФИЯ",
		slideText: "СЛАЙД ТЕКСТ",
		image: "ИЗОБРАЖЕНИЕ",
		question: "ВЪПРОС",
		questions: "ВЪПРОСИ",
		educationSlide: "СЛАЙД ЗА ОБРАЗОВАНИЕ",
		answers: "ОТГОВОРИ",
		summaryText: "РЕЗЮМЕ ТЕКСТ",
		coverPhoto: "СНИМКА НА КОРИЦАТА",
		content: "СЪДЪРЖАНИЕ",
		status: "СЪСТОЯНИЕ",
		close: "ИЗХОД",
		back: "НАЗАД",
		forward: "НАПРЕД",
		save: "СПЕСТЕТЕ",
		password: "ПАРОЛА",
		repeatPassword: "ПОВТОРИ ПАРОЛАТА",
		newPassword: "НОВА ПАРОЛА",
		newPasswordRepeat: "ПОВТОРИ НОВАТА ПАРОЛА",
		code: "КОД",
		companyType: "ТИП КОМПАНИЯ",
		firstName: "ПЪРВО ИМЕ",
		lastName: "ФАМИЛИЯ",
		username: "ПОТРЕБИТЕЛ",
		email: "ЕЛЕКТРОННА ПОЩА",
		supervisor: "РЪКОВОДИТЕЛ",
		users: "ПОТРЕБИТЕЛИ",
		reports: "ДОКЛАДИ",
		importExport: "ВНОС/ИЗНОС",
		masterData: "ОСНОВНИ ДАННИ",
		countries: "ДЪРЖАВИ",
		jobTitles: "ЗАГЛАВИЯ",
		positions: "ПОЗИЦИИ",
		companyTypes: "ВИДОВЕ КОМПАНИЯ",
		cities: "ГРАДОВЕ",
		companies: "ФИРМИ",
		educationCategories: "КАТЕГОРИИ НА ОБРАЗОВАНИЕТО",
		articleCategories: "КАТЕГОРИИ НОВИНИ",
		languages: "ЕЗИЦИ",
		article: "НОВИНИ",
		articles: "НОВИНИ",
		surveys: "ИЗСЛЕДВАНИЯ",
		educations: "ОБУЧЕНИЕ",
		tests: "ИЗПИТВАНИЯ",
		panel: "ПАНЕЛ",
		signOut: "ОТПИСВАНЕ",
		signIn: "ВПИШИ СЕ",
		controlPanel: "КОНТРОЛЕН ПАНЕЛ",
		profile: "ПРОФИЛ",
		cancel: "ОТМЕНЯ",
		delete: "ИЗТРИЙ",
		deleteQuestion: "ИЗТРИВАНЕ НА ВЪПРОС",
		deleteWarningMessage: "Ако изтриете този елемент, няма да можете да го възстановите!",
		yes: "ДА",
		no: "НЕ",
		creationDate: "ДАТА НА СЪЗДАВАНЕ",
		views: "МНЕНИЯ",
		reactions: "РЕАКЦИИ",
		comments: "КОМЕНТАРИ",
		registrationTime: "ВРЕМЕ ЗА РЕГИСТРАЦИЯ",
		lastLogin: "ПОСЛЕДНО ВЛИЗАНЕ",
		active: "АКТИВЕН",
		congratulations: "ЧЕСТИТО",
		completedTest: "ПРИПЪЛНИХТЕ ТЕСТА",
		monthlyReport: "Месечен отчет",
		fileSelected: "Избран е файл",
		chooseCsvFile: "Изберете .csv файл",
		photo: "Изберете .csv файл",
		selectPhoto: "Изберете снимка",
		noFileSelectedMessage: "Файлът не е избран!",
		exportData: "ДАННИ ЗА ИЗНОС",
		export: "ИЗНОС",
		importData: "ИМПОРТИРАНЕ НА ДАННИ",
		import: "ВНОС",
		confirm: "ИЗПРАЩАНЕ",
		noComments: "Без коментари",
		loadMore: "ЗАРЕДИ ОЩЕ",
		author: "АВТОР",
		notFoundMessage: "Страницата, която търсите, не е намерена",
		updateData: "АКТУАЛИЗИРАНЕ НА ДАННИТЕ",
		privacyPolicy: "ПОЛИТИКА ЗА КОНФИДЕНЦИАЛНОСТ",
		result: "РЕЗУЛТАТ",
		serverErrorMessage: "Грешка в сървъра!",
		wrongCredentialsMessage: "Не е намерен акаунт с дадени идентификационни данни!",
		noAccount: "Нямате акаунт?",
		clickHere: "Натисни тук",
		forgotPassword: "Забравена парола?",
		backTo: "Обратно към",
		loginPage: "страница за вход",
		emailAddress: "ИМЕЙЛ АДРЕС",
		passwordReset: "НУЛИРАНЕ НА ПАРОЛАТА",
		registration: "РЕГИСТРИРАЙ СЕ",
		noMore: "Няма повече!",
		welcomeTo: "добре дошли в",
		byUser: "ОТ ПОТРЕБИТЕЛ",
		byCity: "ПО ГРАД",
		byQuestions: "ПО ВЪПРОСИ",
		loginCount: "Брой на вход",
		articleViews: "Изгледи на новини",
		articleReactions: "Реакции на новини",
		educationViews: "Възгледи на образованието",
		passedTests: "Издържани тестове",
		monthly: "МЕСЕЧНО",
		noEduSelectedMessage: "Образованието не е избрано!",
		deleteSlide: "ИЗТРИЙ СЛАЙДА",
		cantDeleteFirst: "Не можете да изтриете първия слайд!",
		agreePrivacyPolicy: "Съгласен съм с политиката за поверителност",
		articleAll: "ВСИЧКО",
		articleByUserReports: "ОТ ДОКЛАДИ НА ПОТРЕБИТЕЛИ",
		imageMissing: "Изображението липсва някъде!",
		monthlyPoints: "ТОЧКИ ЗА ТОЗИ МЕСЕЦ:",
		annualPoints: "ТОЧКИ ЗА ТАЗИ ГОДИНА:",
		notePlaceholder: "Забележка...",
		wrongAppMessage: "Опитвате се да получите достъп до грешна платформа!",
		eduAll: "ВСИЧКО",
		eduChoose: "ИЗБИРАМ",
		eduChooseCompleted: "ИЗБЕРЕТЕ ЗАВЪРШЕНО",
		eduChooseNotCompleted: "ИЗБЕРЕТЕ НЕ ЗАВЪРШЕНО",
		selectFile: "Изберете файл",
		searchPlaceholder: "Търсене...",
		repeatTest: "ПОВТОРИТЕ ТЕСТА",
		usersUpdate: "АКТУАЛИЗАЦИЯ НА ПОТРЕБИТЕЛИТЕ",
		total: "TOTAL",
		track: "TRACK",
		loginData: "LOGIN DATA",
		checkEmailMessage: "Отворете имейл връзка, за да потвърдите регистрацията (проверете спам)",
		subquestion: "ПОДВЪПРОС",
		comment: "КОМЕНТАР",
		reject: "ОТХВЪРЛЯМ",
		approve: "ОДОБРЯВАМ",
		tags: "ЕТИКЕТИ",
		deadline: "краен срок",
		applicationDeadline: "краен срок за кандидатстване",
		jobListings: "ОБЯВИ ЗА РАБОТА",
		apply: "Регистрирай се",
		openFormToApply: "Отворете формуляра за кандидатстване",
		uploadYourCV: "качете автобиографията си",
		uploadFile: "Качи файл",
		yourCVIsUploaded: "автобиографията ви е качена",
		applied: "ВИЕ СИ РЕГИСТРИРАН",
		jobs: "РАБОТНИ МЕСТА",
		job: "РАБОТА",
		note: "Забележка",
		newEmployees: "Нови служители",
		newEmployee: "НОВ СЛУЖИТЕЛ",
		updateEmploymentStatus: "Актуализирайте статуса на заетостта",
		banner: "реклама",
		url: "url-връзка",
		expirationDate: "СРОК НА ГОДНОСТ",
		expired: "ПРОСРОЧЕН",
		days: "ДНИ",
		hours: "ЧАСА",
		useCustomTheme: "използвайте персонализирана тема",
		chooseTheColorOfTheApplication: "изберете цвета на приложението",
		selectTheApplicationLogo: "изберете логото на приложението",
		selectTheApplicationFont: "изберете шрифта на приложението",
		currentLogo: "текущо лого",
		font: "шрифт",
		black: "черен",
		background: "заден план",
		dark: "тъмно",
		medium: "среден",
		light: "светлина",
		red: "червен",
		assignedToUsers: "ПРЕДНАЗНАЧЕНО НА ПОТРЕБИТЕЛИ",
		testAnswerQuestion: "Отговори на въпроса",
		testMaxRepeatNumberReached: "Направихте теста максималния брой пъти",
		selectDocument: "Изберете документ",
		UA_LOGIN_ACTIVITY: "Дейност",
		UA_NEWS_VIEWS: "Новини",
		UA_NEWS_REACTIONS: "Реакции",
		UA_EDUCATION_VIEWS: "Образования",
		UA_TESTS: "Тестове",
		UA_LOGIN_ACTIVITY_TP: "Активност на преминалите тестове през предходните 100 дни",
		UA_NEWS_VIEWS_TP: "Новини, видени през последните 100 дни",
		UA_NEWS_REACTIONS_TP: "Брой реакции през предходните 100 дни",
		UA_EDUCATION_VIEWS_TP: "Успешно завършени обучения през предходните 100 дни",
		UA_TESTS_TP: "Активност на преминалите тестове през предходните 100 дни",
		ACTIVITY: "Дейност",
		NEWS: "Новини",
		EDUCATIONS: "Образования",
		TESTS: "Тестове",
		achievements: "Постижения",
		ACTIVITY_DAYS: "дни на последователно влизане",
		EDUCATIONS_FINISHED: "завършени образования",
		NEWS_LIKED: "харесани новини",
		NEWS_VIEWED: "прегледани новини",
		TESTS_SUCCESS_FINISHED: "преминали тестове с всички верни отговори",
		TESTS_FINISHED: "завършени тестове",
		numberOfAchievements: "Брой постижения",
		currentRank: "Текущ ранг",
		numberOfUnread: "Брой непрочетени",
		numberOfNotCompleted: "Брой незавършени",
		documents: "Документи",
		document: "документ",
		download: "Изтегли",
		completedAllEdu: "Вие сте готови",
		readAllNews: "Вие прочетохте всичко",
		forms: "Форми",
		month: "Месец",
		year: "Година",
		absenceRequests: "МОЛБИ ЗА ОТСЪСТВИЕ",
		absenceRequestType: "ТИП ОТСЪСТВИЕ",
		byAssignedUser: "ОТ ВЪЗЛОЖЕН ПОТРЕБИТЕЛ",
		bySubQuestions: "ПО ПОДВЪПРОСИ",
		lockedEduMessage:
			"Уважаеми потребители, стига да сте нов служител, трябва да провеждате обучения за нови служители, за да имате достъп до други обучения.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Искане за административна забрана",
		FORM_PZDF: "Заявление за споделяне на листовки",
		contactPhoneNumber: "ТЕЛЕФОН ЗА ВРЪЗКА",
		FORM_TK: "Състезателен клуб",
		FORM_ZPOZ: "Заявка за сертификати за заетост",
		bank: "БАНКА",
		certificationPurpose: "ЦЕЛ НА СЕРТИФИЦИРАНЕТО",
		documentType: "ВИД ДОКУМЕНТ",
		documentVerification: "ДОКУМЕНТ ЗА ПРОВЕРКА",
		loanStatement: "ИЗЯВЛЕНИЕ ЗА НЕЗАВИСИМО ПОГАСЯВАНЕ НА ЗАЕМА",
		FORM_ZZO: "Оставете заявки",
		leaveType: "ВИД ОТПУСК",
		dateFrom: "ДАТА ОТ",
		dateTo: "ДАТА НА",
		absenceDocument: "ДОКУМЕНТ ЗА ОТСЪСТВИЕ",
		FORM_PK: "Печеливша комбинация",
		commentNote: "КОМЕНТАР/БЕЛЕЖКА",
		recommendation: "ПРЕПОРЪКА",
		FORM_IZ: "Инициативи на служителите",
		initiative: "ИНИЦИАТИВА",
		choose: "Избирам",
	}
}
