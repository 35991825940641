import { useDisclosure } from "@chakra-ui/hooks"
import { Box, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import AddJobApplication from "../../components/forms/AddJobApplication"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import BoardTable from "../../components/tables/BoardTable"
import Pagination from "../../components/tables/Pagination"
import useBillboard from "../../redux/hooks/useBillboard"
import useGeneric from "../../redux/hooks/useGeneric"
import useMasterdata from "../../redux/hooks/useMasterdata"
import { application, city, company, tag } from "../../utils/constants"
import { handleToast, makeDropdownOptions, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"

const AdminBoardPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const hook = useBillboard()
    const hookMd = useMasterdata()
    const hookG = useGeneric()
    const toast = useToast()

    const [apps, setApps] = useState([])
    const [tags, setTags] = useState([])
    const [cities, setCities] = useState([])
    const [companies, setCompanies] = useState([])

    const pagination = hook.pagination

    useEffect(() => {
        hook.fetchPage(hook.defaultSize, 1)
        hookMd.fetch(application)
        hookMd.fetch(tag)
        hookG.fetch(city)
        hookG.fetch(company)
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) // eslint-disable-line

    useEffect(() => {
        makeDropdownOptions(hookMd.apps, setApps)
        makeDropdownOptions(hookMd.tags, setTags)
        makeDropdownOptions(hookG.cities, setCities)
        makeDropdownOptions(hookG.companies, setCompanies)
    }, [hookMd.apps, hookMd.tags, hookG.cities, hookG.companies]) //eslint-disable-line

    const selectData = {
        apps: apps,
        tags: tags,
        cities: cities,
        companies: companies,
    }

    const submit = (data) => {
        if (
            !data.app ||
            !data.city ||
            !data.company ||
            !data.description ||
            !data.summary_text ||
            !data.title ||
            !data.application_deadline
        ) {
            handleToast(toast, { text: strings.allRequiredFieldsMessage, type: "error" }, "2")
        } else {
            const payload = {
                app: data.app.value,
                city: data.city.value,
                company: data.company.value,
                description: data.description,
                summary_text: data.summary_text,
                tags: makeIdsArray(data.tags),
                title: data.title,
                application_deadline: data.application_deadline,
            }

            hook.create(payload).then((res) => {
                if (res !== undefined && res.status === 200) {
                    onClose()
                    hook.fetchPage(hook.defaultSize, 1)
                }
            })
        }
    }

    return (
        <>
            <Box
                h="95%"
                overflow="auto"
                border={"4px"}
                boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
                borderColor="mc_black"
                borderRadius="4px"
                w="100%"
            >
                <BoardTable data={hook.data} selectData={selectData} />
                <PlusButton onClick={() => onOpen()} />
                <AddUpdateModal isOpen={isOpen} onClose={onClose} size="6xl">
                    <AddJobApplication
                        handleClose={onClose}
                        selectData={selectData}
                        submit={submit}
                    />
                </AddUpdateModal>
            </Box>
            {hook.pagination && pagination.total_count > 10 && (
                <Box w="80%" mx="auto">
                    <Pagination
                        paginationData={{
                            totalCount: pagination.total_count,
                            totalPages: pagination.total_pages,
                            currentPage: pagination.current_page,
                            pageSize: hook.defaultSize,
                            pageSizesArray: [10, 25, 50],
                        }}
                        setSize={hook.changeSize}
                        fetchPage={hook.fetchPage}
                    ></Pagination>
                </Box>
            )}
        </>
    )
}

export default AdminBoardPage
