import { Button } from "@chakra-ui/button"
import { Box, Center, Flex, Heading, Stack, Text } from "@chakra-ui/layout"
import { useEffect } from "react"
import { strings } from "../../utils/localization"
import { ReloadIcon, WhiteArrowIcon } from "../Icons"
import ResultBox from "./ResultBox"

const FinishTest = ({ answers, endTest, handleRedirect, handleReset }) => {
	useEffect(() => {
		endTest()
	}, []) //eslint-disable-line

	const checkMessage = () => {
		if (answers.filter((answer) => answer.finalResult === true).length === answers.length) {
			return strings.completedTest
		} else {
			return strings.repeatTest
		}
	}

	return (
		<Flex w="434px" h="100%" direction="column" justify="center" alignItems="center" alignSelf="center">
			<Stack
				spacing={8}
				alignItems="center"
				justify="center"
				w="434px"
				boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
				bg="mc_bg"
				rounded="xl"
				p="10px"
			>
				<Box>
					<Center>
						<Text color="mc_dark" fontWeight="700">
							{answers.filter((answer) => answer.finalResult === true).length}/{answers.length}
						</Text>
					</Center>
					<Stack direction="row">
						{answers.map((answer, i) => (
							<ResultBox result={answer.finalResult} />
						))}
					</Stack>
				</Box>
				<Heading as="h3" color="mc_dark" fontSize="18">
					{checkMessage()}
				</Heading>
				<Flex gridColumnGap="20px">
					<Button variant="medium" onClick={handleReset}>
						<ReloadIcon fill="mc_bg" />
					</Button>
					<Button variant="medium" rightIcon={<WhiteArrowIcon fill="mc_bg" />} onClick={handleRedirect}>
						{strings.endTest}
					</Button>
				</Flex>
			</Stack>
		</Flex>
	)
}

export default FinishTest
