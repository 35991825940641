import BootstrapTable from "react-bootstrap-table-next"
import DeleteModal from "../modals/DeleteModal"
import useMasterdata from "../../redux/hooks/useMasterdata"
import AddUpdateModal from "../modals/AddUpdateModal"
import MasterdataForm from "../forms/MasterdataForm"
import { useState } from "react"
import UpdateButton from "../buttons/UpdateButton"
import { TablesStyles } from "./TablesStyles"
import { strings } from "../../utils/localization"

const MasterdataTable = ({ data, type }) => {
    const hook = useMasterdata()

    const [updateData, setUpdateData] = useState(null)

    const columns = [
        {
            dataField: "code",
            text: strings.code,
        },
        {
            dataField: "name",
            text: strings.name,
        },
    ]

    const handleUpdateModal = (row) => {
        setUpdateData(row)
        hook.onOpen()
    }

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <DeleteModal remove={() => hook.remove(type, row.id)} />
                <UpdateButton onClick={() => handleUpdateModal(row)} />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }

    const handleSubmit = (data) => {
        const payload = {
            id: updateData.id,
            code: data.code,
            name: data.name,
        }
        hook.update(type, payload)
    }

    return (
        <>
            <TablesStyles>
                <BootstrapTable
                    data={data}
                    columns={columns}
                    keyField="id"
                    classes="TablesStyles"
                    expandRow={expandRow}
                    bordered
                    bootstrap4
                />
            </TablesStyles>
            <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="xl">
                <MasterdataForm
                    updateData={updateData}
                    onClose={hook.onClose}
                    submit={handleSubmit}
                />
            </AddUpdateModal>
        </>
    )
}

export default MasterdataTable
