import { useDisclosure } from "@chakra-ui/hooks"
import { useDispatch, useSelector } from "react-redux"
import {
	cleanMessage,
	confirmRegisterUser,
	login,
	logout,
	refreshAccessToken,
	registerUser,
	resetUserPassword,
	updateProfile,
	updateProfilePicture,
} from "../reducers/userReducer"
import useApp from "./useApp"
export default function useUser() {
	const dispatch = useDispatch()
	const username = useSelector((state) => state.user.username)
	const token = useSelector((state) => state.user.token)
	const refreshToken = useSelector((state) => state.user.refreshToken)
	const id = useSelector((state) => state.user.id)
	const supervisor = useSelector((state) => state.user.supervisor)
	const admin = useSelector((state) => state.user.admin)
	const city = useSelector((state) => state.user.city)
	const company = useSelector((state) => state.user.company)
	const title = useSelector((state) => state.user.title)
	const position = useSelector((state) => state.user.position)
	const personalInfo = useSelector((state) => state.user.personalInfo)
	const profilePicture = useSelector((state) => state.user.profilePicture)
	const licenseNumber = useSelector((state) => state.user.licenseNumber)
	const country = useSelector((state) => state.user.country)
	const language = useSelector((state) => state.user.language)
	const message = useSelector((state) => state.user.message)
	const loading = useSelector((state) => state.user.loading)
	const sector = useSelector((state) => state.user.sector)
	const hr_code = useSelector((state) => state.user.hr_code)
	const newEmployee = useSelector((state) => state.user.newEmployee)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const hookApp = useApp()

	const signin = async (data) => {
		dispatch(login(data, hookApp.id))
	}
	const signout = async () => {
		dispatch(logout())
	}

	const register = async (data) => {
		return dispatch(registerUser(data))
	}

	const confirmRegister = async (data) => {
		return dispatch(confirmRegisterUser(data))
	}

	const update = async (payload) => {
		return dispatch(updateProfile(payload))
	}

	const updatePicture = async (payload) => {
		return dispatch(updateProfilePicture(payload))
	}

	const refreshAccess = async () => {
		dispatch(refreshAccessToken(refreshToken))
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const resetPassword = async (data) => {
		return dispatch(resetUserPassword(data))
	}

	return {
		signin,
		signout,
		cleanToast,
		update,
		updatePicture,
		refreshAccess,
		confirmRegister,
		register,
		resetPassword,
		token,
		username,
		refreshToken,
		profilePicture,
		language,
		licenseNumber,
		country,
		admin,
		message,
		city,
		company,
		title,
		position,
		id,
		personalInfo,
		supervisor,
		loading,
		sector,
		isOpen,
		onOpen,
		onClose,
		hr_code,
		newEmployee,
	}
}
