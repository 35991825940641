import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import jwtDecode from "jwt-decode"
import { strings } from "../../utils/localization"
import { confirmRegisterURL, forgotPasswordURL, loginURL, refreshTokenURL, registerURL, updateProfileURL } from "../api_urls"

const START_REQUEST = createAction("USER/START_REQUEST")
const LOGIN_SUCCESS = createAction("USER/LOGIN_SUCCESS")
const REQUEST_FAIL = createAction("USER/REQUEST_FAIL")
const REQUEST_SUCCESS = createAction("USER/UPDATE_PROFILE")
const REFRESH_SUCCESS = createAction("USER/REFRESH_SUCCESS")
const LOGOUT = createAction("USER/LOGOUT")
const CLEAN_MESSAGE = createAction("USER/CLEAN_MESSAGE")

export const login = (data, appId) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(loginURL, data).then((res) => handleResponse(res, dispatch, LOGIN_SUCCESS, REQUEST_FAIL, appId))
}

export const logout = () => async (dispatch) => {
	dispatch(START_REQUEST())
	dispatch(LOGOUT())
	// return axios.post(logoutURL)
	// .then(res => handleResponse(res, dispatch, LOGOUT, REQUEST_FAIL))
}

export const registerUser = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(registerURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const confirmRegisterUser = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(confirmRegisterURL(data), {}).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateProfile = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(updateProfileURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateProfilePicture = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios
		.put(updateProfileURL, payload, { headers: { "content-type": "multipart/form-data" } })
		.then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const refreshAccessToken = (data) => async (dispatch) => {
	const payload = { refresh: data }
	dispatch(START_REQUEST())
	return axios.post(refreshTokenURL, payload).then((res) => handleResponse(res, dispatch, REFRESH_SUCCESS, REQUEST_FAIL))
}

export const resetUserPassword = (data) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(forgotPasswordURL, data).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanMessage = () => async (dispatch) => {
	return dispatch(CLEAN_MESSAGE())
}

const handleResponse = (res, dispatch, success, fail, appId, type) => {
	if (success === LOGOUT) {
		dispatch(LOGOUT())
	} else if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else if (success === LOGIN_SUCCESS) {
				const decoded = jwtDecode(res.data.access)
				if (decoded.is_admin) {
					dispatch(success(res.data))
				} else if (decoded.app.id !== appId) {
					dispatch(fail({ text: strings.wrongCredentialsMessage, type: "error" }))
				} else {
					dispatch(success(res.data))
				}
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined) {
			if (res.response.status === 401) {
				dispatch(fail({ text: strings.wrongCredentialsMessage, type: "error" }))
				dispatch(CLEAN_MESSAGE())
			} else if (res.response.status === 400) {
				dispatch(fail(res.response.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
				dispatch(CLEAN_MESSAGE())
			}
			return res.response
		} else {
			dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	token: "",
	refreshToken: "",
	id: "",
	username: "",
	admin: false,
	supervisor: false,
	city: null,
	company: null,
	country: null,
	title: null,
	position: null,
	personalInfo: null,
	profilePicture: null,
	licenseNumber: null,
	sector: "",
	language: null,
	loading: false,
	message: null,
	hr_code: "",
	newEmployee: false,
}

export const userReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(LOGIN_SUCCESS, (state, action) => {
			const decoded = jwtDecode(action.payload.access)
			const personalInfo = {
				firstName: decoded.firstname,
				lastName: decoded.lastname,
				bio: decoded.bio,
				email: decoded.email,
				phoneNumber: decoded.phone_number,
			}
			state.loading = false
			state.token = action.payload.access
			state.refreshToken = action.payload.refresh
			state.id = decoded.user_id
			state.admin = decoded.is_admin
			state.username = decoded.username
			state.supervisor = decoded.is_supervisor
			state.personalInfo = personalInfo
			state.city = decoded.city
			state.company = decoded.company
			state.country = decoded.country
			state.title = decoded.title
			state.position = decoded.position
			state.profilePicture = decoded.image
			state.licenseNumber = decoded.licence_number
			state.language = decoded.language
			state.sector = decoded.sector
			state.hr_code = decoded.hr_code
			state.newEmployee = decoded.new_employee
		})
		.addCase(REFRESH_SUCCESS, (state, action) => {
			const decoded = jwtDecode(action.payload.access)
			const personalInfo = {
				firstName: decoded.firstname,
				lastName: decoded.lastname,
				bio: decoded.bio,
				email: decoded.email,
				phoneNumber: decoded.phone_number,
			}
			state.loading = false
			state.token = action.payload.access
			state.id = decoded.user_id
			state.admin = decoded.is_admin
			state.username = decoded.username
			state.supervisor = decoded.is_supervisor
			state.personalInfo = personalInfo
			state.city = decoded.city
			state.company = decoded.company
			state.country = decoded.country
			state.title = decoded.title
			state.position = decoded.position
			state.profilePicture = decoded.image
			state.licenseNumber = decoded.licence_number
			state.language = decoded.language
			state.sector = decoded.sector
			state.hr_code = decoded.hr_code
			state.newEmployee = decoded.new_employee
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(LOGOUT, (state, action) => {
			state.loading = false
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
