import { Box, Heading, Img, Slider, SliderFilledTrack, SliderMark, SliderTrack, Stack } from "@chakra-ui/react"
import React from "react"

const calculatePercent = (value) => {
	const extract = value.split("/")
	return (Number(extract[0]) / Number(extract[1]).toFixed(2)) * 100
}

const VisualSmallBox = ({ title, icon, value, color }) => {
	return (
		<Stack
			justify="space-between"
			align="center"
			w="100%"
			spacing={2}
			h="136px"
			rounded="lg"
			p={1.5}
			bg="mc_bg"
			boxShadow="0px 2px 10px 2px rgba(66, 146, 220, 0.25)"
		>
			<Heading textAlign="center" as="h6" minH="28px" fontSize={["11px", "12px"]} color="mc_dark">
				{title}
			</Heading>
			{/* <Icon as={icon} boxSize="48px" /> */}
			<Img src={icon} />

			<Box w="100%">
				<Slider
					w="100%"
					aria-label="slider-ex-1"
					h="25px"
					isReadOnly
					// defaultValue={50}
					value={calculatePercent(value)}
					cursor="default"
					color="mc_dark"
				>
					<SliderMark
						w="100%"
						// value={30}
						textAlign="center"
						color="mc_dark"
						fontWeight="bold"
						fontSize="12px"
						mt="4px"
						ml={0}
						left={0}
						zIndex="10"
					>
						{value.toString()}
					</SliderMark>
					<SliderTrack h="25px" rounded="md" boxShadow="0px 2px 12px rgba(66, 146, 220, 0.25)" bg="mc_bg">
						<SliderFilledTrack bg={color} borderRight="md" />
					</SliderTrack>
				</Slider>
			</Box>
		</Stack>
	)
}

export default VisualSmallBox
