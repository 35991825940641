import { useDisclosure } from "@chakra-ui/hooks"
import { useDispatch, useSelector } from "react-redux"
import {
	cleanMessage,
	createMasterdata,
	fetchMasterdata,
	fetchPublicMasterdata,
	removeMasterdata,
	updateMasterdata,
} from "../reducers/masterdataReducer"

export default function useMasterdata() {
	const dispatch = useDispatch()
	const titles = useSelector((state) => state.masterdata.titles)
	const positions = useSelector((state) => state.masterdata.positions)
	const educationCategories = useSelector((state) => state.masterdata.educationCategories)
	const companyTypes = useSelector((state) => state.masterdata.companyTypes)
	const articleStatuses = useSelector((state) => state.masterdata.articleStatuses)
	const countries = useSelector((state) => state.masterdata.countries)
	const cities = useSelector((state) => state.masterdata.cities)
	const apps = useSelector((state) => state.masterdata.apps)
	const languages = useSelector((state) => state.masterdata.languages)
	const companies = useSelector((state) => state.masterdata.companies)
	const tags = useSelector((state) => state.masterdata.tags)
	const hqRetails = useSelector((state) => state.masterdata.hqRetails)
	const concepts = useSelector((state) => state.masterdata.concepts)
	const organizationUnits = useSelector((state) => state.masterdata.organizationUnits)
	const sectors = useSelector((state) => state.masterdata.sectors)
	const positionsSe1 = useSelector((state) => state.masterdata.positionsSe1)
	const positionsSe2 = useSelector((state) => state.masterdata.positionsSe2)
	const contentTypes = useSelector((state) => state.masterdata.contentTypes)
	const message = useSelector((state) => state.masterdata.message)
	const loading = useSelector((state) => state.masterdata.loading)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const fetch = async (type) => {
		dispatch(fetchMasterdata(type))
	}

	const fetchPublic = async (type) => {
		dispatch(fetchPublicMasterdata())
	}

	const create = async (type, payload) => {
		dispatch(createMasterdata(type, payload)).then((res) => {
			if (res !== undefined && res.data.message.type === "success") {
				handleRefresh(type)
			}
		})
	}

	const update = async (type, payload) => {
		dispatch(updateMasterdata(type, payload)).then((res) => {
			if (res !== undefined && res.data.message.type === "success") {
				handleRefresh(type)
			}
		})
	}

	const remove = async (type, id) => {
		dispatch(removeMasterdata(type, id)).then(() => handleRefresh(type))
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const handleRefresh = (type) => {
		onClose()
		fetch(type)
	}

	return {
		titles,
		apps,
		positions,
		educationCategories,
		companyTypes,
		articleStatuses,
		countries,
		cities,
		languages,
		loading,
		companies,
		tags,
		hqRetails,
		concepts,
		organizationUnits,
		sectors,
		positionsSe1,
		positionsSe2,
		contentTypes,
		message,
		fetch,
		fetchPublic,
		create,
		update,
		remove,
		cleanToast,
		isOpen,
		onOpen,
		onClose,
	}
}
