import { Flex, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react"
import React from "react"

const VisualSmallBoxesContainer = ({ title, value, children }) => {
	return (
		<Stack
			w="100%"
			minW="290px"
			minH="166px"
			rounded="lg"
			p={[0, 2.5]}
			bg={["transparent", "mc_bg"]}
			boxShadow={["none", "0px 2px 10px 2px rgba(66, 146, 220, 0.25)"]}
		>
			<Flex justify="space-between">
				<Heading as="h4" fontSize="25px" color="mc_dark">
					{title}
				</Heading>
				<Text fontSize="16px" color="mc_dark">
					{value}
				</Text>
			</Flex>
			<SimpleGrid columns={2} spacing={[1, 2]}>
				{children}
			</SimpleGrid>
		</Stack>
	)
}

export default VisualSmallBoxesContainer
