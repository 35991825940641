import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import { strings } from "../../utils/localization"
import {
	articlePageUserURL,
	articleReportsURL,
	articleSingleURL,
	commentRemoveURL,
	commentsURL,
	reactionURL,
	surveyUserURL,
} from "../api_urls"

const START_REQUEST = createAction("ARTICLE/START_REQUEST")
const FETCH_SUCCESS = createAction("ARTICLE/FETCH_SUCCESS")
const FETCH_SUCCESS_SEARCH = createAction("ARTICLE/FETCH_SUCCESS_SEARCH")
const FETCH_ONE = createAction("ARTICLE/FETCH_ONE")
const REQUEST_SUCCESS = createAction("ARTICLE/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("ARTICLE/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("ARTICLE/CLEAN_MESSAGE")
const CLEAN_DETAIL = createAction("ARTICLE/CLEAN_DETAIL")
const CLEAN_DATA = createAction("ARTICLE/CLEAN_DATA")
const SET_REACTION_TOGGLE = createAction("ARTICLE/SET_REACTION_TOGGLE")

export const fetchArticlePage = (perPage, pageNumber, filterData) => async (dispatch) => {
	let filterUrl = articlePageUserURL(perPage, pageNumber)
	if (filterData) {
		if (filterData.title) {
			filterUrl = filterUrl + `&title=${filterData.title}`
		}

		if (filterData.category) {
			filterUrl = filterUrl + `&category=${filterData.category}`
		}

		if (filterData.viewed) {
			filterUrl = filterUrl + `&viewed=True`
		}
	}

	dispatch(START_REQUEST())
	return axios.get(filterUrl).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
}

export const fetchArticle = (id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(articleSingleURL(id)).then((res) => handleResponse(res, dispatch, FETCH_ONE, REQUEST_FAIL))
}

export const cleanArticle = () => async (dispatch) => {
	dispatch(CLEAN_DETAIL())
}

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

export const emptyData = () => async (dispatch) => {
	dispatch(CLEAN_DATA())
}

export const reactionToggleSet = (payload) => async (dispatch) => {
	dispatch(SET_REACTION_TOGGLE(payload))
}

export const sendReaction = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(reactionURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const sendComment = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(commentsURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const sendSurvey = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(surveyUserURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const sendArticleReport = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(articleReportsURL, payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const deleteArticleComment = (id, userId) => async (dispatch) => {
	dispatch(START_REQUEST())

	let url = commentRemoveURL(id)
	if (userId) {
		url = url + `&user_id=${userId}`
	}
	return axios.delete(url).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

const handleResponse = (res, dispatch, success, fail) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else {
				dispatch(success(res.data))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	pagination: null,
	detail: null,
	defaultSize: 25,
	message: null,
	loading: false,
	reactionToggle: false,
}

export const articleReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = state.data.concat(action.payload.articles)
			if (action.payload.articles.length > 0) {
				state.pagination = action.payload.pagination
			} else {
				state.message = { text: strings.noMore, type: "info" }
			}
		})
		.addCase(FETCH_SUCCESS_SEARCH, (state, action) => {
			state.loading = false
			state.data = action.payload.articles
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_ONE, (state, action) => {
			state.loading = false
			state.detail = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CLEAN_DETAIL, (state, action) => {
			state.detail = null
		})
		.addCase(CLEAN_DATA, (state, action) => {
			state.data = []
		})
		.addCase(SET_REACTION_TOGGLE, (state, action) => {
			state.reactionToggle = action.payload
		})
})
