import { Button } from "@chakra-ui/button"
import { Checkbox } from "@chakra-ui/checkbox"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { useDisclosure } from "@chakra-ui/hooks"
import { Input } from "@chakra-ui/input"
import { Box, Flex, ListItem, OrderedList, Stack } from "@chakra-ui/layout"
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/number-input"
import { useToast } from "@chakra-ui/toast"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useForms from "../../redux/hooks/useForms"
import useTest from "../../redux/hooks/useTest"
import useConfig from "../../utils/config"
import { handleToast, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedPermissionsPayload, SmartedFields } from "../../utils/smartedHelper"
import AddItemButton from "../buttons/AddItemButton"
import RemoveItemButton from "../buttons/RemoveItemButton"
import { LeftArrow, RightArrow, SearchIcon } from "../Icons"
import CustomSwitch from "../inputs/CustomSwitch"
import CustomTextInput from "../inputs/CustomTextInput"
import ImageModal from "../modals/ImageModal"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const UpdateTestForm = ({ selectData, updateData, handleClose }) => {
	const [currentId, setCurrentId] = useState(0)
	const [img, setImg] = useState("")
	const [images, setImages] = useState([])

	const { isOpen, onOpen, onClose } = useDisclosure()

	const hook = useForms()
	const hookTest = useTest()
	const toast = useToast()
	const config = useConfig()

	useEffect(() => {
		hookTest.fetchOne(updateData.education).then((res) => {
			if (res !== undefined && res.status === 200) {
				const answersArray = []
				res.data.answers.forEach((slide, i) => {
					const arr = []
					Object.keys(slide.answers).forEach((key) =>
						arr.push({
							text: key,
							result: slide.answers[key],
						})
					)

					const question = {
						id: slide.id,
						answers: arr,
						text: slide.question,
						image: slide.image,
					}

					if (slide.education_slide_serial_num) {
						question["education_slide"] = slide.education_slide_serial_num
					} else {
						question["education_slide"] = ""
					}

					if (slide.subquestion) {
						question["subquestion"] = slide.subquestion
					} else {
						question["subquestion"] = ""
					}

					answersArray.push(question)
				})
				hook.setTestAnswers(answersArray)
			}
		})
	}, []) //eslint-disable-line

	const { handleSubmit, control, reset, register } = useForm({})

	const handleSearch = (data) => {
		setImg(data)
		onOpen()
	}

	const handleNext = () => {
		let exitLoopText
		const correctAnswers = []
		const currentAnswers = hook.answerForms.find((ele, i) => i === currentId)
		currentAnswers.answers.forEach((element, i) => {
			//eslint-disable-line
			if (element.text === "") {
				exitLoopText = true
			}
			if (element.result) {
				correctAnswers.push(true)
			}
		})

		if (exitLoopText) {
			handleToast(toast, { text: strings.requiredTextOfAllAnswersMessage, type: "error" }, "4")
		} else if (correctAnswers.length === 0) {
			handleToast(toast, { text: strings.atLeastOneCorrectAnswerMessage, type: "error" }, "6")
		} else if (!currentAnswers.text) {
			handleToast(toast, { text: strings.requiredTextOrImageMessage, type: "error" }, "1")
		} else {
			if (currentId + 1 === hook.answerForms.length) {
				hook.addQuestion(currentId + 1).then(() => hook.addAnswer(currentId + 1, "", false))
			}
			setCurrentId(currentId + 1)
		}
	}

	const close = () => {
		hook.cleanTestForms()
		handleClose()
		hookTest.cleanDetail()
		reset()
	}

	const handleDeleteQuestion = () => {
		if (currentId === 0 && hook.answerForms.length === 1) {
			handleToast(toast, { text: "ne meze", type: "error" }, "11")
		} else {
			setImages(images.filter((item, i) => i !== currentId))
			hook.deleteQuestion(currentId)
			if (currentId !== 0) {
				setCurrentId(currentId - 1)
			}
		}
	}

	const handleImage = (file, index) => {
		let newImages = [...images]
		if (newImages.length > 0) {
			newImages.map((data, i) => {
				//eslint-disable-line
				if (index === i) {
					newImages = newImages.filter((item, i) => i !== index)
				}
			})
		}

		if (currentId === 0) {
			newImages.unshift({ question: index, data: file })
		} else {
			newImages.push({ question: index, data: file })
		}
		setImages(newImages)
	}

	const checkImage = (serialNum) => {
		if (images.find((image, i) => image.question === serialNum) !== undefined) {
			return images.find((image, i) => image.question === serialNum).data
		} else {
			return null
		}
	}

	const submit = (data) => {
		var formData = new FormData()

		const newQuestions = hook.answerForms.filter((e, i) => !e.id)

		if (newQuestions.length !== images.length) {
			handleToast(toast, { text: strings.imageMissing, type: "error" }, "1")
		} else {
			const questions = []

			hook.answerForms.map((question, i) => {
				const oneQuestion = {}
				if (question.id) {
					oneQuestion["id"] = question.id
				}

				oneQuestion["serial_num"] = i + 1
				oneQuestion["question"] = question.text
				oneQuestion["education_slide"] = question.education_slide
				oneQuestion["subquestion"] = question.subquestion
				oneQuestion["image"] = checkImage(i + 1)

				return questions.push(oneQuestion)
			})

			let exitLoopText = false

			const serial_nums = []

			questions.map((question, i) => {
				if (question.image) {
					formData.append("images[]", question.image, question.image.name)
					serial_nums.push(question.serial_num)
				}

				if (question.question === undefined || question.question === "") {
					exitLoopText = true
				}

				delete question.image
				question["answers"] = {}
				return hook.answerForms.map((questionRed, index) => {
					//eslint-disable-line
					if (index === i) {
						questionRed.answers.map((answer, answerIndex) => {
							const result = answer.result.toString()
							return (question.answers[`${answer.text}`] = result.charAt(0).toUpperCase() + result.slice(1))
						})
					}
				})
			})

			formData.append("serials[]", JSON.stringify(serial_nums))

			const replacer = (key, value) =>
				value instanceof Object && !(value instanceof Array)
					? Object.keys(value)
							.sort()
							.reduce((sorted, key) => {
								sorted[key] = value[key]
								return sorted
							}, {})
					: value

			formData.append("questions", JSON.stringify(questions, replacer))

			let exitLoopAnswers = false
			const correctAnswers = []
			const currentAnswers = hook.answerForms.find((ele, i) => i === currentId)
			currentAnswers.answers.forEach((element, i) => {
				//eslint-disable-line
				if (element.text === "") {
					exitLoopAnswers = true
				}
				if (element.result) {
					correctAnswers.push(true)
				}
			})

			if (exitLoopAnswers) {
				handleToast(toast, { text: strings.requiredTextOfAllAnswersMessage, type: "error" }, "4")
			} else if (correctAnswers.length === 0) {
				handleToast(toast, { text: strings.atLeastOneCorrectAnswerMessage, type: "error" }, "6")
			} else if (exitLoopText) {
				handleToast(toast, { text: strings.requiredQuestionTextMessage, type: "error" }, "8")
			} else if (!data.name || !data.app) {
				handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "10")
			} else {
				let test = {}
				test["id"] = updateData.id
				test["name"] = data.name
				test["app"] = data.app.value

				if (data.education) {
					test["education"] = data.education.value
				} else {
					test["education"] = ""
				}

				if (data.track) {
					test["track"] = "True"
				} else {
					test["track"] = "False"
				}

				if (data.companies && data.companies.length > 0) {
					test["companies"] = makeIdsArray(data.companies)
				} else {
					test["companies"] = []
				}

				if (config.appId === 2) {
					handleSmartedPermissionsPayload(data, test)
				}

				if (formData.getAll("images[]").length === 0) {
					formData.append("images[]", JSON.stringify([]))
				}

				if (formData.getAll("serials[]").length === 0) {
					formData.append("serials[]", JSON.stringify([]))
				}

				formData.append("test", JSON.stringify(test))

				hookTest.update(formData).then((res) => {
					if (res !== undefined && res.status === 200) {
						close()
						hookTest.handleRefresh()
					}
				})
			}
		}
	}

	return (
		// eslint-disable-next-line no-unused-vars
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={6}>
				<Flex alignItems="flex-end" gridGap="20px">
					<FormControl w="70%">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.name} *
						</FormLabel>
						<Controller
							as={<Input boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)" />}
							control={control}
							name="name"
							defaultValue={updateData && updateData.name}
						/>
					</FormControl>
					<FormControl w="30%">
						<FormLabel fontSize="14px" color="mc_dark" textTransform={"uppercase"}>
							{strings.education}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="education"
							options={selectData.educations}
							defaultValue={updateData && updateData.defaultEducation}
						/>
					</FormControl>
				</Flex>
				<Flex gridGap="20px" w="100%" direction={["column", "column", "row"]}>
					<FormControl w={["100%", "100%", "30%"]}>
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.application} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="app"
							options={selectData.apps}
							defaultValue={updateData && updateData.defaultApp}
						/>
					</FormControl>
					<Box w={["100%", "100%", "70%"]}>
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.companies}
						</FormLabel>
						<Controller
							as={<SelectDropdown isMulti />}
							control={control}
							name="companies"
							options={selectData.companies}
							defaultValue={updateData && updateData.defaultCompanies}
						/>
					</Box>
					<Checkbox
						size="lg"
						alignItems="center"
						color="mc_dark"
						fontSize="md"
						ref={register}
						name="track"
						defaultChecked={updateData && updateData.track}
					>
						{strings.track}
					</Checkbox>
				</Flex>
				{config.appId === 2 && <SmartedFields control={control} selectData={selectData} updateData={updateData} />}
				<Stack direction="row" justify="center">
					{hook.answerForms.map((slide, i) =>
						currentId === i ? (
							<Flex
								w="18px"
								h="16px"
								bg="mc_dark"
								rounded="2"
								fontSize="11px"
								color="mc_bg"
								justify="center"
								align="center"
								pt="4px"
								mb={1}
							>
								{i + 1}
							</Flex>
						) : (
							<Flex
								w="18px"
								h="16px"
								border="1px solid"
								borderColor="mc_medium"
								rounded="2"
								fontSize="11px"
								color="mc_dark"
								justify="center"
								align="center"
								pt="4px"
								mb={1}
							>
								{i + 1}
							</Flex>
						)
					)}
				</Stack>
				<Box w="100%">
					{hook.answerForms.map((item, index) => (
						<>
							<Flex
								w="100%"
								gridGap="20px"
								direction={["column", "column", "row"]}
								mb={["20px", "20px", 0]}
								display={currentId === index ? "flex" : "none"}
							>
								<FormControl w="100%">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.question} *
									</FormLabel>
									<Input
										boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
										name={`text${index + 1}`}
										value={item.text}
										onChange={(e) => hook.updateQuestionText(index, e.target.value)}
									/>
								</FormControl>
								<Flex>
									<FormControl w="fit-content">
										<FormLabel fontSize="14px" color="mc_dark">
											{strings.image}
										</FormLabel>
										<Flex gridGap="10px">
											<UploadFile onChange={(val) => handleImage(val, index + 1)} />
											{item.image && (
												<Box alignSelf="baseline">
													<SearchIcon
														fill="mc_medium"
														w="30px"
														h="30px"
														cursor="pointer"
														p="3px"
														border="2px solid"
														borderColor="mc_medium"
														rounded="4px"
														_hover={{
															fill: "mc_bg",
															background: "mc_medium",
														}}
														onClick={() => handleSearch(item.image)}
													/>
												</Box>
											)}
										</Flex>
									</FormControl>
								</Flex>
								<FormControl w="fit-content">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.educationSlide}
									</FormLabel>
									<NumberInput
										min={0}
										precision={0}
										w="80px"
										boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
										name={`education_slide${index + 1}`}
										onChange={(string, number) => hook.updateQuestionSlide(index, number)}
										value={item.education_slide}
									>
										<NumberInputField />
										<NumberInputStepper>
											<NumberIncrementStepper />
											<NumberDecrementStepper />
										</NumberInputStepper>
									</NumberInput>
								</FormControl>
							</Flex>
							<FormControl w="80%" display={currentId === index ? "true" : "none"}>
								<FormLabel fontSize="14px" color="mc_dark">
									{"SUBQUESTION"}
								</FormLabel>
								<Input
									name={`subquestion${index + 1}`}
									boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
									onChange={(e) => hook.updateQuestionSubquestion(index, e.target.value)}
									value={item.subquestion}
								/>
							</FormControl>
						</>
					))}
				</Box>
				<Flex w="100%" gridGap="40px" pl="10px" mb="10px">
					<Box w={["100%", "100%", "80%"]} maxH="380px" overflowY="auto">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.answers}
						</FormLabel>
						<OrderedList mt="0 !important">
							{hook.answerForms.map(
								(form, index) =>
									index === currentId &&
									form.answers.map((answer, i) => (
										<ListItem key={i}>
											<Flex mb="10px">
												<CustomTextInput
													onChange={(data) => hook.updateAnswerText(currentId, i, data)}
													value={answer.text}
												/>
												<RemoveItemButton onClick={() => hook.removeAnswer(currentId, i)} />
												<CustomSwitch
													onChange={(data) => hook.updateAnswerResult(currentId, i, data)}
													value={answer.result}
												/>
											</Flex>
										</ListItem>
									))
							)}
						</OrderedList>
						<AddItemButton onClick={() => hook.addAnswer(currentId, "", false)} />
					</Box>
				</Flex>
				<Stack spacing={[4, 4, 0]}>
					<Flex gridGap="20px" justify="center" w="100%">
						<Button
							display={currentId === 0 ? "none" : "inline-block"}
							variant="dark"
							leftIcon={<LeftArrow fill="mc_bg" />}
							onClick={() => setCurrentId(currentId - 1)}
						>
							{strings.back}
						</Button>
						<Button variant="red" textTransform="uppercase" onClick={handleDeleteQuestion}>
							{strings.deleteQuestion}
						</Button>
						<Button variant="dark" rightIcon={<RightArrow fill="mc_bg" />} onClick={() => handleNext()}>
							{strings.forward}
						</Button>
					</Flex>
					<Flex w="100%" justify="space-between">
						<Button variant="red" onClick={() => close()}>
							{strings.close}
						</Button>

						<Button variant="medium" type="submit">
							{strings.save}
						</Button>
					</Flex>
				</Stack>
			</Stack>
			<ImageModal data={img} isOpen={isOpen} onClose={onClose} size="4xl" />
		</form>
	)
}
export default UpdateTestForm
