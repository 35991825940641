const server_url =
	process.env.REACT_APP_NAME === "PHARMANET"
		? "https://pharmanet.rs"
		: process.env.REACT_APP_NAME === "DOCTORNET"
		? "https://doctor-net.rs"
		: "https://smart-ed.rs"
export const base_url = process.env.NODE_ENV === "development" ? "http://localhost:8000/api" : `${server_url}/api`

//AUTH
export const loginURL = "/user/login/"
export const logoutURL = "/user/logout"
export const refreshTokenURL = "/user/refresh/"
export const updateEmployeeStatusURL = "/user/userconfig"
export const registerURL = "/public/register"
export const confirmRegisterURL = (code) => `/public/register?token=${code}`
export const forgotPasswordURL = "/public/forgotpassword"

//USER
const profile = "/profile"
export const updateProfileURL = profile

//MASTERDATA & GENERIC
export const masterdataURL = (type) => `/masterdata?model=${type}`
export const masterdataRemoveURL = (type, id) => `/masterdata?model=${type}&id=${id}`
export const publicMasterdata = `/public/masterdata`

export const genericURL = (type) => `/generic?model=${type}`
export const genericRemoveURL = (type, id) => `/generic?model=${type}&id=${id}`

//USERS
const users = "/users"

export const usersURL = users
export const usersRemoveURL = (id) => `${users}?id=${id}`
export const usersPageURL = (perPage, pageNumber) =>
	`${users}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created&is_staff=False`
export const usersDropdownURL = users + "?is_staff=False&dropdown_data=True"

//ARTICLE
const articles = "/articles"
export const articleURL = articles
export const articleRemoveURL = (id) => `${articles}?id=${id}`
export const articleSingleURL = (id) => `${articles}?unique_url=${id}`
export const articlePageAdminURL = (perPage, pageNumber) => `${articles}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created`
export const articlePageUserURL = (perPage, pageNumber) =>
	`${articles}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created&user_view=True&status=2`

//COMMENTS
export const commentURL = "/comments"
export const commentsRemoveURL = (id) => `${commentURL}?id=${id}`

//EDUCATION
const educations = "/educations"
export const educationURL = educations
export const educationSingleURL = (id) => `${educations}?id=${id}`
export const educationPageAdminURL = (perPage, pageNumber) =>
	`${educations}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created`
export const educationPageUserURL = (perPage, pageNumber) =>
	`${educations}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created&user_view=True&status=2`
// export const educationPageUserSearchURL = (perPage, pageNumber, name) =>
// 	`${educations}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created&user_view=True&status=2&name=${name}`
export const educationsNewEmployeeURL = `${educations}?order_by=date_created&user_view=True&status=2&new_employee_content=True`
//TEST
const tests = "/tests"
export const testURL = tests
export const testSingleURL = (id) => `${tests}?education_id=${id}`
export const testPageURL = (perPage, pageNumber) => `${tests}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created`
export const testRemoveURL = (id) => `${tests}?id=${id}`

//BILLBOARD
const billboard = "/joblistings"
export const billboardUrl = billboard
export const billboardPageAdminURL = (perPage, pageNumber) => `${billboard}?per_page=${perPage}&page_no=${pageNumber}`
export const billboardRemoveUrl = (id) => `${billboard}?id=${id}`

//TRACK
const track = "/track"
//REACTIONS
const reaction = "/reactions"
export const reactionURL = track + reaction

//SURVEY
const surveys = "/surveys"
export const surveyAdminURL = surveys
export const surveyUserURL = track + surveys
export const surveyRemoveURL = (id) => `${surveys}?id=${id}`
export const surveyPageURL = (perPage, pageNumber) => `${surveys}?per_page=${perPage}&page_no=${pageNumber}&order_by=date_created`

//COMMENTS
const comments = "/comments"
export const commentsURL = track + comments
export const commentRemoveURL = (id) => `${comments}?id=${id}`

//TEST
const test = "/test/results"
export const testResultsURL = track + test

//ARTICLE REPORTS
const articleReports = "/article/reports"
export const articleReportsURL = track + articleReports

//BILLBOARD
const jobApplications = "/jobapplications"
export const jobApplicationURL = track + jobApplications

//REPORTS
const reports = "/reports"
export const reportsUrl = reports
export const reportsComponentUrl = (component) => reports + `?component=${component}`

//EXPORT/IMPORT
const exportImport = "/data"
const uploadFile = "/s3/upload"
export const exportImportUrl = base_url + exportImport
export const uploadFileUrl = base_url + uploadFile

//BANNERS
const banners = "/banners"
export const bannersUrl = banners

//NOTIFICATIONS
const emailNotifications = "/emailnotifications"
export const emailNotificationsUrl = emailNotifications
export const emailNotificationsRemoveUrl = (id) => `${emailNotificationsUrl}?id=${id}`

//DOCUMENTS
const documents = "/documents"
export const documentsUrl = documents
export const documentsRemoveUrl = (id) => `${documentsUrl}?id=${id}`

//FORMS
export const formSubmissionURL = "/formsubmissions"

//ABSENCE APPROVALS
const absenceRequest = "/absencerequests"
export const absenceRequestUrl = absenceRequest
export const absenceRequestRemoveUrl = (id) => `${absenceRequest}?id=${id}`
