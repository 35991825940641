import { Box } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect } from "react"
import AbsenceRequestTable from "../../components/tables/AbsenceRequestTable"
import useAbsenceRequest from "../../redux/hooks/useAbsenceRequest"
import { handleToast } from "../../utils/functions"

const AdminAbsenceRequestPage = () => {
	// const hook = useComment()
	const hook = useAbsenceRequest()
	const toast = useToast()

	useEffect(() => {
		hook.fetchAbsenceRequests()
	}, []) //eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "toast1")
		}
	}, [hook.message]) // eslint-disable-line

	return (
		<Box
			h="95%"
			overflow="auto"
			border={"4px"}
			boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
			borderColor="mc_black"
			borderRadius="4px"
			w="100%"
		>
			<AbsenceRequestTable data={hook.data} />
		</Box>
	)
}

export default AdminAbsenceRequestPage
