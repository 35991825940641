import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import React from "react"

const SearchModal = ({ children, isOpen, onClose, size, overlayClick, fetch }) => {

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            fetch()
            onClose()
        }
    }

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={size}
            closeOnOverlayClick={overlayClick}
            closeOnEsc={true}
            scrollBehavior={"inside"}
        >
            <ModalOverlay />
            <ModalContent mt="6px" onKeyPress={handleKeyDown}>
                <ModalBody h="50px" bg="mc_bg" p={["4", "4"]} borderRadius="md">
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default SearchModal
