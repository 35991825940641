import { usersURL, usersRemoveURL, usersPageURL, usersDropdownURL } from "../api_urls";
import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios";
import { strings } from "../../utils/localization";

const START_REQUEST = createAction("USERS/START_REQUEST")
const FETCH_SUCCESS = createAction("USERS/FETCH_SUCCESS")
const REQUEST_SUCCESS = createAction("USERS/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("USERS/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("USERS/CLEAN_MESSAGE")
const CHANGE_SIZE = createAction("USERS/CHANGE_SIZE")

export const fetchUsersPage = (perSize, pageNumber, email) => async (dispatch) => {  
	dispatch(START_REQUEST())
	let url = usersPageURL(perSize, pageNumber)
	if(email) {
		url = url + `&email=${email}`
	}
	return axios.get(url)
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
};

export const fetchUsersDropdown = () => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.get(usersDropdownURL)
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
};

export const createUser = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.post(usersURL, payload)
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
	
};

export const updateUser = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.put(usersURL, payload)
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const removeUser = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.delete(usersRemoveURL(id))
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

export const changeDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleResponse = (res, dispatch, success, fail) => {
	if(res !== undefined) {
		if(res.status >= 200 && res.status <= 299) {
			if(success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			}else{
				dispatch(success(res.data))
			}
			return res
		}else if(res.response !== undefined && res.response.status === 400){
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		}else{
			dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
			dispatch(CLEAN_MESSAGE())
		}
	}else{
		dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	pagination: null,
	loading: false,
	defaultSize: 25,
	message: null
};

export const usersReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.users
			state.pagination = action.payload.pagination
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
	})
  