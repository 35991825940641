import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { absenceRequestReducer } from "./reducers/absenceRequestReducer"
import { appReducer } from "./reducers/appReducer"
import { articlePanelReducer } from "./reducers/articlePanelReducer"
import { articleReducer } from "./reducers/articleReducer"
import { billboardReducer } from "./reducers/billboardReducer"
import { commentReducer } from "./reducers/commentReducer"
import { dashboardReducer } from "./reducers/dashboardReducer"
import { dataManagementReducer } from "./reducers/dataManagementReducer"
import { educationReducer } from "./reducers/educationReducer"
import { formReducer } from "./reducers/formReducer"
import { genericReducer } from "./reducers/genericReducer"
import { masterdataReducer } from "./reducers/masterdataReducer"
import { surveyReducer } from "./reducers/surveyReducer"
import { testReducer } from "./reducers/testReducer"
import { userReducer } from "./reducers/userReducer"
import { usersReducer } from "./reducers/usersReducer"

const persistConfig = {
	key: "root",
	version: 1,
	storage,
	whitelist: ["user"],
}

const reducer = combineReducers({
	user: userReducer,
	masterdata: masterdataReducer,
	users: usersReducer,
	articles: articleReducer,
	articlesPanel: articlePanelReducer,
	surveys: surveyReducer,
	educations: educationReducer,
	app: appReducer,
	generic: genericReducer,
	tests: testReducer,
	forms: formReducer,
	dataManagement: dataManagementReducer,
	comments: commentReducer,
	billboard: billboardReducer,
	dashboard: dashboardReducer,
	absenceRequests: absenceRequestReducer,
})

const rootReducer = (state, action) => {
	if (action.type === "USER/LOGOUT") {
		state = undefined
		localStorage.removeItem("persist:root")
	}
	return reducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware({
		serializableCheck: {
			ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
		},
	}),
})

export default store
