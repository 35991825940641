import { Box, useDisclosure, useToast } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import DocsForm from "../../components/forms/DocsForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import DocsTable from "../../components/tables/DocsTable"
import Pagination from "../../components/tables/Pagination"
import useDataManagement from "../../redux/hooks/useDataManagement"
import useGeneric from "../../redux/hooks/useGeneric"
import useMasterdata from "../../redux/hooks/useMasterdata"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { application, company } from "../../utils/constants"
import { handleToast, makeDropdownOptions, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"

const AdminDocsPage = () => {
	const hookG = useGeneric()
	const hookMd = useMasterdata()
	const config = useConfig()
	const toast = useToast()
	const hookDd = useDataManagement()
	const hookUser = useUser()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [companies, setCompanies] = useState([])
	const [applications, setApplications] = useState([])

	const [selectedDocument, setSelectedDocument] = useState(null)

	useEffect(() => {
		hookG.cleanToast()
		hookDd.getDocuments()
		hookG.fetch(company)
		hookMd.fetch(application)
	}, []) //eslint-disable-line

	useEffect(() => {
		makeDropdownOptions(hookG.companies, setCompanies)
		makeDropdownOptions(hookMd.apps, setApplications)
	}, [hookG.documents, hookG.companies, hookMd.apps]) //eslint-disable-line

	useEffect(() => {
		if (hookDd.reportMessage) {
			handleToast(toast, hookDd.reportMessage, "1")
		}
	}, [hookDd.reportMessage]) // eslint-disable-line

	let selectData = {
		companies: companies,
		apps: applications,
	}

	const handleSubmit = (data) => {
		if (!selectedDocument) {
			handleToast(toast, { text: strings.noFileSelectedMessage, type: "error" }, "1")
		} else {
			var formData = new FormData()

			formData.append("doc", selectedDocument, selectedDocument.name)
			formData.append("title", data.title)

			if (data.companies !== undefined && data.companies.length > 0) {
				const companies = JSON.stringify(makeIdsArray(data.companies))
				formData.append("companies", companies)
			} else if (hookUser.supervisor) {
				formData.append("companies", `[${hookUser.company.id}]`)
			} else {
				formData.append("companies", `[]`)
			}

			if (data.app !== undefined && data.app.value) {
				formData.append("app", data.app.value)
			} else {
				formData.append("app", config.appId)
			}

			hookDd.createDocument(formData).then((res) => {
				if (res !== undefined && res.status === 200) {
					onClose()
					hookDd.getDocuments()
				}
			})
		}
	}

	return (
		<>
			<Box
				h="95%"
				overflow="auto"
				border={"4px"}
				boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
				borderColor="mc_black"
				borderRadius="4px"
				w="100%"
			>
				<DocsTable data={hookDd.documents} selectData={selectData} />
				<PlusButton onClick={() => onOpen()} />
				<AddUpdateModal isOpen={isOpen} onClose={onClose} size="2xl">
					<DocsForm handleClose={onClose} submit={handleSubmit} selectData={selectData} setSelectedFile={setSelectedDocument} />
				</AddUpdateModal>
			</Box>
			{hookDd.pagination && (
				<Box w="80%" mx="auto">
					<Pagination
						paginationData={{
							totalCount: hookDd.pagination.total_count,
							totalPages: hookDd.pagination.total_pages,
							currentPage: hookDd.pagination.current_page,
							pageSize: hookDd.defaultSize,
							pageSizesArray: [10, 25, 50],
						}}
						setSize={hookDd.changeSize}
						fetchPage={hookDd.fetch}
					></Pagination>
				</Box>
			)}
		</>
	)
}

export default AdminDocsPage
