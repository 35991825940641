import { testURL, testPageURL, testSingleURL, testRemoveURL, testResultsURL } from "../api_urls";
import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios";
import { strings } from "../../utils/localization";

const START_REQUEST = createAction("TEST/START_REQUEST")
const FETCH_SUCCESS = createAction("TEST/FETCH_SUCCESS")
const FETCH_ONE = createAction("TEST/FETCH_ONE")
const REQUEST_SUCCESS = createAction("TEST/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("TEST/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("TEST/CLEAN_MESSAGE")
const CLEAN_DETAIL = createAction("TEST/CLEAN_DETAIL")
const CHANGE_SIZE = createAction("TEST/CHANGE_SIZE")

export const fetchTestPage = (perPage, pageNumber) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.get(testPageURL(perPage, pageNumber))
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
};

export const fetchTest = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.get(testSingleURL(id))
	.then(res => handleResponse(res, dispatch, FETCH_ONE, REQUEST_FAIL))
};


export const createTest = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.post(testURL, payload, {headers: {"content-type": "multipart/form-data"}})
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};


export const startTest = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(testResultsURL, payload)
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const endTest = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(testResultsURL, payload)
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateTest = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.put(testURL, payload, {headers: {"content-type": "multipart/form-data"}})
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))

};

export const removeTest = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.delete(testRemoveURL(id))
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

export const cleanTest = () => async (dispatch) => {  
	dispatch(CLEAN_DETAIL())
    
};

export const changeDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleResponse = (res, dispatch, success, fail) => {
	if(res !== undefined) {
		if(res.status >= 200 && res.status <= 299) {
			if(success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			}else{
				dispatch(success(res.data))
			}
			return res
		}else if(res.response !== undefined && res.response.status === 400){
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		}else{
			dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
			dispatch(CLEAN_MESSAGE())
		}
	}else{
		dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	data: [],
	pagination: null,
	detail: null,
	defaultSize: 25,
	message: null,
	loading: false,
};

export const testReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.tests
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_ONE, (state, action) => {
			state.loading = false
			state.detail = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CLEAN_DETAIL, (state, action) => {
			state.detail = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
	})
  