import { Input } from "@chakra-ui/input"

const CustomTextInput = ({ onChange, value, type, ...props }) => {
    return (
        <Input
            color="mc_dark"
            rounded="md"
            boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
            display="block"
            onChange={(e) => onChange(e.target.value)}
            value={value}
            type={type}
            {...props}
        />
    )
}
export default CustomTextInput
