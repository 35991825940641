import { Button } from "@chakra-ui/button"
import { FormLabel } from "@chakra-ui/form-control"
import { FormControl } from "@chakra-ui/form-control"
import { InputGroup } from "@chakra-ui/input"
import { Input } from "@chakra-ui/input"
import { Center, Stack } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import { OrderedList } from "@chakra-ui/layout"
import { ListItem } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/layout"
import React, { useEffect } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import useApp from "../../redux/hooks/useApp"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { strings } from "../../utils/localization"
import { SmartedFields } from "../../utils/smartedHelper"
import { PlusIcon } from "../Icons"
import SelectDropdown from "../SelectDropdown"

const SurveyForm = ({ updateData, submit, onClose, selectData }) => {

    const hookUser = useUser()
    const hookApp = useApp()
	const config = useConfig()

    let defaultApp = null
    let defaultCompanies = null

    if (updateData) {
        defaultCompanies = updateData.defaultCompanies
        defaultApp = updateData.defaultApp
    } else if (!hookUser.admin && !updateData) {
        defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
        defaultApp = { label: hookApp.name, value: hookApp.id }
    }

    const { register, handleSubmit, control, setValue } = useForm({
        defaultValues: {
            test: [{ inputValue: "" }],
        },
    })
    const { fields, append, remove } = useFieldArray({
        control,
        name: "test",
    })

    useEffect(() => {
        if (updateData) {
            const newArray = []
            Object.keys(updateData.questions).map((key, i) =>
                newArray.push({ inputValue: updateData.questions[key] })
            )
            setValue("test", newArray)
        }
    }, [updateData]) //eslint-disable-line

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Stack spacing={4}>
                <FormControl>
                    <FormLabel textAlign="center" color="mc_medium">
                        {strings.name}
                    </FormLabel>
                    <Input
                        name="name"
                        defaultValue={updateData && updateData.name}
                        ref={register({ required: true })}
                        color="mc_dark"
                        rounded="xl"
                        boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                        mx="auto"
                        display="block"
                    />
                </FormControl>

                <Box gridGap="10px" display={hookUser.admin ? "flex" : "none"}>
                    <FormControl w="70% " mb="16px">
                        <FormLabel textAlign="center" color="mc_medium">
                            {strings.companies}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown isMulti />}
                            control={control}
                            name="companies"
                            options={selectData.companies}
                            defaultValue={defaultCompanies}
                        />
                    </FormControl>
                    <FormControl w="30%">
                        <FormLabel textAlign="center" color="mc_medium">
                            {strings.application} *
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="app"
                            options={selectData.apps}
                            defaultValue={defaultApp}
                        />
                    </FormControl>
                </Box>
				{
					config.appId === 2 &&
					<SmartedFields 
						control={control} 
						selectData={selectData} 
						updateData={updateData}
					/>
				}
                <Stack mt={3} spacing={3} overflowY="auto" overflowX="visible">
                    <FormLabel textAlign="center" color="mc_medium">
                        {strings.questions}
                    </FormLabel>
                    <OrderedList w="100%" mt="0 !important">
                        {fields.map((item, index) => {
                            return (
                                <ListItem key={item.id}>
                                    <Flex>
                                        <FormControl mb="10px">
                                            <InputGroup>
                                                <Controller
                                                    as={
                                                        <Input
                                                            color="mc_dark"
                                                            rounded="xl"
                                                            boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                                                            display="block"
                                                        />
                                                    }
                                                    name={`${index + 1}`}
                                                    control={control}
                                                    defaultValue={
                                                        item.inputValue !== ""
                                                            ? item.inputValue
                                                            : null
                                                    }
                                                />
                                            </InputGroup>
                                        </FormControl>
                                        <Button
                                            type="button"
                                            onClick={() => remove(index)}
                                            ml="5px"
                                            variant="medium"
                                        >
                                            <PlusIcon
                                                fill="mc_bg"
                                                style={{
                                                    transform: "rotate(45deg)",
                                                }}
                                            />
                                        </Button>
                                    </Flex>
                                </ListItem>
                            )
                        })}
                    </OrderedList>
                </Stack>

                <Center mb="50px">
                    <Button
                        bg="mc_bg"
                        h="38px"
                        w="14px"
                        px="0"
                        border="2px solid"
                        borderColor="mc_medium"
                        rounded="full"
                        mt={3}
                        type="button"
                        onClick={() => append({ inputValue: "" })}
                    >
                        <PlusIcon fill="mc_dark" />
                    </Button>
                </Center>
            </Stack>
            <Flex justify="flex-end" mt={4}>
                <Button onClick={onClose} variant="red">
                    {strings.close}
                </Button>
                <Button type="submit" variant="medium" ml={3}>
                    {strings.save}
                </Button>
            </Flex>
        </form>
    )
}

export default SurveyForm
