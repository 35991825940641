import { Navigate } from "react-router-dom"
import AdminPanelPage from "../components/layouts/AdminPanelPage"
import Layout from "../components/layouts/Layout"
import UnauthorizedLayout from "../components/layouts/UnauthorizedLayout"
import AdminAbsenceRequestPage from "../pages/admin/AdminAbsenceRequestPage"
import AdminArticlesPage from "../pages/admin/AdminArticlesPage"
import AdminBannersPage from "../pages/admin/AdminBannersPage"
import AdminBoardPage from "../pages/admin/AdminBoardPage"
import AdminCategoriesPage from "../pages/admin/AdminCategoriesPage"
import AdminCitiesPage from "../pages/admin/AdminCitiesPage"
import AdminCommentsPage from "../pages/admin/AdminCommentsPage"
import AdminCompaniesPage from "../pages/admin/AdminCompaniesPage"
import AdminDocsPage from "../pages/admin/AdminDocsPage"
import AdminEducationsPage from "../pages/admin/AdminEducationsPage"
import AdminEmailNotificationPage from "../pages/admin/AdminEmailNotificationPage"
import AdminMasterdataPage from "../pages/admin/AdminMasterdataPage"
import AdminPanelHomePage from "../pages/admin/AdminPanelHomePage"
import AdminReportsPage from "../pages/admin/AdminReportsPage"
import AdminSurveyPage from "../pages/admin/AdminSurveyPage"
import AdminTestPage from "../pages/admin/AdminTestPage"
import AdminUsersTablePage from "../pages/admin/AdminUsersTablePage"
import ArticleDetailPage from "../pages/ArticleDetailPage"
import ArticlesPage from "../pages/ArticlesPage"
import BoardPage from "../pages/BoardPage"
import EducationsPage from "../pages/EducationsPage"
import LoginPage from "../pages/LoginPage"
import NotFoundPage from "../pages/NotFoundPage"
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage"
import ProfilePage from "../pages/ProfilePage"
import RegistrationPage from "../pages/RegistrationPage"
import RequestPage from "../pages/RequestPage"
import ResetPassPage from "../pages/ResetPassPage"
import SingleEduPage from "../pages/SingleEduPage"
import TestPage from "../pages/TestPage"
import WelcomePage from "../pages/WelcomePage"
import { articleCategory, companyType, country, educationCategory, position, tag, title } from "../utils/constants"

const routes = (paths, token, admin, supervisor) => [
	{
		path: paths.panel,
		element: admin || supervisor ? <AdminPanelPage /> : <Navigate to={paths.defaultPath} />,
		children: [
			{ path: paths.panelExportImport, element: admin || supervisor ? <AdminPanelHomePage /> : <Navigate to={paths.panelReports} /> },
			{ path: paths.panelUsers, element: <AdminUsersTablePage /> },
			{ path: paths.panelReports, element: <AdminReportsPage /> },
			{ path: paths.panelJobs, element: admin ? <AdminBoardPage /> : <Navigate to={paths.panelReports} /> },
			{ path: paths.panelArticles, element: <AdminArticlesPage /> },
			{ path: paths.panelEducations, element: <AdminEducationsPage /> },
			{ path: paths.panelTests, element: <AdminTestPage /> },
			{ path: paths.panelTitles, element: admin ? <AdminMasterdataPage type={title} /> : <Navigate to={paths.panelReports} /> },
			{ path: paths.panelPositions, element: admin ? <AdminMasterdataPage type={position} /> : <Navigate to={paths.panelReports} /> },
			{
				path: paths.panelArticleCategory,
				element: admin ? <AdminCategoriesPage type={articleCategory} /> : <Navigate to={paths.panelReports} />,
			},
			{ path: paths.panelTags, element: admin ? <AdminMasterdataPage type={tag} /> : <Navigate to={paths.panel} /> },
			{
				path: paths.panelEducationCategory,
				element: admin ? <AdminCategoriesPage type={educationCategory} /> : <Navigate to={paths.panelReports} />,
			},
			{
				path: paths.panelCompanyTypes,
				element: admin ? <AdminMasterdataPage type={companyType} /> : <Navigate to={paths.panelReports} />,
			},
			{ path: paths.panelCountries, element: admin ? <AdminMasterdataPage type={country} /> : <Navigate to={paths.panelReports} /> },
			{ path: paths.panelCompanies, element: admin ? <AdminCompaniesPage /> : <Navigate to={paths.panel} /> },
			{ path: paths.panelCities, element: admin ? <AdminCitiesPage /> : <Navigate to={paths.panel} /> },
			{ path: paths.panelBanners, element: admin ? <AdminBannersPage /> : <Navigate to={paths.panel} /> },
			{ path: paths.panelEmailNotification, element: admin ? <AdminEmailNotificationPage /> : <Navigate to={paths.panelReports} /> },
			{ path: paths.panelSurveys, element: <AdminSurveyPage /> },
			{ path: paths.panelDocs, element: <AdminDocsPage /> },
			{ path: paths.panelComments, element: <AdminCommentsPage /> },
			{ path: paths.panelAbsenceRequests, element: <AdminAbsenceRequestPage /> },
		],
	},
	{
		path: paths.defaultPath,
		element: supervisor ? <Navigate to={paths.panelUsers} /> : token ? <Layout /> : <Navigate to={paths.login} />,
		children: [
			{ path: paths.defaultPath, element: <ArticlesPage /> },
			{ path: paths.articles, element: <ArticlesPage /> },
			{ path: paths.educations, element: <EducationsPage /> },
			{ path: "/test", element: <TestPage /> },
			{ path: paths.articlesDetail, element: <ArticleDetailPage /> },
			{ path: paths.educationsDetail, element: <SingleEduPage /> },
			{ path: paths.profile, element: <ProfilePage /> },
			{ path: paths.jobs, element: <BoardPage /> },
		],
	},
	{
		path: paths.defaultPath,
		element: <UnauthorizedLayout />,
		children: [
			{ path: paths.login, element: <LoginPage /> },
			{ path: paths.privacyPolicy, element: <PrivacyPolicyPage /> },
			{ path: paths.resetPassword, element: <ResetPassPage /> },
			{ path: paths.registration, element: <RegistrationPage /> },
			{ path: paths.registerConfirm, element: <WelcomePage /> },
			{ path: paths.requestAccepted, element: <RequestPage type={"accepted"} /> },
			{ path: paths.requestDenied, element: <RequestPage /> },
		],
	},
	{
		path: "*",
		element: <NotFoundPage />,
	},
]

export default routes
