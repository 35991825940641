import { Stack } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import { Flex } from "@chakra-ui/layout"
import { LeftArrow, RightArrow } from "../Icons"

const SlideFooter = ({ slides, currentId, back, next }) => {
    return (
        <Flex
            w="100%"
            h="80px"
            alignItems="center"
            justify="space-between"
            p="30px"
        >
            <LeftArrow
                fill="mediumGreen"
                visibility={currentId > 1 ? "visible" : "hidden"}
                w="22px"
                h="22px"
                cursor="pointer"
                onClick={back}
            />

            <Stack direction="row">
                {slides &&
                    slides.map((slide, i) =>
                        currentId >= slide.serial_num ? (
                            <Box w="14px" h="12px" bg="mc_dark" rounded="4" />
                        ) : (
                            <Box
                                w="14px"
                                h="12px"
                                border="2px solid"
                                borderColor="mc_medium"
                                rounded="4"
                            />
                        )
                    )}
            </Stack>
            <RightArrow
                fill="mc_medium"
                w="22px"
                h="22px"
                cursor="pointer"
                onClick={next}
            />
        </Flex>
    )
}

export default SlideFooter
