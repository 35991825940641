import { Box } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import PlusButton from "../../components/buttons/PlusButton"
import AddEduForm from "../../components/forms/AddEduForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import EducationTable from "../../components/tables/EducationTable"
import Pagination from "../../components/tables/Pagination"
import useEducation from "../../redux/hooks/useEducation"
import useGeneric from "../../redux/hooks/useGeneric"
import useMasterdata from "../../redux/hooks/useMasterdata"
import useUsers from "../../redux/hooks/useUsers"
import {
	application,
	articleStatus,
	city,
	company,
	concept,
	contentType,
	country,
	educationCategory,
	hqRetail,
	organizationUnit,
	positionSe1,
	positionSe2,
	sector,
} from "../../utils/constants"
import { handleToast, makeDropdownOptions, makeDropdownOptionsUsers } from "../../utils/functions"
import { handleSmartedSelectData } from "../../utils/smartedHelper"

const AdminEducationsPage = () => {
	const hook = useEducation()
	const toast = useToast()
	const hookMd = useMasterdata()
	const hookG = useGeneric()
	const config = useOutletContext()
	const hookUsers = useUsers()

	useEffect(() => {
		hook.cleanToast()
		hook.fetchPage(hook.defaultSizeAdmin, 1, true)
		hookMd.fetch(educationCategory)
		hookG.fetch(company)
		hookMd.fetch(application)
		hookMd.fetch(articleStatus)
		hookMd.fetch(contentType)

		if (config.appId === 2) {
			hookMd.fetch(hqRetail)
			hookMd.fetch(concept)
			hookMd.fetch(organizationUnit)
			hookMd.fetch(sector)
			hookMd.fetch(positionSe1)
			hookMd.fetch(positionSe2)
			hookG.fetch(city)
			hookMd.fetch(country)
			hookUsers.fetchDropdown()
		}
	}, []) //eslint-disable-line

	const [categories, setCategories] = useState([])
	const [companies, setCompanies] = useState([])
	const [applications, setApplications] = useState([])
	const [hqRetails, setHqRetails] = useState([])
	const [concepts, setConcepts] = useState([])
	const [organizationUnits, setOrganizationUnits] = useState([])
	const [sectors, setSectors] = useState([])
	const [positionsSe1, setPositionsSe1] = useState([])
	const [positionsSe2, setPositionsSe2] = useState([])
	const [statuses, setStatuses] = useState([])
	const [countries, setCountries] = useState([])
	const [cities, setCities] = useState([])
	const [users, setUsers] = useState([])
	const [contentTypes, setContentTypes] = useState([])

	useEffect(() => {
		makeDropdownOptions(hookMd.educationCategories, setCategories)
		makeDropdownOptions(hookG.companies, setCompanies)
		makeDropdownOptions(hookMd.apps, setApplications)
		makeDropdownOptions(hookMd.articleStatuses, setStatuses)
		makeDropdownOptions(hookMd.contentTypes, setContentTypes)

		if (config.appId === 2) {
			makeDropdownOptions(hookMd.hqRetails, setHqRetails)
			makeDropdownOptions(hookMd.concepts, setConcepts)
			makeDropdownOptions(hookMd.organizationUnits, setOrganizationUnits)
			makeDropdownOptions(hookMd.sectors, setSectors)
			makeDropdownOptions(hookMd.positionsSe1, setPositionsSe1)
			makeDropdownOptions(hookMd.positionsSe2, setPositionsSe2)
			makeDropdownOptions(hookG.cities, setCities)
			makeDropdownOptions(hookMd.countries, setCountries)
			makeDropdownOptionsUsers(hookUsers.data, setUsers)
		}
	}, [
		//eslint-disable-line
		hookMd.educationCategories,
		hookG.companies,
		hookMd.apps,
		hookMd.hqRetails,
		hookMd.concepts,
		hookMd.organizationUnits,
		hookMd.sectors,
		hookMd.positionsSe1,
		hookMd.positionsSe2,
		hookG.cities,
		hookMd.countries,
		hookUsers.data,
		hookMd.articleStatuses,
		hookMd.contentTypes,
	])

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "toast1")
		}
	}, [hook.message]) // eslint-disable-line

	const pagination = hook.pagination

	let selectData = {
		categories: categories,
		companies: companies,
		apps: applications,
		statuses: statuses,
		contentTypes: contentTypes,
	}

	if (config.appId === 2) {
		const allOptions = {
			hqRetails: hqRetails,
			concepts: concepts,
			organizationUnits: organizationUnits,
			sectors: sectors,
			positionsSe1: positionsSe1,
			positionsSe2: positionsSe2,
			cities: cities,
			countries: countries,
			users: users,
		}

		handleSmartedSelectData(allOptions, selectData)
	}

	return (
		<>
			<Box
				h="95%"
				overflow="auto"
				border={"4px"}
				boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
				borderColor="mc_black"
				borderRadius="4px"
				w="100%"
			>
				<EducationTable data={hook.data} selectData={selectData} />
				<PlusButton onClick={() => hook.onOpen()} />
				<AddUpdateModal isOpen={hook.isOpen} onClick={hook.onClose} size="full">
					<AddEduForm selectData={selectData} handleClose={hook.onClose} />
				</AddUpdateModal>
			</Box>
			{hook.pagination && pagination.total_count > 10 && (
				<Box w="80%" mx="auto">
					<Pagination
						paginationData={{
							totalCount: pagination.total_count,
							totalPages: pagination.total_pages,
							currentPage: pagination.current_page,
							pageSize: hook.defaultSizeAdmin,
							pageSizesArray: [10, 25, 50],
						}}
						setSize={hook.changeSize}
						fetchPage={hook.fetchPage}
						role={true}
					></Pagination>
				</Box>
			)}
		</>
	)
}

export default AdminEducationsPage
