import { Box, Button, Checkbox, Divider, Flex, FormLabel, HStack, IconButton, Stack, Text, useClipboard } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import { ExportToCsv } from "export-to-csv"
import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import { CopyIcon, CorrectIcon } from "../../components/Icons"
import SelectDropdown from "../../components/SelectDropdown"
import UploadFile from "../../components/UploadFile"
import useDataManagement from "../../redux/hooks/useDataManagement"
import useUser from "../../redux/hooks/useUser"
import {
	articleCategory,
	city,
	company,
	companyType,
	country,
	educationCategory,
	exportAction,
	importAction,
	language,
	position,
	title,
} from "../../utils/constants"
import { csvOptions, handleToast } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { smartedExportOptions } from "../../utils/smartedHelper"

const AdminPanelHomePage = () => {
	const hook = useDataManagement()
	const toast = useToast()
	const config = useOutletContext()
	const user = useUser()
	console.log(user, "user")

	const [csvFile, setCsvFile] = useState(null)
	const [uploadFile, setUploadFile] = useState(null)
	const [urlFileValue, setUrlFileValue] = useState("")
	const { hasCopied, onCopy } = useClipboard(urlFileValue)

	const [selectedExportOption, setSelectedExportOption] = useState(null)
	const [selectedImportOption, setSelectedImportOption] = useState(null)
	const [disabled, setDisabled] = useState(false)
	const [newEmployee, setNewEmployee] = useState(false)

	const handleFile = (csvFile) => {
		setCsvFile(csvFile)
	}

	const handleUploadAnyFile = (uploadFile) => {
		setUploadFile(uploadFile)
	}

	const exportOptions = [
		{ label: strings.countries, value: country },
		{ label: strings.jobTitles, value: title },
		{ label: strings.positions, value: position },
		{ label: strings.companyTypes, value: companyType },
		{ label: strings.cities, value: city },
		{ label: strings.companies, value: company },
		{ label: strings.educationCategories, value: educationCategory },
		{ label: strings.articleCategories, value: articleCategory },
		{ label: strings.application, value: "App" },
		{ label: strings.language, value: language },
	]

	if (config.appId === 2) {
		smartedExportOptions.forEach((option) => exportOptions.push(option))
	}

	const importOptions = [
		{ label: strings.users, value: "users" },
		{ label: strings.usersUpdate, value: "users-update" },
	]

	useEffect(() => {
		if (hook.importExportMessage) {
			handleToast(toast, hook.importExportMessage, "toast1", true)
		}

		if (hook.reportMessage) {
			handleToast(toast, hook.reportMessage, "toast2")
		}
	}, [hook.importExportMessage, hook.reportMessage]) //eslint-disable-line

	const handleExportData = () => {
		if (!selectedExportOption) {
			handleToast(toast, { text: strings.noDataSelectedMessage, type: "error" }, "toast1")
		} else {
			const payload = {
				action: exportAction,
				target: selectedExportOption.value,
			}
			hook.exportFile(payload).then((res) => {
				if (res !== undefined && res.status === 200) {
					const csvExporter = new ExportToCsv(csvOptions(res.data.file_name, res.data.headers))
					csvExporter.generateCsv(res.data.file_data)
				}
			})
		}
	}

	const handleImportData = () => {
		if (!selectedImportOption) {
			handleToast(toast, { text: strings.noDataSelectedMessage, type: "error" }, "toast2")
		} else if (!csvFile) {
			handleToast(toast, { text: strings.noFileSelectedMessage, type: "error" }, "toast3")
		} else {
			let formData = new FormData()

			formData.append("action", importAction)
			formData.append("target", selectedImportOption.value)
			formData.append("file", csvFile, csvFile.name)

			if (newEmployee) {
				formData.append("new_employee", "True")
			} else {
				formData.append("new_employee", "False")
			}

			hook.importFile(formData)
		}
	}

	const handleUpdateEmployeeStatus = () => {
		setDisabled(true)
		hook.updateEmployeeStatus({ action: "update_new_employee" })
	}

	const handleUploadFile = () => {
		if (!uploadFile) {
			handleToast(toast, { text: strings.noFileSelectedMessage, type: "error" }, "toast3")
		} else {
			let formData = new FormData()
			formData.append("file", uploadFile, uploadFile.name)
			hook.uploadAnyFile(formData).then((res) => {
				if (res !== undefined && res.status === 200) {
					setUrlFileValue(res.data.file_url)
				}
			})
		}
	}

	return (
		<Stack
			w="100%"
			// pt={{ base: "20px", xl: "100px" }}
			// h="300px"
			justify={{ base: "flex-start" }}
			alignItems={{ base: "center" }}
			direction={{ base: "column" }}
			spacing={{ base: 12, xl: 14 }}
			minH="90vh"
			overflow="auto"
		>
			{user.admin && (
				<>
					<Stack alignItems="center" w="100%" maxW="500px" gridRowGap={{ base: "0px" }}>
						<FormLabel color="mc_medium">{strings.exportData}</FormLabel>
						{/* <Show above="xl">
					<Box h="68px" />
				</Show> */}

						<Flex w="100%" gridGap="10px">
							<Box w="100%">
								<SelectDropdown
									options={exportOptions}
									value={selectedExportOption}
									onChange={(val) => setSelectedExportOption(val)}
								/>
							</Box>
							<Button variant="medium" color="mc_bg" onClick={handleExportData}>
								{strings.export}
							</Button>
						</Flex>
					</Stack>
					<Divider borderColor="mc_medium" borderWidth={2} maxW="500px" />
					<Stack alignItems="center" maxW="500px" w="100%" gridRowGap="20px">
						<FormLabel color="mc_medium">{strings.importData}</FormLabel>
						<Box>
							<UploadFile onChange={handleFile} format=".csv" />
						</Box>

						<Flex w="100%" gridGap="10px">
							<Box w="100%">
								<SelectDropdown
									options={importOptions}
									value={selectedImportOption}
									onChange={(val) => setSelectedImportOption(val)}
								/>
							</Box>
							<Button variant="medium" onClick={handleImportData}>
								{strings.import}
							</Button>
						</Flex>
						<Checkbox
							mt={"10px"}
							size="lg"
							alignItems="center"
							color="mc_dark"
							fontSize="md"
							value={newEmployee}
							onChange={(e) => setNewEmployee(e.target.checked)}
						>
							{strings.newEmployees}
						</Checkbox>
					</Stack>
					<Divider borderColor="mc_medium" borderWidth={2} maxW="500px" />
					<Flex>
						<Button mt={{ base: 0 }} variant="medium" disabled={disabled} onClick={() => handleUpdateEmployeeStatus()}>
							{strings.updateEmploymentStatus}
						</Button>
					</Flex>
					<Divider borderColor="mc_medium" borderWidth={2} maxW="500px" />
				</>
			)}
			<Stack maxW="500px" w="100%" align="center" spacing={6}>
				<FormLabel color="mc_medium" textTransform="uppercase">
					{strings.uploadFile}
				</FormLabel>
				<Flex w="100%" justify="center" align="center" gap={3}>
					<Box>
						<UploadFile onChange={handleUploadAnyFile} fileUpload={uploadFile} w="180px" h="40px" textAlign="center" />
					</Box>
					<Button variant="medium" onClick={handleUploadFile} isLoading={hook.loading}>
						{strings.import}
					</Button>
				</Flex>
				{urlFileValue && (
					<Flex w="100%" maxW="500px" justify="space-between" align="center" py={6} gap={2}>
						<HStack>
							<Text textAlign="center">URL:</Text>
							<Box bg="rgba(66, 146, 220,0.2)" p={1} borderRadius="4px" fontStyle="italic" overflow="auto" maxW="420px">
								<Text fontSize="14px">{urlFileValue}</Text>
							</Box>
						</HStack>
						<IconButton
							aria-label="copy"
							onClick={onCopy}
							icon={
								hasCopied ? (
									<CorrectIcon />
								) : (
									<CopyIcon
										boxSize={5}
										// transform={hasCopied ? "scale(1.1)" : null}
									/>
								)
							}
						/>
					</Flex>
				)}
			</Stack>
		</Stack>
	)
}

export default AdminPanelHomePage
