import { billboardUrl, billboardPageAdminURL, billboardRemoveUrl, jobApplicationURL } from "../api_urls";
import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios";
import { strings } from "../../utils/localization";

const START_REQUEST = createAction("BOARD/START_REQUEST")
const FETCH_SUCCESS = createAction("BOARD/FETCH_SUCCESS")
const FETCH_SUCCESS_DETAIL = createAction("BOARD/FETCH_SUCCESS_DETAIL")
const REQUEST_SUCCESS = createAction("BOARD/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("BOARD/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("BOARD/CLEAN_MESSAGE")
const CHANGE_SIZE = createAction("BOARD/CHANGE_SIZE")

export const fetchBillboardPage = (perPage, pageNumber) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.get(billboardPageAdminURL(perPage, pageNumber))
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL))
};

export const fetchOneJob = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.get(billboardRemoveUrl(id))
	.then(res => handleResponse(res, dispatch, FETCH_SUCCESS_DETAIL, REQUEST_FAIL))
}

export const createBillboardAd = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.post(billboardUrl, payload)
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const updateBillboardAd  = (payload) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.put(billboardUrl, payload)
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const removeBillboardAd  = (id) => async (dispatch) => {  
	dispatch(START_REQUEST())
	return axios.delete(billboardRemoveUrl(id))
		.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
};

export const postJobApplication = (payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(jobApplicationURL, payload)
	.then(res => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}


export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

export const changeDefaultSize = (size) => async (dispatch) => {
	dispatch(CHANGE_SIZE(size))
}

const handleResponse = (res, dispatch, success, fail) => {
	if(res !== undefined) {
		if(res.status >= 200 && res.status <= 299) {
		
			dispatch(success(res.data))
	
			return res
		}else if(res.response !== undefined && res.response.status === 400){
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		}else{
			dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
			dispatch(CLEAN_MESSAGE())
		}
	}else{
		dispatch(fail({text: strings.serverErrorMessage, type: "error"}))
		dispatch(CLEAN_MESSAGE())
	}
}


const initState = {
	data: [],
	detail: null,
	pagination: null,
	defaultSize: 25,
	message: null,
	loading: false,
};

export const billboardReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			state.data = action.payload.job_listings
			state.pagination = action.payload.pagination
		})
		.addCase(FETCH_SUCCESS_DETAIL, (state, action) => {
			state.loading = false
			state.detail = action.payload
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload.message
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
		.addCase(CHANGE_SIZE, (state, action) => {
			state.defaultSize = action.payload
		})
	})
  