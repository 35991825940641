import { createAction, createReducer } from "@reduxjs/toolkit"
import axios from "axios"
import {
	application,
	articleStatus,
	companyType,
	concept,
	contentType,
	country,
	educationCategory,
	hqRetail,
	language,
	organizationUnit,
	position,
	positionSe1,
	positionSe2,
	sector,
	tag,
	title,
} from "../../utils/constants"
import { strings } from "../../utils/localization"
import { masterdataRemoveURL, masterdataURL, publicMasterdata } from "../api_urls"

const START_REQUEST = createAction("MD/START_REQUEST")
const FETCH_SUCCESS = createAction("MD/FETCH_SUCCESS")
const FETCH_PUBLIC_SUCCESS = createAction("MD/FETCH_PUBLIC_SUCCESS")
const REQUEST_SUCCESS = createAction("MD/REQUEST_SUCCESS")
const REQUEST_FAIL = createAction("MD/REQUEST_FAIL")
const CLEAN_MESSAGE = createAction("MD/CLEAN_MESSAGE")

export const fetchMasterdata = (type) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(masterdataURL(type)).then((res) => handleResponse(res, dispatch, FETCH_SUCCESS, REQUEST_FAIL, type))
}

export const fetchPublicMasterdata = () => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.get(publicMasterdata).then((res) => handleResponse(res, dispatch, FETCH_PUBLIC_SUCCESS, REQUEST_FAIL))
}

export const createMasterdata = (type, payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.post(masterdataURL(type), payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const updateMasterdata = (type, payload) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.put(masterdataURL(type), payload).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const removeMasterdata = (type, id) => async (dispatch) => {
	dispatch(START_REQUEST())
	return axios.delete(masterdataRemoveURL(type, id)).then((res) => handleResponse(res, dispatch, REQUEST_SUCCESS, REQUEST_FAIL))
}

export const cleanMessage = () => async (dispatch) => {
	dispatch(CLEAN_MESSAGE())
}

const handleResponse = (res, dispatch, success, fail, type) => {
	if (res !== undefined) {
		if (res.status >= 200 && res.status <= 299) {
			if (success === REQUEST_SUCCESS) {
				dispatch(success(res.data.message))
				dispatch(CLEAN_MESSAGE())
			} else if (success === FETCH_PUBLIC_SUCCESS) {
				dispatch(success(res.data))
			} else {
				dispatch(success({ data: res.data, type: type }))
			}
			return res
		} else if (res.response !== undefined && res.response.status === 400) {
			dispatch(fail(res.response.data.message))
			dispatch(CLEAN_MESSAGE())
		} else {
			dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
			dispatch(CLEAN_MESSAGE())
		}
	} else {
		dispatch(fail({ text: strings.serverErrorMessage, type: "error" }))
		dispatch(CLEAN_MESSAGE())
	}
}

const initState = {
	titles: [],
	positions: [],
	educationCategories: [],
	companyTypes: [],
	articleStatuses: [],
	countries: [],
	apps: [],
	languages: [],
	companies: [],
	cities: [],
	tags: [],
	hqRetails: [],
	concepts: [],
	organizationUnits: [],
	sectors: [],
	positionsSe1: [],
	positionsSe2: [],
	contentTypes: [],
	message: null,
	loading: false,
}

export const masterdataReducer = createReducer(initState, (builder) => {
	builder
		.addCase(START_REQUEST, (state, action) => {
			state.loading = true
			state.message = null
		})
		.addCase(FETCH_SUCCESS, (state, action) => {
			state.loading = false
			if (action.payload.type === title) {
				state.titles = action.payload.data
			} else if (action.payload.type === position) {
				state.positions = action.payload.data
			} else if (action.payload.type === educationCategory) {
				state.educationCategories = action.payload.data
			} else if (action.payload.type === companyType) {
				state.companyTypes = action.payload.data
			} else if (action.payload.type === articleStatus) {
				state.articleStatuses = action.payload.data
			} else if (action.payload.type === country) {
				state.countries = action.payload.data
			} else if (action.payload.type === application) {
				state.apps = action.payload.data
			} else if (action.payload.type === language) {
				state.languages = action.payload.data
			} else if (action.payload.type === tag) {
				state.tags = action.payload.data
			} else if (action.payload.type === hqRetail) {
				state.hqRetails = action.payload.data
			} else if (action.payload.type === concept) {
				state.concepts = action.payload.data
			} else if (action.payload.type === organizationUnit) {
				state.organizationUnits = action.payload.data
			} else if (action.payload.type === sector) {
				state.sectors = action.payload.data
			} else if (action.payload.type === positionSe1) {
				state.positionsSe1 = action.payload.data
			} else if (action.payload.type === positionSe2) {
				state.positionsSe2 = action.payload.data
			} else if (action.payload.type === contentType) {
				state.contentTypes = action.payload.data
			}
		})
		.addCase(FETCH_PUBLIC_SUCCESS, (state, action) => {
			state.loading = false
			state.cities = action.payload.cities
			state.countries = action.payload.countries
			state.positions = action.payload.positions
			state.titles = action.payload.titles
			state.companies = action.payload.companies
		})
		.addCase(REQUEST_SUCCESS, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(REQUEST_FAIL, (state, action) => {
			state.loading = false
			state.message = action.payload
		})
		.addCase(CLEAN_MESSAGE, (state, action) => {
			state.message = null
		})
})
