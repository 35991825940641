import { Flex } from "@chakra-ui/layout"

const Count = ({ count }) => {
	return (
		<Flex
			alignItems="baseline"
			justifyContent="center"
			minW="17px"
			h="17px"
			px="3px"
			rounded="full"
			bg="mc_black"
			color="mc_bg"
			fontSize="12px"
			position="absolute"
			top="-2"
			right="-2"
		>
			{count}
		</Flex>
	)
}

export default Count
