import { Button } from "@chakra-ui/button"
import { Badge, Box, Flex, Heading, Text } from "@chakra-ui/layout"
import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Icon,
	useDisclosure,
} from "@chakra-ui/react"
import { useRef } from "react"
import { formatDateWithoutTime, getTimeRemaining } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { CorrectIcon, Created, Hourglass, LockerIcon, WhiteArrowIcon } from "../Icons"

const EducationListItem = ({ data, redirect, locked }) => {
	const timeRemaining = getTimeRemaining(data.expiration_date)
	const { isOpen, onOpen, onClose } = useDisclosure()
	const cancelRef = useRef()

	return (
		<>
			<Flex
				minH="65px"
				w="100%"
				boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
				bg={locked ? "gray.200" : "mc_bg"}
				rounded="xl"
				justify="space-between"
				alignItems="center"
				gridGap={["4px", "30px"]}
				onClick={() => (locked ? onOpen() : redirect())}
				cursor="pointer"
				border={data.completed && "2px"}
				// _hover={{ transform: "scale(1.006)", transition: "0.3s" }}
			>
				<Box mx={["4px", "10px"]}>
					<Flex
						direction={["column", "row"]}
						gridGap={["4px", "10px", "30px"]}
						mb="10px"
						mt="4px"
						align={["flex-start", "center"]}
					>
						<Badge
							bg={locked ? "gray.400" : "mc_medium"}
							color={"mc_bg"}
							letterSpacing="1px"
							rounded="md"
							px="6px"
							pt="3px"
							fontSize={["10px", "14px"]}
							whiteSpace="pre-wrap"
							maxW={["160px", "220px", "100%"]}
						>
							{data.category_name}
						</Badge>
						<Flex direction={["row", "column", "row"]} gridGap={["4px", "4px", "20px", "30px"]}>
							<Flex color={locked ? "grey" : "mc_dark"} gridGap={["4px", "10px"]} align="center" title={strings.creationDate}>
								<Icon as={Created} h="20px" w="18px" />

								<Text letterSpacing="1px" fontSize={["11px", "14px"]}>
									{formatDateWithoutTime(data.date_created)}
								</Text>
							</Flex>

							{data.expiration_date && (
								<Flex gridGap={["4px", "10px"]} align="center" title={strings.expirationDate}>
									<Icon as={Hourglass} h="20px" w="18px" fill="mc_black" />
									<Text letterSpacing="1px" fontSize={["11px", "14px"]}>
										{timeRemaining.days >= 0 && timeRemaining.hours > 0
											? `${strings.days} : ${timeRemaining.days} , ${strings.hours} : ${timeRemaining.hours}`
											: strings.expired}
									</Text>
								</Flex>
							)}
						</Flex>
					</Flex>
					<Heading as="h3" fontSize={["14px", "14px", "14px", "18px"]} color="mc_dark" textAlign="left">
						{data.name}
					</Heading>
				</Box>
				<Flex w={["50px", "80px"]} align="center" justify="flex-end" mr="5px">
					{locked ? (
						<Button
							h={["30px", "50px"]}
							bg="gray.400"
							w={["40px", "70px"]}
							px={["8px", "16px"]}
							boxShadow="0px 2px 12px 2px rgba(0, 0, 0, 0.15)"
							borderRadius="xl"
						>
							<Icon as={LockerIcon} boxSize={6} color="mc_bg" />
						</Button>
					) : data.completed ? (
						<Button h={["30px", "50px"]} w={["40px", "70px"]} variant="dark" px={["8px", "16px"]} borderRadius="xl">
							<CorrectIcon fill="mc_bg" />
						</Button>
					) : (
						<Button h={["30px", "50px"]} w={["40px", "70px"]} variant="medium" px={["8px", "16px"]} borderRadius="xl">
							<WhiteArrowIcon fill="mc_bg" />
						</Button>
					)}
				</Flex>
			</Flex>
			<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose} isCentered>
				<AlertDialogOverlay>
					<AlertDialogContent borderRadius="xl">
						<AlertDialogHeader fontSize="lg" fontWeight="bold" display="flex" justifyContent="center">
							<Icon as={LockerIcon} boxSize={7} />
						</AlertDialogHeader>

						<AlertDialogBody py={6} fontWeight={700}>
							{strings.lockedEduMessage}
						</AlertDialogBody>

						<AlertDialogFooter justifyContent="center">
							<Button ref={cancelRef} onClick={onClose} w="86px" variant="medium">
								Ok
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	)
}
export default EducationListItem
