import { Button } from "@chakra-ui/button"
import { FormLabel } from "@chakra-ui/form-control"
import { Box, Flex } from "@chakra-ui/layout"
import { useToast } from "@chakra-ui/toast"
import { ExportToCsv } from "export-to-csv"
import { useEffect, useState } from "react"
import PieChart from "../../components/charts/PieChart"
import { DatePickerWrapper } from "../../components/DatePickerComponent"
import SelectDropdown from "../../components/SelectDropdown"
import useReport from "../../redux/hooks/useDataManagement"
import useGeneric from "../../redux/hooks/useGeneric"
import useConfig from "../../utils/config"
import {
	article,
	education,
	form,
	jobListing,
	reportModelArticle,
	reportModelComment,
	reportModelEducation,
	reportModelFormSubmissions,
	reportModelJobListing,
	reportModelSurvey,
	reportModelTest,
	reportTypeArticleAll,
	reportTypeArticleByUserReports,
	reportTypeArticleChoose,
	reportTypeAssignedToUsers,
	reportTypeCity,
	reportTypeEduAll,
	reportTypeEduChoose,
	reportTypeEduChooseCompleted,
	reportTypeEduChooseNotCompleted,
	// reportTypeTotal,
	reportTypeLoginData,
	reportTypeMonthly,
	reportTypeQuestion,
	reportTypeSubQuestions,
	reportTypeUser,
	survey,
	test,
} from "../../utils/constants"
import {
	csvOptions,
	handleSelectedDropdownOption,
	handleToast,
	makeDropdownOptions,
	makeDropdownOptionsArticle,
} from "../../utils/functions"
import { strings } from "../../utils/localization"

// import BarChart from "../../components/charts/BarChart"

const defaultReportModelOptions = [
	{ label: strings.articles, value: reportModelArticle },
	{ label: strings.educations, value: reportModelEducation },
	{ label: strings.tests, value: reportModelTest },
	{ label: strings.surveys, value: reportModelSurvey },
	{ label: strings.comments, value: reportModelComment },
	{ label: strings.users, value: reportTypeUser },
	{ label: strings.forms, value: reportModelFormSubmissions },
]

const AdminReportsPage = () => {
	const hook = useReport()
	const hookG = useGeneric()
	const toast = useToast()
	const config = useConfig()

	const [selectedReportModel, setSelectedReportModel] = useState(null)
	const [selectedReportTypeUser, setSelectedReportTypeUser] = useState(null)
	const [selectedReportTypeEdu, setSelectedReportTypeEdu] = useState(null)
	const [selectedReportTypeTest, setSelectedReportTypeTest] = useState(null)
	const [selectedReportTypeArticle, setSelectedReportTypeArticle] = useState(null)
	const [selectedTest, setSelectedTest] = useState(null)
	const [selectedArticle, setSelectedArticle] = useState(null)
	const [selectedSurvey, setSelectedSurvey] = useState(null)
	const [selectedEdu, setSelectedEdu] = useState(null)
	const [selectedJob, setSelectedJob] = useState(null)
	const [selectedForm, setSelectedForm] = useState(null)
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const [tests, setTests] = useState([])
	const [articles, setArticles] = useState([])
	const [surveys, setSurveys] = useState([])
	const [educations, setEducations] = useState([])
	const [jobListings, setJobListings] = useState([])
	const [forms, setForms] = useState([])
	const [pieGraphData, setPieGraphData] = useState(null)
	const [reportModelOptions, setReportModelOptions] = useState(defaultReportModelOptions)
	// const [barGraphData, setBarGraphData] = useState(null)

	useEffect(() => {
		hookG.fetch(test)
		hookG.fetch(article)
		hookG.fetch(survey)
		hookG.fetch(education)
		hookG.fetch(form)

		if (config.appId !== 2) {
			const currentOptions = reportModelOptions
			currentOptions.push({ label: strings.jobs, value: reportModelJobListing })
			setReportModelOptions(currentOptions)
			hookG.fetch(jobListing)
		}
	}, []) //eslint-disable-line

	useEffect(() => {
		makeDropdownOptions(hookG.tests, setTests)
		makeDropdownOptionsArticle(hookG.articles, setArticles)
		makeDropdownOptions(hookG.surveys, setSurveys)
		makeDropdownOptions(hookG.educations, setEducations)
		makeDropdownOptionsArticle(hookG.jobs, setJobListings)
		makeDropdownOptions(hookG.forms, setForms)
	}, [hookG.tests, hookG.articles, hookG.surveys, hookG.educations, hookG.jobs, hookG.forms])

	const handleSubmitReport = () => {
		let payload = {}

		if (!selectedReportModel) {
			handleToast(toast, { text: strings.noReportSelectedMessage, type: "error" }, "2")
		} else {
			payload["report_model"] = selectedReportModel
			if (selectedReportModel === reportModelTest) {
				if (!selectedTest) {
					handleToast(toast, { text: strings.noTestSelectedMessage, type: "error" }, "3")
				} else if (!selectedReportTypeTest) {
					handleToast(toast, { text: strings.noTypeSelectedMessage, type: "error" }, "4")
				} else {
					payload["report_type"] = selectedReportTypeTest
					payload["test_id"] = selectedTest
					hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
				}
			} else if (selectedReportModel === reportModelComment) {
				if (!selectedArticle) {
					handleToast(toast, { text: strings.noArticleSelected, type: "error" }, "5")
				} else {
					payload["article_id"] = selectedArticle
					hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
				}
			} else if (selectedReportModel === reportModelEducation) {
				if (!selectedReportTypeEdu) {
					handleToast(toast, { text: strings.noTypeSelectedMessage, type: "error" }, "4")
				} else {
					payload["report_type"] = selectedReportTypeEdu
					if (selectedReportTypeEdu === reportTypeEduAll) {
						if (startDate) {
							payload["date_from"] = startDate
						}

						if (endDate) {
							payload["date_to"] = endDate
						}
						hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
					} else if (eduChooseCondition.includes(selectedReportTypeEdu)) {
						if (!selectedEdu) {
							handleToast(toast, { text: strings.noEduSelectedMessage, type: "error" }, "3")
						} else {
							payload["education"] = selectedEdu
							hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
						}
					}
				}
			} else if (selectedReportModel === reportModelArticle) {
				if (!selectedReportTypeArticle) {
					handleToast(toast, { text: strings.noTypeSelectedMessage, type: "error" }, "4")
				} else {
					payload["report_type"] = selectedReportTypeArticle
					if (articleChooseCondition.includes(selectedReportTypeArticle)) {
						if (!selectedArticle) {
							handleToast(toast, { text: strings.noArticleSelected, type: "error" }, "5")
						} else {
							payload["article_id"] = selectedArticle
							hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
						}
					} else if (selectedReportTypeArticle === reportTypeArticleAll) {
						if (startDate) {
							payload["date_from"] = startDate
						}

						if (endDate) {
							payload["date_to"] = endDate
						}
						hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
					}
				}
			} else if (selectedReportModel === reportModelSurvey) {
				if (!selectedSurvey) {
					handleToast(toast, { text: strings.noSurveySelected, type: "error" }, "3")
				} else {
					payload["survey_id"] = selectedSurvey
					hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
				}
			} else if (selectedReportModel === reportTypeUser) {
				if (!selectedReportTypeUser) {
					handleToast(toast, { text: strings.noTypeSelectedMessage, type: "error" }, "4")
				} else {
					payload["report_type"] = selectedReportTypeUser
					if (selectedReportTypeUser) {
						if (startDate) {
							payload["date_from"] = startDate
						}

						if (endDate) {
							payload["date_to"] = endDate
						}
					}
					hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
				}
			} else if (selectedReportModel === reportModelJobListing) {
				if (!selectedJob) {
					handleToast(toast, { text: strings.noJobSelected, type: "error" }, "5")
				} else {
					payload["job_listing_id"] = selectedJob
					hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
				}
			} else if (selectedReportModel === reportModelFormSubmissions) {
				if (!selectedForm) {
					handleToast(toast, { text: strings.noTypeSelectedMessage, type: "error" }, "4")
				} else {
					payload["form_id"] = selectedForm
					if (startDate) {
						payload["date_from"] = startDate
					}

					if (endDate) {
						payload["date_to"] = endDate
					}
					hook.makeReport(payload).then((res) => checkResAndExportToCsv(res))
				}
			}
		}
	}

	const checkResAndExportToCsv = (res) => {
		if (res !== undefined && res.status === 200) {
			if (res.data !== undefined && res.data.file_data !== undefined && res.data.file_data.length > 0) {
				const csvExporter = new ExportToCsv(csvOptions(res.data.file_name))
				csvExporter.generateCsv(res.data.file_data)

				// if(res.data.bar_graph_data !== undefined && Object.keys(res.data.bar_graph_data).length > 0) {
				// 	setBarGraphData(res.data.bar_graph_data)
				// }
			} else {
				handleToast(toast, { text: strings.notEnoughReportDataMessage, type: "info" }, "6")
			}
			if (res.data.pie_graph_data !== undefined) {
				setPieGraphData(res.data.pie_graph_data)
			} else {
				setPieGraphData(null)
			}
		}
	}

	useEffect(() => {
		if (hook.reportMessage) {
			handleToast(toast, hook.reportMessage, "1")
		}
	}, [hook.reportMessage]) // eslint-disable-line

	const reportTypeTestOptions = [
		{ label: strings.byUser, value: reportTypeUser },
		{ label: strings.byCity, value: reportTypeCity },
		{ label: strings.byQuestions, value: reportTypeQuestion },
		{ label: strings.byAssignedUser, value: reportTypeAssignedToUsers },
		{ label: strings.bySubQuestions, value: reportTypeSubQuestions },
	]

	const reportTypeUserOptions = [
		{ label: strings.monthly, value: reportTypeMonthly },
		// { label: strings.total, value: reportTypeTotal },
		{ label: strings.loginData, value: reportTypeLoginData },
	]

	const reportTypeEduOptions = [
		{ label: strings.eduAll, value: reportTypeEduAll },
		{ label: strings.eduChoose, value: reportTypeEduChoose },
		{ label: strings.eduChooseCompleted, value: reportTypeEduChooseCompleted },
		{ label: strings.eduChooseNotCompleted, value: reportTypeEduChooseNotCompleted },
	]

	const reportTypeArticleOptions = [
		{ label: strings.articleAll, value: reportTypeArticleAll },
		{ label: strings.articleByUserReports, value: reportTypeArticleByUserReports },
		{ label: strings.eduChoose, value: reportTypeArticleChoose },
		//{ label: strings.byAssignedUser, value: reportTypeAssignedToUsers }, // treba dodati article id kao na choose
	]

	const clean = () => {
		setSelectedReportTypeUser(null)
		setSelectedReportTypeEdu(null)
		setSelectedReportTypeTest(null)
		setSelectedTest(null)
		setSelectedArticle(null)
		setSelectedSurvey(null)
		setSelectedEdu(null)
		setStartDate(null)
		setEndDate(null)
	}

	const eduChooseCondition = [reportTypeEduChooseCompleted, reportTypeEduChooseNotCompleted, reportTypeEduChoose]
	const articleChooseCondition = [reportTypeArticleChoose, reportTypeArticleByUserReports]

	return (
		<>
			<Flex
				direction={["column", "column", "column", "column", "row"]}
				w="90%"
				m="auto"
				gridGap="30px"
				justify="flex-start"
				alignItems={["center", "center", "center", "center", "flex-end"]}
				mt="100px"
			>
				<Box w="300px">
					<FormLabel color="mc_medium" mb="10px">
						{strings.report}
					</FormLabel>
					<SelectDropdown
						options={reportModelOptions}
						onChange={(val) => handleSelectedDropdownOption(val, setSelectedReportModel, clean)}
					/>
				</Box>

				{selectedReportModel === reportModelTest && (
					<>
						<Box w="300px">
							<FormLabel color="mc_medium" mb="10px">
								{strings.test}
							</FormLabel>
							<SelectDropdown options={tests} onChange={(val) => handleSelectedDropdownOption(val, setSelectedTest)} />
						</Box>
						<Box w="300px">
							<FormLabel color="mc_medium" mb="10px">
								{strings.type}
							</FormLabel>
							<SelectDropdown
								options={reportTypeTestOptions}
								onChange={(val) => handleSelectedDropdownOption(val, setSelectedReportTypeTest)}
							/>
						</Box>
					</>
				)}
				{selectedReportModel === reportModelComment && (
					<Box w="300px">
						<FormLabel color="mc_medium" mb="10px">
							{strings.article}
						</FormLabel>
						<SelectDropdown options={articles} onChange={(val) => handleSelectedDropdownOption(val, setSelectedArticle)} />
					</Box>
				)}
				{selectedReportModel === reportModelSurvey && (
					<Box w="300px">
						<FormLabel color="mc_medium" mb="10px">
							{strings.survey}
						</FormLabel>
						<SelectDropdown options={surveys} onChange={(val) => handleSelectedDropdownOption(val, setSelectedSurvey)} />
					</Box>
				)}
				{selectedReportModel === reportTypeUser && (
					<>
						<Box w="300px">
							<FormLabel color="mc_medium" mb="10px">
								{strings.type}
							</FormLabel>
							<SelectDropdown
								options={reportTypeUserOptions}
								onChange={(val) => handleSelectedDropdownOption(val, setSelectedReportTypeUser)}
							/>
						</Box>
						{selectedReportTypeUser && (
							<>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.from}
									</FormLabel>
									<DatePickerWrapper
										selected={startDate}
										onChange={(date) => setStartDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.to}
									</FormLabel>
									<DatePickerWrapper
										selected={endDate}
										showPopperArrow={true}
										onChange={(date) => setEndDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
							</>
						)}
					</>
				)}
				{selectedReportModel === reportModelArticle && (
					<>
						<Box w="300px">
							<FormLabel color="mc_medium" mb="10px">
								{strings.type}
							</FormLabel>
							<SelectDropdown
								options={reportTypeArticleOptions}
								onChange={(val) => handleSelectedDropdownOption(val, setSelectedReportTypeArticle)}
							/>
						</Box>
						{selectedReportTypeArticle === reportTypeArticleAll && (
							<>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.from}
									</FormLabel>
									<DatePickerWrapper
										selected={startDate}
										onChange={(date) => setStartDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.to}
									</FormLabel>
									<DatePickerWrapper
										selected={endDate}
										showPopperArrow={true}
										onChange={(date) => setEndDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
							</>
						)}
						{articleChooseCondition.includes(selectedReportTypeArticle) && (
							<Box w="300px">
								<FormLabel color="mc_medium" mb="10px">
									{strings.article}
								</FormLabel>
								<SelectDropdown
									options={articles}
									onChange={(val) => handleSelectedDropdownOption(val, setSelectedArticle)}
								/>
							</Box>
						)}
					</>
				)}
				{selectedReportModel === reportModelEducation && (
					<>
						<Box w="300px">
							<FormLabel color="mc_medium" mb="10px">
								{strings.type}
							</FormLabel>
							<SelectDropdown
								options={reportTypeEduOptions}
								onChange={(val) => handleSelectedDropdownOption(val, setSelectedReportTypeEdu)}
							/>
						</Box>
						{selectedReportTypeEdu === reportTypeEduAll && (
							<>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.from}
									</FormLabel>
									<DatePickerWrapper
										selected={startDate}
										onChange={(date) => setStartDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.to}
									</FormLabel>
									<DatePickerWrapper
										selected={endDate}
										showPopperArrow={true}
										onChange={(date) => setEndDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
							</>
						)}
						{eduChooseCondition.includes(selectedReportTypeEdu) && (
							<Box w="300px">
								<FormLabel color="mc_medium" mb="10px" textTransform={"uppercase"}>
									{strings.education}
								</FormLabel>
								<SelectDropdown
									options={educations}
									onChange={(val) => handleSelectedDropdownOption(val, setSelectedEdu)}
								/>
							</Box>
						)}
					</>
				)}
				{selectedReportModel === reportModelJobListing && (
					<Box w="300px">
						<FormLabel color="mc_medium" mb="10px">
							{strings.job}
						</FormLabel>
						<SelectDropdown options={jobListings} onChange={(val) => handleSelectedDropdownOption(val, setSelectedJob)} />
					</Box>
				)}
				{selectedReportModel === reportModelFormSubmissions && (
					<>
						<Box w="300px">
							<FormLabel color="mc_medium" mb="10px" textTransform={"uppercase"}>
								{strings.forms}
							</FormLabel>
							<SelectDropdown options={forms} onChange={(val) => handleSelectedDropdownOption(val, setSelectedForm)} />
						</Box>
						{selectedForm && (
							<>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.from}
									</FormLabel>
									<DatePickerWrapper
										selected={startDate}
										onChange={(date) => setStartDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
								<Box w="300px">
									<FormLabel color="mc_medium" mb="10px">
										{strings.to}
									</FormLabel>
									<DatePickerWrapper
										selected={endDate}
										showPopperArrow={true}
										onChange={(date) => setEndDate(date)}
										dateFormat="dd-MM-yyyy"
									/>
								</Box>
							</>
						)}
					</>
				)}
				<Button variant="medium" onClick={handleSubmitReport} minW="100px">
					{strings.generate}
				</Button>
			</Flex>
			<Flex justify="center" align="center" h="600px">
				{pieGraphData && <PieChart data={pieGraphData} user={false} />}
			</Flex>
		</>
	)
}

export default AdminReportsPage
