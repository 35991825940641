import BootstrapTable from "react-bootstrap-table-next"
import useAbsenceRequest from "../../redux/hooks/useAbsenceRequest"
import { absenceTypeOptions } from "../../utils/constants"
import { formatDateForCell } from "../../utils/functions"
import { strings } from "../../utils/localization"
import CustomButton from "../buttons/CustomButton"
import { TablesStyles } from "./TablesStyles"

const AbsenceRequestTable = ({ data }) => {
	const hook = useAbsenceRequest()

	const columns = [
		{
			dataField: "date_created",
			text: strings.creationDate,
			formatter: (cell) => formatDateForCell(cell),
		},
		{
			dataField: "data.first_name",
			text: strings.firstName,
		},
		{
			dataField: "data.last_name",
			text: strings.lastName,
		},
		{
			dataField: "data.email",
			text: strings.email,
		},
		{
			dataField: "data.mpo_name",
			text: "MPO",
		},
		{
			dataField: "data.absence_type",
			text: strings.absenceRequestType,
			formatter: (cell) => cell && absenceTypeOptions.find((ele) => ele.value === cell).label,
		},
		{
			dataField: "data.date_from",
			text: strings.from,
		},
		{
			dataField: "data.date_to",
			text: strings.to,
		},
	]

	const handleApprove = (id) => {
		const payload = {
			id: id,
		}
		hook.approveAbsenceRequest(payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.fetchAbsenceRequests()
			}
		})
	}

	const handleReject = (id) => {
		hook.rejectAbsenceRequest(id).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.fetchAbsenceRequests()
			}
		})
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<CustomButton onClick={() => handleApprove(row.id)} text={strings.approve} variant={"medium"} />
				<CustomButton onClick={() => handleReject(row.id)} text={strings.reject} variant={"red"} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>
		</>
	)
}

export default AbsenceRequestTable
