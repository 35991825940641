import { Button } from "@chakra-ui/button"
import { PlusIcon } from "../Icons"

const RemoveItemButton = ({ onClick }) => {
    return (
        <Button
            type="button"
            onClick={() => onClick()}
            ml="5px"
            variant="medium"
        >
            <PlusIcon
                fill="mc_bg"
                style={{
                    transform: "rotate(45deg)",
                }}
            />
        </Button>
    )
}

export default RemoveItemButton
