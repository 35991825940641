import { useDispatch, useSelector } from "react-redux"
import { approveUserComment, fetchUserComments, rejectUserComment } from "../reducers/commentReducer"

export default function useComment() {

	const dispatch = useDispatch();
	const data = useSelector((state) => state.comments.data);
	const message = useSelector((state) => state.comments.message);
	const loading = useSelector((state) => state.comments.loading);

	const fetchComments = async () => {
		return dispatch(fetchUserComments())
	}

	const approveComment = async (payload) => {
		return dispatch(approveUserComment(payload))
	}

    const rejectComment = async (payload) => {
		return dispatch(rejectUserComment(payload))
	}

	return { data, message, loading, fetchComments, approveComment, rejectComment }
}