import { useDisclosure } from "@chakra-ui/hooks"
import { useDispatch, useSelector } from "react-redux"
import {
	changeDefaultSize,
	cleanMessage,
	createArticle,
	fetchArticlePage,
	removeArticle,
	updateArticle,
} from "../reducers/articlePanelReducer"

export default function useArticlePanel() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.articlesPanel.data)
	const pagination = useSelector((state) => state.articlesPanel.pagination)
	const defaultSize = useSelector((state) => state.articlesPanel.defaultSize)
	const message = useSelector((state) => state.articlesPanel.message)
	const loading = useSelector((state) => state.articlesPanel.loading)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const fetchPage = async (perPage, pageNumber) => {
		dispatch(fetchArticlePage(perPage, pageNumber))
	}

	const create = async (payload) => {
		dispatch(createArticle(payload)).then((res) => {
			if (res !== undefined && res.status === 200) {
				handleRefresh()
			}
		})
	}

	const update = async (payload) => {
		dispatch(updateArticle(payload)).then((res) => {
			if (res !== undefined && res.status === 200) {
				handleRefresh()
			}
		})
	}

	const remove = async (id) => {
		dispatch(removeArticle(id)).then((res) => {
			if (res !== undefined && res.status === 200) {
				onClose()
				fetchPage(defaultSize, pagination.current_page)
			}
		})
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const changeSize = async (size) => {
		dispatch(changeDefaultSize(size))
	}

	const handleRefresh = () => {
		onClose()
		fetchPage(defaultSize, 1)
	}

	return {
		data,
		loading,
		message,
		pagination,
		defaultSize,
		fetchPage,
		cleanToast,
		changeSize,
		remove,
		create,
		update,
		isOpen,
		onOpen,
		onClose,
	}
}
