import { Button } from "@chakra-ui/button"
import { Flex } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import useConfig from "../../utils/config"
import { SearchIcon } from "../Icons"

const EducationPointer = ({ onClick, imgUrl }) => {

    const config = useConfig()

    return (
        <Box
            w="277px"
            h="250px"
            p="10px"
            rounded="2xl"
            boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
            bg="mc_light"
            position="relative"
            my="20px"
        >
            {/* bgImg={``} */}
            <Flex
                bgImg={`url(${config.baseImageUrl}${imgUrl})`}
                bgSize="cover"
                h="100%"
                bgPos="center"
                rounded="2xl"
                justify="center"
                alignItems="center"
                opacity="0.4"
            />
            <Button
                variant="medium"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%,-50%)"
                onClick={() => onClick()}
            >
                <SearchIcon fill="mc_bg" />
            </Button>
        </Box>
    )
}

export default EducationPointer
