import BootstrapTable from "react-bootstrap-table-next"
import UpdateButton from "../buttons/UpdateButton"
import DeleteModal from "../modals/DeleteModal"
import useUsers from "../../redux/hooks/useUsers"
import { useState } from "react"
import AddUpdateModal from "../modals/AddUpdateModal"
import UserForm from "../forms/UserForm"
import { useToast } from "@chakra-ui/toast"
import { TablesStyles } from "./TablesStyles"
import { Text } from "@chakra-ui/layout"
import useApp from "../../redux/hooks/useApp"
import useUser from "../../redux/hooks/useUser"
import { formatDateForCell, handleToast, trueOrFalse } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { smartedColumnsUser } from "../../utils/smartedHelper"
import useConfig from "../../utils/config"
import { Button } from "@chakra-ui/button"
import Icon from "@chakra-ui/icon"
import { SearchIcon } from "../Icons"

const UsersTable = ({ data, selectData, onOpen }) => {
    const hook = useUsers()
    const toast = useToast()
    const hookUser = useUser()
    const hookApp = useApp()
    const config = useConfig()

    const [updateData, setUpdateData] = useState(null)

    const columns = [
        {
            dataField: "username",
            text: strings.username,
        },
        {
            dataField: "first_name",
            text: strings.firstName,
        },
        {
            dataField: "last_name",
            text: strings.lastName,
        },
        {
            dataField: "email",
            text: strings.email,
            headerStyle: () => {
                return { width: "170px" }
            },
        },
        {
            dataField: "date_joined",
            text: strings.registrationTime,

            formatter: (cell) => {
                return formatDateForCell(cell)
            },
        },
        {
            dataField: "last_login",
            text: strings.lastLogin,
            formatter: (cell) => {
                return formatDateForCell(cell)
            },
        },
        {
            dataField: "is_active",
            text: strings.active,
            formatter: (cell) => trueOrFalse(cell),
        },
		{
            dataField: "new_employee",
            text: strings.newEmployee,
            formatter: (cell) => trueOrFalse(cell),
        },
        {
            dataField: "is_supervisor",
            text: strings.supervisor,
            formatter: (cell) => trueOrFalse(cell),
        },
        {
            dataField: "title_name",
            text: strings.jobTitle,
        },
        {
            dataField: "position_name",
            text: strings.position,
        },
        {
            dataField: "phone_number",
            text: strings.phoneNumber,
        },
        {
            dataField: "city_name",
            text: strings.city,
        },
        {
            dataField: "country_name",
            text: strings.country,
        },
        {
            dataField: "language_name",
            text: strings.language,
        },
        {
            dataField: "bio",
            text: strings.biography,
            headerStyle: () => {
                return { width: "500px" }
            },
            formatter: (cell) => {
                return (
                    <Text overflow="hidden" noOfLines={3}>
                        {cell}
                    </Text>
                )
            },
        },
    ]

    if (hookUser.admin) {
        columns.push(
            {
                dataField: "company_name",
                text: strings.company,
            },
            {
                dataField: "app_name",
                text: strings.application,
            }
        )
    }

    if (config.appId === 2) {
        smartedColumnsUser.forEach((column) => columns.push(column))
    }

    const handleUpdateModal = (row) => {
        const defaultTitle = selectData.titles.find((e) => e.value === row.title)
        const defaultPosition = selectData.positions.find((e) => e.value === row.position)
        const defaultCity = selectData.cities.find((e) => e.value === row.city)
        const defaultCountry = selectData.countries.find((e) => e.value === row.country)
        const defaultLanguage = selectData.languages.find((e) => e.value === row.language)

        let defaultCompany
        let defaultApp

        if (!hookUser.admin) {
            defaultCompany = { label: hookUser.company.name, value: hookUser.company.id }
            defaultApp = { label: hookApp.name, value: hookApp.id }
        } else {
            if (selectData.companies && selectData.companies.length > 0 && row.company) {
                defaultCompany = selectData.companies.find((e) => e.value === row.company)
            }

            defaultApp = selectData.apps.find((e) => e.label === row.app_name)
        }

        const defaultValues = {
            id: row.id,
            defaultCompany: defaultCompany,
            defaultCity: defaultCity,
            defaultTitle: defaultTitle,
            defaultPosition: defaultPosition,
            defaultApp: defaultApp,
            defaultCountry: defaultCountry,
            defaultLanguage: defaultLanguage,
            username: row.username,
            firstName: row.first_name,
            lastName: row.last_name,
            phoneNumber: row.phone_number,
            email: row.email,
            bio: row.bio,
            supervisor: row.is_supervisor,
            isActive: row.is_active,
			newEmployee: row.new_employee
        }

        setUpdateData(defaultValues)

        hook.onOpen()
    }

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <DeleteModal remove={() => hook.remove(row.id)} />
                <UpdateButton onClick={() => handleUpdateModal(row)} />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }

    const handleSubmit = (data) => {
        const payload = {
            id: updateData.id,
        }

        var exitLoop = false

        Object.keys(data).map((key, i) =>
            data[key] && typeof data[key] === "object"
                ? (payload[`${key}`] = data[key].value)
                : data[key]
                ? (payload[`${key}`] = data[key])
                : (payload[`${key}`] = "")
        )

        if (!data.is_supervisor) {
            payload["is_supervisor"] = false
        }

        if (!data.is_active) {
            payload["is_active"] = false
        }

		if (!data.new_employee) {
            payload["new_employee"] = false
        }

        if (data.password && data.repeated_password) {
            if (!(data.password === data.repeated_password)) {
                exitLoop = true
            } else {
                payload["password"] = data.password
                payload["repeated_password"] = data.repeated_password
            }
        }

        if (exitLoop) {
            handleToast(toast, { type: "error", text: strings.passwordDontMatchMessage }, "2")
        } else {
            hook.update(payload)
        }
    }

    return (
        <>
            <TablesStyles style={{ minWidth: "5000px" }}>
                <Button
                    pos="absolute"
                    right={68}
                    bottom={5}
                    h="44px"
                    w="44px"
                    variant="medium"
                    opacity="1"
                    onClick={() => onOpen()}
                >
                    <Icon as={SearchIcon} fill="#fff" />
                </Button>
                <BootstrapTable
                    data={data}
                    columns={columns}
                    keyField="id"
                    classes="TablesStyles"
                    wrapperClasses="table-responsive"
                    expandRow={expandRow}
                    bordered
                    bootstrap4
                />
            </TablesStyles>
            <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="6xl">
                <UserForm
                    updateData={updateData}
                    onClose={hook.onClose}
                    selectData={selectData}
                    submit={handleSubmit}
                />
            </AddUpdateModal>
        </>
    )
}

export default UsersTable
