import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useSurvey from "../../redux/hooks/useSurvey"
import useUser from "../../redux/hooks/useUser"
import useApp from "../../redux/hooks/useApp"
import UpdateButton from "../buttons/UpdateButton"
import SurveyForm from "../forms/SurveyForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"
import { useToast } from "@chakra-ui/toast"
import {
    formatDateForCell,
    handleToast,
    makeDropdownOptions,
    makeIdsArray,
} from "../../utils/functions"
import { strings } from "../../utils/localization"
import useConfig from "../../utils/config"
import {
    handleSmartedDefaultValues,
    handleSmartedPermissionsPayload,
    smartedColumns,
} from "../../utils/smartedHelper"

const SurveyTable = ({ data, selectData }) => {
    const hook = useSurvey()
    const hookUser = useUser()
    const hookApp = useApp()
    const toast = useToast()
    const config = useConfig()

    const [updateData, setUpdateData] = useState(null)

    const columns = [
        {
            dataField: "name",
            text: strings.name,
        },
        {
            dataField: "date_created",
            text: strings.creationDate,
            formatter: (cell) => {
                return formatDateForCell(cell)
            },
        },
    ]

    if (hookUser.admin) {
        columns.push(
            {
                dataField: "companies_value",
                text: strings.companies,
            },
            {
                dataField: "app_name",
                text: strings.application,
            }
        )
    }

    if (config.appId === 2) {
        smartedColumns.forEach((column) => columns.push(column))
    }

    const handleUpdateModal = (row) => {
        let defaultCompanies
        let defaultApp

        if (!hookUser.admin) {
            defaultCompanies = hookUser.company
            defaultApp = { label: hookApp.name, value: hookApp.id }
        } else {
            defaultCompanies = makeDropdownOptions(row.companies)
            defaultApp = selectData.apps.find((e) => e.value === row.app)
        }

        const defaultValues = {
            id: row.id,
            defaultCompanies: defaultCompanies,
            defaultApp: defaultApp,
            name: row.name,
            questions: row.questions,
        }

        if (config.appId === 2) {
            handleSmartedDefaultValues(defaultValues, row, selectData)
        }

        setUpdateData(defaultValues)

        hook.onOpen()
    }

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <DeleteModal remove={() => hook.remove(row.id)} />
                <UpdateButton onClick={() => handleUpdateModal(row)} />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }
    const handleSubmit = (data) => {
        let payload = {}
        if (!data.name || !data.app) {
            handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "1")
        } else {
            payload["id"] = updateData.id
            payload["name"] = data.name
            payload["app"] = data.app.value
            if (data.companies !== undefined && data.companies.length > 0) {
                payload["companies"] = makeIdsArray(data.companies)
            } else {
                payload["companies"] = []
            }

            if (config.appId === 2) {
                handleSmartedPermissionsPayload(data, payload)
                delete data.countries
                delete data.cities
                delete data.users
                delete data.hq_retails
                delete data.concepts
                delete data.organization_units
                delete data.sectors
                delete data.positions_se_1
                delete data.positions_se_2
                delete data.superiors
            }

            delete data.name
            delete data.app
            delete data.companies

            let exitLoop

            Object.keys(data).forEach((key) => {
                if (!data[key]) {
                    return (exitLoop = true)
                }
            })

            if (exitLoop) {
                handleToast(toast, { text: strings.requiredAllQuestions, type: "error" }, "2")
            } else {
                payload["questions"] = data
                hook.update(payload)
            }
        }
    }

    return (
        <>
            <TablesStyles appId={config.appId}>
                <BootstrapTable
                    data={data}
                    columns={columns}
                    keyField="id"
                    classes="TablesStyles"
                    expandRow={expandRow}
                    bordered
                    bootstrap4
                />
            </TablesStyles>
            <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="6xl">
                <SurveyForm
                    updateData={updateData}
                    onClose={hook.onClose}
                    submit={handleSubmit}
                    selectData={selectData}
                />
            </AddUpdateModal>
        </>
    )
}

export default SurveyTable
