import React, { useEffect } from "react"
import { handleToast } from "../../utils/functions"
import useComment from "../../redux/hooks/useComment"
import { useToast } from "@chakra-ui/toast"
import CommentTable from "../../components/tables/CommentTable"
import { Box } from "@chakra-ui/react"

const AdminCommentsPage = () => {
    const hook = useComment()
    const toast = useToast()

    useEffect(() => {
        hook.fetchComments()
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "toast1")
        }
    }, [hook.message]) // eslint-disable-line

    return (
        <Box
            h="95%"
            overflow="auto"
            border={"4px"}
            boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
            borderColor="mc_black"
            borderRadius="4px"
            w="100%"
        >
            <CommentTable data={hook.data} />
        </Box>
    )
}

export default AdminCommentsPage
