import { useDisclosure } from "@chakra-ui/react"
import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useDataManagement from "../../redux/hooks/useDataManagement"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { columnTableString, makeDropdownOptions, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import UpdateButton from "../buttons/UpdateButton"
import DocsForm from "../forms/DocsForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"

const DocsTable = ({ data, selectData }) => {
	const config = useConfig()
	const hookUser = useUser()
	const hookDm = useDataManagement()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [updateData, setUpdateData] = useState(null)
	const [selectedFile, setSelectedFile] = useState(null)

	const columns = [
		{
			dataField: "title",
			text: strings.title,
		},
		{
			dataField: "doc",
			text: strings.url,
		},
	]

	if (hookUser.admin) {
		columns.push(
			{
				dataField: "companies",
				text: strings.companies,
				formatter: (cell) => columnTableString(cell, "name"),
			},
			{
				dataField: "app_name",
				text: strings.application,
			}
		)
	}

	const handleUpdateModal = (row) => {
		const defaultValues = {
			id: row.id,
			defaultCompanies: makeDropdownOptions(row.companies),
			defaultApp: selectData.apps.find((e) => e.value === row.app),
			title: row.title,
		}
		setUpdateData(defaultValues)
		onOpen()
	}

	const handleDelete = (id) => {
		hookDm.removeDocument(id).then((res) => {
			if (res !== undefined && res.status === 200) {
				hookDm.getDocuments()
			}
		})
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<DeleteModal remove={() => handleDelete(row.id)} />
				<UpdateButton onClick={() => handleUpdateModal(row)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleSubmit = (data) => {
		var formData = new FormData()

		formData.append("id", updateData.id)
		if (selectedFile) {
			formData.append("doc", selectedFile, selectedFile.name)
		}

		formData.append("title", data.title)

		if (data.companies !== undefined && data.companies.length > 0) {
			const companies = JSON.stringify(makeIdsArray(data.companies))
			formData.append("companies", companies)
		} else if (hookUser.supervisor) {
			formData.append("companies", `[${hookUser.company.id}]`)
		} else {
			formData.append("companies", `[]`)
		}

		if (data.app !== undefined && data.app.value) {
			formData.append("app", data.app.value)
		} else {
			formData.append("app", config.appId)
		}

		hookDm.updateDocument(formData).then((res) => {
			if (res !== undefined && res.status === 200) {
				setSelectedFile(null)
				onClose()
				hookDm.getDocuments()
			}
		})
	}

	return (
		<>
			<TablesStyles>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<AddUpdateModal isOpen={isOpen} onClose={onClose} size="2xl">
				<DocsForm
					updateData={updateData}
					handleClose={onClose}
					submit={handleSubmit}
					selectData={selectData}
					setSelectedFile={setSelectedFile}
				/>
			</AddUpdateModal>
		</>
	)
}

export default DocsTable
