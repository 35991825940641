import { Box } from "@chakra-ui/react"
import React from "react"
import PrivacyPolicyInEnglish from "../components/PrivacyPolicyInEnglish"

const PrivacyPolicyPage = () => {
    return (
        <Box p="50px">
            <PrivacyPolicyInEnglish />
        </Box>
    )
}

export default PrivacyPolicyPage
