import { useDisclosure } from "@chakra-ui/hooks";
import { useDispatch, useSelector } from "react-redux";
import { changeDefaultSize } from "../reducers/surveyReducer";
import { removeUser, createUser, updateUser, cleanMessage, fetchUsersPage, fetchUsersDropdown } from "../reducers/usersReducer";

export default function useUsers() {

	const dispatch = useDispatch();
	const data = useSelector((state) => state.users.data);
	const pagination = useSelector((state) => state.users.pagination);
	const defaultSize = useSelector((state) => state.users.defaultSize);
	const message = useSelector((state) => state.users.message);
	const loading = useSelector((state) => state.users.loading);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const fetch = async (perPage, pageNumber, email) => {
		dispatch(fetchUsersPage(perPage, pageNumber, email))
	};

	const fetchDropdown = async () => {
		dispatch(fetchUsersDropdown())
	};

	const create = async (payload) => {
		dispatch(createUser(payload)).then((res) => {
			if(res !== undefined && res.data.message.type === "success") {
				handleRefresh()
			}	
		})
	};

	const update = async (payload) => {
		dispatch(updateUser(payload)).then((res) => {
			if(res !== undefined && res.data.message.type === "success") {
				handleRefresh()
			}	
		})
	};

	const remove = async (id) => {
		dispatch(removeUser(id)).then(() => handleRefresh())
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const changeSize = async (size) => {
		dispatch(changeDefaultSize(size))
	}

	const handleRefresh = () => {
		onClose()
		fetch(defaultSize, pagination.current_page)
	}

	return { 
		data, loading, message, defaultSize, pagination,
		fetch, remove, create, update, cleanToast,
		changeSize, fetchDropdown,
		isOpen, onOpen, onClose
	};
}