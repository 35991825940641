import { Button } from "@chakra-ui/button"
import { useDisclosure } from "@chakra-ui/hooks"
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
} from "@chakra-ui/modal"
import React, { useRef } from "react"
import { strings } from "../../utils/localization"

const DeleteModal = ({ remove }) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = useRef()

	const handleRemove = () => {
		remove()
		onClose()
	}

    return (
        <>
            <Button onClick={onOpen} w="120px" h="38px" variant="red" rounded="xl">
                {strings.delete}
            </Button>
            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>Da li ste sigurni?</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {strings.deleteWarningMessage}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose} variant="medium">
                            {strings.no}
                        </Button>
                        <Button variant="red" ml={3} onClick={() => handleRemove()}>
                            {strings.yes}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    )
}

export default DeleteModal
