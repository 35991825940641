import { Container } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import { createSearchParams, useNavigate, useOutletContext } from "react-router-dom"
import { AchievementsIcon, ArticlesIcon, EduIcon, TopTenIcon } from "../components/Icons"
import Achievements from "../components/profile/Achievements"
import Main from "../components/profile/Main"
import Top10 from "../components/profile/Top10"
import useDashboard from "../redux/hooks/useDashboard"
import useDataManagement from "../redux/hooks/useDataManagement"
import useGeneric from "../redux/hooks/useGeneric"
import useMasterdata from "../redux/hooks/useMasterdata"
import useUser from "../redux/hooks/useUser"
import {
	achievementsCount,
	city,
	country,
	currentRankTop10Month,
	education,
	form,
	language,
	position,
	sector,
	title,
	top10Month,
	top10Year,
	unfinishedEducationCount,
	unreadArticleCount,
	userAchievements,
	userActivityComponent,
} from "../utils/constants"
import { handleToast, makeDropdownOptions, makeDropdownOptionsDocuments } from "../utils/functions"
import { strings } from "../utils/localization"

const ProfilePage = () => {
	const hook = useUser()
	const hookD = useDashboard()
	const resultsModal = useUser()
	const hookMd = useMasterdata()
	const hookG = useGeneric()
	const hookDm = useDataManagement()
	const toast = useToast()
	const config = useOutletContext()
	const navigate = useNavigate()

	const [isOpenTop10, setIsOpenTop10] = useState(false)
	const [isOpenAchievements, setIsOpenAchievements] = useState(false)

	const handleArticleRedirect = () => {
		const params = { viewed: true }
		navigate({
			pathname: "/news",
			search: `?${createSearchParams(params)}`,
		})
	}

	const handleEduRedirect = () => {
		const params = { completed: true }
		navigate({
			pathname: "/educations",
			search: `?${createSearchParams(params)}`,
		})
	}

	const handleOpenTop10 = () => {
		setIsOpenTop10(!isOpenTop10)
	}
	const handleOpenAchievements = () => {
		setIsOpenAchievements(!isOpenAchievements)
	}

	const [countries, setCountries] = useState([])
	const [cities, setCities] = useState([])
	const [titles, setTitles] = useState([])
	const [languages, setLanguages] = useState([])
	const [positions, setPositions] = useState([])
	const [educations, setEducations] = useState([])
	const [documents, setDocuments] = useState([])
	const [sectors, setSectors] = useState([])
	const [forms, setForms] = useState([])

	const [defaultCountry, setDefaultCountry] = useState(null)
	const [defaultCity, setDefaultCity] = useState(null)
	const [defaultTitle, setDefaultTitle] = useState(null)
	const [defaultLanguage, setDefaultLanguage] = useState(null)
	const [defaultPosition, setDefaultPosition] = useState(null)
	const [defaultPhoneNumber, setDefaultPhoneNumber] = useState(null)
	const [defaultLicenseNumber, setDefaultLicenseNumber] = useState(null)
	const [defaultBio, setDefaultBio] = useState(null)
	const [defaultFirstName, setDefaultFirstName] = useState("")
	const [defaultLastName, setDefaultLastName] = useState("")

	const defaultCategoriesData = [
		{
			icon: ArticlesIcon,
			title: strings["UA_NEWS_VIEWS"],
			subtitle: strings.numberOfUnread,
			number: hookD.unreadArticleCount,
			action: handleArticleRedirect,
		},
		{
			icon: EduIcon,
			title: strings["UA_EDUCATION_VIEWS"],
			subtitle: strings.numberOfNotCompleted,
			number: "",
			action: handleEduRedirect,
		},
		{
			icon: TopTenIcon,
			title: "Top 10",
			subtitle: strings.currentRank,
			number: "",
			action: handleOpenTop10,
		},
		{
			icon: AchievementsIcon,
			title: strings["achievements"],
			subtitle: strings.numberOfAchievements,
			number: "",
			action: handleOpenAchievements,
		},
	]

	const [categoriesData, setCategoriesData] = useState(defaultCategoriesData)

	useEffect(() => {
		hook.refreshAccess()
		hookD.cleanTop10()
		hookMd.fetch(country)
		hookMd.fetch(language)
		hookMd.fetch(position)
		hookG.fetch(sector)
		hookG.fetch(city)
		if (config.appId !== 2) {
			hookMd.fetch(title)
		}
		hookD.fetchUserComponent(userActivityComponent)
		hookD.fetchUserComponent(userAchievements)
		hookD.fetchUserComponent(unreadArticleCount)
		hookD.fetchUserComponent(unfinishedEducationCount)
		hookD.fetchUserComponent(currentRankTop10Month)
		hookD.fetchUserComponent(achievementsCount)
		hookD.fetchUserComponent(top10Month)
		hookD.fetchUserComponent(top10Year)
		hookG.fetch(education, true)
		hookG.fetch(form)
		hookDm.getDocumentsDropdown()
	}, []) //eslint-disable-line

	useEffect(() => {
		if (hook.country) {
			setDefaultCountry({ label: hook.country.name, value: hook.country.id })
		}

		if (config.appId !== 2 && hook.title) {
			setDefaultTitle({ label: hook.title.name, value: hook.title.id })
		}
		if (hook.language) {
			setDefaultLanguage({ label: hook.language.name, value: hook.language.id })
		}
		if (hook.position) {
			setDefaultPosition({ label: hook.position.name, value: hook.position.id })
		}
		if (hook.city) {
			setDefaultCity({ label: hook.city.name, value: hook.city.id })
		}
		if (hook.personalInfo.phoneNumber) {
			setDefaultPhoneNumber(hook.personalInfo.phoneNumber)
		}
		if (hook.personalInfo.bio) {
			setDefaultBio(hook.personalInfo.bio)
		}
		if (hook.licenseNumber) {
			setDefaultLicenseNumber(hook.licenseNumber)
		}

		if (hook.personalInfo.firstName) {
			setDefaultFirstName(hook.personalInfo.firstName)
		}

		if (hook.personalInfo.lastName) {
			setDefaultLastName(hook.personalInfo.lastName)
		}
	}, [
		hook.country,
		hook.title,
		hook.language,
		hook.position,
		hook.city,
		hook.personalInfo.phoneNumber,
		hook.personalInfo.bio,
		hook.personalInfo.firstName,
		hook.personalInfo.lastName,
		hook.licenseNumber,
		config.appId,
	])

	useEffect(() => {
		makeDropdownOptions(hookMd.countries, setCountries)
		if (config.appId !== 2) {
			makeDropdownOptions(hookMd.titles, setTitles)
		}
		makeDropdownOptions(hookMd.languages, setLanguages)
		makeDropdownOptions(hookMd.positions, setPositions)
		makeDropdownOptions(hookG.cities, setCities)
		makeDropdownOptions(hookG.educations, setEducations)
		makeDropdownOptionsDocuments(hookDm.documentsDropdown, setDocuments)
		makeDropdownOptions(hookG.sectors, setSectors)
		makeDropdownOptions(hookG.forms, setForms, true)
	}, [
		hookMd.countries,
		hookMd.titles,
		hookMd.languages,
		hookMd.positions,
		hookG.cities,
		config.appId,
		hookG.educations,
		hookDm.documentsDropdown,
		hookG.sectors,
		hookG.forms,
		hook.language,
	])

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "1")
		}
	}, [hook.message]) //eslint-disable-line

	const handleProfilePicture = (data) => {
		let formData = new FormData()
		formData.append("username", hook.username)
		formData.append("image", data, data.name)

		hook.updatePicture(formData).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.refreshAccess()
			}
		})
	}

	const defaultData = {
		defaultCountry: defaultCountry,
		defaultCity: defaultCity,
		defaultTitle: defaultTitle,
		defaultPosition: defaultPosition,
		defaultLanguage: defaultLanguage,
		defaultPhoneNumber: defaultPhoneNumber,
		defaultBio: defaultBio,
		defaultLicenseNumber: defaultLicenseNumber,
		defaultFirstName: defaultFirstName,
		defaultLastName: defaultLastName,
	}

	const selectData = {
		countries: countries,
		cities: cities,
		positions: positions,
		titles: titles,
		languages: languages,
		documents: documents,
		sectors: sectors,
		forms: forms,
	}

	return (
		<Container maxW="1216px" px={[2, 4]}>
			{!isOpenTop10 && !isOpenAchievements && (
				<Main
					categoriesData={categoriesData}
					handleProfilePicture={handleProfilePicture}
					avatarSrc={hook.profilePicture}
					results={resultsModal.onOpen}
					selectData={selectData}
					defaultData={defaultData}
				/>
			)}
			{isOpenTop10 && <Top10 back={handleOpenTop10} educations={educations} />}
			{isOpenAchievements && <Achievements data={hookD.userAchievements} back={handleOpenAchievements} />}
		</Container>
	)
}

export default ProfilePage
