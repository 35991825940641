export const greek = () => {
	return {
		showMore: "Δείτε περισσότερα",
		edit: "επεξεργασία",
		educationAuthorIs: "Αυτή η εκπαίδευση έγινε από την εταιρεία:",
		startTest: "έναρξη δοκιμής",
		didTest: "Κάνατε ένα τεστ",
		maxTimes: "μέγιστος αριθμός φορών",
		endTest: "ΟΛΟΚΛΗΡΩΣΤΕ ΤΗ ΔΟΚΙΜΗ",
		requiredFieldsMessage: "Τα πεδία που είναι μαρκαρισμένα με * είναι υποχρεωτικά!",
		allRequiredFieldsMessage: "Όλα τα πεδία είναι υποχρεωτικά!",
		requiredTextOrImageMessage: "Δεν έχετε εισαγάγει κείμενο ή εικόνα!",
		requiredTextMessage: "Δεν έχετε εισαγάγει κανένα κείμενο!",
		requiredTextOfAllAnswersMessage: "Συμπληρώστε το κείμενο όλων των απαντήσεων!",
		oneCorrectAnswerMessage: "Μόνο μία απάντηση μπορεί να είναι σωστή!",
		atLeastOneCorrectAnswerMessage: "Τουλάχιστον μία απάντηση πρέπει να είναι σωστή!",
		requiredImageMessage: "Η εικόνα πρέπει να επιλεγεί",
		requiredQuestionTextMessage: "Εισαγάγετε το κείμενο της ερώτησης!",
		requiredEducationSlideNumberMessage: "Εισαγάγετε τον αριθμό της διαφάνειας εκπαίδευσης!",
		requiredAllQuestions: "Συμπληρώστε όλες τις ερωτήσεις!",
		passwordDontMatchMessage: "Οι κωδικοί πρόσβασης δεν ταιριάζουν!",
		noDataSelectedMessage: "Τα δεδομένα δεν έχουν επιλεγεί!",
		noReportSelectedMessage: "Η αναφορά δεν επιλέχθηκε!",
		noTestSelectedMessage: "Η δοκιμή δεν επιλέχθηκε!",
		noTypeSelectedMessage: "Ο τύπος δεν έχει επιλεγεί!",
		noArticleSelected: "Τα νέα δεν επιλέχθηκαν!!",
		noSurveySelected: "Η έρευνα δεν επιλέχθηκε!",
		noJobSelected: "Η εργασία δεν επιλέχθηκε!",
		notEnoughReportDataMessage: "Δεν υπάρχουν αρκετά δεδομένα για την επιλεγμένη αναφορά",
		enterCommentMessage: "Εισαγάγετε ένα σχόλιο!",
		leaveComment: "ΑΦΗΣΤΕ ΕΝΑ ΣΧΟΛΙΟ",
		report: "ΚΑΝΩ ΑΝΑΦΟΡΑ",
		test: "ΔΟΚΙΜΗ",
		type: "ΤΥΠΟΣ",
		from: "ΑΠΟ",
		to: "ΠΡΟΣ ΤΗΝ",
		previous: "Προηγούμενη",
		next: "Επόμενη",
		page: "Σελίδα",
		of: "από",
		generate: "ΠΑΡΑΓΩ",
		choosePlaceholder: "Επιλέγω...",
		name: "ΟΝΟΜΑ",
		title: "ΤΙΤΛΟΣ",
		education: "Εκπαιδευση",
		category: "ΚΑΤΗΓΟΡΙΑ",
		application: "ΕΦΑΡΜΟΓΗ",
		company: "ΕΤΑΙΡΙΑ",
		survey: "ΕΠΙΣΚΟΠΗΣΗ",
		country: "ΧΩΡΑ",
		city: "ΠΟΛΗ",
		jobTitle: "ΤΙΤΛΟΣ",
		position: "ΘΕΣΗ",
		language: "ΓΛΩΣΣΑ",
		phoneNumber: "ΤΗΛΕΦΩΝΙΚΟ ΝΟΥΜΕΡΟ",
		licenceNumber: "ΑΡΙΘΜΟΣ ΑΔΕΙΑΣ",
		biography: "ΒΙΟΓΡΑΦΙΑ",
		slideText: "ΚΕΙΜΕΝΟ ΔΙΑΦΑΝΕΙΩΝ",
		image: "ΕΙΚΟΝΑ",
		question: "ΕΡΩΤΗΣΗ",
		questions: "ΕΡΩΤΗΣΕΙΣ",
		educationSlide: "ΔΙΑΦΑΝΕΙΑ ΕΚΠΑΙΔΕΥΣΗΣ",
		answers: "ΑΠΑΝΤΗΣΕΙΣ",
		summaryText: "ΣΥΝΟΠΤΙΚΟ ΚΕΙΜΕΝΟ",
		coverPhoto: "ΦΩΤΟΓΡΑΦΙΑ ΕΞΩΦΥΛΛΟΥ",
		content: "ΠΕΡΙΕΧΟΜΕΝΟ",
		status: "ΚΑΤΑΣΤΑΣΗ",
		close: "ΤΈΛΟΣ",
		back: "ΠΙΣΩ",
		forward: "ΠΡΟΣ ΤΑ ΕΜΠΡΟΣ",
		save: "ΣΩΣΕΙ",
		password: "ΚΩΔΙΚΟΣ ΠΡΟΣΒΑΣΗΣ",
		repeatPassword: "Επαναλάβετε τον κωδικό πρόσβασης",
		newPassword: "ΝΕΟΣ ΚΩΔΙΚΟΣ ΠΡΟΣΒΑΣΗΣ",
		newPasswordRepeat: "ΕΠΑΝΑΛΆΒΕΤΕ ΤΟ ΝΈΟ ΚΩΔΙΚΌ",
		code: "ΚΩΔΙΚΑΣ",
		companyType: "ΤΥΠΟΣ ΕΤΑΙΡΕΙΑΣ",
		firstName: "ΟΝΟΜΑ",
		lastName: "ΕΠΙΘΕΤΟ",
		username: "ΧΡΗΣΤΗΣ",
		email: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
		supervisor: "ΕΠΟΠΤΗΣ",
		users: "ΧΡΗΣΤΕΣ",
		reports: "ΑΝΑΦΟΡΕΣ",
		importExport: "ΕΙΣΑΓΩΓΗ/ΕΞΑΓΩΓΗ",
		masterData: "MASTERDATA",
		countries: "ΧΩΡΕΣ",
		jobTitles: "ΤΙΤΛΟΙ",
		positions: "ΘΕΣΕΙΣ",
		companyTypes: "ΤΥΠΟΙ ΕΤΑΙΡΕΙΑΣ",
		cities: "ΠΟΛΙΤΕΣ",
		companies: "ΕΤΑΙΡΕΙΕΣ",
		educationCategories: "ΚΑΤΗΓΟΡΙΕΣ ΕΚΠΑΙΔΕΥΣΗΣ",
		articleCategories: "ΚΑΤΗΓΟΡΙΕΣ ΝΕΩΝ",
		languages: "ΓΛΩΣΣΕΣ",
		article: "ΝΕΑ",
		articles: "ΝΕΑ",
		surveys: "ΕΡΕΥΝΕΣ",
		educations: "ΕΚΠΑΙΔΕΥΣΕΙΣ",
		tests: "ΔΟΚΙΜΕΣ",
		panel: "ΠΙΝΑΚΑΣ",
		signOut: "ΑΠΟΣΥΝΔΕΣΗ",
		signIn: "ΣΥΝΔΕΘΕΙΤΕ",
		controlPanel: "ΠΙΝΑΚΑΣ ΕΛΕΓΧΟΥ",
		profile: "ΠΡΟΦΙΛ",
		cancel: "ΜΑΤΑΙΩΣΗ",
		delete: "ΔΙΑΓΡΑΦΩ",
		deleteQuestion: "ΔΙΑΓΡΑΦΗ ΕΡΩΤΗΣΗΣ",
		deleteWarningMessage: "Εάν διαγράψετε αυτό το στοιχείο, δεν θα μπορείτε να το ανακτήσετε!",
		yes: "ΝΑΙ",
		no: "ΟΧΙ",
		creationDate: "ΗΜΕΡΟΜΗΝΙΑ ΔΗΜΙΟΥΡΓΙΑΣ",
		views: "ΠΡΟΒΟΛΕΣ",
		reactions: "ΑΝΤΙΔΡΑΣΕΙΣ",
		comments: "ΣΧΟΛΙΑ",
		registrationTime: "ΧΡΟΝΟΣ ΕΓΓΡΑΦΗΣ",
		lastLogin: "ΤΕΛΕΥΤΑΙΑ ΕΙΣΟΔΟΣ",
		active: "ΕΝΕΡΓΟΣ",
		congratulations: "ΣΥΓΧΑΡΗΤΗΡΙΑ",
		completedTest: "ΟΛΟΚΛΗΡΩΣΕΤΕ ΤΗ ΔΟΚΙΜΗ",
		monthlyReport: "Μηνιαία αναφορά",
		fileSelected: "Το αρχείο επιλέχθηκε",
		chooseCsvFile: "Επιλέξτε αρχείο .csv",
		photo: "φωτογραφία",
		selectPhoto: "Επιλέξτε φωτογραφία",
		noFileSelectedMessage: "Το αρχείο δεν επιλέχθηκε!",
		exportData: "ΔΕΔΟΜΕΝΑ ΕΞΑΓΩΓΗΣ",
		export: "ΕΞΑΓΩΓΗ",
		importData: "ΔΕΔΟΜΕΝΑ ΕΙΣΑΓΩΓΗΣ",
		import: "ΕΙΣΑΓΩΓΗ",
		confirm: "ΕΠΙΒΕΒΑΙΩΝΩ",
		noComments: "Χωρίς σχόλια",
		loadMore: "ΦΟΡΤΩΣΕ ΠΕΡΙΣΣΟΤΕΡΑ",
		author: "ΣΥΝΤΑΚΤΗΣ",
		notFoundMessage: "Η σελίδα που αναζητάτε δεν βρέθηκε",
		updateData: "ΕΝΗΜΕΡΩΣΗ ΔΕΔΟΜΕΝΩΝ",
		privacyPolicy: "ΠΟΛΙΤΙΚΗ ΑΠΟΡΡΗΤΟΥ",
		result: "ΑΠΟΤΕΛΕΣΜΑ",
		serverErrorMessage: "Σφάλμα Διακομιστή!",
		wrongCredentialsMessage: "Δεν βρέθηκε λογαριασμός με συγκεκριμένα διαπιστευτήρια!",
		noAccount: "Χωρίς λογαριασμό;",
		clickHere: "Κάντε κλικ ΕΔΩ",
		forgotPassword: "Ξεχάσατε τον κωδικό?",
		backTo: "Πίσω στο",
		loginPage: "σελίδα σύνδεσης",
		emailAddress: "ΔΙΕΥΘΥΝΣΗ ΗΛΕΚΤΡΟΝΙΚΟΥ ΤΑΧΥΔΡΟΜΕΙΟΥ",
		passwordReset: "ΕΠΑΝΑΦΟΡΑ ΚΩΔΙΚΟΥ",
		registration: "ΕΓΓΡΑΦΕΙΤΕ",
		noMore: "ΟΧΙ πια!",
		welcomeTo: "Καλωσόρισες στο",
		byUser: "ΚΑΤΑ ΧΡΗΣΤΗ",
		byCity: "ΚΑΤΑ ΠΟΛΗ",
		byQuestions: "ΑΠΟ ΕΡΩΤΗΣΕΙΣ",
		loginCount: "Πλήθος σύνδεσης",
		articleViews: "Προβολές ειδήσεων",
		articleReactions: "Αντιδράσεις ειδήσεων",
		educationViews: "Εκπαιδευτικές απόψεις",
		passedTests: "Πέρασε τις εξετάσεις",
		monthly: "ΜΗΝΙΑΙΟ",
		eduAll: "ΟΛΑ",
		eduChoose: "ΕΠΙΛΕΓΩ",
		eduChooseCompleted: "ΕΠΙΛΟΓΗ ΟΛΟΚΛΗΡΩΜΕΝΗ",
		eduChooseNotCompleted: "ΕΠΙΛΟΓΗ ΔΕΝ ΟΛΟΚΛΗΡΩΘΗΚΕ",
		noEduSelectedMessage: "Η εκπαίδευση δεν επιλέχθηκε!",
		deleteSlide: "ΔΙΑΓΡΑΦΗ ΔΙΑΦΑΝΕΙΑΣ",
		cantDeleteFirst: "Δεν μπορείτε να διαγράψετε την πρώτη διαφάνεια!",
		agreePrivacyPolicy: "Συμφωνώ με την πολιτική απορρήτου",
		articleAll: "ΟΛΑ",
		articleByUserReports: "ΑΠΟ ΕΚΘΕΣΕΙΣ ΧΡΗΣΤΗ",
		imageMissing: "Κάπου λείπει η εικόνα!",
		monthlyPoints: "ΣΗΜΕΙΑ ΓΙΑ ΑΥΤΟ ΤΟΝ ΜΗΝΑ:",
		annualPoints: "ΣΗΜΕΙΑ ΓΙΑ ΦΕΤΟΣ:",
		notePlaceholder: "Σημείωση...",
		wrongAppMessage: "Προσπαθείτε να αποκτήσετε πρόσβαση σε λάθος πλατφόρμα!",
		selectFile: "Επιλέξτε αρχείο",
		searchPlaceholder: "Αναζήτηση...",
		repeatTest: "ΕΠΑΝΑΛΑΒΕ ΤΟ ΤΕΣΤ",
		usersUpdate: "ΕΝΗΜΕΡΩΣΗ ΧΡΗΣΤΩΝ",
		total: "TOTAL",
		track: "TRACK",
		loginData: "LOGIN DATA",
		checkEmailMessage: "Ανοίξτε τον σύνδεσμο email για επιβεβαίωση εγγραφής (έλεγχος ανεπιθύμητης αλληλογραφίας)",
		subquestion: "ΥΠΟΕΡΩΤΗΣΗ",
		comment: "ΣΧΟΛΙΟ",
		reject: "ΑΠΟΡΡΙΠΤΩ",
		approve: "ΕΓΚΡΙΝΩ",
		tags: "ΕΤΙΚΕΤΕΣ",
		deadline: "προθεσμία",
		applicationDeadline: "προθεσμία εφαρμογής",
		jobListings: "ΚΑΤΑΛΟΓΕΣ ΕΡΓΑΣΙΑΣ",
		apply: "Εγγραφείτε",
		openFormToApply: "Ανοίξτε τη φόρμα αίτησης",
		uploadYourCV: "ανεβάστε το βιογραφικό σας",
		uploadFile: "Ανέβασμα αρχείου",
		yourCVIsUploaded: "το βιογραφικό σας έχει ανέβει",
		applied: "ΕΙΣΑΙ ΕΓΓΡΑΦΗ",
		jobs: "ΕΡΓΑΣΙΕΣ",
		job: "ΔΟΥΛΕΙΑ",
		note: "Σημείωση",
		newEmployees: "Καινούργιοι υπάλληλοι",
		newEmployee: "ΝΕΟΣ ΥΠΑΛΛΗΛΟΣ",
		updateEmploymentStatus: "Ενημέρωση κατάστασης απασχόλησης",
		banner: "αγγελία",
		url: "url-Σύνδεσμος",
		expirationDate: "ΗΜΕΡΟΜΗΝΊΑ ΛΉΞΗΣ",
		expired: "ΈΧΕΙ ΛΉΞΕΙ",
		days: "ΗΜΈΡΕΣ",
		hours: "ΏΡΕΣ",
		useCustomTheme: "χρησιμοποιήστε προσαρμοσμένο θέμα",
		chooseTheColorOfTheApplication: "επιλέξτε το χρώμα της εφαρμογής",
		selectTheApplicationLogo: "επιλέξτε το λογότυπο της εφαρμογής",
		selectTheApplicationFont: "επιλέξτε τη γραμματοσειρά της εφαρμογής",
		currentLogo: "τρέχον λογότυπο",
		font: "γραμματοσειρά",
		black: "μαύρος",
		background: "Ιστορικό",
		dark: "σκοτάδι",
		medium: "Μεσαίο",
		light: "φως",
		red: "το κόκκινο",
		assignedToUsers: "ΑΝΑΘΕΣΗ ΣΕ ΧΡΗΣΤΕΣ",
		testAnswerQuestion: "Απάντησε την ερώτηση",
		testMaxRepeatNumberReached: "Έχετε κάνει τη δοκιμή τις μέγιστες φορές",
		selectDocument: "Επιλέξτε έγγραφο",
		UA_LOGIN_ACTIVITY: "Δραστηριότητα",
		UA_NEWS_VIEWS: "Νέα",
		UA_NEWS_REACTIONS: "Αντιδράσεις",
		UA_EDUCATION_VIEWS: "Εκπαιδεύσεις",
		UA_TESTS: "Δοκιμές",
		UA_LOGIN_ACTIVITY_TP: "Δραστηριότητα χρήστη τις προηγούμενες 100 ημέρες",
		UA_NEWS_VIEWS_TP: "Ειδήσεις που είδαμε τις προηγούμενες 100 ημέρες",
		UA_NEWS_REACTIONS_TP: "Αριθμός αντιδράσεων τις προηγούμενες 100 ημέρες",
		UA_EDUCATION_VIEWS_TP: "Ολοκληρώθηκαν επιτυχώς οι εκπαιδεύσεις τις προηγούμενες 100 ημέρες",
		UA_TESTS_TP: "Δραστηριότητα επιτυχών εξετάσεων τις προηγούμενες 100 ημέρες",
		ACTIVITY: "Δραστηριότητα",
		NEWS: "Νέα",
		EDUCATIONS: "Εκπαιδεύσεις",
		TESTS: "Δοκιμές",
		achievements: "Επιτεύγματα",
		ACTIVITY_DAYS: "ημέρες διαδοχικής σύνδεσης",
		EDUCATIONS_FINISHED: "ολοκληρωμένες εκπαιδεύσεις",
		NEWS_LIKED: "άρεσε νέα",
		NEWS_VIEWED: "προβολή ειδήσεων",
		TESTS_SUCCESS_FINISHED: "πέρασε τεστ με όλες τις σωστές απαντήσεις",
		TESTS_FINISHED: "ολοκληρωμένες δοκιμές",
		numberOfAchievements: "Αριθμός επιτευγμάτων",
		currentRank: "Τρέχουσα κατάταξη",
		numberOfUnread: "Αριθμός μη αναγνωσμένων",
		numberOfNotCompleted: "Αριθμός μη ολοκληρωμένων",
		documents: "Εγγραφα",
		document: "Εγγραφο",
		download: "Κατεβάστε",
		completedAllEdu: "Τα έχετε ολοκληρώσει όλα",
		readAllNews: "Τα έχεις διαβάσει όλα",
		forms: "Φόρμες",
		month: "Μήνας",
		year: "Ετος",
		absenceRequests: "ΑΙΤΗΜΑΤΑ ΑΠΟΥΣΙΑΣ",
		absenceRequestType: "ΕΙΔΟΣ ΑΠΟΥΣΙΑΣ",
		byAssignedUser: "ΑΠΟ ΑΝΟΡΙΣΜΕΝΟ ΧΡΗΣΤΗ",
		bySubQuestions: "ΜΕ ΥΠΟΕΡΩΤΗΣΕΙΣ",
		lockedEduMessage:
			"Αγαπητέ χρήστη, εφόσον είσαι νέος υπάλληλος, πρέπει να κάνεις εκπαιδεύσεις για νέους υπαλλήλους, για να έχεις πρόσβαση σε άλλες εκπαιδεύσεις.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Αίτημα για διοικητική απαγόρευση",
		FORM_PZDF: "Αίτηση για κοινή χρήση φυλλαδίων",
		contactPhoneNumber: "ΑΡΙΘΜΌΣ ΤΗΛΕΦΏΝΟΥ ΕΠΙΚΟΙΝΩΝΊΑΣ",
		FORM_TK: "Αγωνιστική λέσχη",
		FORM_ZPOZ: "Αίτημα για πιστοποιητικά απασχόλησης",
		bank: "ΤΡΑΠΕΖΑ",
		certificationPurpose: "ΣΚΟΠΌΣ ΠΙΣΤΟΠΟΊΗΣΗΣ",
		documentType: "ΤΥΠΟΣ ΕΓΓΡΑΦΟΥ",
		documentVerification: "ΈΓΓΡΑΦΟ ΓΙΑ ΕΠΑΛΉΘΕΥΣΗ",
		loanStatement: "ΔΉΛΩΣΗ ΓΙΑ ΑΝΕΞΆΡΤΗΤΗ ΑΠΟΠΛΗΡΩΜΉ ΔΑΝΕΊΟΥ",
		FORM_ZZO: "Αίτημα άδειας",
		leaveType: "ΤΎΠΟΣ ΆΔΕΙΑΣ",
		dateFrom: "ΗΜΕΡΟΜΗΝΙΑ ΑΠΟ",
		dateTo: "ΧΡΟΝΟΛΟΓΏ",
		absenceDocument: "ΈΓΓΡΑΦΟ ΓΙΑ ΑΠΟΥΣΊΑ",
		FORM_PK: "Ένας νικηφόρος συνδυασμός",
		commentNote: "ΣΧΌΛΙΟ/ΣΗΜΕΊΩΣΗ",
		recommendation: "ΣΥΣΤΑΣΗ",
		FORM_IZ: "Πρωτοβουλίες εργαζομένων",
		initiative: "ΠΡΩΤΟΒΟΥΛΙΑ",
		choose: "Επιλέγω",
	}
}
