import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { useDisclosure } from "@chakra-ui/hooks"
import { Input } from "@chakra-ui/input"
import { Box, Flex, Stack } from "@chakra-ui/layout"
import { Checkbox } from "@chakra-ui/react"
import { Textarea } from "@chakra-ui/textarea"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import useApp from "../../redux/hooks/useApp"
import useUser from "../../redux/hooks/useUser"
import { strings } from "../../utils/localization"
import { SmartedFields } from "../../utils/smartedHelper"
import { SearchIcon } from "../Icons"
import ImageModal from "../modals/ImageModal"
import RichTextEditor from "../RichTextEditor"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const ArticleForm = ({ updateData, submit, handleClose, selectData, setSelectedFile }) => {
	const hookUser = useUser()
	const hookApp = useApp()
	const config = useOutletContext()

	let defaultApp = null
	let defaultCompanies = null

	if (updateData) {
		defaultCompanies = updateData.defaultCompanies
		defaultApp = updateData.defaultApp
	} else if (!hookUser.admin && !updateData) {
		defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
		defaultApp = { label: hookApp.name, value: hookApp.id }
	}

	const { register, handleSubmit, control, setValue, watch } = useForm({
		defaultValues: {
			content: "",
			image: null,
		},
	})

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [img, setImg] = useState("")

	const handleSearch = (data) => {
		setImg(data)
		onOpen()
	}

	const handleContent = (data) => {
		setValue("content", data)
	}

	const handleImage = (data) => {
		setValue("image", data)
	}

	const currentContentType = watch("content_type")

	const checkFileName = () => {
		if (currentContentType) {
			if (currentContentType.value === 2) {
				return "content_video"
			} else if (currentContentType.value === 3) {
				return "content_image"
			} else if (currentContentType.value === 4) {
				return "content_pdf"
			}
		} else {
			return "content_test"
		}
	}

	const handleFile = (data) => {
		setValue(checkFileName(), data)
	}

	// console.log(currentContentType)

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={4}>
				<FormControl>
					<FormLabel color="mc_medium" fontSize="12px">
						{strings.title} *
					</FormLabel>
					<Input
						name="title"
						defaultValue={updateData && updateData.title}
						ref={register}
						color="mc_dark"
						rounded="xl"
						boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
					/>
				</FormControl>
				<FormControl>
					<FormLabel color="mc_medium" fontSize="12px">
						{strings.summaryText} *
					</FormLabel>
					<Controller
						as={
							<Textarea
								color="mc_dark"
								rounded="xl"
								size="xl"
								resize="none"
								p="10px"
								boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
							/>
						}
						control={control}
						name="summary_text"
						defaultValue={updateData && updateData.summaryText}
					/>
				</FormControl>
				<Flex gridGap="20px" flexWrap={["wrap", "wrap", "nowrap"]}>
					<FormControl minW="200px" w="20%">
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.coverPhoto} {!updateData && "*"}
						</FormLabel>
						<Flex gridGap="10px">
							<Controller
								as={<UploadFile />}
								control={control}
								name="image"
								onChange={handleImage}
								imgUrl={updateData && updateData.imagePath}
							/>
							{updateData && updateData.imagePath && (
								<SearchIcon
									fill="mc_medium"
									w="30px"
									h="30px"
									cursor="pointer"
									p="3px"
									border="2px solid"
									borderColor="mc_medium"
									rounded="4px"
									_hover={{
										fill: "mc_bg",
										background: "mc_medium",
									}}
									onClick={() => handleSearch(updateData.imagePath)}
								/>
							)}
						</Flex>
					</FormControl>
					<FormControl w="20%" minW="200px">
						<FormLabel color="mc_medium" textTransform="uppercase" fontSize="12px">
							{strings.status} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="status"
							options={selectData.statuses}
							defaultValue={updateData && updateData.defaultStatus}
						/>
					</FormControl>

					<FormControl w="20%" minW="200px" display={hookUser.admin ? "block" : "none"}>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.application} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="app"
							options={selectData.apps}
							defaultValue={defaultApp}
						/>
					</FormControl>
					<FormControl w="40%" minW={["100%", "400px"]} display={hookUser.admin ? "block" : "none"}>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.companies}
						</FormLabel>
						<Controller
							as={<SelectDropdown isMulti />}
							control={control}
							name="companies"
							options={selectData.companies}
							defaultValue={defaultCompanies}
						/>
					</FormControl>
				</Flex>
				<Flex gap={4}>
					<FormControl w="20%">
						<FormLabel color="mc_medium" fontSize="12px">
							{"CONTENT TYPE"} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="content_type"
							options={selectData.contentTypes}
							defaultValue={
								updateData ? updateData.defaultContentType : selectData.contentTypes.find((ele) => ele.value === 1)
							}
						/>
					</FormControl>
					<FormControl>
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.content} *
						</FormLabel>
						{currentContentType && currentContentType.value === 1 ? (
							<Box rounded="xl" boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)">
								<Controller
									as={<RichTextEditor />}
									control={control}
									name="content"
									onChange={handleContent}
									setContent={updateData && updateData.content}
								/>
							</Box>
						) : currentContentType && currentContentType.value === 2 ? (
							<Controller as={<UploadFile />} control={control} name={"content_video"} onChange={handleFile} />
						) : currentContentType && currentContentType.value === 3 ? (
							<Controller as={<UploadFile />} control={control} name={"content_image"} onChange={handleFile} />
						) : currentContentType && currentContentType.value === 4 ? (
							<Controller as={<UploadFile />} control={control} name={"content_pdf"} onChange={handleFile} />
						) : (
							<></>
						)}
					</FormControl>
				</Flex>

				<Flex w="100%" gridColumnGap="20px">
					<FormControl w="70%">
						<FormLabel color="mc_medium" fontSize="12px">
							{strings.survey}
						</FormLabel>
						<Box w="100%">
							<Controller
								as={<SelectDropdown />}
								control={control}
								name="survey"
								options={selectData.surveys}
								defaultValue={updateData && updateData.defaultSurvey}
							/>
						</Box>
					</FormControl>
					<FormControl w="300px">
						<FormLabel color="mc_medium" textTransform="uppercase" fontSize="12px">
							{strings.category}
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="category"
							options={selectData.categories}
							defaultValue={updateData && updateData.defaultCategory}
						/>
					</FormControl>
					<Checkbox
						size="lg"
						alignItems="center"
						color="mc_dark"
						fontSize="md"
						ref={register}
						name="new_employee_content"
						defaultChecked={updateData ? updateData.new_employee_content : false}
					>
						{strings.newEmployees}
					</Checkbox>
				</Flex>
				{config.appId === 2 && (
					<SmartedFields
						control={control}
						selectData={selectData}
						updateData={updateData}
						setSelectedFile={setSelectedFile}
						fileImport={true}
					/>
				)}
				<Flex justify="flex-end" mt={4}>
					<Button onClick={handleClose} variant="red">
						{strings.close}
					</Button>
					<Button type="submit" variant="medium" color="mc_bg" ml={3}>
						{strings.save}
					</Button>
				</Flex>
			</Stack>

			<ImageModal data={img} isOpen={isOpen} onClose={onClose} size="4xl" />
		</form>
	)
}

export default ArticleForm
