export const generateToast = (message, id, longer) => {
	const toastObject = {
		id,
		description: message.text,
		status: message.type,
		duration: longer ? 15000 : 5000,
		isClosable: true,
		position: "top-right",
	}

	return toastObject
}

export default generateToast
