import { createAction, createReducer } from "@reduxjs/toolkit"

const SET_ANSWERS = createAction("FORM/TEST/SET_ANSWERS")
const ADD_QUESTION = createAction("FORM/TEST/ADD_QUEST")
const DELETE_QUESTION = createAction("FORM/TEST/DELETE_QUESTION")
const UPDATE_QUESTION_TEXT = createAction("FORM/TEST/UPDATE_QUESTION_TEXT")
const UPDATE_QUESTION_SLIDE = createAction("FORM/TEST/UPDATE_QUESTION_SLIDE")
const UPDATE_QUESTION_SUBQUESTION = createAction("FORM/TEST/UPDATE_QUESTION_SUBQUESTION")
const ADD_ANSWER = createAction("FORM/TEST/ADD_ANSWER")
const REMOVE_ANSWER = createAction("FORM/TEST/REMOVE_ANSWER")
const UPDATE_ANSWER_TEXT = createAction("FORM/TEST/UPDATE_ANSWER_TEXT")
const UPDATE_ANSWER_RESULT = createAction("FORM/TEST/UPDATE_ANSWER_RESULT")
const UPDATE_ANSWER_SUBQUESTION = createAction("UPDATE_ANSWER_SUBQUESTION")
const ADD_USER_ANSWER = createAction("FORM/TESTS/ADD_TEST_ANSWER")
const CONFIRM_USER_ANSWER = createAction("FORM/TESTS/CONFIRM_USER_ANSWER")
const HANDLE_USER_ANSWER = createAction("FORM/TESTS/HANDLE_USER_ANSWER")
const CLEAN_TEST_ANSWERS = createAction("FORM/TESTS/CLEAN_ANSWERS")
const CLEAN_ANSWER_FORMS = createAction("FORM/TESTS/CLEAN_FORMS")
const UPDATE_USER_SUBQUESTION = createAction("FORM/TESTS/UPDATE_USER_SUBQUESTION")

const SET_EDU_SLIDES = createAction("FORM/EDU/SET_SLIDES")
const ADD_EDU_SLIDE = createAction("FORM/EDU/ADD_SLIDE")
const DELETE_EDU_SLIDE = createAction("FORM/EDU/DELETE_SLIDE")
const UPDATE_EDU_TEXT = createAction("FORM/EDU/UPDATE_TEXT")
const CLEAN_EDU_FORMS = createAction("FORM/EDU/CLEAN_FORMS")

export const setAnswers = (payload) => async (dispatch) => {
	dispatch(SET_ANSWERS(payload))
}

export const addNewQuestion = (index) => async (dispatch) => {
	const payload = {
		question: index,
		text: "",
		education_slide: "",
		subquestion: "",
		answers: [],
	}

	dispatch(ADD_QUESTION(payload))
}

export const deleteOneQuestion = (index) => async (dispatch) => {
	dispatch(DELETE_QUESTION(index))
}

export const changeQuestionText = (questionIndex, value) => async (dispatch) => {
	dispatch(UPDATE_QUESTION_TEXT({ questionIndex: questionIndex, value: value }))
}

export const changeQuestionSlide = (questionIndex, value) => async (dispatch) => {
	let newValue = value
	if (!value || value <= 0) {
		newValue = ""
	}
	dispatch(UPDATE_QUESTION_SLIDE({ questionIndex: questionIndex, value: newValue }))
}

export const changeQuestionSubquestion = (questionIndex, value) => async (dispatch) => {
	dispatch(UPDATE_QUESTION_SUBQUESTION({ questionIndex: questionIndex, value: value }))
}

export const addNewAnswer = (index, text, result) => async (dispatch) => {
	const payload = { text: text, result: result }
	dispatch(ADD_ANSWER({ index: index, answer: payload }))
}

export const updateUserSubquestion = (index, result) => async (dispatch) => {
	const payload = { answerIndex: index, value: result }
	dispatch(UPDATE_USER_SUBQUESTION(payload))
}

export const removeOneAnswer = (questionIndex, answerIndex) => async (dispatch) => {
	dispatch(REMOVE_ANSWER({ questionIndex: questionIndex, answerIndex: answerIndex }))
}

export const changeAnswerText = (questionIndex, answerIndex, value) => async (dispatch) => {
	dispatch(UPDATE_ANSWER_TEXT({ questionIndex: questionIndex, answerIndex: answerIndex, value: value }))
}

export const changeAnswerSubquestion = (questionIndex, answerIndex, value) => async (dispatch) => {
	dispatch(UPDATE_ANSWER_SUBQUESTION({ questionIndex: questionIndex, answerIndex: answerIndex, value: value }))
}

export const changeAnswerResult = (questionIndex, answerIndex, value) => async (dispatch) => {
	dispatch(UPDATE_ANSWER_RESULT({ questionIndex: questionIndex, answerIndex: answerIndex, value: value }))
}

//EDU

export const setEduSlides = (slides) => async (dispatch) => {
	dispatch(SET_EDU_SLIDES(slides))
}

export const addEduSlide = (serialNum) => async (dispatch) => {
	const payload = {
		serial_num: serialNum,
		text: "",
	}

	dispatch(ADD_EDU_SLIDE(payload))
}

export const deleteEduSlide = (index) => async (dispatch) => {
	dispatch(DELETE_EDU_SLIDE(index))
}

export const changeEduSlideText = (index, value) => async (dispatch) => {
	dispatch(UPDATE_EDU_TEXT({ index: index, value: value }))
}

export const cleanEduSlides = () => async (dispatch) => {
	dispatch(CLEAN_EDU_FORMS())
}

//TESTS

export const addAnsweredTestQuestion = (payload) => async (dispatch) => {
	dispatch(ADD_USER_ANSWER(payload))
}

export const confirmNewUserAnswer = (payload) => async (dispatch) => {
	dispatch(CONFIRM_USER_ANSWER(payload))
}

export const handleNewUserAnswer = (payload) => async (dispatch) => {
	dispatch(HANDLE_USER_ANSWER(payload))
}

export const cleanTestAnswers = () => async (dispatch) => {
	dispatch(CLEAN_TEST_ANSWERS())
}

export const cleanFormAnswers = () => async (dispatch) => {
	dispatch(CLEAN_ANSWER_FORMS())
}

const initState = {
	answerForms: [{ question: 0, text: "", education_slide: "", subquestion: "", answers: [{ text: "", result: false }] }],
	eduForms: [{ serial_num: 0, text: "" }],
	testAnswers: [],
}

export const formReducer = createReducer(initState, (builder) =>
	builder
		.addCase(SET_ANSWERS, (state, action) => {
			state.answerForms = action.payload
		})
		.addCase(ADD_QUESTION, (state, action) => {
			state.answerForms.push(action.payload)
		})
		.addCase(DELETE_QUESTION, (state, action) => {
			state.answerForms = state.answerForms.filter((question, i) => i !== action.payload)
		})
		.addCase(UPDATE_QUESTION_TEXT, (state, action) => {
			state.answerForms[action.payload.questionIndex].text = action.payload.value
		})
		.addCase(UPDATE_QUESTION_SLIDE, (state, action) => {
			state.answerForms[action.payload.questionIndex].education_slide = action.payload.value
		})
		.addCase(UPDATE_QUESTION_SUBQUESTION, (state, action) => {
			state.answerForms[action.payload.questionIndex].subquestion = action.payload.value
		})
		.addCase(ADD_ANSWER, (state, action) => {
			state.answerForms[action.payload.index].answers.push(action.payload.answer)
		})
		.addCase(REMOVE_ANSWER, (state, action) => {
			state.answerForms[action.payload.questionIndex].answers = state.answerForms[action.payload.questionIndex].answers.filter(
				(answer, i) => i !== action.payload.answerIndex
			)
		})
		.addCase(UPDATE_ANSWER_TEXT, (state, action) => {
			state.answerForms[action.payload.questionIndex].answers[action.payload.answerIndex].text = action.payload.value
		})
		.addCase(UPDATE_ANSWER_SUBQUESTION, (state, action) => {
			state.answerForms[action.payload.questionIndex].answers[action.payload.answerIndex].subquestion = action.payload.value
		})
		.addCase(UPDATE_ANSWER_RESULT, (state, action) => {
			state.answerForms[action.payload.questionIndex].answers[action.payload.answerIndex].result = action.payload.value
		})
		.addCase(CONFIRM_USER_ANSWER, (state, action) => {
			let currentTest = state.testAnswers.find((test) => test.id === action.payload.id)
			currentTest["confirmed"] = true
			currentTest["finalResult"] = action.payload.finalResult
		})
		.addCase(HANDLE_USER_ANSWER, (state, action) => {
			let currentTest = state.testAnswers.find((test) => test.id === action.payload.id)
			if (action.payload.type === "add") {
				currentTest.userResult[`${action.payload.key}`] = action.payload.value
			} else if (action.payload.type === "remove") {
				delete currentTest.userResult[`${action.payload.key}`]
			}
		})
		.addCase(ADD_USER_ANSWER, (state, action) => {
			state.testAnswers.push(action.payload)
		})
		.addCase(UPDATE_USER_SUBQUESTION, (state, action) => {
			state.testAnswers[action.payload.answerIndex].subquestion = action.payload.value
		})
		.addCase(CLEAN_TEST_ANSWERS, (state, action) => {
			state.testAnswers = []
		})
		.addCase(CLEAN_ANSWER_FORMS, (state, action) => {
			state.answerForms = [{ question: 0, text: "", education_slide: "", answers: [{ text: "", result: false }] }]
		})
		.addCase(ADD_EDU_SLIDE, (state, action) => {
			state.eduForms.push(action.payload)
		})
		.addCase(DELETE_EDU_SLIDE, (state, action) => {
			state.eduForms = state.eduForms.filter((slide, i) => i !== action.payload)
		})
		.addCase(UPDATE_EDU_TEXT, (state, action) => {
			state.eduForms[action.payload.index].text = action.payload.value
		})
		.addCase(SET_EDU_SLIDES, (state, action) => {
			state.eduForms = action.payload
		})
		.addCase(CLEAN_EDU_FORMS, (state, action) => {
			state.eduForms = [{ serial_num: 0, text: "" }]
		})
)
