export const albanian = () => {
	return {
		showMore: "Trego më shumë",
		edit: "redaktoni",
		educationAuthorIs: "Ky edukim është bërë nga kompania:",
		startTest: "fillimi i provës",
		didTest: "Ju bëtë një provë",
		maxTimes: "numri maksimal i herëve",
		endTest: "P COMPRFUNDONI TESTIN",
		requiredFieldsMessage: "Fushat e shënuara me * janë të detyrueshme!",
		allRequiredFieldsMessage: "Të gjitha fushat janë të nevojshme!",
		requiredTextOrImageMessage: "Ju nuk keni futur asnjë tekst ose imazh!",
		requiredTextMessage: "Ju nuk keni futur asnjë tekst!",
		requiredTextOfAllAnswersMessage: "Plotësoni tekstin e të gjitha përgjigjeve!",
		oneCorrectAnswerMessage: "Vetëm një përgjigje mund të jetë e saktë!",
		atLeastOneCorrectAnswerMessage: "Të paktën një përgjigje duhet të jetë e saktë!",
		requiredImageMessage: "Imazhi duhet të zgjidhet!",
		requiredQuestionTextMessage: "Futni tekstin e pyetjes!",
		requiredEducationSlideNumberMessage: "Vendosni numrin e diapozitivit të arsimit!",
		requiredAllQuestions: "Plotësoni të gjitha pyetjet!",
		passwordDontMatchMessage: "Fjalëkalimet nuk përputhen!",
		noDataSelectedMessage: "Të dhënat nuk janë zgjedhur!",
		noReportSelectedMessage: "Raporti nuk është zgjedhur!",
		noTestSelectedMessage: "Test nuk është zgjedhur!",
		noTypeSelectedMessage: "Lloji nuk është zgjedhur!",
		noArticleSelected: "Lajmet nuk janë zgjedhur!",
		noSurveySelected: "Sondazhi nuk është zgjedhur!",
		noJobSelected: "Puna nuk është zgjedhur!",
		notEnoughReportDataMessage: "Nuk ka të dhëna të mjaftueshme për raportin e zgjedhur",
		enterCommentMessage: "Fut një koment!",
		leaveComment: "LINI NJË KOMENT",
		report: "RAPORTI",
		test: "PROVIMI",
		type: "LLOJI",
		from: "NGA",
		to: "TE",
		generate: "GJENERATA",
		choosePlaceholder: "Zgjidhni ...",
		name: "EMRI",
		title: "TITULLI",
		education: "Arsimimi",
		category: "KATEGORIA",
		application: "APLIKACION",
		company: "KOMPANIA",
		survey: "ANKETIMI",
		country: "VENDI",
		city: "QYTETI",
		jobTitle: "TITULLI",
		position: "Pozicioni",
		language: "GJUHE",
		phoneNumber: "NUMRI I TELEFONIT",
		licenceNumber: "Numri i licencës",
		biography: "BIOGRAFIA",
		slideText: "TEKSTI I RREZIKUT",
		image: "IMAZH",
		question: "PYETJE",
		questions: "PYETJE",
		educationSlide: "RREGULLORE E ARSIMIT",
		answers: "P ANRGJIGJE",
		summaryText: "TEKSTI P SRMBLEDHJE",
		coverPhoto: "FOTO MBULIMI",
		content: "P CONRMBAJTJA",
		status: "STATUSI",
		close: "MBYLL",
		back: "Prapa",
		forward: "P FORRPARA",
		previous: "E mëparshme",
		next: "Tjetra",
		page: "Faqja",
		of: "nga",
		save: "RUAJ",
		password: "Fjalekalimi",
		repeatPassword: "PERSERITJE Fjalekalimin",
		newPassword: "FJALËKALIM I RI",
		newPasswordRepeat: "Përsëritni fjalëkalimin e ri",
		code: "KODI",
		companyType: "LLOJI I KOMPANISE",
		firstName: "EMRI",
		lastName: "MBIEMRI",
		username: "Emri i përdoruesit",
		email: "E-MAIL",
		supervisor: "MBIKQYRS",
		users: "PERSRDORUESIT",
		reports: "RAPORTET",
		importExport: "IMPORT/EKSPORT",
		masterData: "INFORMATA THEMELORE",
		countries: "VENDET",
		jobTitles: "TITUJ",
		positions: "Pozicionet",
		companyTypes: "LLOJET E KOMPANISE",
		cities: "QYTETET",
		companies: "KOMPANIT",
		educationCategories: "KATEGORIT E ARSIMIT",
		articleCategories: "KATEGORIT NEW E LAJMEVE",
		languages: "GJUHT",
		article: "LAJME",
		articles: "LAJME",
		surveys: "ANKETIMET",
		educations: "ARSIMIMET",
		tests: "TESTET",
		panel: "PANELI",
		signOut: "SHENJO",
		signIn: "HYNI",
		controlPanel: "PANELI I KONTROLLIT",
		profile: "PROFILI",
		cancel: "ANULOJ",
		delete: "Fshije",
		deleteQuestion: "Fshije pyetjen",
		deleteWarningMessage: "Nëse e fshini këtë artikull, nuk do të jeni në gjendje ta rikuperoni atë!",
		yes: "PO",
		no: "JO",
		creationDate: "DATA E KRIJIMIT",
		views: "PIKAT",
		reactions: "REAGIMET",
		comments: "COMMENTS",
		registrationTime: "KOHA E REGJISTRIMIT",
		lastLogin: "HYRJA E FUNDIT",
		active: "AKTIVE",
		congratulations: "URIME",
		completedTest: "E KENI P COMPRFUNDUAR TESTIN",
		monthlyReport: "Raporti Mujor",
		fileSelected: "Skedari u zgjodh",
		chooseCsvFile: "Zgjidhni skedarin .csv",
		photo: "Foto",
		selectPhoto: "Zgjidhni foton",
		noFileSelectedMessage: "Skedari nuk është zgjedhur!",
		exportData: "T D DHNAT E EKSPORTIT",
		export: "EKSPORTI",
		importData: "T D DHNAT E IMPORTIT",
		import: "IMPORTI",
		confirm: "KONFIRMO",
		noComments: "S'ka Komente",
		loadMore: "LOAD ME SHUME",
		author: "AUTORI",
		notFoundMessage: "Faqja që po kërkoni nuk u gjet",
		updateData: "Të dhënat e azhurnimit",
		privacyPolicy: "POLITIKA E PRIVATËSISË",
		result: "REZULTATI",
		serverErrorMessage: "Gabim në server!",
		wrongCredentialsMessage: "Asnjë llogari me kredencialet e dhëna nuk u gjet!",
		noAccount: "Nuk ka llogari?",
		clickHere: "Kliko këtu",
		forgotPassword: "keni harruar fjalëkalimin?",
		backTo: "Kthehu te",
		loginPage: "faqe në hyrje",
		emailAddress: "ADRESA E-MAJL",
		passwordReset: "Rivendosja e fjalëkalimit",
		registration: "N SNSHKRUAR",
		noMore: "Jo më!",
		welcomeTo: "mire se erdhet ne",
		byUser: "NGA PERDORUESI",
		byCity: "NGA QYTETI",
		byQuestions: "NGA PYETJE",
		loginCount: "Numërimi i hyrjeve",
		articleViews: "Pamjet e lajmeve",
		articleReactions: "Reagimet e lajmeve",
		educationViews: "Pikëpamjet e arsimit",
		passedTests: "Provat e kaluara",
		monthly: "MUAJ",
		eduAll: "TE GJITHA",
		eduChoose: "ZGJIDH",
		eduChooseCompleted: "ZGJIDH P CORFUNDIM",
		eduChooseNotCompleted: "ZGJIDH NUK PMPRFUNDIM",
		noEduSelectedMessage: "Arsimi nuk është zgjedhur!",
		deleteSlide: "FSHIJ S Rrëshqitjen",
		cantDeleteFirst: "Nuk mund ta fshish rrëshqitjen e parë!",
		agreePrivacyPolicy: "Unë pajtohem me politikën e privatësisë",
		articleAll: "GJITHA",
		articleByUserReports: "NGA RAPORTET E PERRDORUESIT",
		imageMissing: "Imazhi mungon diku!",
		monthlyPoints: "PIKAT PR KIST MUAJ:",
		annualPoints: "PIKAT P FORR KIST VIT:",
		notePlaceholder: "Shënim...",
		wrongAppMessage: "Po përpiqeni të hyni në platformën e gabuar!",
		selectFile: "Zgjidhni skedarin",
		searchPlaceholder: "Kërko...",
		repeatTest: "PERSERITJE TESTIN",
		usersUpdate: "PËRDITËSIM I PËRDORËSVE",
		total: "TOTAL",
		track: "GJURMIMI",
		loginData: "LOGIN DATA",
		checkEmailMessage: "Hapni lidhjen e emailit për të konfirmuar regjistrimin (kontrollo mesazhet e padëshiruara)",
		subquestion: "NËNPYETJE",
		comment: "KOMENT",
		reject: "REFUZOJE",
		approve: "MIRATOJE",
		tags: "TAGS",
		jobListings: "LISTA E PUNËS",
		deadline: "afati i aplikimit",
		applicationDeadline: "afati i aplikimit",
		apply: "regjistrohu",
		openFormToApply: "Hapni formularin e aplikimit",
		uploadYourCV: "ngarkoni CV-në tuaj",
		uploadFile: "Ngarko skedarin",
		yourCVIsUploaded: "CV-ja juaj është ngarkuar",
		applied: "APLIKUAR",
		jobs: "PUNE",
		job: "PUNË",
		note: "shënim",
		newEmployees: "Punonjësit e rinj",
		newEmployee: "PUNONJES I RI",
		updateEmploymentStatus: "Përditëso statusin e punësimit",
		banner: "reklamë",
		url: "url - lidhje",
		expirationDate: "DATA E SKADENCËS",
		expired: "I SKADUAR",
		days: "DITË",
		hours: "ORË",
		useCustomTheme: "përdorni temën e personalizuar",
		chooseTheColorOfTheApplication: "zgjidhni ngjyrën e aplikacionit",
		selectTheApplicationLogo: "zgjidhni logon e aplikacionit",
		selectTheApplicationFont: "zgjidhni fontin e aplikacionit",
		currentLogo: "logoja aktuale",
		font: "font",
		black: "e zezë",
		background: "sfond",
		dark: "errët",
		medium: "e mesme",
		light: "dritë",
		red: "e kuqe",
		assignedToUsers: "TË CAKTUAR PËR PËRDORËSIT",
		testAnswerQuestion: "Përgjigju pyetjes",
		testMaxRepeatNumberReached: "Ju e keni bërë testin numrin maksimal të herë",
		selectDocument: "Zgjidhni dokumentin",
		UA_LOGIN_ACTIVITY: "Aktiviteti",
		UA_NEWS_VIEWS: "Lajme",
		UA_NEWS_REACTIONS: "Reagimet",
		UA_EDUCATION_VIEWS: "Edukimet",
		UA_TESTS: "Testet",
		UA_LOGIN_ACTIVITY_TP: "Aktiviteti i përdoruesit në 100 ditët e mëparshme",
		UA_NEWS_VIEWS_TP: "Lajme të parë në 100 ditët e mëparshme",
		UA_NEWS_REACTIONS_TP: "Numri i reagimeve në 100 ditët e mëparshme",
		UA_EDUCATION_VIEWS_TP: "Arsimet e përfunduara me sukses në 100 ditët e mëparshme",
		UA_TESTS_TP: "Aktiviteti i testeve të kaluara në 100 ditët e mëparshme",
		ACTIVITY: "Aktiviteti",
		NEWS: "Lajme",
		EDUCATIONS: "Edukimet",
		TESTS: "Testet",
		achievements: "Arritjet",
		ACTIVITY_DAYS: "ditë të hyrjes së njëpasnjëshme",
		EDUCATIONS_FINISHED: "arsimet e përfunduara",
		NEWS_LIKED: "i pëlqente lajmet",
		NEWS_VIEWED: "lajmet e shikuara",
		TESTS_SUCCESS_FINISHED: "kaloi testet me të gjitha përgjigjet e sakta",
		TESTS_FINISHED: "testet e përfunduara",
		numberOfAchievements: "Numri i arritjeve",
		currentRank: "Renditja aktuale",
		numberOfUnread: "Numri i të palexuarve",
		numberOfNotCompleted: "Numri i të paplotësuarve",
		documents: "Dokumentet",
		document: "Dokumenti",
		download: "Shkarko",
		completedAllEdu: "Ju i keni përfunduar të gjitha",
		readAllNews: "Ju i keni lexuar të gjitha",
		forms: "Format",
		month: "Muaj",
		year: "Viti",
		absenceRequests: "KËRKESAT PËR MUNGESË",
		absenceRequestType: "LLOJI I MUNGESES",
		byAssignedUser: "NGA PËRDORËSI I CAKTUAR",
		bySubQuestions: "SIPAS NËNPYETJEVE",
		lockedEduMessage:
			"I nderuar përdorues, përderisa jeni punonjës i ri, duhet të bëni trajnime për punonjësit e rinj, në mënyrë që të keni akses në trajnime të tjera.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Kërkoni për një ndalim administrativ",
		FORM_PZDF: "Aplikim për ndarjen e fletushkave",
		contactPhoneNumber: "NUMRI I TELEFONIT TË KONTAKTIT",
		FORM_TK: "Klub garash",
		FORM_ZPOZ: "Kërkesa për Certifikata të Punësimit",
		bank: "BANKË",
		certificationPurpose: "QËLLIMI I CERTIFIKIMIT",
		documentType: "LLOJI I DOKUMENTIT",
		documentVerification: "DOKUMENT PËR VERIFIKIM",
		loanStatement: "DEKLARATË PËR RIPAGIMIN E PAVARUR TË HUASË",
		FORM_ZZO: "Lini kërkesat",
		leaveType: "LLOJI I PUSHIMIT",
		dateFrom: "NGA",
		dateTo: "TAKOJ",
		absenceDocument: "DOKUMENT PËR MUNGESË",
		FORM_PK: "Një kombinim fitues",
		commentNote: "KOMENT/SHËNIM",
		recommendation: "REKOMANDIM",
		FORM_IZ: "Iniciativat e punonjësve",
		initiative: "INICIATIVË",
		choose: "Zgjedh",
	}
}
