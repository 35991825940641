import { Button } from "@chakra-ui/button"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input"
import { Box, Center, Flex, Heading, Stack } from "@chakra-ui/layout"
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import EducationListItem from "../components/educations/EducationListItem"
import { SearchIcon } from "../components/Icons"
import SelectDropdown from "../components/SelectDropdown"
import Pagination from "../components/tables/Pagination"
import useEducation from "../redux/hooks/useEducation"
import useForms from "../redux/hooks/useForms"
import useGeneric from "../redux/hooks/useGeneric"
import useTest from "../redux/hooks/useTest"
import useUser from "../redux/hooks/useUser"
import { educationCategory } from "../utils/constants"
import { makeDropdownOptions } from "../utils/functions"
import { strings } from "../utils/localization"

const EducationsPage = () => {
	const hook = useEducation()
	const hookTest = useTest()
	const hookForms = useForms()
	const hookUser = useUser()
	const hookG = useGeneric()

	let navigate = useNavigate()
	const location = useLocation()

	const [searchValue, setSearchValue] = useState("")
	const [filter, setFilter] = useState(null)
	const [locked, setLocked] = useState(false)
	const [eduCategories, setEduCategories] = useState([])
	const [selectedCategory, setSelectedCategory] = useState(null)

	useEffect(() => {
		hook.clean()
		let filterData = null
		hookG.fetch(educationCategory)
		if (location.search) {
			filterData = {
				completed: true,
			}
			setFilter(filterData)
		}
		hook.fetchPage(hook.defaultSizeUser, 1, false, filterData)
		if (hookUser.newEmployee) {
			hook.fetchNewEmp()
		}
		hookTest.cleanDetail()
		hookForms.cleanAnswers()
	}, []) //eslint-disable-line

	// useEffect(() => {
	// 	if (!location.search) {
	// 		hook.fetchPage(hook.defaultSizeUser, 1, false)
	// 	}
	// }, [location]) //eslint-disable-line

	useEffect(() => {
		makeDropdownOptions(hookG.educationCategories, setEduCategories)
	}, [hookG.educationCategories])

	const handleRedirect = (id) => {
		navigate(`/educations/${id}`)
	}

	const handleSearch = (eduCategory, clearBox) => {
		// hook.cleanData()
		let filterData = {}

		if (searchValue) {
			filterData["name"] = searchValue
		}

		// if (eduCategory) {
		// 	filterData["eduCategory"] = eduCategory.value
		// }

		if (clearBox) {
			hook.fetchPage(hook.defaultSizeUser, 1, null, filterData)
		} else if (eduCategory) {
			filterData["eduCategory"] = eduCategory.value
			hook.fetchPage(hook.defaultSizeUser, 1, null, filterData)
		} else if (selectedCategory) {
			filterData["eduCategory"] = selectedCategory.value
			hook.fetchPage(hook.defaultSizeUser, 1, null, filterData)
		} else {
			hook.fetchPage(hook.defaultSizeUser, 1, null, filterData)
		}

		// hook.fetchPage(hook.defaultSizeUser, 1, false, filterData)
	}

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			let filterData = filter
			if (searchValue) {
				filterData["name"] = searchValue
				setFilter(filterData)
				hook.fetchPage(hook.defaultSizeUser, 1, filterData)
			}
		}
	}

	const handleCategoryChange = (val) => {
		if (val) {
			setSelectedCategory(val)
			handleSearch(val)
		} else {
			setSelectedCategory(null)
			handleSearch(null, true)
		}
	}

	useEffect(() => {
		if (hook.newEmployeeData.length > 0) {
			hook.newEmployeeData.map((edu, i) => !edu.completed && setLocked(true))
		}
	}, [hook.newEmployeeData])

	const pagination = hook.pagination

	return (
		<Box>
			<Flex
				mx={"auto"}
				w={["95%", "95%", "95%", "80%"]}
				gridGap={[4, 8]}
				justifyContent="space-between"
				direction={["column-reverse", "row"]}
			>
				<Box width={["100%", "420px"]}>
					<SelectDropdown
						options={eduCategories}
						value={selectedCategory}
						onChange={(val) => handleCategoryChange(val)}
						placeholder={strings.category}
						smallPlaceholder
					/>
				</Box>
				<InputGroup
					w={["100%"]}
					maxW={["100%", "500px", "600px"]}
					boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
					rounded="xl"
					bg="mc_bg"
				>
					<InputRightElement w="80px">
						<Button variant="dark" w="80px" rounded="md" onClick={() => handleSearch()} onEnter>
							<SearchIcon fill="mc_bg" />
						</Button>
					</InputRightElement>
					<Input
						onChange={(e) => setSearchValue(e.target.value)}
						onKeyPress={handleKeyPress}
						placeholder={strings.searchPlaceholder}
					/>
				</InputGroup>
			</Flex>
			<Stack mx="auto" w={["95%", "95%", "95%", "80%"]} spacing={3} my="30px">
				{hook.newEmployeeData.map((element, i) => (
					<EducationListItem key={i} data={element} redirect={() => handleRedirect(element.id)} locked={false} />
				))}
				{hook.data.map((element, i) => (
					<EducationListItem key={i} data={element} redirect={() => handleRedirect(element.id)} locked={locked} />
				))}
			</Stack>
			{hook.pagination && (
				<Box w="80%" mx="auto">
					{pagination.total_count > 0 ? (
						<Pagination
							paginationData={{
								totalCount: pagination.total_count,
								totalPages: pagination.total_pages,
								currentPage: pagination.current_page,
								pageSize: hook.defaultSizeUser,
								pageSizesArray: [10, 25, 50],
							}}
							setSize={hook.changeSize}
							fetchPage={hook.fetchPage}
							filterData={filter}
							role={false}
						/>
					) : (
						<Center>
							<Heading size={"md"}>{strings.completedAllEdu}</Heading>
						</Center>
					)}
				</Box>
			)}
		</Box>
	)
}

export default EducationsPage
