import { Input } from "@chakra-ui/input"
import { Box, Flex, Text } from "@chakra-ui/layout"
import { Icon } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
// import { ReactComponent as UploadIcon } from "../assets/icons/upload-icon.svg"
import { strings } from "../utils/localization"
import { UploadIcon } from "./Icons"

const UploadFile = ({ onChange, imgUrl, format, fileUpload, uploadCVIcon, setCleanTrigger, cleanTrigger, ...rest }) => {
	// function sizeConverter(a) {
	//     //eslint-disable-line
	//     let b = 0,
	//         c = parseInt(a, 10) || 0
	//     for (; 1024 <= c && ++b; ) c /= 1024
	//     return c.toFixed(10 > c && 0 < b ? 1 : 0) + " " + ["bytes", "KB", "MB"][b]
	// }
	const [files, setFiles] = useState([])

	useEffect(() => {
		if (files.length > 0) {
			onChange(files[0])
		}
	}, [files]) //eslint-disable-line

	useEffect(() => {
		if (cleanTrigger) {
			setFiles([])
			setCleanTrigger(false)
		}
	}, [cleanTrigger]) //eslint-disable-line

	const { getRootProps, getInputProps } = useDropzone({
		maxSize: "1004221205",
		multiple: false,
		// accept: "image/jpeg, image/png",
		accept: format,
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			)
		},
	})
	//   const acceptedFileItems = acceptedFiles.map((file) => (
	//     <Text key={file.path}>{file.path}</Text>
	//   ));
	const file = files.map((file) => (
		<Flex
			key={file.name}
			gridGap="10px"
			// h="60px"
			w="100%"
			overflow="hidden"
			alignItems="center"
			p="3px"
		>
			{/* <Box w="30%" pb="5px" overflow="hidden">
        <Img src={file.preview} style={{ objectFit: "cover" }} alt="preview" />
      </Box>
      <Box h="60px" w="40%" overflow="hidden">
        <Text color="mc_bg" fontSize="12px">
          {acceptedFileItems}
        </Text>
      </Box> */}
			<Text color="mc_bg" fontSize="14px" whiteSpace="nowrap">
				{/* {sizeConverter(file.size)} */}
			</Text>
		</Flex>
	))

	const checkString = () => {
		if (format === ".csv" || JSON.stringify(format) === JSON.stringify([".csv", ".xlsx"])) {
			if (file[0]) {
				return strings.fileSelected
			} else {
				return strings.chooseCsvFile
			}
		} else if (uploadCVIcon) {
			if (file[0]) {
				return strings.yourCVIsUploaded
			} else {
				return strings.uploadYourCV
			}
		} else if (fileUpload) {
			if (file[0]) {
				return strings.fileSelected
			} else {
				return strings.selectFile
			}
		}
	}

	return (
		<Flex w="240px" bg="mc_medium" py="4px" align="center" justify="center" rounded="md" _hover={{ opacity: 0.85 }} {...rest}>
			<Box w="100%" {...getRootProps()}>
				<Input {...getInputProps()} w="100%" />
				<Flex
					alignItems="center"
					gridGap="10px"
					justify="center"
					// mb={uploadCVIcon ? 0 : "5px"}
					h={uploadCVIcon ? "40px" : "auto"}
					cursor="pointer"
					_focus={{ outline: "none", border: "none" }}
				>
					<Text color="mc_bg" textTransform="uppercase" fontSize="14px">
						{checkString()}
					</Text>
					{uploadCVIcon ? uploadCVIcon : <Icon as={UploadIcon} boxSize={5} />}
					<Box>{file}</Box>
				</Flex>
			</Box>
		</Flex>
	)
}

export default UploadFile
