import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Box, Flex } from "@chakra-ui/layout"
import { Controller, useForm } from "react-hook-form"
import { strings } from "../../utils/localization"
import SelectDropdown from "../SelectDropdown"

const CategoryForm = ({ updateData, submit, close, selectData }) => {
	const { register, handleSubmit, control } = useForm()

	return (
		<form onSubmit={handleSubmit(submit)}>
			<FormControl>
				<FormLabel>{strings.code}</FormLabel>
				<Input name="code" defaultValue={updateData && updateData.code} ref={register({ required: true })} />
			</FormControl>
			<FormControl mt={4}>
				<FormLabel>{strings.name}</FormLabel>
				<Input name="name" defaultValue={updateData && updateData.name} ref={register({ required: true })} />
			</FormControl>
			<Box mt={4}>
				<FormLabel>{strings.application}</FormLabel>
				<Controller
					as={<SelectDropdown />}
					control={control}
					name="app"
					options={selectData.applications}
					defaultValue={updateData && updateData.defaultApp}
				/>
			</Box>
			<Box mt={4}>
				<FormLabel>{strings.companies}</FormLabel>
				<Controller
					as={<SelectDropdown />}
					isMulti
					control={control}
					name="companies"
					options={selectData.companies}
					defaultValue={updateData && updateData.defaultCompanies}
				/>
			</Box>
			<Flex justify="flex-end" mt={4}>
				<Button variant="red" onClick={() => close()}>
					{strings.close}
				</Button>
				<Button type="submit" variant="medium" ml={3}>
					{strings.save}
				</Button>
			</Flex>
		</form>
	)
}

export default CategoryForm
