import { Button } from "@chakra-ui/button"
import { strings } from "../../utils/localization"

const UpdateButton = ({ onClick }) => {
    return (
        <Button
            w="120px"
            h="38px"
            variant="medium"
            rounded="xl"
            onClick={() => onClick()}
			textTransform="uppercase"
        >
			{strings.edit}
        </Button>
    )
}

export default UpdateButton
