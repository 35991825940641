import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Box, Flex, Stack } from "@chakra-ui/layout"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import styled from "styled-components"
import { strings } from "../../utils/localization"
import SelectDropdown from "../SelectDropdown"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import RichTextEditor from "../RichTextEditor"
import { Button } from "@chakra-ui/button"
import { Textarea } from "@chakra-ui/textarea"
import { DatePickerWrapper } from "../DatePickerComponent"

const AddJobApplication = ({ handleClose, updateData, selectData, submit }) => {
    const [date, setDate] = useState(null)

    useEffect(() => {
        if (updateData) {
            setDate(new Date(updateData.date))
            // setValue("content", updateData.defaultContent)
        }
    }, [updateData])

    const { handleSubmit, control, setValue } = useForm({
        defaultValues: {
            content: "",
        },
    })

    const handleContent = (data) => {
        setValue("content", data)
    }

    const newSubmit = (data) => {
        let payload = data
        payload["application_deadline"] = date
        submit(data)
    }

    return (
        <form onSubmit={handleSubmit(newSubmit)}>
            <Stack spacing={6}>
                <FormControl minW="200px">
                    <FormLabel fontSize="14px" color="mc_dark">
                        {strings.title}
                    </FormLabel>
                    <Controller
                        as={<Input boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)" />}
                        control={control}
                        name="title"
                        defaultValue={updateData && updateData.title}
                    />
                </FormControl>
                <Flex gridGap={6} wrap="wrap">
                    <FormControl w={["100%", "100%", "418px"]}>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.application}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="app"
                            options={selectData.apps}
                            defaultValue={updateData && updateData.defaultApp}
                        />
                    </FormControl>
                    <FormControl w={["100%", "100%", "418px"]}>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.city}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="city"
                            options={selectData.cities}
                            defaultValue={updateData && updateData.defaultCity}
                        />
                    </FormControl>
                    <FormControl w={["100%", "100%", "418px"]}>
                        <FormLabel fontSize="14px" color="mc_dark">
                            {strings.company}
                        </FormLabel>
                        <Controller
                            as={<SelectDropdown />}
                            control={control}
                            name="company"
                            options={selectData.companies}
                            defaultValue={updateData && updateData.defaultCompany}
                        />
                    </FormControl>
                    <FormControl w={["100%", "100%", "200px"]}>
                        <FormLabel fontSize="14px" color="mc_dark" textTransform="uppercase">
                            {strings.deadline}
                        </FormLabel>
                        <DatePickerWrapper
                            wrapperClassName="container"
                            selected={date}
                            popperClassName="some-custom-class"
                            onChange={(date) => setDate(date)}
                            dateFormat="dd-MM-yyyy"
                        />
                    </FormControl>
                </Flex>
                <FormControl w={["100%", "100%", "418px"]}>
                    <FormLabel fontSize="14px" color="mc_dark">
                        {strings.tags}
                    </FormLabel>
                    <Controller
                        as={<SelectDropdown isMulti />}
                        control={control}
                        name="tags"
                        options={selectData.tags}
                        defaultValue={updateData && updateData.defaultTags}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel color="mc_dark" fontSize="14px">
                        {strings.summaryText}
                    </FormLabel>
                    <Controller
                        as={
                            <Textarea
                                color="mc_dark"
                                rounded="xl"
                                size="xl"
                                resize="none"
                                p="10px"
                                boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                            />
                        }
                        control={control}
                        name="summary_text"
                        defaultValue={updateData && updateData.summaryText}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel color="mc_dark" fontSize="14px">
                        {strings.content}
                    </FormLabel>
                    <Box rounded="xl" boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)">
                        <Controller
                            as={<RichTextEditor />}
                            control={control}
                            name="description"
                            onChange={handleContent}
                            // value={updateData && updateData.defaultContent}

                            setContent={updateData && updateData.defaultContent}
                        />
                    </Box>
                </FormControl>
                <Flex justify="flex-end" mt={4}>
                    <Button onClick={handleClose} variant="red">
                        {strings.close}
                    </Button>
                    <Button type="submit" variant="medium" color="mc_bg" ml={3}>
                        {strings.save}
                    </Button>
                </Flex>
            </Stack>
        </form>
    )
}

export default AddJobApplication
