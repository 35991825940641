import { Button, Flex, Heading, Icon, Stack } from "@chakra-ui/react"
import { saveAs } from "file-saver"
import { useState } from "react"
import useConfig from "../../utils/config"
import { strings } from "../../utils/localization"
import { downloadIcon } from "../Icons"
import SelectDropdown from "../SelectDropdown"

const DocumentsForm = ({ onClose, options }) => {
	const [selectedFile, setSelectedFile] = useState(null)

	const config = useConfig()

	const handleDownload = () => {
		saveAs(`${config.baseImageUrl}${selectedFile.value}`, selectedFile.label)
	}

	return (
		<>
			<Stack spacing={4} minH="240px">
				<Heading as="h3" fontSize={["18px", "24px"]}>
					{strings.documents}
				</Heading>

				<SelectDropdown
					placeholder={strings.selectFile}
					options={options}
					value={selectedFile}
					onChange={(item) => setSelectedFile(item)}
				/>
			</Stack>
			<Flex h="full" w="100%" justify="space-between">
				<Button variant="red" textTransform="uppercase" onClick={() => onClose()}>
					{strings.close}
				</Button>

				{selectedFile && (
					// <a
					// 	href={`${config.baseImageUrl}${selectedFile.value}`}
					// 	download={selectedFile.label + ".doc"}
					// 	target="_blank"
					// 	rel="noreferrer"
					// 	// textDecoration={"none"}
					// 	// title={selectedFile.label}
					// 	// isExternal
					// >
					// <Link
					// 	href={`${config.baseImageUrl}${selectedFile.value}`}
					// 	download={selectedFile.label + ".doc"}
					// 	target="_blank"
					// 	rel="noreferrer"
					// >
					<Button
						onClick={() => handleDownload()}
						variant="medium"
						textTransform="uppercase"
						rightIcon={<Icon as={downloadIcon} boxSize={5} />}
					>
						{strings.download}
					</Button>
					// </Link>
				)}
			</Flex>
		</>
	)
}

export default DocumentsForm
