import { Switch } from "@chakra-ui/switch"

const CustomSwitch = ({ onChange, value }) => {
    return (
        <Switch
            mx={["4px", "30px"]}
            colorScheme="teal"
            size="lg"
            onChange={(e) => onChange(e.target.checked)}
            isChecked={value}
        />
    )
}

export default CustomSwitch
