import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import { useToast } from "@chakra-ui/toast"
import {
    handleToast,
    makeDropdownOptions,
    makeDropdownOptionsUsers,
    makeIdsArray,
} from "../../utils/functions"
import EmailNotificationForm from "../../components/forms/EmailNotificationForm"
import EmailNotificationTable from "../../components/tables/EmailNotificationTable"
import useDataManagement from "../../redux/hooks/useDataManagement"
import { Box, useDisclosure } from "@chakra-ui/react"
import { application, articleCategory } from "../../utils/constants"
import useUsers from "../../redux/hooks/useUsers"
import useMasterdata from "../../redux/hooks/useMasterdata"

const AdminEmailNotificationPage = () => {
    const hook = useDataManagement()
    const hookMd = useMasterdata()
    const hookUsers = useUsers()
    const toast = useToast()

    const { isOpen, onOpen, onClose } = useDisclosure()

    const [applications, setApplications] = useState([])
    const [articleCategories, setArticleCategories] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        hook.getEmailNotifications()
        hookMd.fetch(application)
        hookMd.fetch(articleCategory)
        hookUsers.fetchDropdown()
    }, []) //eslint-disable-line

    useEffect(() => {
        makeDropdownOptionsUsers(hookUsers.data, setUsers)
        makeDropdownOptions(hookMd.articleCategories, setArticleCategories)
        makeDropdownOptions(hookMd.apps, setApplications)
    }, [hookMd.articleCategories, hookUsers.data, hookMd.apps])

    useEffect(() => {
        if (hook.importExportMessage) {
            handleToast(toast, hook.importExportMessage, "toast1")
        }
    }, [hook.importExportMessage]) // eslint-disable-line

    const onSubmit = (data) => {
        const payload = {
            notification: data.notification,
            article_category: data.article_category.value,
            users: makeIdsArray(data.users),
            app: data.application.value,
        }

        hook.createNotification(payload).then((res) => {
            if (res !== undefined && res.status === 200) {
                hook.getEmailNotifications()
                onClose()
            }
        })
    }

    const selectData = {
        users: users,
        articleCategories: articleCategories,
        applications: applications,
    }

    return (
        <Box
            h="95%"
            overflow="auto"
            border={"4px"}
            boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
            borderColor="mc_black"
            borderRadius="4px"
            w="100%"
        >
            <EmailNotificationTable data={hook.emailNotifications} selectData={selectData} />
            <PlusButton onClick={() => onOpen()} />
            <AddUpdateModal isOpen={isOpen} onClose={onClose} size="4xl">
                <EmailNotificationForm submit={onSubmit} close={onClose} selectData={selectData} />
            </AddUpdateModal>
        </Box>
    )
}

export default AdminEmailNotificationPage
