import { Box } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import CategoryForm from "../../components/forms/CategoryForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import CategoryTable from "../../components/tables/CategoryTable"
import useGeneric from "../../redux/hooks/useGeneric"
import useMasterdata from "../../redux/hooks/useMasterdata"
import { application, articleCategory, company } from "../../utils/constants"
import { handleToast, makeDropdownOptions, makeIdsArray } from "../../utils/functions"

const AdminCategoriesPage = ({ type }) => {
	const hook = useGeneric()
	const hookMd = useMasterdata()
	const toast = useToast()

	const [applications, setApplications] = useState([])
	const [companies, setCompanies] = useState([])

	useEffect(() => {
		hook.cleanToast()
		hook.fetch(type)
		hook.fetch(company)
		hookMd.fetch(application)
	}, [type])

	useEffect(() => {
		makeDropdownOptions(hook.companies, setCompanies)
		makeDropdownOptions(hookMd.apps, setApplications)
	}, [hook.companies, hookMd.apps])

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "toast1")
		}
	}, [hook.message]) // eslint-disable-line

	const onSubmit = (data) => {
		const payload = {
			code: data.code,
			name: data.name,
			app: data.app.value,
		}

		if (data.companies && data.companies.length > 0) {
			payload["companies"] = makeIdsArray(data.companies)
		} else {
			payload["companies"] = []
		}

		hook.create(type, payload).then((res) => {
			if (res !== undefined && res.status === 200) {
				hook.handleRefresh(type)
			}
		})
	}

	const selectData = {
		applications: applications,
		companies: companies,
	}

	return (
		<Box
			h="95%"
			overflow="auto"
			border={"4px"}
			boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
			borderColor="mc_black"
			borderRadius="4px"
			w="100%"
		>
			<CategoryTable
				data={type === articleCategory ? hook.articleCategories : hook.educationCategories}
				selectData={selectData}
				type={type}
			/>
			<PlusButton onClick={() => hook.onOpen()} />
			<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="4xl">
				<CategoryForm submit={onSubmit} close={hook.onClose} selectData={selectData} />
			</AddUpdateModal>
		</Box>
	)
}

export default AdminCategoriesPage
