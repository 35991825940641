import { Button } from "@chakra-ui/button"
import { Flex, Heading, Stack, Text } from "@chakra-ui/layout"
import React from "react"
import { formatDateForCell } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { LocationIcon } from "../Icons"

const Card = ({ cardData, handleOpen }) => {
    const {
        title,
        application_deadline,
        company_name,
        summary_text,
        city_name,
        id,
        is_registered,
    } = cardData

    return (
        <>
            <Stack
                spacing={6}
                w={["94vw", "384px"]}
                maxW="384px"
                h="338px"
                bg="mc_bg"
                borderRadius="19px"
                boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                p={["20px"]}
            >
                <Text fontSize="14px" color="mc_black">
                    {company_name}
                </Text>
                <Heading as="h4" textTransform="uppercase" fontSize="18px" color="mc_dark">
                    {title}
                </Heading>
                <Text noOfLines={5} color="mc_black" fontSize="16px" h="117px">
                    {summary_text}
                </Text>
                <Flex justify="space-between" mt="48px !important" align="flex-end">
                    <Stack spacing={0}>
                        <Flex gridGap="8px">
                            <LocationIcon />
                            <Text textTransform="uppercase" fontSize="14px" color="mc_black">
                                {city_name}
                            </Text>
                        </Flex>
                        <Text fontSize="14px" color="mc_black">
                            {strings.applicationDeadline}:
                        </Text>
                        <Text fontSize="14px" color="mc_black">
                            {formatDateForCell(application_deadline)}
                        </Text>
                    </Stack>

                    <Button
                        variant={is_registered ? "red" : "medium"}
                        textTransform="uppercase"
                        onClick={() => handleOpen(id)}
                    >
                        {is_registered ? strings.applied : strings.apply}
                    </Button>
                </Flex>
            </Stack>
        </>
    )
}

export default Card
