import { useDisclosure } from "@chakra-ui/hooks"
import { useDispatch, useSelector } from "react-redux"
import { cleanMessage, createGeneric, fetchGeneric, removeGeneric, submitFormSubmission, updateGeneric } from "../reducers/genericReducer"

export default function useGeneric() {
	const dispatch = useDispatch()
	const companies = useSelector((state) => state.generic.companies)
	const cities = useSelector((state) => state.generic.cities)
	const educations = useSelector((state) => state.generic.educations)
	const surveys = useSelector((state) => state.generic.surveys)
	const tests = useSelector((state) => state.generic.tests)
	const articles = useSelector((state) => state.generic.articles)
	const jobs = useSelector((state) => state.generic.jobs)
	const forms = useSelector((state) => state.generic.forms)
	const articleCategories = useSelector((state) => state.generic.articleCategories)
	const educationCategories = useSelector((state) => state.generic.educationCategories)
	const message = useSelector((state) => state.generic.message)
	const loading = useSelector((state) => state.generic.loading)
	const sectors = useSelector((state) => state.generic.sectors)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const fetch = async (type, dropdownData) => {
		dispatch(fetchGeneric(type, dropdownData))
	}

	const create = async (type, payload) => {
		return dispatch(createGeneric(type, payload))
	}

	const update = async (type, payload) => {
		return dispatch(updateGeneric(type, payload))
	}

	const remove = async (type, id) => {
		dispatch(removeGeneric(type, id)).then(() => handleRefresh(type))
	}

	const submitForm = async (payload) => {
		return dispatch(submitFormSubmission(payload))
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const handleRefresh = (type) => {
		onClose()
		fetch(type)
	}

	return {
		companies,
		educations,
		cities,
		articles,
		tests,
		surveys,
		loading,
		message,
		fetch,
		create,
		update,
		remove,
		submitForm,
		cleanToast,
		isOpen,
		onOpen,
		onClose,
		handleRefresh,
		jobs,
		forms,
		articleCategories,
		educationCategories,
		sectors,
	}
}
