import { createReducer } from "@reduxjs/toolkit";
import { checkAppId } from "../../utils/appHelper";

const appName = process.env.REACT_APP_NAME

const initState = {
	name: appName,
	id: checkAppId(appName),
	version: "" || process.env.REACT_APP_VERSION,
};

export const appReducer = createReducer(initState, (builder) => builder)

  