import { Button } from "@chakra-ui/button"
import { Flex, Heading, Text } from "@chakra-ui/layout"
import React from "react"
import { useNavigate } from "react-router-dom"
import { LeftArrow } from "../components/Icons"
import useConfig from "../utils/config"
import { strings } from "../utils/localization"

const NotFoundPage = () => {
	let navigate = useNavigate()
	const config = useConfig()
	return (
		<Flex w="100%" h="100vh" bgColor="mc_errorRed">
			<Flex
				w="100%"
				h="100vh"
				alignItems="center"
				justify="center"
				bgImg={config.background}
				bgColor="mc_errorRed"
				opacity=".9"
				direction="column"
				gridGap="30px"
			>
				<Heading fontSize="150px" color="mc_errorRed">
					404
				</Heading>
				<Text as="h3" color="mc_errorRed" fontSize="24px" textTransform="uppercase">
					{strings.notFoundMessage}
				</Text>
				<Button w="160px" variant="red" mx="auto" leftIcon={<LeftArrow fill="mc_bg" />} h="50px" onClick={() => navigate(-1)}>
					{strings.back}
				</Button>
			</Flex>
		</Flex>
	)
}

export default NotFoundPage
