import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import UpdateButton from "../buttons/UpdateButton"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"
import { strings } from "../../utils/localization"
import useUser from "../../redux/hooks/useUser"
import EmailNotificationForm from "../forms/EmailNotificationForm"
import { useDisclosure } from "@chakra-ui/react"
import { columnTableString, makeDropdownOptionsValueField, makeIdsArray } from "../../utils/functions"
import useDataManagement from "../../redux/hooks/useDataManagement"

const EmailNotificationTable = ({ data, selectData }) => {

    const hook = useDataManagement()
	const hookUser = useUser()

	const { isOpen, onOpen, onClose } = useDisclosure()

    const [updateData, setUpdateData] = useState(null)

    const columns = [
        {
            dataField: "notification",
            text: "NOTIFICATION",
        },
        {
            dataField: "article_category_name",
            text: strings.articleCategories,
        },
        {
            dataField: "users",
            text: strings.users,
			formatter: (cell) => columnTableString(cell)
        },
    ]

    if (hookUser.admin) {
        columns.push(
            {
                dataField: "app_name",
                text: strings.application,
            }
        )
    }

    const handleUpdateModal = (row) => {

        const defaultValues = {
            id: row.id,
			defaultArticleCategory: selectData.articleCategories.find((cat) => cat.value === row.article_category),
			defaultApp: selectData.applications.find((val) => val.value === row.app),
			defaultUsers: makeDropdownOptionsValueField(row.users),
			defaultNotification: row.notification
        }

        setUpdateData(defaultValues)
		onOpen()
    
    }

	const handleRemove = (id) => {
		hook.deleteNotification(id).then((res) => {
			if(res !== undefined && res.status === 200) {
				hook.getEmailNotifications()
			}
		})
	}

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <DeleteModal remove={() => handleRemove(row.id)}/>
                <UpdateButton onClick={() => handleUpdateModal(row)} />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }

    const handleSubmit = (data) => {

		const payload = {
			id: updateData.id,
			notification: data.notification,
			article_category: data.article_category.value,
			users: makeIdsArray(data.users),
			app: data.application.value
		}

		hook.updateNotification(payload).then((res) => {
			if(res !== undefined && res.status === 200) {
				hook.getEmailNotifications()
				onClose()
			}
		})
    }

    return (
        <>
            <TablesStyles>
                <BootstrapTable
                    data={data}
                    columns={columns}
                    keyField="id"
                    classes="TablesStyles"
                    expandRow={expandRow}
                    bordered
                    bootstrap4
                />
            </TablesStyles>

            <AddUpdateModal isOpen={isOpen} onClose={onClose} size="5xl">
				<EmailNotificationForm 
					updateData={updateData}
					close={onClose}
					submit={handleSubmit}
					selectData={selectData}
				/>
            </AddUpdateModal>
        </>
    )
}

export default EmailNotificationTable
