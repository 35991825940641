import { Box, Flex } from "@chakra-ui/layout"
import { Img, SimpleGrid } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import { useEffect, useLayoutEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Player } from "video-react"
import CompanyBanner from "../components/educations/CompanyBanner"
import LastSlideFooter from "../components/educations/LastSlideFooter"
import SlideContent from "../components/educations/SlideContent"
import SlideFooter from "../components/educations/SlideFooter"
import PdfRender from "../components/PdfRender"
import useKeyPress from "../components/useKeyPress"
import useEducation from "../redux/hooks/useEducation"
import useForms from "../redux/hooks/useForms"
import useTest from "../redux/hooks/useTest"
import useConfig from "../utils/config"
import { handleToast } from "../utils/functions"

const SingleEduPage = () => {
	const hook = useEducation()
	const navigate = useNavigate()
	const hookTest = useTest()
	const hookForms = useForms()
	const toast = useToast()
	let { id } = useParams()

	const config = useConfig()

	const [currentId, setCurrentId] = useState(1)

	useLayoutEffect(() => {
		hook.cleanDetail()
		hook.fetchOne(id)
		hookForms.cleanAnswers()
		hookTest.cleanDetail().then(() => hookTest.fetchOne(id))
	}, []) //eslint-disable-line

	const edu = hook.detail

	const increment = () => {
		if (edu && currentId !== edu.slides.length) {
			setCurrentId((prev) => prev + 1)
		} else {
			setCurrentId(1)
		}
	}

	const decrement = () => {
		if (currentId !== 1) {
			setCurrentId((prev) => prev - 1)
		}
	}

	useKeyPress({ key: "ArrowRight", onKeyDown: increment })
	useKeyPress({ key: "ArrowLeft", onKeyDown: decrement })

	useEffect(() => {
		if (hookTest.message) {
			handleToast(toast, hookTest.message, "1")
		}
	}, [hookTest.message]) //eslint-disable-line

	const handleStart = () => {
		hookTest.start({
			test: hookTest.detail.test.id,
			serial_num: hookTest.detail.test.user_test_attempts,
			education: edu.education.id,
		})
		navigate("/test")
	}

	const renderTextType = () => {
		if (edu && edu.slides.length > 0) {
			if (hookTest.detail && currentId === edu.slides.length) {
				return (
					<LastSlideFooter
						reload={() => setCurrentId(1)}
						startTest={() => handleStart()}
						testAttempts={hookTest.detail.test.user_test_attempts}
						userAllowed={hookTest.detail && hookTest.detail.test.user_allowed_test}
					/>
				)
			} else if (!hookTest.detail && currentId === edu.slides.length) {
				return <LastSlideFooter reload={() => setCurrentId(1)} noTest={true} />
			} else {
				return (
					<SlideFooter
						slides={edu && edu.slides}
						currentId={currentId}
						back={() => setCurrentId(currentId - 1)}
						next={() => setCurrentId(currentId + 1)}
					/>
				)
			}
		} else {
			return <></>
		}
	}

	const renderOtherType = () => {
		const lastSlide = () => {
			if (edu.slides.length === 0) {
				return (
					<LastSlideFooter
						startTest={() => handleStart()}
						testAttempts={hookTest.detail.test.user_test_attempts}
						userAllowed={hookTest.detail && hookTest.detail.test.user_allowed_test}
					/>
				)
			}
		}

		if (edu) {
			if (edu.education.content_type === 4) {
				return (
					<>
						<Flex justify="center" direction={"column"}>
							<PdfRender url={`${config.baseImageUrl}${edu.education.content_pdf}`} renderTest={true} />
							{hookTest.detail && lastSlide()}
						</Flex>
					</>
				)
			} else if (edu.education.content_type === 3) {
				return (
					<Box position="relative" w={{ xl: "860px" }}>
						<Img w="100%" src={`${config.baseImageUrl}${edu.education.content_image}`} objectFit="contain" />
						{hookTest.detail && lastSlide()}
					</Box>
				)
			} else if (edu.education.content_type === 2) {
				return (
					<>
						<Player playsInline>
							<source src={`${config.baseImageUrl}${edu.education.content_video}`} type="video/mp4" />
						</Player>
						{hookTest.detail && lastSlide()}
					</>
				)
			}
		}
	}

	return (
		<>
			<SimpleGrid
				w="100%"
				// minH="90vh"
				alignItems="center"
				// justify="center"
				px={["10px", "0px"]}
				templateColumns={[null, null, null, "1fr auto 1fr"]}
				spacing="40px"
				// px={["10px", "20px", "20px", "50px"]}
				// direction={["column-reverse", "column-reverse", "column-reverse", "row"]}
			>
				<Box justifySelf="center">
					{edu && edu.education.company_name && (
						<CompanyBanner name={edu.education.company_name} image={edu.education.company_image} />
					)}
				</Box>
				<Box
					w={["100%", "90%", "800px", "860px"]}
					maxW={["100%", "100%"]}
					// h={["550px", "550px", "550px", "628px"]}
					rounded="2xl"
					boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
					bg="mc_bg"
					justifySelf="center"
				>
					{edu &&
						edu.slides.length > 0 &&
						edu.slides.map((slide, i) => (
							<SlideContent
								display={currentId === slide.serial_num ? true : "none"}
								heading={slide.text}
								imgUrl={slide.image}
							/>
						))}
					{renderTextType()}
					{renderOtherType()}
				</Box>
			</SimpleGrid>
		</>
	)
}

export default SingleEduPage
