import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Flex, Heading, SimpleGrid } from "@chakra-ui/layout"
import { useToast } from "@chakra-ui/toast"
import React, { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useUser from "../../redux/hooks/useUser"
import { handleToast } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { CorrectIcon, UploadCVIcon } from "../Icons"
import CustomTextInput from "../inputs/CustomTextInput"
import UploadFile from "../UploadFile"
import useBillboard from "../../redux/hooks/useBillboard"
import { Textarea, Stack } from "@chakra-ui/react"

const JobApplicationForm = ({ id, isRegistered }) => {
    const hookUser = useUser()
    const toast = useToast()
    const hook = useBillboard()

    const [successMessage, setSuccessMessage] = useState(false)
    const [file, setFile] = useState(null)

    const { handleSubmit, control } = useForm({})

    const handleFileSelect = (chosenFile) => {
        setFile(chosenFile)
    }

    const submit = (data) => {
        if (!file) {
            handleToast(toast, { text: strings.allRequiredFieldsMessage, type: "error" }, "2")
        } else {
            let formData = new FormData()

            formData.append("job_listing", id)
            formData.append("first_name", data.first_name)
            formData.append("last_name", data.last_name)
            formData.append("phone_number", data.phone_number)
            formData.append("email", data.email)
            formData.append("note", data.note)
            formData.append("file", file, file.name)

            hook.sendJobApplication(formData).then((res) => {
                if (res !== undefined && res.status === 200) {
                    setSuccessMessage(true)
                    hook.fetchPage(hook.defaultSize, 1)
                }
            })
        }
    }

    return (
        <form onSubmit={handleSubmit(submit)}>
            {successMessage || isRegistered ? (
                <Flex
                    justify="center"
                    align="center"
                    h="168px"
                    w="100%"
                    borderRadius="19px"
                    direction="column"
                    gridGap="20px"
                    boxShadow="0px 2px 10px 2px rgba(36, 180, 188, 0.25)"
                    mt="26px"
                >
                    <CorrectIcon
                        h="35px"
                        w="35px"
                        rounded="full"
                        boxShadow="0px 2px 10px 2px rgba(36, 180, 188, 0.25)"
                        p="4px"
                    />
                    <Heading as="h4" color="mc_dark" fontSize="26px">
                        Uspešno ste se prijavili!
                    </Heading>
                </Flex>
            ) : (
                <Stack spacing="30px">
                    <SimpleGrid columns={[1, 2]} gridGap="30px" mt="26px">
                        <FormControl>
                            <FormLabel textTransform="uppercase" color="mc_medium" mb="2px">
                                {strings.firstName}
                            </FormLabel>
                            <Controller
                                as={<CustomTextInput rounded="md" />}
                                control={control}
                                name="first_name"
                                defaultValue={
                                    hookUser.personalInfo && hookUser.personalInfo.firstName
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel textTransform="uppercase" color="mc_medium" mb="2px">
                                {strings.lastName}
                            </FormLabel>
                            <Controller
                                as={<CustomTextInput rounded="md" />}
                                control={control}
                                name="last_name"
                                defaultValue={
                                    hookUser.personalInfo && hookUser.personalInfo.lastName
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel textTransform="uppercase" color="mc_medium" mb="2px">
                                {strings.phoneNumber}
                            </FormLabel>
                            <Controller
                                as={<CustomTextInput rounded="md" type="number" />}
                                control={control}
                                name="phone_number"
                                defaultValue={
                                    hookUser.personalInfo && hookUser.personalInfo.phoneNumber
                                }
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel textTransform="uppercase" color="mc_medium" mb="2px">
                                {strings.email}
                            </FormLabel>
                            <Controller
                                as={<CustomTextInput rounded="md" type="email" />}
                                control={control}
                                name="email"
                                defaultValue={hookUser.personalInfo && hookUser.personalInfo.email}
                            />
                        </FormControl>
                    </SimpleGrid>
                    <FormControl>
                        <FormLabel textTransform="uppercase" color="mc_medium" mb="2px">
                            {strings.note}
                        </FormLabel>
                        <Controller
                            as={
                                <Textarea
                                    color="mc_dark"
                                    rounded="xl"
                                    boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                                />
                            }
                            control={control}
                            name="note"
                        />
                    </FormControl>

                    <UploadFile uploadCVIcon={<UploadCVIcon />} onChange={handleFileSelect} />
                </Stack>
            )}
            <Flex justify="center" my="26px">
                {!successMessage && (
                    <Button variant="medium" textTransform="uppercase" type="submit">
                        {strings.apply}
                    </Button>
                )}
            </Flex>
        </form>
    )
}

export default JobApplicationForm
