import { useDisclosure } from "@chakra-ui/hooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestPage, createTest, updateTest, cleanTest,
	 removeTest, changeDefaultSize, cleanMessage, 
	 fetchTest, startTest, endTest } from "../reducers/testReducer";
import useForms from "./useForms";

export default function useTest() {

	const dispatch = useDispatch();
	const data = useSelector((state) => state.tests.data);
	const pagination = useSelector((state) => state.tests.pagination);
	const detail = useSelector((state) => state.tests.detail);
	const defaultSize = useSelector((state) => state.tests.defaultSize);
	const message = useSelector((state) => state.tests.message);
	const loading = useSelector((state) => state.tests.loading);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const hookForms = useForms()

	const fetchPage = async (perPage, pageNumber) => {
		dispatch(fetchTestPage(perPage, pageNumber))
	}

	const fetchOne = async (id) => {
		return dispatch(fetchTest(id))
	}

	const create = async (payload) => {
		return dispatch(createTest(payload))
	};

	const update = async (payload) => {
		return dispatch(updateTest(payload))
	};

	const remove = async (id) => {
		dispatch(removeTest(id)).then(() => handleRefresh())
	}

	const start = async (payload) => {
		dispatch(startTest(payload))
	}

	const end = async (payload) => {
		dispatch(endTest(payload))
	}


	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const changeSize = async (size) => {
		dispatch(changeDefaultSize(size))
	}

	const cleanDetail = async () => {
		dispatch(cleanTest())
	}

	const handleRefresh = () => {
		onClose()
		fetchPage(defaultSize, pagination.current_page)
		hookForms.cleanTestForms()
	}

	return {
		data, loading, message, detail,
	 	pagination, defaultSize,
		fetchPage, remove, create, fetchOne,
		update, cleanToast, changeSize, cleanDetail,
		start, end, 
		isOpen, onOpen, onClose, handleRefresh
	};
}