import { useDisclosure } from "@chakra-ui/hooks"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useApp from "../../redux/hooks/useApp"
import useTest from "../../redux/hooks/useTest"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { formatDateForCell, makeDropdownOptions, trueOrFalse } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedDefaultValues, smartedColumns } from "../../utils/smartedHelper"
import UpdateButton from "../buttons/UpdateButton"
import UpdateTestForm from "../forms/UpdateTestForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"

const TestTable = ({ data, selectData }) => {
	const hook = useTest()
	const hookUser = useUser()
	const hookApp = useApp()
	const config = useConfig()

	const [updateData, setUpdateData] = useState(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

	const columns = [
		{
			dataField: "name",
			text: strings.name,
		},
		{
			dataField: "date_created",
			text: strings.creationDate,
			formatter: (cell) => {
				return formatDateForCell(cell)
			},
		},
		{
			dataField: "education_name",
			text: strings.education.toUpperCase(),
		},
		{
			dataField: "track",
			text: strings.track,
			formatter: (cell) => trueOrFalse(cell),
		},
	]

	if (hookUser.admin) {
		columns.push(
			{
				dataField: "app_name",
				text: strings.application,
			},
			{
				dataField: "companies_value",
				text: strings.companies,
			}
		)
	}

	if (config.appId === 2) {
		smartedColumns.forEach((column) => columns.push(column))
	}

	const handleUpdateModal = (row) => {
		const defaultEducation = selectData.educations.find((e) => e.value === row.education)

		let defaultCompanies = []
		let defaultApp

		if (!hookUser.admin) {
			defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
			defaultApp = { label: hookApp.name, value: hookApp.id }
		} else {
			defaultCompanies = makeDropdownOptions(row.companies)
			defaultApp = selectData.apps.find((e) => e.value === row.app)
		}

		const defaultValues = {
			id: row.id,
			defaultEducation: defaultEducation,
			defaultApp: defaultApp,
			defaultCompanies: defaultCompanies,
			name: row.name,
			education: row.education,
			track: row.track,
		}

		if (config.appId === 2) {
			handleSmartedDefaultValues(defaultValues, row, selectData)
		}

		setUpdateData(defaultValues)

		onOpen()
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<DeleteModal remove={() => hook.remove(row.id)} />
				<UpdateButton onClick={() => handleUpdateModal(row)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	return (
		<>
			<TablesStyles appId={config.appId}>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<AddUpdateModal isOpen={isOpen} onClose={onClose} size="6xl">
				<UpdateTestForm selectData={selectData} updateData={updateData} handleClose={onClose} />
			</AddUpdateModal>
		</>
	)
}

export default TestTable
