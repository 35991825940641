import { Box, Flex } from "@chakra-ui/layout"
import { AspectRatio, Img } from "@chakra-ui/react"
import React from "react"
import { NavLink, Outlet } from "react-router-dom"
import styled from "styled-components"
import useUser from "../../redux/hooks/useUser"
import paths from "../../routing/paths"
import useConfig from "../../utils/config"
import { strings } from "../../utils/localization"

const Layout = () => {
	const hookUser = useUser()
	const config = useConfig()
	const path = paths()

	const checkLogo = () => {
		if (hookUser.admin) {
			return true
		} else if (hookUser.company && !hookUser.company.use_custom_theme) {
			return true
		} else {
			return false
		}
	}

	const Nav = () => {
		return (
			<Flex
				w="100%"
				pos={"sticky"}
				top={0}
				zIndex={100}
				bg={"mc_bg"}
				h={hookUser.company && hookUser.company.use_custom_theme ? ["60px", "120px"] : ["90px", "80px"]}
				alignItems="center"
				direction={["column", "column", "row"]}
				py="5px"
				mb="15px"

				// gridGap={["10px", 0]}
			>
				{checkLogo() && (
					<Box h="100vh" pos="relative">
						<Flex
							width={["160px", "160px", "160px", "230px", "260px"]}
							h={["80px", "80px", "80px", "120px"]}
							pos="absolute"
							top="50%"
							left="50%"
							transform={["translate(-50%,-50%)", "translate(-50%,-50%)", "translate(0%,-50%)"]}
							justify={["center", "center", "flex-start"]}
							align="center"
							ml={[0, 0, "20px"]}
						>
							{config.logo}
						</Flex>
					</Box>
				)}

				<Flex
					w="100%"
					gridColumnGap={["15px", "20px", "20px", "42px", "62px"]}
					justifyContent={["center", "center", "flex-end", "flex-end", "center"]}
					mr={[0, 0, "20px", "20px", 0]}
					fontSize={["10px", "11px", "12px", "12px", "16px"]}
					fontWeight="700"
					letterSpacing={["1px", "2px", "2px", "5px"]}
					color="mc_dark"
					mt={["10px", "10px", 0]}
				>
					{(hookUser.admin || hookUser.supervisor) && (
						<Flex display={["none", "flex"]}>
							<NavLink to={path.panelReports}>{strings.controlPanel}</NavLink>
						</Flex>
					)}
					<NavLink to={path.articles}>{strings.articles}</NavLink>
					<NavLink to={path.educations}>{strings.educations}</NavLink>
					{config.appId === 1 && <NavLink to={path.jobs}>{strings.jobListings}</NavLink>}
					<NavLink to={path.profile}>{strings.profile}</NavLink>
				</Flex>
			</Flex>
		)
	}

	return (
		<WrapContainer>
			{hookUser.company && hookUser.company.use_custom_theme ? (
				<>
					<Box w="100%" h="100vh" bg="mc_bg" pos="fixed" overflow="auto">
						<Flex pos="fixed" top="50%" left="50%" transform="translate(-50%,-50%)" zIndex="-10" opacity="0.1" maxW="100%">
							{hookUser.company.company_image && (
								<AspectRatio w={["100vw", "1620px"]} maxH="84vh" ratio={4 / 3} objectFit="contain">
									<Img
										src={`${config.baseImageUrl}${hookUser.company.company_image}`}
										objectFit="contain !important"
										alt="logo"
									/>
								</AspectRatio>
							)}
						</Flex>
						<Box w="100%" minH="100vh" pb="40px">
							{hookUser.token && <Nav />}
							<Outlet context={config} />
						</Box>
					</Box>
				</>
			) : (
				<>
					<Box
						w="100%"
						minH="100vh"
						pb="120px"
						//alternatively solution
						background="mc_bg"
						bgImg={config.background}
						backgroundSize="102vw"
						bgPos="top center"
						bgRepeat="repeat-y"
					>
						{hookUser.token && <Nav />}
						<Outlet context={config} />
					</Box>
				</>
			)}
		</WrapContainer>
	)
}

const WrapContainer = styled.div`
	.active {
		border-bottom: 2px solid var(--mc_dark);
	}
`

export default Layout
