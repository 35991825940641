import { useDispatch, useSelector } from "react-redux"
import {
	cleanArticle,
	cleanMessage,
	deleteArticleComment,
	emptyData,
	fetchArticle,
	fetchArticlePage,
	reactionToggleSet,
	sendArticleReport,
	sendComment,
	sendReaction,
	sendSurvey,
} from "../reducers/articleReducer"

export default function useArticle() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.articles.data)
	const pagination = useSelector((state) => state.articles.pagination)
	const detail = useSelector((state) => state.articles.detail)
	const defaultSize = useSelector((state) => state.articles.defaultSize)
	const message = useSelector((state) => state.articles.message)
	const loading = useSelector((state) => state.articles.loading)
	const reactionToggle = useSelector((state) => state.articles.reactionToggle)

	const fetchPage = async (perPage, pageNumber, filterData) => {
		dispatch(fetchArticlePage(perPage, pageNumber, filterData))
	}

	const fetchOne = async (id) => {
		dispatch(fetchArticle(id))
	}

	const cleanToast = async () => {
		dispatch(cleanMessage())
	}

	const cleanDetail = async () => {
		dispatch(cleanArticle())
	}

	const cleanData = async () => {
		dispatch(emptyData())
	}

	const setReactionToggle = async (payload) => {
		dispatch(reactionToggleSet(payload))
	}

	const postReaction = async (reaction, articleId) => {
		const payload = {
			reaction: reaction,
			article: articleId,
		}
		dispatch(sendReaction(payload)).then(() => fetchOne(detail.unique_url))
	}

	const postComment = async (articleId, comment) => {
		const payload = {
			article: articleId,
			comment: comment,
		}

		dispatch(sendComment(payload)).then(() => fetchOne(detail.unique_url))
	}

	const postSurvey = async (payload) => {
		dispatch(sendSurvey(payload)).then(() => fetchOne(detail.unique_url))
	}

	const postArticleReport = async (payload) => {
		return dispatch(sendArticleReport(payload))
	}

	const removeComment = async (id, userId) => {
		return dispatch(deleteArticleComment(id, userId))
	}

	return {
		data,
		loading,
		message,
		detail,
		pagination,
		defaultSize,
		reactionToggle,
		fetchPage,
		cleanToast,
		fetchOne,
		cleanDetail,
		cleanData,
		postReaction,
		postComment,
		postSurvey,
		postArticleReport,
		removeComment,
		setReactionToggle,
	}
}
