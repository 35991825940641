import { Box, Flex, Heading, HStack, Slider, SliderFilledTrack, SliderMark, SliderTrack, Stack, Text, Tooltip } from "@chakra-ui/react"
import React from "react"
import { strings } from "../../utils/localization"

const VisualMainBox = ({ title, resultNumber, color, resultPercent, tooltipLabel, ...rest }) => {
	return (
		<Stack w="100%" h="98px" rounded="lg" p={2.5} bg="mc_bg" boxShadow="0px 2px 10px 2px rgba(66, 146, 220, 0.25)" {...rest}>
			<Flex justify="space-between">
				<HStack spacing={4}>
					<Heading as="h4" fontSize={["16px", "20px", "25px"]} color="mc_dark">
						{title ? strings[title] : strings.numberOfAchievements}
					</Heading>
					<Tooltip label={tooltipLabel} placement="top" hasArrow bg="mc_dark" color="mc_bg" rounded="lg" py={1}>
						<Flex
							w={["18px", "23px"]}
							h={["18px", "23px"]}
							rounded="full"
							bg="mc_dark"
							color="mc_bg"
							justify="center"
							fontSize={["14px", "16px"]}
						>
							?
						</Flex>
					</Tooltip>
				</HStack>
				<Text fontSize={["14px", "16px"]} color="mc_dark">
					{resultNumber}
				</Text>
			</Flex>
			<Box w="100%">
				<Slider
					w="100%"
					aria-label="slider-ex-1"
					h={["28px", "40px"]}
					isReadOnly
					// defaultValue={50}
					value={resultPercent}
					cursor="default"
					color="mc_dark"
				>
					<SliderMark
						w="100%"
						// value={30}
						textAlign="center"
						color="mc_dark"
						fontWeight="bold"
						fontSize={["12px", "16px"]}
						mt={["4px", "8px"]}
						ml={0}
						left={0}
						zIndex="10"
					>
						{resultPercent}%
					</SliderMark>
					<SliderTrack h={["28px", "40px"]} rounded="md" boxShadow="0px 2px 12px rgba(66, 146, 220, 0.25)" bg="mc_bg">
						<SliderFilledTrack bg={color} />
					</SliderTrack>
				</Slider>
			</Box>
		</Stack>
	)
}

export default VisualMainBox
