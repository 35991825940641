import { Img } from "@chakra-ui/image"
import { Text } from "@chakra-ui/layout"
import { Box } from "@chakra-ui/layout"
import React from "react"
import { strings } from "../../utils/localization"

const CompanyBanner = (props) => {
    
    return (
        <Box
            w="277px"
            h={props.image ? "250px" : "65px"}
            p="10px"
            boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
            bg="mc_bg"
            rounded="2xl"
            overflow="hidden"
            order={props.order}
        >
			{props.name && 
				<Text p="3px" fontSize="14px">
					{strings.educationAuthorIs} {props.name}
				</Text>
			
			}
			{props.image && 
				<Img
					src={props.image}
					alt="Kompanija"
					objectFit="cover"
					bgPos="center"
					rounded="xl"
					boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
				/>
			}

        </Box>
    )
}

export default CompanyBanner
