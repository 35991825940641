import React from "react"
import styled from "styled-components"
import SunEditor from "suneditor-react"
import "suneditor/dist/css/suneditor.min.css"
require("typeface-josefin-sans")

const RichTextEditor = ({ onChange, setContent }) => {
    return (
        <Container>
            <SunEditor
                setContents={setContent}
                showToolbar={true}
                onChange={onChange}
                setDefaultStyle="height: auto"
                setOptions={{
                    buttonList: [
                        [
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "list",
                            "align",
                            "indent",
                            "font",
                            "fontSize",
                            "formatBlock",
                            "link",
                            "horizontalRule",
                            "lineHeight",
                            "table",
                            "fontColor",
                            "removeFormat",
                            "codeView",
                            "image",
                            "video",
                            "fullScreen",
                        ],
                    ],
                    font: ["sans-serif", "Arial", "tohoma", "Courier New,Courier"],
                    fontSize: [8, 10, 12, 13, 14, 15, 16, 17, 18, 20, 22, 24, 28, 32, 36],
                    height: "200px",
                    maxHeight: "270px",
                    colorList: [
                        ["#ccc", "#dedede", "OrangeRed", "Orange", "RoyalBlue", "SaddleBrown"],
                        ["SlateGray", "BurlyWood", "DeepPink", "FireBrick", "Gold", "SeaGreen"],
                    ],
                }}
            />
        </Container>
    )
}
const Container = styled.div`
    .sun-editor-common {
        background: #f5feff;
    }
    .se-menu-list li button {
        max-width: 65px;
    }
    .se-wrapper {
        /* overflow: hidden; */
    }
`
export default RichTextEditor
