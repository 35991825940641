import { useDisclosure } from "@chakra-ui/hooks"
import { Box, SimpleGrid, Grid, GridItem } from "@chakra-ui/layout"
import { useToast, Link, Img } from "@chakra-ui/react"
import React, { useEffect } from "react"
import BoardModal from "../components/board/BoardModal"
import Card from "../components/board/Card"
import useBillboard from "../redux/hooks/useBillboard"
import { handleToast } from "../utils/functions"
import useApp from "../redux/hooks/useApp"
import styled from "styled-components"

import useDataManagement from "../redux/hooks/useDataManagement"

const BoardPage = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const hook = useBillboard()
    const hookBanners = useDataManagement()
    const toast = useToast()
    const appName = useApp().name

    useEffect(() => {
        hook.fetchPage(hook.defaultSize, 1)
    }, []) //eslint-disable-line

    useEffect(() => {
        hookBanners.getBanners()
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) //eslint-disable-line

    const handleOpen = (id) => {
        hook.fetchOne(id).then((res) => {
            if (res !== undefined && res.status === 200) {
                onOpen()
            }
        })
    }

    const firstBanner = hookBanners.banners.find((val) => val.id === 1)
    const secondBanner = hookBanners.banners.find((val) => val.id === 2)
    const thirdBanner = hookBanners.banners.find((val) => val.id === 3)
    const fourthBanner = hookBanners.banners.find((val) => val.id === 4)

    return (
        <>
            {hook.data && (
                <Grid
                    templateColumns={["1fr", "1fr", "1fr", "1fr", "1fr", "1fr auto 1fr"]}
                    gridAutoFlow="column"
                    gridAutoRows="auto"
                    // minH="100vh"
                    w="100%"
                    gap={["10px", "20px"]}
                    bg="transparent"
                    justifyItems="center"
                >
                    <GridItem
                        display={["none", "none", "none", "none", "none", "flex"]}
                        minW="300px"
                        width="100%"
                        justifyContent="center"
                    >
                        {appName === "PHARMANET" && hook.data && hookBanners.banners.length > 0 && (
                            <BannerWrapper>
                                <Box
                                    h="42%"
                                    maxH="300px"
                                    w="100%"
                                    border={firstBanner.image ? "1px solid" : null}
                                    borderColor={firstBanner.image ? "mc_medium" : null}
                                    borderRadius="2px"
                                    overflow="hidden"
                                >
                                    {firstBanner.image && (
                                        <Link
                                            href={
                                                firstBanner.url.length > 0 ? firstBanner.url : null
                                            }
                                            isExternal
                                            cursor={firstBanner.url.length ? "pointer" : "default"}
                                        >
                                            <Img
                                                src={firstBanner && firstBanner.image}
                                                h="100%"
                                                w="100%"
                                                bg="mc_bg"
                                                transitionDuration="0.2s"
                                                _hover={{
                                                    transform: "scale(1.15)",
                                                }}
                                            />
                                        </Link>
                                    )}
                                </Box>

                                <Box
                                    h="42%"
                                    maxH="300px"
                                    w="100%"
                                    border={secondBanner.image ? "1px solid" : null}
                                    borderColor={secondBanner.image ? "mc_medium" : null}
                                    borderRadius="2px"
                                    overflow="hidden"
                                >
                                    {secondBanner.image && (
                                        <Link
                                            href={
                                                secondBanner.url.length > 0
                                                    ? secondBanner.url
                                                    : null
                                            }
                                            isExternal
                                            cursor={secondBanner.url.length ? "pointer" : "default"}
                                        >
                                            <Img
                                                src={secondBanner && secondBanner.image}
                                                h="100%"
                                                w="100%"
                                                bg="mc_bg"
                                                transitionDuration="0.2s"
                                                _hover={{
                                                    transform: "scale(1.15)",
                                                }}
                                            />
                                        </Link>
                                    )}
                                </Box>
                            </BannerWrapper>
                        )}
                    </GridItem>
                    <SimpleGrid columns={[1, 1, 2]} spacing={["24px"]}>
                        {hook.data.map((cardData, i) => {
                            return <Card key={i} cardData={cardData} handleOpen={handleOpen} />
                        })}
                    </SimpleGrid>
                    <BoardModal isOpen={isOpen} onClose={onClose} size="4xl" data={hook.detail} />
                    <GridItem
                        display={["none", "none", "none", "none", "none", "flex"]}
                        width="100%"
                        minW="300px"
                        justifyContent="center"
                    >
                        {appName === "PHARMANET" && hook.data && hookBanners.banners.length > 0 && (
                            <BannerWrapper>
                                <Box
                                    h="42%"
                                    maxH="300px"
                                    w="100%"
                                    border={thirdBanner.image ? "1px solid" : null}
                                    borderColor={thirdBanner.image ? "mc_medium" : null}
                                    borderRadius="2px"
                                    overflow="hidden"
                                >
                                    {thirdBanner.image && (
                                        <Link
                                            href={
                                                thirdBanner.url.length > 0 ? thirdBanner.url : null
                                            }
                                            isExternal
                                            cursor={thirdBanner.url.length ? "pointer" : "default"}
                                        >
                                            <Img
                                                src={thirdBanner && thirdBanner.image}
                                                h="100%"
                                                w="100%"
                                                bg="mc_bg"
                                                transitionDuration="0.2s"
                                                _hover={{
                                                    transform: "scale(1.15)",
                                                }}
                                            />
                                        </Link>
                                    )}
                                </Box>
                                <Box
                                    h="42%"
                                    maxH="300px"
                                    w="100%"
                                    border={fourthBanner.image ? "1px solid" : null}
                                    borderColor={fourthBanner.image ? "mc_medium" : null}
                                    borderRadius="2px"
                                    overflow="hidden"
                                >
                                    {fourthBanner.image && (
                                        <Link
                                            href={
                                                fourthBanner.url.length > 0
                                                    ? fourthBanner.url
                                                    : null
                                            }
                                            isExternal={fourthBanner.url.length > 0 ? true : false}
                                            cursor={fourthBanner.url.length ? "pointer" : "default"}
                                        >
                                            <Img
                                                src={fourthBanner && fourthBanner.image}
                                                h="100%"
                                                w="100%"
                                                bg="mc_bg"
                                                transitionDuration="0.2s"
                                                _hover={{
                                                    transform: "scale(1.15)",
                                                }}
                                            />
                                        </Link>
                                    )}
                                </Box>
                            </BannerWrapper>
                        )}
                    </GridItem>
                </Grid>
            )}
        </>
    )
}

const BannerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    grid-gap: 16%;
    position: -webkit-sticky;
    position: sticky;
    top: 15vh;
    min-height: 65vh;
    height: calc(80vh - 200px);
    width: 100%;
    max-width: 380px;
    padding: 0 15px;
`

export default BoardPage
