import { Heading, Icon, Stack, Text } from "@chakra-ui/react"
import React from "react"
import useDashboard from "../../redux/hooks/useDashboard"
import { strings } from "../../utils/localization"

const CategoryButton = ({ icon, title, subtitle, number, action }) => {
	const hookD = useDashboard()

	const checkNumber = (title) => {
		if (title === strings["UA_NEWS_VIEWS"]) {
			return hookD.unreadArticleCount
		} else if (title === strings["UA_EDUCATION_VIEWS"]) {
			return hookD.unfinishedEducationCount
		} else if (title === "Top 10") {
			return hookD.currentRankTop10MonthCount
		} else if (title === strings["achievements"]) {
			return hookD.achievementsCount
		}
	}

	return (
		<Stack
			w="100%"
			// maxW="279px"
			as="button"
			minH={["58px", "80px", "112px", "132px", "132px"]}
			direction={["column", "column", "row", "column"]}
			rounded="lg"
			bg="mc_bg"
			justify={{ base: "flex-start", md: "center" }}
			align={["center"]}
			spacing={[1, 1, 4, 2]}
			boxShadow="35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset -4px -4px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255)"
			p={[1, 2, 3]}
			pb={[2]}
			cursor="pointer"
			_hover={{
				boxShadow:
					"35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset -6px -6px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255)",
				transform: "scale(1.01)",
			}}
			_active={{ transform: "scale(1)" }}
			onClick={action}
		>
			<Icon as={icon} boxSize={[7, 10, 10, "54px"]} />
			<Stack spacing={[0]}>
				<Heading
					as="h4"
					fontSize={["16px", "20px", "25px", "25px"]}
					lineHeight={["18px", "20px", "25px", "25px"]}
					fontWeight="semibold"
					color="mc_dark"
				>
					{title}
				</Heading>
				<Text fontSize={["12px", "14px", "14px", "16px"]} color="mc_dark">
					{subtitle}:{" "}
					<Text as="span" color="mc_errorRed">
						{checkNumber(title)}
					</Text>
				</Text>
			</Stack>
		</Stack>
	)
}

export default CategoryButton
