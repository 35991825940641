import { Image } from "@chakra-ui/image"
import { Box, Flex, Heading, Text } from "@chakra-ui/layout"
import { Skeleton } from "@chakra-ui/react"
import { Tag } from "@chakra-ui/tag"
import React from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import { formatDateWithoutTime } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { LikeIcon, MessageIcon } from "../Icons"
import Count from "./Count"
import ReactionCountBox from "./ReactionCountBox"

const Card = ({ data }) => {
	const navigate = useNavigate()
	const config = useOutletContext()

	return (
		<Box
			w="100%"
			// maxH={["430px", "577px"]}
			rounded="2xl"
			bg="mc_bg"
			boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
			p="10px 10px 6px 10px"
			mx="auto"
		>
			<Box rounded={["lg", "2xl"]}>
				<Skeleton isLoaded={!!config.baseImageUrl && !!data.image}>
					<Image
						src={`${config.baseImageUrl}${data.image}`}
						display="block"
						rounded={["lg", "2xl"]}
						w="100%"
						h={["160px", "217px", "274px", "400px"]}
						objectFit="contain"
						mx="auto"
						cursor={"pointer"}
						onClick={() => navigate(`/news/${data.unique_url}`)}
					/>
				</Skeleton>
			</Box>
			<Heading
				as="h2"
				color="mc_dark"
				maxW={["330px", "700px"]}
				fontSize="18px"
				textAlign="left"
				my="10px"
				cursor={"pointer"}
				noOfLines={2}
				onClick={() => navigate(`/news/${data.unique_url}`)}
			>
				{data.title}
			</Heading>
			<Flex direction="column">
				<Text fontSize="15px" textAlign="left" display="inline" h="40px" maxW={["330px", "700px"]} overflow="hidden" noOfLines={2}>
					{data.summary_text}
				</Text>
				<Text fontSize="14px" onClick={() => navigate(`/news/${data.unique_url}`)} color="mc_medium" cursor="pointer" mx="auto">
					{strings.showMore}
				</Text>
			</Flex>
			<Flex mt="8px" justify="space-between" h="47px">
				<Flex gridColumnGap="10px" pt="10px">
					<ReactionCountBox>
						<LikeIcon fill={data.reacted ? null : "mc_bg"} />
						<Count count={data.reactions_count} />
					</ReactionCountBox>

					<ReactionCountBox>
						<MessageIcon fill="mc_bg" />
						<Count count={data.comments_count} />
					</ReactionCountBox>
				</Flex>
				<Flex align="flex-end">
					<Flex align="flex-end" justify="space-between" direction="column" h="40px">
						{data.category_name && (
							<Tag size="sm" bg="mc_medium" color="mc_bg" fontSize={["12px", "13px"]}>
								{data.category_name}
							</Tag>
						)}
						<Box color="mc_medium" fontSize="12px">
							{formatDateWithoutTime(data.date_created)}
						</Box>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	)
}

export default Card
