import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { useDisclosure } from "@chakra-ui/hooks"
import { Box, Flex, Stack } from "@chakra-ui/layout"
import { Checkbox } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import useEducation from "../../redux/hooks/useEducation"
import useForms from "../../redux/hooks/useForms"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { fixTimezoneOffset, fixTimezoneOffsetDate, handleToast, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedPermissionsPayload, SmartedFields } from "../../utils/smartedHelper"
import { DatePickerWrapper } from "../DatePickerComponent"
import { SearchIcon } from "../Icons"
import CustomTextInput from "../inputs/CustomTextInput"
import ImageModal from "../modals/ImageModal"
import RichTextEditor from "../RichTextEditor"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const UpdateEduForm = ({ updateData, selectData, handleClose }) => {
	const hook = useEducation()
	const admin = useUser().admin
	const toast = useToast()
	const hookForms = useForms()
	const config = useConfig()

	const { isOpen, onOpen, onClose } = useDisclosure()

	const [currentId, setCurrentId] = useState(0)
	const [img, setImg] = useState("")
	const [images, setImages] = useState([])
	const [expiryDate, setExpiryDate] = useState(null)
	const [selectedFile, setSelectedFile] = useState(null)

	useEffect(() => {
		if (updateData.expiration_date) {
			const date = fixTimezoneOffsetDate(new Date(updateData.expiration_date))
			setExpiryDate(date)
		}

		hook.fetchOne(updateData.id).then((res) => {
			if (res !== undefined && res.status === 200) {
				const slides = []
				res.data.slides.map((slide, i) => {
					//eslint-disable-line
					slides.push({
						id: slide.id,
						serial_num: i,
						text: slide.text,
						image: slide.image,
					})
				})
				if (res.data.slides.length > 0) {
					hookForms.setSlides(slides)
				}
			}
		})
	}, []) //eslint-disable-line

	const { handleSubmit, control, reset, register, watch, setValue } = useForm({})

	const handleNext = () => {
		const currentSlide = hookForms.eduForms.find((ele, i) => i === currentId)
		if (!currentSlide.text) {
			handleToast(toast, { text: strings.requiredTextOrImageMessage, type: "error" }, "1")
		} else {
			if (currentId + 1 === hookForms.eduForms.length) {
				hookForms.addSlide(currentId + 1)
			}
			setCurrentId(currentId + 1)
		}
	}

	const handleSearch = (data) => {
		setImg(data)
		onOpen()
	}

	const handleExit = () => {
		hookForms.cleanSlides()
		handleClose()
		hook.cleanDetail()
		reset()
	}

	const handleDeleteSlide = () => {
		if (currentId === 0 && hookForms.eduForms.length === 1) {
			handleToast(toast, { text: strings.cantDeleteFirst, type: "error" }, "11")
		} else {
			setImages(images.filter((item, i) => i !== currentId))
			hookForms.deleteSlide(currentId)
			if (currentId !== 0) {
				setCurrentId(currentId - 1)
			}
		}
	}

	const handleImage = (file, index) => {
		let newImages = [...images]
		if (newImages.length > 0) {
			newImages.map((data, i) => {
				//eslint-disable-line
				if (index === i) {
					newImages = newImages.filter((item, i) => i !== index)
				}
			})
		}

		if (currentId === 0) {
			newImages.unshift({ question: index, data: file })
		} else {
			newImages.push({ question: index, data: file })
		}
		setImages(newImages)
	}

	const checkImage = (serialNum) => {
		if (images.find((image, i) => image.question === serialNum) !== undefined) {
			return images.find((image, i) => image.question === serialNum).data
		} else {
			return null
		}
	}

	const submit = (data) => {
		var formData = new FormData()

		const contentType = data.content_type.value

		const newSlides = hookForms.eduForms.filter((e, i) => !e.id)

		if (contentType === 1 && newSlides.length > images.length) {
			handleToast(toast, { text: strings.imageMissing, type: "error" }, "1")
		} else {
			const slides = []
			const serial_nums = []

			hookForms.eduForms.map((slide, i) => {
				const oneSlide = {}
				oneSlide["serial_num"] = i + 1
				if (slide.id) {
					oneSlide["id"] = slide.id
				}
				if (images.length > 0) {
					oneSlide["image"] = checkImage(i + 1)
				} else {
					oneSlide["image"] = ""
				}
				oneSlide["text"] = slide.text

				return slides.push(oneSlide)
			})

			slides.forEach((slide) => {
				if (slide.image) {
					formData.append("images[]", slide.image, slide.image.name)
					serial_nums.push(slide.serial_num)
				}
			})

			formData.append("slides", JSON.stringify(slides, ["id", "text", "serial_num"]))

			formData.append("serials[]", JSON.stringify(serial_nums))

			let contentChecker = ""

			let contentTypes = ["content_pdf", "content_image", "content_video"]

			contentTypes.forEach((c) => {
				if (c in data) {
					contentChecker = c
				}
			})

			if (!data.name || !data.app || !data.category || !data.status) {
				handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "3")
			} else {
				let education = {}
				education["id"] = updateData.id
				education["name"] = data.name
				education["app"] = data.app.value
				education["category"] = data.category.value
				education["status"] = data.status.value
				education["content_type"] = contentType

				if (contentChecker && data[contentChecker]) {
					formData.append(contentChecker, data[contentChecker], data[contentChecker].name)
				}

				if (expiryDate) {
					education["expiration_date"] = fixTimezoneOffset(expiryDate)
				} else {
					education["expiration_date"] = ""
				}

				if (data.new_employee_content) {
					education["new_employee_content"] = "True"
				} else {
					education["new_employee_content"] = "False"
				}

				if (config.appId === 2) {
					handleSmartedPermissionsPayload(data, education)
				}

				if (data.companies && data.companies.length > 0) {
					education["companies"] = makeIdsArray(data.companies)
				} else {
					education["companies"] = []
				}

				formData.append("education", JSON.stringify(education))

				if (selectedFile) {
					formData.append("users_file", selectedFile, selectedFile.name)
				}

				hook.update(formData).then((res) => {
					if (res !== undefined && res.status === 200) {
						handleExit()
						hook.handleRefresh()
					}
				})
			}
		}
	}

	const currentContentType = watch("content_type")

	const checkFileName = () => {
		if (currentContentType) {
			if (currentContentType.value === 2) {
				return "content_video"
			} else if (currentContentType.value === 3) {
				return "content_image"
			} else if (currentContentType.value === 4) {
				return "content_pdf"
			}
		} else {
			return "content_test"
		}
	}

	const handleFile = (data) => {
		setValue(checkFileName(), data)
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={6}>
				<Flex alignItems="flex-end" gridGap="20px" direction={["column", "row"]}>
					<FormControl w={["100%", "50%"]}>
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.name} *
						</FormLabel>
						<Controller as={<CustomTextInput />} control={control} name="name" defaultValue={updateData && updateData.name} />
					</FormControl>
					<FormControl w={["100%", "50%"]} minW="200px">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.category} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="category"
							options={selectData.categories}
							defaultValue={updateData && updateData.defaultCategory}
						/>
					</FormControl>
				</Flex>
				<Flex gridGap="20px" w="100%" flexWrap="wrap" display={admin ? "flex" : "none"}>
					<FormControl w={["100%", "48%", "20%"]} minW="220px">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.application} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="app"
							options={selectData.apps}
							defaultValue={updateData && updateData.defaultApp}
						/>
					</FormControl>
					<FormControl w={["100%", "40%"]} minW="350px">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.companies}
						</FormLabel>
						<Controller
							as={<SelectDropdown isMulti />}
							control={control}
							name="companies"
							options={selectData.companies}
							defaultValue={updateData && updateData.defaultCompanies}
						/>
					</FormControl>
				</Flex>
				<Flex gridGap="20px" w="100%">
					<FormControl w={["100%", "48%", "20%"]} minW="220px">
						<FormLabel color="mc_dark" textTransform="uppercase" fontSize="14px">
							{strings.status} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="status"
							defaultValue={updateData && updateData.defaultStatus}
							options={selectData.statuses}
						/>
					</FormControl>
					<FormControl w={"20%"} minW="328px">
						<FormLabel color="mc_dark" textTransform="uppercase" fontSize="14px">
							{strings.expirationDate}
						</FormLabel>
						<DatePickerWrapper
							selected={expiryDate}
							showPopperArrow={true}
							timeFormat="HH:mm"
							onChange={(date) => setExpiryDate(date)}
							dateFormat="dd-MM-yyyy HH:mm"
							showTimeSelect
						/>
					</FormControl>
					<Flex align="center" minW="160px">
						<Checkbox
							size="lg"
							alignItems="center"
							color="mc_dark"
							fontSize="md"
							ref={register}
							name="new_employee_content"
							defaultChecked={updateData.new_employee_content}
						>
							{strings.newEmployees}
						</Checkbox>
					</Flex>
				</Flex>

				{config.appId === 2 && (
					<SmartedFields
						control={control}
						selectData={selectData}
						updateData={updateData}
						setSelectedFile={setSelectedFile}
						fileImport={true}
					/>
				)}

				<Flex justifyItems={"center"} w={"30%"} minW="328px">
					<Box w="100%">
						<FormLabel color="mc_dark" textTransform="uppercase" fontSize="14px">
							{"CONTENT TYPE"} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="content_type"
							options={selectData.contentTypes}
							defaultValue={updateData.defaultContentType}
						/>
					</Box>
					<Box>
						{currentContentType && currentContentType.value === 2 ? (
							<Controller as={<UploadFile />} control={control} name={"content_video"} onChange={handleFile} />
						) : currentContentType && currentContentType.value === 3 ? (
							<Controller as={<UploadFile />} control={control} name={"content_image"} onChange={handleFile} />
						) : currentContentType && currentContentType.value === 4 ? (
							<Controller as={<UploadFile />} control={control} name={"content_pdf"} onChange={handleFile} />
						) : (
							<></>
						)}
					</Box>
				</Flex>
				<>
					<Stack direction="row" justify="center" flexWrap="wrap">
						{hookForms.eduForms.map((slide, i) =>
							currentId === i ? (
								<Flex
									w="18px"
									h="16px"
									bg="mc_dark"
									rounded="2"
									fontSize="11px"
									color="mc_bg"
									justify="center"
									align="center"
									pt="4px"
									mb={1}
								>
									{i + 1}
								</Flex>
							) : (
								<Flex
									w="18px"
									h="16px"
									border="1px solid"
									borderColor="mc_medium"
									rounded="2"
									fontSize="11px"
									color="mc_dark"
									justify="center"
									align="center"
									pt="4px"
									mb={1}
								>
									{i + 1}
								</Flex>
							)
						)}
					</Stack>
					<Box w="100%">
						{hookForms.eduForms.map((item, index) => (
							<Flex
								w="100%"
								gridGap="20px"
								direction={["column", "column", "column", "column", "row"]}
								display={currentId === index ? "flex" : "none"}
							>
								<FormControl w="100%">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.slideText} *
									</FormLabel>

									<Box rounded="xl" boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)">
										<RichTextEditor
											onChange={(data) => hookForms.updateSlideText(index, data)}
											setContent={item.text}
										/>
									</Box>
								</FormControl>
								<Box>
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.image}
									</FormLabel>
									<Flex gridGap="10px">
										<UploadFile onChange={(val) => handleImage(val, index + 1)} />
										{item.image && (
											<SearchIcon
												fill="mc_medium"
												w="22px"
												h="22px"
												cursor="pointer"
												onClick={() => handleSearch(item.image)}
											/>
										)}
									</Flex>
								</Box>
							</Flex>
						))}
					</Box>
				</>
				<Stack spacing={[4, 4, 0]}>
					<Flex gridGap="20px" justify="center" w="100%">
						<Button
							display={currentId === 0 ? "none" : "inline-block"}
							variant="dark"
							onClick={() => setCurrentId(currentId - 1)}
						>
							{strings.back}
						</Button>
						<Button variant="red" textTransform="uppercase" onClick={handleDeleteSlide}>
							{strings.deleteSlide}
						</Button>
						<Button variant="dark" onClick={() => handleNext()}>
							{strings.forward}
						</Button>
					</Flex>

					<Flex w="100%" justify="space-between">
						<Button variant="red" onClick={() => handleExit()}>
							{strings.close}
						</Button>

						<Button variant="medium" type="submit">
							{strings.save}
						</Button>
					</Flex>
				</Stack>
			</Stack>
			<ImageModal data={img} isOpen={isOpen} onClose={onClose} size="4xl" />
		</form>
	)
}

export default UpdateEduForm
