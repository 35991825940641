import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { TablesStyles } from "./TablesStyles"
import DeleteModal from "../modals/DeleteModal"
import UpdateButton from "../buttons/UpdateButton"
import { strings } from "../../utils/localization"
import { columnTableString, formatDateForCell, handleToast, makeDropdownOptionsValueField, makeIdsArray } from "../../utils/functions"
import { useDisclosure } from "@chakra-ui/hooks"
import useBillboard from "../../redux/hooks/useBillboard"
import AddUpdateModal from "../modals/AddUpdateModal"
import AddJobApplication from "../forms/AddJobApplication"
import { toast } from "@chakra-ui/react"

const BoardTable = ({ data, selectData }) => {

	const hook = useBillboard()

	const [updateData, setUpdateData] = useState(null)

	const { isOpen, onOpen, onClose } = useDisclosure()

    const columns = [
        {
            dataField: "title",
            text: strings.title,
        },
        {
            dataField: "city_name",
            text: strings.city,
        },
        {
            dataField: "company_name",
            text: strings.company,
        },
        {
            dataField: "application_deadline",
            text: strings.deadline,
            formatter: (cell) => formatDateForCell(cell)
        },
        {
            dataField: "summary_text",
            text: strings.summaryText,
        },
        {
            dataField: "tags",
            text: strings.tags,
            formatter: (cell) => columnTableString(cell)
        },
        {
            dataField: "app_name",
            text: strings.application,
        },
        
    ]

	const handleUpdateModal = (row) => {
	
        const defaultValues = {
            id: row.id,
            defaultApp: selectData.apps.find((e) => e.value === row.app),
            defaultContent: row.description,
            summaryText: row.summary_text,
            title: row.title,
			defaultTags: makeDropdownOptionsValueField(row.tags),
			defaultCompany: selectData.companies.find((e) => e.value === row.company),
			defaultCity: selectData.cities.find((e) => e.value === row.city),
			date: row.application_deadline
        }

        setUpdateData(defaultValues)

        onOpen()
    }

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <DeleteModal />
                <UpdateButton onClick={() => handleUpdateModal(row)} />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }

	const submit = (data) => {

        if(!data.app || !data.city || !data.company || !data.description 
            || !data.summary_text || !data.title || !data.application_deadline) {
            handleToast(toast, { text: strings.allRequiredFieldsMessage, type: "error"}, "2")
        }else{
            const payload = {
                id: updateData.id,
                app: data.app.value,
                city: data.city.value,
                company: data.company.value,
                description: data.description,
                summary_text: data.summary_text,
                tags: makeIdsArray(data.tags),
                title: data.title,
                application_deadline: data.application_deadline
            }

            hook.update(payload).then((res) => {
                if (res !== undefined && res.status === 200) {
                    onClose()
                    hook.fetchPage(hook.defaultSize, 1)
                }
            })
        }
	}

    return (
        <>
			<TablesStyles>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>
			<AddUpdateModal isOpen={isOpen} onClose={onClose} size="6xl">
				<AddJobApplication 
					handleClose={onClose} 
					selectData={selectData}
					submit={submit}
					updateData={updateData}
				/>
            </AddUpdateModal>
		</>
    )
}

export default BoardTable
