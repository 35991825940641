import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import UserForm from "../../components/forms/UserForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import UsersTable from "../../components/tables/UsersTable"
import useUsers from "../../redux/hooks/useUsers"
import useMasterdata from "../../redux/hooks/useMasterdata"
import useGeneric from "../../redux/hooks/useGeneric"
import { handleToast, makeDropdownOptions } from "../../utils/functions"
import {
    application,
    city,
    company,
    country,
    language,
    position,
    title,
} from "../../utils/constants"
import { strings } from "../../utils/localization"
import { useDisclosure } from "@chakra-ui/hooks"
import { Input, InputGroup, InputLeftElement, InputRightElement, Icon, Box } from "@chakra-ui/react"
import SearchModal from "../../components/modals/SearchModal"
import { SearchIcon, RightArrow } from "../../components/Icons.js"
import { Button } from "@chakra-ui/button"
import Pagination from "../../components/tables/Pagination"

const AdminUsersTablePage = () => {
    const hook = useUsers()
    const hookMd = useMasterdata()
    const hookG = useGeneric()
    const toast = useToast()

    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        hook.cleanToast()
        hook.fetch(hook.defaultSize, 1)
        hookG.fetch(company)
        hookG.fetch(city)
        hookMd.fetch(application)
        hookMd.fetch(position)
        hookMd.fetch(title)
        hookMd.fetch(language)
        hookMd.fetch(country)
    }, []) //eslint-disable-line

    const [positions, setPositions] = useState(null)
    const [cities, setCities] = useState(null)
    const [companies, setCompanies] = useState(null)
    const [applications, setApplications] = useState(null)
    const [titles, setTitles] = useState(null)
    const [languages, setLanguages] = useState(null)
    const [countries, setCountries] = useState(null)
    const [searchInput, setSearchInput] = useState("")

    useEffect(() => {
        makeDropdownOptions(hookMd.positions, setPositions)
        makeDropdownOptions(hookMd.apps, setApplications)
        makeDropdownOptions(hookMd.titles, setTitles)
        makeDropdownOptions(hookMd.countries, setCountries)
        makeDropdownOptions(hookMd.languages, setLanguages)
        makeDropdownOptions(hookG.cities, setCities)
        makeDropdownOptions(hookG.companies, setCompanies)
    }, [
        hookMd.positions,
        hookMd.apps,
        hookMd.titles,
        hookMd.languages,
        hookMd.countries,
        hookG.cities,
        hookG.companies,
    ])

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) // eslint-disable-line

    const selectData = {
        apps: applications,
        companies: companies,
        cities: cities,
        positions: positions,
        titles: titles,
        languages: languages,
        countries: countries,
    }

    const handleSubmit = (data) => {
        if (!(data.password === data.repeated_password)) {
            handleToast(toast, { type: "error", text: strings.passwordDontMatchMessage }, "2")
        } else if (
            !data.email ||
            !data.username ||
            !data.password ||
            !data.repeated_password ||
            !data.app ||
            !data.first_name ||
            !data.last_name
        ) {
            handleToast(toast, { type: "error", text: strings.requiredFieldsMessage }, "3")
        } else {
            const payload = {}

            Object.keys(data).map((key, i) =>
                data[key] && typeof data[key] === "object"
                    ? (payload[`${key}`] = data[key].value)
                    : data[key]
                    ? (payload[`${key}`] = data[key])
                    : ""
            )

            if (!data.is_supervisor) {
                payload["is_supervisor"] = false
            }

            if (!data.is_active) {
                payload["is_active"] = false
            }

            if (!data.new_employee) {
                payload["new_employee"] = false
            }

            hook.create(payload)
        }
    }

    const handleSearch = () => {
        hook.fetch(hook.defaultSize, 1, searchInput)
    }

    const pagination = hook.pagination

    return (
        <>
            <Box
                h="95%"
                overflow="auto"
                border={"4px"}
                boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
                borderColor="mc_black"
                borderRadius="4px"
                w="100%"
            >
                <UsersTable data={hook.data} selectData={selectData} onOpen={onOpen} />
                <PlusButton onClick={() => hook.onOpen()} />
                <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="6xl">
                    <UserForm
                        selectData={selectData}
                        submit={handleSubmit}
                        onClose={hook.onClose}
                    />
                </AddUpdateModal>
                <SearchModal isOpen={isOpen} onClose={onClose} size="xl" fetch={handleSearch}>
                    <InputGroup>
                        <InputLeftElement children={<Icon as={SearchIcon} fill="mc_medium" />} />
                        <Input
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
                        />
                        <InputRightElement
                            children={
                                <Button w="60px" variant="medium" onClick={() => handleSearch()}>
                                    <RightArrow fill="#fff" />
                                </Button>
                            }
                        />
                    </InputGroup>
                </SearchModal>
            </Box>
            {hook.pagination && (
                <Box w="80%" mx="auto">
                    <Pagination
                        paginationData={{
                            totalCount: pagination.total_count,
                            totalPages: pagination.total_pages,
                            currentPage: pagination.current_page,
                            pageSize: hook.defaultSize,
                            pageSizesArray: [10, 25, 50],
                        }}
                        setSize={hook.changeSize}
                        fetchPage={hook.fetch}
                    ></Pagination>
                </Box>
            )}
        </>
    )
}

export default AdminUsersTablePage
