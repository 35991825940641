import { Button } from "@chakra-ui/button"
import { Input } from "@chakra-ui/input"
import { Box, Flex, ListItem, OrderedList, Stack, Text } from "@chakra-ui/layout"
import { useEffect } from "react"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { WhiteArrowIcon } from "../Icons"

const Survey = ({ questions, submit }) => {
	const { handleSubmit, control, setValue } = useForm({})

	const { fields } = useFieldArray({
		control,
		name: "surveyQuestions",
	})

	useEffect(() => {
		if (questions) {
			const newArray = []
			Object.keys(questions).map((key, i) => newArray.push({ inputValue: "", question: questions[key] }))
			setValue("surveyQuestions", newArray)
		}
	}, [questions]) //eslint-disable-line

	return (
		<form onSubmit={handleSubmit(submit)}>
			<OrderedList>
				<Stack spacing={4} p="10px">
					{fields.map((element, i) => (
						<Box>
							<Flex alignItems="center" m="10px" gridGap="10px">
								<ListItem color="mc_bg" />
								<Text color="mc_bg" fontSize="16px" letterSpacing="4px" fontWeight="700">
									{element.question}
								</Text>
							</Flex>
							<Controller
								as={
									<Input
										placeholder="Odgovor..."
										size="lg"
										bg="mc_bg"
										_placeholder={{
											color: "mc_medium",
										}}
									/>
								}
								name={`${i + 1}`}
								control={control}
							/>
						</Box>
					))}
					<Button
						rightIcon={<WhiteArrowIcon fill="mc_bg" />}
						alignSelf="center"
						w="160px"
						h="50px"
						variant="medium"
						mx="auto"
						textTransform="uppercase"
						type="submit"
					>
						Potvrdi
					</Button>
				</Stack>
			</OrderedList>
		</form>
	)
}

export default Survey
