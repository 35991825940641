import { Box, Img, Stack } from "@chakra-ui/react"

import { useOutletContext } from "react-router-dom"

const SlideContent = ({ display, heading, imgUrl }) => {
	const config = useOutletContext()

	return (
		<Stack spacing={2} display={display}>
			<Box m={["4px", "20px"]} maxH={["500px", "500px", "500px", "606px"]}>
				<Img
					w="100%"
					h="100%"
					maxH={["500px", "500px", "500px", "606px"]}
					borderRadius="3xl"
					src={`${config.baseImageUrl}${imgUrl}`}
					objectFit="contain"
				/>
			</Box>
			<Box
				fontSize={["18px", "22px"]}
				textAlign="left"
				px="20px"
				dangerouslySetInnerHTML={{
					__html: heading,
				}}
			/>
		</Stack>
	)
}

export default SlideContent
