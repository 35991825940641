export const english = () => {
	return {
		showMore: "Show more",
		edit: "edit",
		educationAuthorIs: "This education was made by the company:",
		startTest: "start test",
		didTest: "You did a test",
		maxTimes: "maximum number of times",
		endTest: "COMPLETE THE TEST",
		requiredFieldsMessage: "Fields marked with * are required!",
		allRequiredFieldsMessage: "All fields are required!",
		requiredTextOrImageMessage: "You have not entered any text or image!",
		requiredTextMessage: "You have not entered any text!",
		requiredTextOfAllAnswersMessage: "Fill in the text of all answers!",
		oneCorrectAnswerMessage: "Only one answer can be correct!",
		atLeastOneCorrectAnswerMessage: "At least one answer must be correct!",
		requiredImageMessage: "The image must be selected!",
		requiredQuestionTextMessage: "Enter the text of the question!",
		requiredEducationSlideNumberMessage: "Enter the number of the education slide!",
		requiredAllQuestions: "Fill in all the questions!",
		passwordDontMatchMessage: "Passwords do not match!",
		noDataSelectedMessage: "Data not selected!",
		noReportSelectedMessage: "Report not selected!",
		noTestSelectedMessage: "Test not selected!",
		noTypeSelectedMessage: "Type not selected!",
		noArticleSelected: "News not selected!",
		noSurveySelected: "Survey not selected!",
		noJobSelected: "Job not selected!",
		notEnoughReportDataMessage: "There is not enough data for the selected report",
		enterCommentMessage: "Enter a comment!",
		leaveComment: "LEAVE A COMMENT",
		report: "REPORT",
		test: "TEST",
		type: "TYPE",
		from: "FROM",
		to: "TO",
		previous: "Previous",
		next: "Next",
		page: "Page",
		of: "of",
		generate: "GENERATE",
		choosePlaceholder: "Choose...",
		name: "NAME",
		title: "TITLE",
		education: "Education",
		category: "CATEGORY",
		application: "APPLICATION",
		company: "COMPANY",
		survey: "SURVEY",
		country: "COUNTRY",
		city: "CITY",
		jobTitle: "TITLE",
		position: "POSITION",
		language: "LANGUAGE",
		phoneNumber: "PHONE NUMBER",
		licenceNumber: "LICENCE NUMBER",
		biography: "BIOGRAPHY",
		slideText: "SLIDE TEXT",
		image: "IMAGE",
		question: "QUESTION",
		questions: "QUESTIONS",
		educationSlide: "EDUCATION SLIDE",
		answers: "ANSWERS",
		summaryText: "SUMMARY TEXT",
		coverPhoto: "COVER PHOTO",
		content: "CONTENT",
		status: "STATUS",
		close: "CLOSE",
		back: "BACK",
		forward: "FORWARD",
		save: "SAVE",
		password: "PASSWORD",
		repeatPassword: "REPEAT PASSWORD",
		newPassword: "NEW PASSWORD",
		newPasswordRepeat: "REPEAT NEW PASSWORD",
		code: "CODE",
		companyType: "COMPANY TYPE",
		firstName: "FIRST NAME",
		lastName: "LAST NAME",
		username: "USERNAME",
		email: "E-MAIL",
		supervisor: "SUPERVISOR",
		users: "USERS",
		reports: "REPORTS",
		importExport: "IMPORT/EXPORT",
		masterData: "MASTERDATA",
		countries: "COUNTRIES",
		jobTitles: "TITLES",
		positions: "POSITIONS",
		companyTypes: "COMPANY TYPES",
		cities: "CITIES",
		companies: "COMPANIES",
		educationCategories: "EDUCATION CATEGORIES",
		articleCategories: "NEWS CATEGORIES",
		languages: "LANGUAGES",
		article: "NEWS",
		articles: "NEWS",
		surveys: "SURVEYS",
		educations: "EDUCATIONS",
		tests: "TESTS",
		panel: "PANEL",
		signOut: "SIGN OUT",
		signIn: "SIGN IN",
		controlPanel: "CONTROL PANEL",
		profile: "DASHBOARD",
		cancel: "CANCEL",
		delete: "DELETE",
		deleteQuestion: "DELETE QUESTION",
		deleteWarningMessage: "If you delete this item, you will not be able to recover it!",
		yes: "YES",
		no: "NO",
		creationDate: "CREATION DATE",
		views: "VIEWS",
		reactions: "REACTIONS",
		comments: "COMMENTS",
		registrationTime: "REGISTRATION TIME",
		lastLogin: "LAST LOGIN",
		active: "ACTIVE",
		congratulations: "CONGRATULATIONS",
		completedTest: "YOU HAVE SUCCESSFULLY COMPLETED THE TEST",
		monthlyReport: "Monthly report",
		fileSelected: "File selected",
		chooseCsvFile: "Select .csv file",
		photo: "Photo",
		selectPhoto: "Select photo",
		noFileSelectedMessage: "File not selected!",
		exportData: "EXPORT DATA",
		export: "EXPORT",
		importData: "IMPORT DATA",
		import: "IMPORT",
		confirm: "CONFIRM",
		noComments: "No comments",
		loadMore: "LOAD MORE",
		author: "AUTHOR",
		notFoundMessage: "The page you are looking for was not found",
		updateData: "UPDATE DATA",
		privacyPolicy: "PRIVACY POLICY",
		result: "RESULT",
		serverErrorMessage: "Server error!",
		wrongCredentialsMessage: "No account with given credentials was found!",
		noAccount: "No account?",
		clickHere: "Click here",
		forgotPassword: "Forgot password?",
		backTo: "Back to",
		loginPage: "sign in page",
		emailAddress: "E-MAIL ADDRESS",
		passwordReset: "PASSWORD RESET",
		registration: "SIGN UP",
		noMore: "No more!",
		welcomeTo: "welcome to",
		byUser: "BY USER",
		byCity: "BY CITY",
		byQuestions: "BY QUESTIONS",
		loginCount: "Login count",
		articleViews: "News views",
		articleReactions: "News reactions",
		educationViews: "Education views",
		passedTests: "Passed tests",
		monthly: "MONTHLY",
		eduAll: "ALL",
		eduChoose: "CHOOSE",
		eduChooseCompleted: "CHOOSE COMPLETED",
		eduChooseNotCompleted: "CHOOSE NOT COMPLETED",
		noEduSelectedMessage: "Education not selected!",
		deleteSlide: "DELETE SLIDE",
		cantDeleteFirst: "You can't delete first slide!",
		agreePrivacyPolicy: "I agree to Privacy policy",
		articleAll: "ALL",
		articleByUserReports: "BY USER REPORTS",
		imageMissing: "Image is missing somewhere!",
		monthlyPoints: "POINTS FOR THIS MONTH:",
		annualPoints: "POINTS FOR THIS YEAR:",
		notePlaceholder: "Note...",
		wrongAppMessage: "You are trying to access wrong platform!",
		selectFile: "Select file",
		selectDocument: "Select document",
		searchPlaceholder: "Search...",
		repeatTest: "REPEAT THE TEST",
		usersUpdate: "USERS UPDATE",
		total: "TOTAL",
		track: "TRACK",
		loginData: "LOGIN DATA",
		checkEmailMessage: "Open email link to confirm registration (check spam)",
		subquestion: "SUBQUESTION",
		comment: "COMMENT",
		reject: "REJECT",
		approve: "APPROVE",
		tags: "TAGS",
		jobListings: "JOB LISTINGS",
		deadline: "DEADLINE",
		applicationDeadline: "Application deadline",
		apply: "apply",
		openFormToApply: "open form to apply",
		uploadYourCV: "upload YOUR CV",
		uploadFile: "Upload file",
		yourCVIsUploaded: "your CV is uploaded",
		applied: "APPLIED",
		jobs: "JOBS",
		job: "JOB",
		note: "note",
		newEmployees: "New employees",
		newEmployee: "NEW EMPLOYEE",
		updateEmploymentStatus: "Update employment status",
		banner: "banner",
		url: "url",
		expirationDate: "EXPIRATION DATE",
		expired: "EXPIRED",
		days: "DAYS",
		hours: "HOURS",
		useCustomTheme: "use custom theme",
		chooseTheColorOfTheApplication: "choose the color of the application",
		selectTheApplicationLogo: "select the application logo",
		selectTheApplicationFont: "select the application font",
		currentLogo: "current logo",
		font: "font",
		black: "black",
		background: "background",
		dark: "dark",
		medium: "medium",
		light: "light",
		red: "red",
		assignedToUsers: "ASSIGNED TO USERS",
		testAnswerQuestion: "Answer the question",
		testMaxRepeatNumberReached: "You have done the test the maximum number of times",
		UA_LOGIN_ACTIVITY: "Activity",
		UA_NEWS_VIEWS: "News",
		UA_NEWS_REACTIONS: "Reactions",
		UA_EDUCATION_VIEWS: "Educations",
		UA_TESTS: "Tests",
		UA_LOGIN_ACTIVITY_TP: "User activity in the previous 100 days",
		UA_NEWS_VIEWS_TP: "News seen in the previous 100 days",
		UA_NEWS_REACTIONS_TP: "Number of reactions in the previous 100 days",
		UA_EDUCATION_VIEWS_TP: "Successfully completed educations in the previous 100 days",
		UA_TESTS_TP: "Activity of passed tests in the previous 100 days",
		ACTIVITY: "Activity",
		NEWS: "News",
		EDUCATIONS: "Educations",
		TESTS: "Tests",
		achievements: "Achievements",
		ACTIVITY_DAYS: "days of consecutive login",
		EDUCATIONS_FINISHED: "completed educations",
		NEWS_LIKED: "liked news",
		NEWS_VIEWED: "viewed news",
		TESTS_SUCCESS_FINISHED: "passed tests with all correct answers",
		TESTS_FINISHED: "completed tests",
		numberOfAchievements: "Number of achievements",
		currentRank: "Current rank",
		numberOfUnread: "Number of unread",
		numberOfNotCompleted: "Number of not completed",
		documents: "Documents",
		document: "Document",
		download: "download",
		completedAllEdu: "You have completed all",
		readAllNews: "You have read all",
		forms: "Forms",
		month: "Month",
		year: "Year",
		absenceRequests: "ABSENCE REQUESTS",
		absenceRequestType: "ABSENCE TYPE",
		byAssignedUser: "BY ASSIGNED USER",
		bySubQuestions: "BY SUBQUESTIONS",
		lockedEduMessage:
			"Dear user, as long as you are a new employee, you must do trainings for new employees, in order to have access to other trainings.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Request for an administrative ban",
		FORM_PZDF: "Application for sharing flyers",
		contactPhoneNumber: "CONTACT PHONE NUMBER",
		FORM_TK: "Racing club",
		FORM_ZPOZ: "Request for employment certificates",
		bank: "BANK",
		certificationPurpose: "PURPOSE OF CERTIFICATION",
		documentType: "TYPE OF DOCUMENT",
		documentVerification: "DOCUMENT FOR VERIFICATION",
		loanStatement: "STATEMENT FOR INDEPENDENT LOAN REPAYMENT",
		FORM_ZZO: "Leave requests",
		leaveType: "TYPE OF LEAVE",
		dateFrom: "DATE FROM",
		dateTo: "DATE TO",
		absenceDocument: "DOCUMENT FOR ABSENCE",
		FORM_PK: "A winning combination",
		commentNote: "COMMENT/NOTE",
		recommendation: "RECOMMENDATION",
		FORM_IZ: "Employee initiatives",
		initiative: "INITIATIVE",
		choose: "Choose",
	}
}
