const paths = () => {
	const defaultPath = "/"
	const articles = "/news"
	const articlesDetail = articles + "/:id"
	const educations = "/educations"
	const educationsDetail = educations + "/:id"
	const tests = "/tests"
	const jobs = "/jobs"
	const profile = "/dashboard"
	const resetPassword = "/reset-password"
	const registration = "/registration"
	const confirmRegister = registration + "/confirm"
	const login = "/login"
	const privacyPolicy = "/privacy"
	const requestAccepted = "/request-accepted"
	const requestDenied = "/request-denied"

	const panel = "/panel"
	const panelUsers = panel + "/users"
	const panelExportImport = panel + "/export-import"
	const panelReports = panel + "/reports"
	const panelArticles = panel + articles
	const panelEducations = panel + educations
	const panelTests = panel + tests
	const panelJobs = panel + jobs
	const panelTitles = panel + "/titles"
	const panelPositions = panel + "/positions"
	const panelArticleCategory = panel + "/article-category"
	const panelTags = panel + "/tags"
	const panelEducationCategory = panel + "/education-category"
	const panelCompanyTypes = panel + "/company-types"
	const panelCountries = panel + "/countries"
	const panelCompanies = panel + "/companies"
	const panelCities = panel + "/cities"
	const panelBanners = panel + "/banners"
	const panelEmailNotification = panel + "/email-notifications"
	const panelSurveys = panel + "/surveys"
	const panelComments = panel + "/comments"
	const panelDocs = panel + "/docs"
	const panelAbsenceRequests = panel + "/absence-requests"

	return {
		defaultPath,
		articles,
		articlesDetail,
		educations,
		educationsDetail,
		tests,
		jobs,
		profile,
		resetPassword,
		registration,
		confirmRegister,
		panel,
		panelUsers,
		panelReports,
		panelJobs,
		panelArticles,
		panelEducations,
		panelTests,
		panelTitles,
		panelPositions,
		panelArticleCategory,
		panelTags,
		panelEducationCategory,
		panelCompanyTypes,
		panelCountries,
		panelCompanies,
		panelCities,
		panelBanners,
		panelEmailNotification,
		panelSurveys,
		panelComments,
		panelDocs,
		panelAbsenceRequests,
		login,
		panelExportImport,
		privacyPolicy,
		requestAccepted,
		requestDenied,
	}
}

export default paths
