import { ChakraProvider } from "@chakra-ui/react"
import axios from "axios"
import { Helmet } from "react-helmet"
import { useRoutes } from "react-router-dom"
import "./GlobalStyledComponents.js"
import { base_url, loginURL } from "./redux/api_urls"
import useApp from "./redux/hooks/useApp.js"
import useUser from "./redux/hooks/useUser.js"
import store from "./redux/store"
import paths from "./routing/paths.js"
import routes from "./routing/routes.js"
import useConfig from "./utils/config.js"
import { strings } from "./utils/localization.js"

const App = () => {
	const hookApp = useApp()
	const hookUser = useUser()
	const config = useConfig()

	if (hookUser.language) {
		strings.setLanguage(hookUser.language.code)
	}

	const appName = hookApp.name

	const favicon = document.getElementById("favicon")

	axios.defaults.baseURL = base_url

	axios.interceptors.request.use(function (config) {
		config.headers.Authorization = `Bearer ${store.getState().user.token}`

		config.headers["x-app-name"] = appName

		return config
	})

	favicon.href = config.favico

	axios.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			if (error.response !== undefined) {
				if (error.response.status === 401) {
					if (error.response.data.code === "token_not_valid") {
						hookUser.signout()
					} else if (error.response.config.url !== loginURL) {
						hookUser.refreshAccess()
					}
				}
			}

			return error
		}
	)

	const routing = useRoutes(routes(paths(), hookUser.token, hookUser.admin, hookUser.supervisor))

	return (
		<div className="App">
			<Helmet>
				<meta charSet="utf-8" />
				<title>{config.title}</title>
			</Helmet>
			{config.globalStyles}
			<ChakraProvider theme={config.appTheme}>{routing}</ChakraProvider>
		</div>
	)
}

export default App
