import { Button } from "@chakra-ui/button"
import { Box, Flex, Heading, Text } from "@chakra-ui/layout"
import { Tag } from "@chakra-ui/tag"
import React, { useState } from "react"
import { formatDateForCell } from "../../utils/functions"
import { strings } from "../../utils/localization"
import JobApplicationForm from "../forms/JobApplicationForm"
import { ClockIcon, LocationIcon } from "../Icons"
import AddUpdateModal from "../modals/AddUpdateModal"
import useBillboard from "../../redux/hooks/useBillboard"

const BoardModal = ({ size, isOpen, onClose }) => {
    const [openForm, setOpenForm] = useState(false)

    const hook = useBillboard()

    const handleClose = () => {
        setOpenForm(false)
        onClose()
    }

    // const checkCondition = (isRegistered, opened) => {
    //     if(isRegistered) {
    //         return false
    //     }else if(opened) {
    //         return false
    //     }else{
    //         return true
    //     }
    // }

    if (hook.detail) {
        const { id, title, application_deadline, company_name, city_name, tags, description, is_registered } = hook.detail
        return (
            <AddUpdateModal size={size} isOpen={isOpen} onClose={handleClose} closeButton>
                <Heading color="mc_dark" fontSize="30px" mb="4px" textTransform="uppercase">
                    {title}
                </Heading>
                <Flex gridColumnGap="16px" gridRowGap="8px" wrap="wrap">
                    <Flex gridGap="8px">
                        <LocationIcon />
                        <Text textTransform="uppercase" fontSize="14px" color="mc_black">
                            {`${company_name}, ${city_name}`}
                        </Text>
                    </Flex>
                    <Flex gridGap="8px">
                        <ClockIcon />
                        <Text fontSize="14px" color="mc_black">
                            {strings.applicationDeadline}:
                        </Text>
                        <Text fontSize="14px" color="mc_errorRed">
                            {formatDateForCell(application_deadline)}
                        </Text>
                    </Flex>
                </Flex>
                <Flex spacing={2} my="26px" flexWrap="wrap" gridGap="6px">
                    {tags &&
                        tags.map((tag, i) => {
                            return (
                                <Tag key={i} size="md" bg="mc_medium" fontSize="16px" color="mc_bg">
                                    {tag.value_field}
                                </Tag>
                            )
                        })}
                </Flex>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
                {!is_registered && !openForm && (
                    <Flex justify="center" mt="26px">
                        <Button
                            variant="medium"
                            textTransform="uppercase"
                            onClick={() => setOpenForm(true)}
                        >
                            {strings.openFormToApply}
                        </Button>
                    </Flex>
                    )
                }
                {
                    is_registered && (
                        <Flex justify="center" mt="26px">
                        <Button
                            variant="red"
                            textTransform="uppercase"
                            // onClick={() => setOpenForm(true)}
                        >
                            {strings.applied}
                        </Button>
                    </Flex> 
                    )
                }
                {openForm && (
                    <Box>
                        <JobApplicationForm id={id} isRegistered={is_registered} />
                    </Box>
                )}
            </AddUpdateModal>
        )
    } else {
        return <></>
    }
}

export default BoardModal
