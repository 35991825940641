import { Button } from "@chakra-ui/button";
import { PlusIcon } from "../Icons";

const AddItemButton = ({ onClick }) => {

	return (
		<Button
			bg="mc_bg"
			h="38px"
			w="14px"
			px="0"
			border="2px solid"
			borderColor="mc_medium"
			rounded="full"
			_active={{ outline: "none" }}
			mt={3}
			display="block"
			mx="auto"
			mb="10px"
			type="button"
			onClick={() => onClick()}
		>
			<PlusIcon fill="mc_medium" />
		</Button>
	)

}

export default AddItemButton;