import React from "react"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"
import persistStore from "redux-persist/es/persistStore"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import store from "./redux/store"
import reportWebVitals from "./reportWebVitals"

const persistor = persistStore(store)

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Router>
				<App />
			</Router>
		</PersistGate>
	</Provider>,
	document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
