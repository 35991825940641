import { Button } from "@chakra-ui/button"
import { Input } from "@chakra-ui/input"
import { Box, Grid, GridItem } from "@chakra-ui/layout"
import { Flex, Img, Link, Stack } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import styled from "styled-components"
import Card from "../components/article/Card"
import { SearchIcon } from "../components/Icons"
import SelectDropdown from "../components/SelectDropdown"
import useApp from "../redux/hooks/useApp"
import useArticle from "../redux/hooks/useArticle"
import useDataManagement from "../redux/hooks/useDataManagement"
import useGeneric from "../redux/hooks/useGeneric"
import { articleCategory } from "../utils/constants"
import { handleToast, makeDropdownOptions } from "../utils/functions"
import { strings } from "../utils/localization"

const ArticlesPage = () => {
	const hook = useArticle()
	const hookBanners = useDataManagement()
	const hookG = useGeneric()
	const appName = useApp().name
	const toast = useToast()
	const location = useLocation()

	const [categories, setCategories] = useState([])

	const [searchValue, setSearchValue] = useState("")
	const [selectedCategory, setSelectedCategory] = useState(null)

	useLayoutEffect(() => {
		hook.cleanData()
		let filterData = null
		if (location.search) {
			filterData = {
				viewed: true,
			}
		}
		hook.fetchPage(hook.defaultSize, 1, filterData)
		hookG.fetch(articleCategory)
	}, []) //eslint-disable-line

	useEffect(() => {
		hookBanners.getBanners()
	}, []) //eslint-disable-line

	useEffect(() => {
		if (hookG.articleCategories.length > 0) {
			makeDropdownOptions(hookG.articleCategories, setCategories)
		}
	}, [hookG.articleCategories]) // eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "1")
		}
	}, [hook.message]) // eslint-disable-line

	const handleSearch = (categoryVal, clearBox) => {
		hook.cleanData()
		const filterData = {}

		if (searchValue) {
			filterData["title"] = searchValue
		}

		if (location.search) {
			filterData["viewed"] = true
		}

		if (clearBox) {
			hook.fetchPage(hook.defaultSize, 1, filterData)
		} else if (selectedCategory) {
			filterData["category"] = selectedCategory.value
			hook.fetchPage(hook.defaultSize, 1, filterData)
		} else if (categoryVal) {
			filterData["category"] = categoryVal.value
			hook.fetchPage(hook.defaultSize, 1, filterData)
		} else {
			hook.fetchPage(hook.defaultSize, 1, filterData)
		}
	}

	const handleLoadMore = () => {
		const filterData = {}

		if (searchValue) {
			filterData["title"] = searchValue
		}

		if (selectedCategory) {
			filterData["category"] = selectedCategory.value
		}

		if (location.search) {
			filterData["viewed"] = true
		}

		hook.fetchPage(hook.defaultSize, parseInt(hook.pagination.current_page) + 1, filterData)
	}

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSearch()
		}
	}

	const handleCategoryChange = (val) => {
		if (val) {
			setSelectedCategory(val)
			handleSearch(val)
		} else {
			setSelectedCategory(null)
			handleSearch(null, true)
		}
	}

	const firstBanner = hookBanners.banners.find((val) => val.id === 1)
	const secondBanner = hookBanners.banners.find((val) => val.id === 2)
	const thirdBanner = hookBanners.banners.find((val) => val.id === 3)
	const fourthBanner = hookBanners.banners.find((val) => val.id === 4)

	return (
		<>
			<Flex
				w={["300px", "90%", "500px", "700px", "900px"]}
				direction={["column", "column", "column", "row"]}
				mx="auto"
				gridGap="20px"
				mb="30px"
				align="center"
			>
				<Input
					w={["100%", "400px", "500px", "50%"]}
					onChange={(e) => setSearchValue(e.target.value)}
					onKeyPress={handleKeyPress}
					placeholder="Search..."
					boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
					bg="mc_bg"
					rounded="xl"
					pr="60px"
					fontSize={["14px", "14px", "16px"]}
					_placeholder={{
						fontSize: ["14px", "14px", "16px"],
					}}
				/>
				<Flex w={["100%", "400px", "500px", "50%"]} gridGap="20px">
					<Box width={["100%", "400px", "100%"]}>
						<SelectDropdown
							options={categories}
							value={selectedCategory}
							onChange={(val) => handleCategoryChange(val)}
							placeholder={strings.category}
							smallPlaceholder
						/>
					</Box>

					<Button variant="dark" w="60px" rounded="md" onClick={() => handleSearch()}>
						<SearchIcon fill="mc_bg" />
					</Button>
				</Flex>
			</Flex>
			{hook.data.length > 0 && (
				<Grid
					templateColumns={["1fr", "1fr", "1fr", "1fr", "1fr", "1fr auto 1fr"]}
					gridAutoFlow="column"
					gridAutoRows="auto"
					// minH="100vh"
					w="100%"
					gap={["10px", "20px"]}
					mx="auto"
					bg="transparent"
				>
					<GridItem
						display={["none", "none", "none", "none", "none", "flex"]}
						w="100%"
						// minW="300px"
						justifyContent="center"
					>
						{appName === "PHARMANET" && (
							<BannerWrapper>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={firstBanner.image ? "1px solid" : null}
									borderColor={firstBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{firstBanner.image && (
										<Link
											href={firstBanner.url.length > 0 ? firstBanner.url : null}
											isExternal
											cursor={firstBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={firstBanner && firstBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={secondBanner.image ? "1px solid" : null}
									borderColor={secondBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{secondBanner.image && (
										<Link
											href={secondBanner.url.length > 0 ? secondBanner.url : null}
											isExternal
											cursor={secondBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={secondBanner && secondBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
							</BannerWrapper>
						)}
					</GridItem>

					<GridItem w={["100%", "100%", "100%", "900px"]} p={["4px", "20px"]} maxW="900px" mx="auto" minH="100vh">
						<Stack spacing={[6, 6, 8]}>
							{hook.data.map((item, i) => (
								<Card key={i} data={item} />
							))}
						</Stack>
					</GridItem>

					<GridItem
						display={["none", "none", "none", "none", "none", "flex"]}
						w="100%"
						// minW="300px"
						justifyContent="center"
					>
						{appName === "PHARMANET" && (
							<BannerWrapper>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={thirdBanner.image ? "1px solid" : null}
									borderColor={thirdBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{thirdBanner.image && (
										<Link
											href={thirdBanner.url.length > 0 ? thirdBanner.url : null}
											isExternal
											cursor={thirdBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={thirdBanner && thirdBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
								<Box
									h="42%"
									maxH="300px"
									w="100%"
									border={fourthBanner.image ? "1px solid" : null}
									borderColor={fourthBanner.image ? "mc_medium" : null}
									borderRadius="2px"
									overflow="hidden"
								>
									{fourthBanner.image && (
										<Link
											href={fourthBanner.url.length > 0 ? fourthBanner.url : null}
											isExternal={fourthBanner.url.length > 0 ? true : false}
											cursor={fourthBanner.url.length ? "pointer" : "default"}
										>
											<Img
												src={fourthBanner && fourthBanner.image}
												h="100%"
												w="100%"
												bg="mc_bg"
												transitionDuration="0.2s"
												_hover={{
													transform: "scale(1.15)",
												}}
											/>
										</Link>
									)}
								</Box>
							</BannerWrapper>
						)}
					</GridItem>
				</Grid>
			)}
			{hook.data.length > 0 && (
				<Flex w="100%" justify="center" align="center" h="110px">
					<Button type="button" w="160px" variant="dark" h="50px" textTransform="uppercase" onClick={() => handleLoadMore()}>
						{strings.loadMore}
					</Button>
				</Flex>
			)}
		</>
	)
}

const BannerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	grid-gap: 16%;
	position: -webkit-sticky;
	position: sticky;
	top: 24vh;
	min-height: 65vh;
	height: calc(80vh - 200px);
	width: 100%;
	max-width: 380px;
	padding: 0 15px;
`
export default ArticlesPage
