import { Flex } from "@chakra-ui/layout"
import { extendTheme, theme } from "@chakra-ui/react"
import "@fontsource/inter"
import "@fontsource/josefin-sans"
import "@fontsource/montserrat"
import "@fontsource/mulish"
import "@fontsource/nunito"
import "@fontsource/open-sans"
import "@fontsource/oswald"
import "@fontsource/raleway"
import "@fontsource/roboto-slab"
import "@fontsource/rubik"
import { createGlobalStyle } from "styled-components"
import { ReactComponent as DoctornetLogo } from "../assets/icons/doctornet-logo.svg"
import { ReactComponent as PharmanetLogo } from "../assets/icons/pharmanet-logo-dark.svg"
import { ReactComponent as SmartedLogo } from "../assets/icons/smarted-logo.svg"
import useApp from "../redux/hooks/useApp"
import useUser from "../redux/hooks/useUser"
import { doctornet, pharmanet, smarted } from "./appHelper"
import { buttonVariant, fontSizes, themeConfig } from "./themeHelper"

export default function useConfig() {
	const appName = useApp().name
	const userAppId = useApp().id
	const hookUser = useUser()

	const pharmaConfig = () => {
		const mcBlack = "#012C31"
		const mcBg = "#F5FEFF"
		const mcMedium = "#24B4BC"
		const mcDark = "#00707C"
		const mcError = "#ED7474"
		const mcLight = "#CBF2F4"
		const mcGray = "#959595"
		const mcDropdownOutline = "rgb(66, 153, 225)"

		const chakraRootColors = {
			mc_black: mcBlack,
			mc_bg: mcBg,
			mc_medium: mcMedium,
			mc_dark: mcDark,
			mc_errorRed: mcError,
			mc_light: mcLight,
			mc_gray: mcGray,
		}

		const styledRootColors = `{
			--mc_black: ${mcBlack};
			--mc_bg: ${mcBg};
			--mc_medium: ${mcMedium};
			--mc_dark: ${mcDark};
			--mc_errorRed: ${mcError};
			--mc_gray:${mcGray}
			--mc_dropdownOutline: ${mcDropdownOutline};
		}`

		let styledColors = chakraRootColors
		styledColors["dropdownOutline"] = mcDropdownOutline

		const GlobalStylesPharma = createGlobalStyle`
			*{
				margin: 0;
				padding:0;
				box-sizing: border-box;
			}
			:root ${styledRootColors}
		`

		const pharmaTheme = extendTheme({
			themeConfig,
			...theme,
			colors: chakraRootColors,
			fonts: {
				heading: "josefin sans, sans-serif",
				body: "josefin sans, sans-serif",
			},
			fontSizes: fontSizes,
			components: buttonVariant,
		})

		const appId = 1
		const favico = "/pharmanet-logo-dark.ico"
		const title = "Pharmanet"
		const appTheme = pharmaTheme
		const background = "url('/pharmanetBackground.png')"
		const globalStyles = <GlobalStylesPharma />
		const logo = <PharmanetLogo />
		const styledLogo = <PharmanetLogo style={{ width: "100px", height: "100px" }} />
		const colors = styledColors

		//policy
		const companyName = "PHARMA SOLUTIONS"
		const website = "https://pharmasolutions.rs/"
		const support = "office@pharmasolutions.rs"
		const companyInfo = "MB: 21585769, PIB: 111981535"
		const policyAppName = "Pharma Net"

		const baseImageUrl =
			process.env.NODE_ENV === "development"
				? "https://pharmanet-smarted-staging.s3.eu-central-1.amazonaws.com/"
				: "https://pharmanet-production.s3.eu-central-1.amazonaws.com/"

		return {
			appId,
			favico,
			title,
			appTheme,
			background,
			globalStyles,
			logo,
			styledLogo,
			companyName,
			website,
			support,
			companyInfo,
			policyAppName,
			baseImageUrl,
			colors,
		}
	}

	const smartedConfig = () => {
		const mcBlack = "#041829"
		const mcBg = "#F5FEFF"
		const mcMedium = "#4292DC"
		const mcDark = "#082D4F"
		const mcError = "#ED7474"
		const mcLight = "#CBDFED"
		const mcGray = "rgb(149, 149, 149)"
		const mcDropdownOutline = "rgb(66, 153, 225)"

		const chakraRootColors = {
			mc_black: mcBlack,
			mc_bg: mcBg,
			mc_medium: mcMedium,
			mc_dark: mcDark,
			mc_errorRed: mcError,
			mc_light: mcLight,
			mc_gray: mcGray,
		}

		const styledRootColors = `{
			--mc_black: ${mcBlack};
			--mc_bg: ${mcBg};
			--mc_medium: ${mcMedium};
			--mc_dark: ${mcDark};
			--mc_errorRed: ${mcError};
			--mc_gray:${mcGray}
			--mc_dropdownOutline: ${mcDropdownOutline};
		}`

		let styledColors = chakraRootColors
		styledColors["dropdownOutline"] = mcDropdownOutline

		const GlobalStylesSmarted = createGlobalStyle`
			*{
				margin: 0;
				padding:0;
				box-sizing: border-box;
			}
			:root ${styledRootColors}			
		`

		const smartedTheme = extendTheme({
			themeConfig,
			...theme,
			colors: chakraRootColors,
			fonts: {
				heading: "inter, sans-serif",
				body: "inter, sans-serif",
			},
			fontSizes: fontSizes,
			components: buttonVariant,
		})

		const appId = 2
		const favico = "/smarted-logo.ico"
		const title = "Smart-ed"
		const appTheme = smartedTheme
		const background = "url('/baseAppBackground.png')"
		const globalStyles = <GlobalStylesSmarted />
		const colors = styledColors

		const baseImageUrl =
			process.env.NODE_ENV === "development"
				? "https://pharmanet-smarted-staging.s3.eu-central-1.amazonaws.com/"
				: "https://smarted-production.s3.eu-central-1.amazonaws.com/"

		const logo = (
			<Flex w="100%" h="100%" align="center" justify={["center", "center", "flex-start"]}>
				<SmartedLogo />
			</Flex>
		)

		const styledLogo = <SmartedLogo style={{ width: "100px", height: "100px" }} />

		//policy
		const companyName = "PARTNER CONSULTING"
		const website = "https://smart-ed.rs/"
		const support = "podrska@smart-ed.rs"
		const companyInfo = "MB: 21714496, PIB: 112655570"
		const policyAppName = "Smart-ed"

		return {
			appId,
			favico,
			title,
			appTheme,
			background,
			globalStyles,
			logo,
			styledLogo,
			companyName,
			website,
			support,
			companyInfo,
			policyAppName,
			baseImageUrl,
			colors,
		}
	}

	const doctorConfig = () => {
		const mcBlack = "#181818"
		const mcBg = "#EFFFFC"
		const mcMedium = "#06AB8F"
		const mcDark = "#065547"
		const mcError = "#ED7474"
		const mcLight = "#B8DDD6"
		const mcDropdownOutline = "rgb(66, 153, 225)"

		const chakraRootColors = {
			mc_black: mcBlack,
			mc_bg: mcBg,
			mc_medium: mcMedium,
			mc_dark: mcDark,
			mc_errorRed: mcError,
			mc_light: mcLight,
		}

		const styledRootColors = `{
			--mc_black: ${mcBlack};
			--mc_bg: ${mcBg};
			--mc_medium: ${mcMedium};
			--mc_dark: ${mcDark};
			--mc_errorRed: ${mcError};
			--mc_dropdownOutline: ${mcDropdownOutline};
		}`

		let styledColors = chakraRootColors
		styledColors["dropdownOutline"] = mcDropdownOutline

		const GlobalStylesDoctornet = createGlobalStyle`

			*{
				margin: 0;
				padding:0;
				box-sizing: border-box;
			}
			:root ${styledRootColors}
		`

		const doctornetTheme = extendTheme({
			themeConfig,
			...theme,
			colors: chakraRootColors,
			fonts: {
				heading: "josefin sans, sans-serif",
				body: "josefin sans, sans-serif",
			},
			fontSizes: fontSizes,
			breakpoints: ["30em", "48em", "62em", "80em", "96em"],
			components: buttonVariant,
		})

		const appId = 3
		const favico = "/doctornet-logo.ico"
		const title = "Doctornet"
		const appTheme = doctornetTheme
		const background = "url('/doctornetBackground.png')"
		const globalStyles = <GlobalStylesDoctornet />
		const logo = <DoctornetLogo />
		const styledLogo = <DoctornetLogo style={{ width: "100px", height: "100px" }} />
		const colors = styledColors

		//policy
		const companyName = "PHARMA SOLUTIONS"
		const website = "https://pharmasolutions.rs/"
		const support = "office@pharmasolutions.rs"
		const companyInfo = "MB: 21585769, PIB: 111981535"
		const policyAppName = "Doctornet"

		const baseImageUrl =
			process.env.NODE_ENV === "development"
				? "https://pharmanet-smarted-staging.s3.eu-central-1.amazonaws.com/"
				: "https://smarted-production.s3.eu-central-1.amazonaws.com/"

		return {
			appId,
			favico,
			title,
			appTheme,
			background,
			globalStyles,
			logo,
			styledLogo,
			companyName,
			website,
			support,
			companyInfo,
			policyAppName,
			baseImageUrl,
			colors,
		}
	}

	const customConfig = (company) => {
		const mcBlack = company.master_color_black
		const mcBg = company.master_color_background
		const mcMedium = company.master_color_normal
		const mcDark = company.master_color_dark
		const mcError = company.master_color_error
		const mcLight = company.master_color_light
		const mcDropdownOutline = "rgb(66, 153, 225)"

		const chakraRootColors = {
			mc_black: mcBlack,
			mc_bg: mcBg,
			mc_medium: mcMedium,
			mc_dark: mcDark,
			mc_errorRed: mcError,
			mc_light: mcLight,
		}

		const styledRootColors = `{
			--mc_black: ${mcBlack};
			--mc_bg: ${mcBg};
			--mc_medium: ${mcMedium};
			--mc_dark: ${mcDark};
			--mc_errorRed: ${mcError};
			--mc_dropdownOutline: ${mcDropdownOutline};
		}`

		let styledColors = chakraRootColors
		styledColors["dropdownOutline"] = mcDropdownOutline

		const GlobalStylesCustom = createGlobalStyle`
			*{
				margin: 0;
				padding:0;
				box-sizing: border-box;
			}
			:root ${styledRootColors}
		`

		const font = `${company.font}, sans-serif`

		const customTheme = extendTheme({
			themeConfig,
			...theme,
			colors: chakraRootColors,
			fonts: {
				heading: font,
				body: font,
			},
			fontSizes: fontSizes,
			breakpoints: ["30em", "48em", "62em", "80em", "96em"],
			components: buttonVariant,
		})

		let defaultApp = null
		if (userAppId === 1) {
			defaultApp = pharmaConfig()
		} else if (userAppId === 2) {
			defaultApp = smartedConfig()
		} else if (userAppId === 3) {
			defaultApp = doctorConfig()
		}

		const appId = defaultApp.appId
		const favico = defaultApp.favico
		const title = defaultApp.title

		const appTheme = customTheme
		const background = defaultApp.background
		const globalStyles = <GlobalStylesCustom />
		const logo = defaultApp.logo
		const styledLogo = defaultApp.styledLogo
		const colors = styledColors

		//policy
		const companyName = defaultApp.companyName
		const website = defaultApp.website
		const support = defaultApp.support
		const companyInfo = defaultApp.companyInfo
		const policyAppName = defaultApp.policyAppName

		const baseImageUrl =
			process.env.NODE_ENV === "development"
				? "https://pharmanet-smarted-staging.s3.eu-central-1.amazonaws.com/"
				: "https://smarted-production.s3.eu-central-1.amazonaws.com/"

		return {
			appId,
			favico,
			title,
			appTheme,
			background,
			globalStyles,
			logo,
			styledLogo,
			companyName,
			website,
			support,
			companyInfo,
			policyAppName,
			baseImageUrl,
			colors,
		}
	}

	if (hookUser.company && hookUser.company.use_custom_theme) {
		return customConfig(hookUser.company)
	} else if (appName === smarted) {
		return smartedConfig()
	} else if (appName === pharmanet) {
		return pharmaConfig()
	} else if (appName === doctornet) {
		return doctorConfig()
	}
}
