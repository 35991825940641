import { Button } from "@chakra-ui/button"
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-icon.svg"

const PlusButton = ({ onClick }) => {
    return (
        <Button
            variant="medium"
            w="44px"
            h="44px"
            position="absolute"
            bottom="5"
            right="5"
            onClick={() => onClick()}
        >
            <PlusIcon style={{ width: "22px", height: "22px" }} />
        </Button>
    )
}

export default PlusButton
