import { Box } from "@chakra-ui/layout"
import { Outlet } from "react-router-dom"
import useConfig from "../../utils/config"

const UnauthorizedLayout = () => {
	const config = useConfig()

	return (
		<Box w="100%" minH="100vh" pb="40px" bgImg={config.background} backgroundSize="102vw" bgPos="top center" bgRepeat="repeat-y">
			<Outlet context={config} />
		</Box>
	)
}

export default UnauthorizedLayout
