import {
	Avatar,
	Box,
	Center,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Stack,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
} from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import useDashboard from "../../redux/hooks/useDashboard"
import useUser from "../../redux/hooks/useUser"
import { top10Education, top10Month, top10Year } from "../../utils/constants"
import { strings } from "../../utils/localization"
import { LeftArrow } from "../Icons"
import SelectDropdown from "../SelectDropdown"

const AnimationVariant = {
	hidden: {
		opacity: 0,
		transition: {
			scale: 0.3,
		},
	},
	visible: {
		opacity: 1,
		transition: { delay: 0, scale: 0.3, duration: 0.5 },
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.3,
		},
	},
}

const TabButton = ({ title, onClick }) => {
	return (
		<Tab
			rounded="md"
			color="mc_dark"
			fontWeight="bold"
			boxShadow="0px 0px 12px rgba(66, 146, 220, 0.25)"
			bg="mc_bg"
			ml={1}
			onClick={() => onClick && onClick()}
			_selected={{ color: "white", bg: "mc_dark" }}
		>
			{title}
		</Tab>
	)
}
const AnimatedStack = ({ children, ...rest }) => {
	return (
		<AnimatePresence>
			<Stack
				as={motion.div}
				spacing={3}
				w="100%"
				align="center"
				maxH={["unset", "calc(100vh - 200px)"]}
				overflowY={["unset", "auto"]}
				initial="hidden"
				animate="visible"
				exit="exit"
				variants={AnimationVariant}
				{...rest}
			>
				{children}
			</Stack>
		</AnimatePresence>
	)
}

const ListItem = ({ name, number, points, index, currentUser, username }) => {
	return (
		<Flex
			w="100%"
			mt={4}
			h="84px"
			align="center"
			justify="space-between"
			bg={currentUser ? "mc_medium" : index === 0 ? "mc_dark" : "mc_bg"}
			rounded="md"
			px={5}
			py={1}
		>
			<HStack>
				<Avatar />
				<Stack spacing={1}>
					<Text fontWeight="bold" color={currentUser ? "mc_bg" : index === 0 ? "mc_medium" : "mc_errorRed"}>
						{number}
					</Text>
					<Text fontWeight="bold" color={currentUser ? "mc_bg" : index === 0 ? "mc_bg" : "mc_dark"}>
						{name} {`(${username})`}
					</Text>
				</Stack>
			</HStack>
			<Center
				w="65px"
				h="30px"
				rounded="md"
				bg={currentUser ? "mc_bg" : index === 0 ? "mc_bg" : "mc_dark"}
				color={currentUser ? "mc_dark" : index === 0 ? "mc_dark" : "mc_bg"}
			>
				<Text>{points}</Text>
			</Center>
		</Flex>
	)
}

const Top10 = ({ back, educations }) => {
	const hook = useUser()
	const hookD = useDashboard()

	const [currentEdu, setCurrentEdu] = useState(null)

	const handleOnChange = (item) => {
		setCurrentEdu(item)
		if (item) {
			hookD.fetchUserComponent(top10Education, item.value)
		} else {
			hookD.cleanTop10()
		}
	}

	const checkEdu = () => {
		if (!currentEdu) {
			hookD.cleanTop10()
		}
	}

	return (
		<Tabs isLazy={true} variant="unstyled">
			<Flex w="100%" direction={["column", "row"]} align={["flex-start", "center"]} justify="space-between">
				<IconButton onClick={back} aria-label="Back" icon={<Icon as={LeftArrow} color="mc_dark" boxSize={5} />} bg="transparent" />
				<Heading w="100%" textAlign="center" mb={[4, 0]}>
					Top 10
				</Heading>

				<TabList justify="center">
					<TabButton onClick={() => hookD.fetchUserComponent(top10Month)} title={strings.month} />
					<TabButton onClick={() => hookD.fetchUserComponent(top10Year)} title={strings.year} />
					<TabButton onClick={() => checkEdu()} title={strings.education} />
				</TabList>
			</Flex>
			<Box w="100%" minH="300px">
				<TabPanels w="100%">
					<TabPanel w="100%" p={[2, 4]}>
						<AnimatedStack>
							{hookD.topListMonth.map((item, index) => {
								return (
									<ListItem
										name={`${item.first_name} ${item.last_name}`}
										number={item.rank}
										currentUser={item.username === hook.username}
										index={index}
										points={item.points}
										key={index}
										username={item.username}
									/>
								)
							})}
						</AnimatedStack>
					</TabPanel>
					<TabPanel>
						<AnimatedStack>
							{hookD.topListYear.map((item, index) => {
								return (
									<ListItem
										name={`${item.first_name} ${item.last_name}`}
										number={item.rank}
										index={index}
										points={item.points}
										currentUser={item.username === hook.username}
										key={index}
										username={item.username}
									/>
								)
							})}
						</AnimatedStack>
					</TabPanel>
					<TabPanel w="100%">
						<AnimatedStack minH="400px" mt={4}>
							<Box w="350px">
								<SelectDropdown
									placeholder={strings.choosePlaceholder}
									options={educations}
									onChange={(item) => handleOnChange(item)}
									value={currentEdu}
								/>
							</Box>
							{hookD.topListEducation.length > 0 &&
								hookD.topListEducation.map((item, index) => {
									return (
										<ListItem
											name={`${item.first_name} ${item.last_name}`}
											number={item.rank}
											index={index}
											points={item.points}
											currentUser={item.username === hook.username}
											key={index}
											username={item.username}
										/>
									)
								})}
							{!currentEdu && <Text color="mc_medium">Trenutno nije izabrana ni jedna edukacija.</Text>}
						</AnimatedStack>
					</TabPanel>
				</TabPanels>
			</Box>
		</Tabs>
	)
}

export default Top10
