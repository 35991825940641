import { Box, Button, Flex, FormControl, FormLabel, Heading, Icon, Stack } from "@chakra-ui/react"
import React, { useState } from "react"
import CustomTextInput from "../inputs/CustomTextInput"
import UploadFile from "../UploadFile"

import { CorrectIcon, PlusIcon } from "../Icons"
import useDataManagement from "../../redux/hooks/useDataManagement"
import { strings } from "../../utils/localization"

const BannersForm = ({ banners }) => {
    const hook = useDataManagement()

    const [banner1, setBanner1] = useState(null)
    const [banner2, setBanner2] = useState(null)
    const [banner3, setBanner3] = useState(null)
    const [banner4, setBanner4] = useState(null)
    const [cleanTrigger, setCleanTrigger] = useState(false)
    const [cleanTrigger2, setCleanTrigger2] = useState(false)
    const [cleanTrigger3, setCleanTrigger3] = useState(false)
    const [cleanTrigger4, setCleanTrigger4] = useState(false)

    const firstBanner = banners.find((val) => val.id === 1)
    const secondBanner = banners.find((val) => val.id === 2)
    const thirdBanner = banners.find((val) => val.id === 3)
    const fourthBanner = banners.find((val) => val.id === 4)

    const [urlBanner1, setUrlBanner1] = useState(firstBanner.url)
    const [urlBanner2, setUrlBanner2] = useState(secondBanner.url)
    const [urlBanner3, setUrlBanner3] = useState(thirdBanner.url)
    const [urlBanner4, setUrlBanner4] = useState(fourthBanner.url)

    const handleBanners = (file, setBannerData) => {
        setBannerData(file)
    }

    const submit = (id, position, banner, urlBanner, clean, cleanTrigger) => {
        var formData = new FormData()
        if (banner === null) {
            formData.append("image", null)
            formData.append("url", "")
        } else {
            formData.append("image", banner, banner.name)
            formData.append("url", urlBanner)
        }
        formData.append("id", id)
        formData.append("position", position)

        hook.updateBanners(formData).then((res) => {
            if (res !== undefined && res.status === 200) {
                hook.getBanners()
                cleanBannerAfterSubmit(clean, cleanTrigger)
            }
        })
    }
    const cleanBannerAfterSubmit = (setBannerData, setCleanTrigger) => {
        setBannerData(null)
        setCleanTrigger(true)
    }

    const cleanBanner = (setBannerData, setCleanTrigger, setUrlBanner) => {
        setBannerData(null)
        setCleanTrigger(true)
        setUrlBanner("")
    }

    // const half = Math.ceil(hook.banners.length / 2)
    // const leftBanners = hook.banners.slice(0, half)
    // const rightBanners = hook.banners.slice(half)

    return (
        <Flex
            w="100%"
            // justify="space-between"
            justify="center"
            align="center"
            gridGap={[8]}
            direction={["column", "column", "column", "column", "row"]}
        >
            <Stack spacing={4} w={["100%", "100%", "100%", "90%", "600px"]}>
                <FormControl
                    minW="420px"
                    border="2px solid"
                    borderColor="mc_dark"
                    p="8px"
                    borderRadius="2px"
                >
                    <Heading
                        textAlign="center"
                        fontSize="18px"
                        color="mc_dark"
                        textTransform="uppercase"
                    >
                        {`${strings.banner} 1`}
                    </Heading>
                    <FormLabel fontSize="14px" color="mc_dark" mb="2px" textTransform="uppercase">
                        {strings.url}
                    </FormLabel>
                    <Box mb="8px">
                        <CustomTextInput
                            value={urlBanner1}
                            onChange={(url) => {
                                setUrlBanner1(url)
                            }}
                        />
                    </Box>
                    <Flex w="100%" justify="center">
                        <UploadFile
                            onChange={(val) => handleBanners(val, setBanner1)}
                            setCleanTrigger={setCleanTrigger}
                            cleanTrigger={cleanTrigger}
                        />
                    </Flex>
                    <Flex w="100%" justify="center" gridGap={4} mt="8px">
                        <Button
                            onClick={() => cleanBanner(setBanner1, setCleanTrigger, setUrlBanner1)}
                        >
                            <Icon as={PlusIcon} fill="red" transform="rotate(45deg)" />
                        </Button>
                        <Button
                            onClick={() =>
                                submit(1, 1, banner1, urlBanner1, setBanner1, setCleanTrigger)
                            }
                            // isDisabled={banner1 === null ? true : false}
                        >
                            <Icon as={CorrectIcon} fill="green" />
                        </Button>
                    </Flex>
                </FormControl>
                <FormControl
                    minW="420px"
                    border="2px solid"
                    borderColor="mc_dark"
                    p="8px"
                    borderRadius="2px"
                >
                    <Heading
                        textAlign="center"
                        fontSize="18px"
                        color="mc_dark"
                        textTransform="uppercase"
                    >
                        {`${strings.banner} 2`}
                    </Heading>
                    <FormLabel fontSize="14px" color="mc_dark" mb="2px" textTransform="uppercase">
                        {strings.url}
                    </FormLabel>
                    <Box mb="8px">
                        <CustomTextInput
                            value={urlBanner2}
                            onChange={(url) => {
                                setUrlBanner2(url)
                            }}
                        />
                    </Box>
                    <Flex w="100%" justify="center">
                        <UploadFile
                            onChange={(val) => handleBanners(val, setBanner2)}
                            setCleanTrigger={setCleanTrigger2}
                            cleanTrigger={cleanTrigger2}
                        />
                    </Flex>
                    <Flex w="100%" justify="center" gridGap={4} mt="8px">
                        <Button
                            onClick={() => cleanBanner(setBanner2, setCleanTrigger2, setUrlBanner2)}
                        >
                            <Icon as={PlusIcon} fill="red" transform="rotate(45deg)" />
                        </Button>
                        <Button
                            onClick={() =>
                                submit(2, 2, banner2, urlBanner2, setBanner2, setCleanTrigger2)
                            }
                            // isDisabled={banner2 === null ? true : false}
                        >
                            <Icon as={CorrectIcon} fill="green" />
                        </Button>
                    </Flex>
                </FormControl>
            </Stack>
            <Stack spacing={4} w={["100%", "100%", "100%", "90%", "600px"]}>
                <FormControl
                    minW="420px"
                    border="2px solid"
                    borderColor="mc_dark"
                    p="8px"
                    borderRadius="2px"
                >
                    <Heading
                        textAlign="center"
                        fontSize="18px"
                        color="mc_dark"
                        textTransform="uppercase"
                    >
                        {`${strings.banner} 3`}
                    </Heading>
                    <FormLabel fontSize="14px" color="mc_dark" mb="2px" textTransform="uppercase">
                        {strings.url}
                    </FormLabel>
                    <Box mb="8px">
                        <CustomTextInput
                            value={urlBanner3}
                            onChange={(url) => {
                                setUrlBanner3(url)
                            }}
                        />
                    </Box>
                    <Flex w="100%" justify="center">
                        <UploadFile
                            onChange={(val) => handleBanners(val, setBanner3)}
                            setCleanTrigger={setCleanTrigger3}
                            cleanTrigger={cleanTrigger3}
                        />
                    </Flex>
                    <Flex w="100%" justify="center" gridGap={4} mt="8px">
                        <Button
                            onClick={() => cleanBanner(setBanner3, setCleanTrigger3, setUrlBanner3)}
                        >
                            <Icon as={PlusIcon} fill="red" transform="rotate(45deg)" />
                        </Button>
                        <Button
                            onClick={() =>
                                submit(3, 3, banner3, urlBanner3, setBanner3, setCleanTrigger3)
                            }
                            // isDisabled={banner3 === null ? true : false}
                        >
                            <Icon as={CorrectIcon} fill="green" />
                        </Button>
                    </Flex>
                </FormControl>
                <FormControl
                    minW="420px"
                    border="2px solid"
                    borderColor="mc_dark"
                    p="8px"
                    borderRadius="2px"
                >
                    <Heading
                        textAlign="center"
                        fontSize="18px"
                        color="mc_dark"
                        textTransform="uppercase"
                    >
                        {`${strings.banner} 4`}
                    </Heading>
                    <FormLabel fontSize="14px" color="mc_dark" mb="2px" textTransform="uppercase">
                        {strings.url}
                    </FormLabel>
                    <Box mb="8px">
                        <CustomTextInput
                            value={urlBanner4}
                            onChange={(url) => {
                                setUrlBanner4(url)
                            }}
                        />
                    </Box>
                    <Flex w="100%" justify="center">
                        <UploadFile
                            onChange={(val) => handleBanners(val, setBanner4)}
                            setCleanTrigger={setCleanTrigger4}
                            cleanTrigger={cleanTrigger4}
                        />
                    </Flex>
                    <Flex w="100%" justify="center" gridGap={4} mt="8px">
                        <Button
                            onClick={() => cleanBanner(setBanner4, setCleanTrigger4, setUrlBanner4)}
                        >
                            <Icon as={PlusIcon} fill="red" transform="rotate(45deg)" />
                        </Button>
                        <Button
                            onClick={() =>
                                submit(4, 4, banner4, urlBanner4, setBanner4, setCleanTrigger4)
                            }
                            // isDisabled={banner4 === null ? true : false}
                        >
                            <Icon as={CorrectIcon} fill="green" />
                        </Button>
                    </Flex>
                </FormControl>
            </Stack>
        </Flex>
    )
}

export default BannersForm
