import React, { useEffect, useState } from "react"
import PlusButton from "../../components/buttons/PlusButton"
import SurveyForm from "../../components/forms/SurveyForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import SurveyTable from "../../components/tables/SurveyTable"
import useSurvey from "../../redux/hooks/useSurvey"
import { Box, useToast } from "@chakra-ui/react"
import useMasterdata from "../../redux/hooks/useMasterdata"
import useGeneric from "../../redux/hooks/useGeneric"
import {
    handleToast,
    makeDropdownOptions,
    makeDropdownOptionsUsers,
    makeIdsArray,
} from "../../utils/functions"
import {
    application,
    city,
    company,
    concept,
    country,
    hqRetail,
    organizationUnit,
    positionSe1,
    positionSe2,
    sector,
} from "../../utils/constants"
import { strings } from "../../utils/localization"
import { handleSmartedPermissionsPayload, handleSmartedSelectData } from "../../utils/smartedHelper"
import useUsers from "../../redux/hooks/useUsers"
import Pagination from "../../components/tables/Pagination"
import { useOutletContext } from "react-router-dom"

const AdminSurveyPage = () => {
    const hook = useSurvey()
    const toast = useToast()
    const hookMd = useMasterdata()
    const hookG = useGeneric()
    const config = useOutletContext()
    const hookUsers = useUsers()

    const [companies, setCompanies] = useState([])
    const [apps, setApps] = useState([])
    const [hqRetails, setHqRetails] = useState([])
    const [concepts, setConcepts] = useState([])
    const [organizationUnits, setOrganizationUnits] = useState([])
    const [sectors, setSectors] = useState([])
    const [positionsSe1, setPositionsSe1] = useState([])
    const [positionsSe2, setPositionsSe2] = useState([])
    const [countries, setCountries] = useState([])
    const [cities, setCities] = useState([])
    const [users, setUsers] = useState([])

    useEffect(() => {
        hook.cleanToast()
        hook.fetch(hook.defaultSize, 1)
        hookMd.fetch(application)
        hookG.fetch(company)

        if (config.appId === 2) {
            hookMd.fetch(hqRetail)
            hookMd.fetch(concept)
            hookMd.fetch(organizationUnit)
            hookMd.fetch(sector)
            hookMd.fetch(positionSe1)
            hookMd.fetch(positionSe2)
            hookG.fetch(city)
            hookMd.fetch(country)
            hookUsers.fetchDropdown()
        }
    }, []) //eslint-disable-line

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) //eslint-disable-line

    useEffect(() => {
        makeDropdownOptions(hookG.companies, setCompanies)
        makeDropdownOptions(hookMd.apps, setApps)

        if (config.appId === 2) {
            makeDropdownOptions(hookMd.hqRetails, setHqRetails)
            makeDropdownOptions(hookMd.concepts, setConcepts)
            makeDropdownOptions(hookMd.organizationUnits, setOrganizationUnits)
            makeDropdownOptions(hookMd.sectors, setSectors)
            makeDropdownOptions(hookMd.positionsSe1, setPositionsSe1)
            makeDropdownOptions(hookMd.positionsSe2, setPositionsSe2)
            makeDropdownOptions(hookG.cities, setCities)
            makeDropdownOptions(hookMd.countries, setCountries)
            makeDropdownOptionsUsers(hookUsers.data, setUsers)
        }
    }, [
        hookG.companies,
        hookMd.apps,
        hookMd.hqRetails, //eslint-disable-line
        hookMd.concepts,
        hookMd.organizationUnits,
        hookMd.sectors,
        hookMd.positionsSe1,
        hookMd.positionsSe2,
        hookG.cities,
        hookMd.countries,
        hookUsers.data,
    ])

    const onSubmit = (data) => {
        let payload = {}

        if (!data.app || !data.name) {
            handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "1")
        } else {
            payload["name"] = data.name
            payload["app"] = data.app.value
            if (data.companies && data.companies.length > 0) {
                payload["companies"] = makeIdsArray(data.companies)
            } else {
                payload["companies"] = []
            }

            if (config.appId === 2) {
                handleSmartedPermissionsPayload(data, payload)
                delete data.countries
                delete data.cities
                delete data.users
                delete data.hq_retails
                delete data.concepts
                delete data.organization_units
                delete data.sectors
                delete data.positions_se_1
                delete data.positions_se_2
                delete data.superiors
            }

            delete data.name
            delete data.app
            delete data.companies

            let exitLoop

            Object.keys(data).forEach((key) => {
                if (!data[key]) {
                    return (exitLoop = true)
                }
            })

            if (exitLoop) {
                handleToast(toast, { text: strings.requiredAllQuestions, type: "error" }, "2")
            } else {
                payload["questions"] = data
                hook.create(payload)
            }
        }
    }

    let selectData = {
        companies: companies,
        apps: apps,
    }

    if (config.appId === 2) {
        const allOptions = {
            hqRetails: hqRetails,
            concepts: concepts,
            organizationUnits: organizationUnits,
            sectors: sectors,
            positionsSe1: positionsSe1,
            positionsSe2: positionsSe2,
            cities: cities,
            countries: countries,
            users: users,
        }

        handleSmartedSelectData(allOptions, selectData)
    }

    const pagination = hook.pagination

    return (
        <>
            <Box
                h="95%"
                overflow="auto"
                border={"4px"}
                boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
                borderColor="mc_black"
                borderRadius="4px"
                w="100%"
            >
                <SurveyTable data={hook.data} selectData={selectData} />
                <PlusButton onClick={() => hook.onOpen()} />
                <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="6xl">
                    <SurveyForm submit={onSubmit} onClose={hook.onClose} selectData={selectData} />
                </AddUpdateModal>
            </Box>
            {hook.pagination && pagination.total_count > 10 && (
                <Box w="80%" mx="auto">
                    <Pagination
                        paginationData={{
                            totalCount: pagination.total_count,
                            totalPages: pagination.total_pages,
                            currentPage: pagination.current_page,
                            pageSize: hook.defaultSize,
                            pageSizesArray: [10, 25, 50],
                        }}
                        setSize={hook.changeSize}
                        fetchPage={hook.fetchPage}
                    ></Pagination>
                </Box>
            )}
        </>
    )
}

export default AdminSurveyPage
