import { useDispatch, useSelector } from "react-redux"
import { approveUserAbsenceRequest, fetchUserAbsenceRequests, rejectUserAbsenceRequest } from "../reducers/absenceRequestReducer"

export default function useAbsenceRequest() {
	const dispatch = useDispatch()
	const data = useSelector((state) => state.absenceRequests.data)
	const message = useSelector((state) => state.absenceRequests.message)
	const loading = useSelector((state) => state.absenceRequests.loading)

	const fetchAbsenceRequests = async () => {
		return dispatch(fetchUserAbsenceRequests())
	}

	const approveAbsenceRequest = async (payload) => {
		return dispatch(approveUserAbsenceRequest(payload))
	}

	const rejectAbsenceRequest = async (payload) => {
		return dispatch(rejectUserAbsenceRequest(payload))
	}

	return { data, message, loading, fetchAbsenceRequests, approveAbsenceRequest, rejectAbsenceRequest }
}
