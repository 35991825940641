import { createGlobalStyle } from "styled-components"
import useApp from "./redux/hooks/useApp"

export const GlobalStylesPharma = createGlobalStyle`

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}
:root {
--mc_black:#012C31;
--mc_bg: #F5FEFF;
--mc_medium:#24B4BC;
--mc_dark: #00707C;
--mc_errorRed: #ED7474;
--mc_dropdownOutline: rgb(66, 153, 225);
}
`

export const GlobalStylesBase = createGlobalStyle`

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}
:root {
--mc_black:#041829;
--mc_bg: #F5FEFF;
--mc_medium:#4292DC;
--mc_dark: #082D4F;
--mc_errorRed: #ED7474;
--mc_dropdownOutline: rgb(66, 153, 225);
}

`

export const GlobalStylesDoctornet = createGlobalStyle`

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}
:root {
--mc_black:#181818;
--mc_bg:#EFFFFC;
--mc_medium:#06AB8F;
--mc_dark:#065547;
--mc_errorRed:#ED7474;
--mc_dropdownOutline: rgb(66, 153, 225);
}

`

export const Colors = () => {
    const hookApp = useApp()
    const colors =
        hookApp.name === "PHARMANET"
            ? {
                  mc_black: "#012C31",
                  mc_bg: "#F5FEFF",
                  mc_medium: "#24B4BC",
                  mc_dark: "#00707C",
                  mc_errorRed: "#ED7474",
                  dropdownOutline: "rgb(66, 153, 225)",
              }
            : hookApp.name === "DOCTORNET"
            ? {
                  mc_black: "#181818",
                  mc_bg: "#EFFFFC",
                  mc_medium: "#06AB8F",
                  mc_dark: "#065547",
                  mc_errorRed: "#ED7474",
                  dropdownOutline: "rgb(66, 153, 225)",
              }
            : {
                  mc_black: "#041829",
                  mc_bg: "#F5FEFF",
                  mc_medium: "#4292DC",
                  mc_dark: "#082D4F",
                  mc_errorRed: "#ED7474",
                  dropdownOutline: "rgb(66, 153, 225)",
              }
    return { colors }
}
