import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal"
import React from "react"
import SlideContent from "../educations/SlideContent"

const SlideModal = ({ heading, imgUrl, isOpen, onClose, size }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size={size} isCentered>
            <ModalOverlay />
            <ModalContent maxH="90vh" overflowY="scroll">
                <ModalBody bg="mc_bg" p={[2, 8]}>
                    <SlideContent heading={heading} imgUrl={imgUrl} />
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default SlideModal
