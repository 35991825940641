import { useSelector } from "react-redux";

export default function useApp() {

	// const dispatch = useDispatch();
	const name = useSelector((state) => state.app.name);
	const id = useSelector((state) => state.app.id);

	return {
		name, id
	};
}