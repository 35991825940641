export const romanian = () => {
	return {
		showMore: "Afișați mai multe",
		edit: "editați",
		educationAuthorIs: "Această educație a fost făcută de companie:",
		startTest: "începe testul",
		didTest: "Ai făcut un test",
		maxTimes: "numărul maxim de ori",
		endTest: "COMPLETĂ TESTUL",
		requiredFieldsMessage: "Câmpurile marcate cu * sunt necesare!",
		allRequiredFieldsMessage: "Toate campurile sunt necesare!",
		requiredTextOrImageMessage: "Nu ați introdus niciun text sau imagine!",
		requiredTextMessage: "Nu ați introdus niciun text!",
		requiredTextOfAllAnswersMessage: "Completați textul tuturor răspunsurilor!",
		oneCorrectAnswerMessage: "Un singur răspuns poate fi corect!",
		atLeastOneCorrectAnswerMessage: "Cel puțin un răspuns trebuie să fie corect!",
		requiredImageMessage: "Imaginea trebuie selectată!",
		requiredQuestionTextMessage: "Introduceți textul întrebării!",
		requiredEducationSlideNumberMessage: "Introduceți numărul diapozitivului educațional!",
		requiredAllQuestions: "Completați toate întrebările!",
		passwordDontMatchMessage: "Parolele nu se potrivesc!",
		noDataSelectedMessage: "Datele nu sunt selectate!",
		noReportSelectedMessage: "Raportul nu a fost selectat!",
		noTestSelectedMessage: "Testul nu a fost selectat!",
		noTypeSelectedMessage: "Tipul nu este selectat!",
		noArticleSelected: "Știrile nu sunt selectate!!",
		noSurveySelected: "Sondajul nu a fost selectat!",
		noJobSelected: "Job nu este selectat!",
		notEnoughReportDataMessage: "Nu există suficiente date pentru raportul selectat",
		enterCommentMessage: "Introduceți un comentariu!",
		leaveComment: "LASA UN COMENTARIU",
		report: "RAPORT",
		test: "TEST",
		type: "TIP",
		from: "DIN",
		to: "ACEA",
		previous: "Anterioară",
		next: "Următoare",
		page: "Pagina",
		of: "din",
		generate: "GENERA",
		choosePlaceholder: "Alege...",
		name: "NUME",
		title: "TITLU",
		education: "Educaţie",
		category: "CATEGORIE",
		application: "CERERE",
		company: "COMPANIE",
		survey: "STUDIU",
		country: "ȚARĂ",
		city: "ORAȘ",
		jobTitle: "TITLU",
		position: "POZIŢIE",
		language: "LIMBA",
		phoneNumber: "NUMAR DE TELEFON",
		licenceNumber: "NUMĂR DE ÎNMATRICULARE",
		biography: "BIOGRAFIE",
		slideText: "TEXTUL SLIDE",
		image: "IMAGINE",
		question: "ÎNTREBARE",
		questions: "ÎNTREBĂRI",
		educationSlide: "DIAPOZITIV EDUCAȚIONAL",
		answers: "RĂSPUNSURI",
		summaryText: "TEXT REZUMAT",
		coverPhoto: "COPERTĂ FOTOGRAFIE",
		content: "CONŢINUT",
		status: "STARE",
		close: "ÎNCHIDE",
		back: "ÎNAPOI",
		forward: "REDIRECŢIONA",
		save: "SALVAȚI",
		password: "PAROLA",
		repeatPassword: "REPETA PAROLA",
		newPassword: "PAROLĂ NOUĂ",
		newPasswordRepeat: "REPETA NOUA PAROLA",
		code: "COD",
		companyType: "TIPUL COMPANIEI",
		firstName: "NUME",
		lastName: "NUMELE DE FAMILIE",
		username: "NUME DE UTILIZATOR",
		email: "E-MAIL",
		supervisor: "SUPERVIZOR",
		users: "UTILIZATORI",
		reports: "RAPOARTE",
		importExport: "IMPORT/EXPORT",
		masterData: "MASTERDATA",
		countries: "ȚĂRI",
		jobTitles: "TITLURI",
		positions: "POZIȚII",
		companyTypes: "TIPURI DE FIRME",
		cities: "ORASE",
		companies: "COMPANII",
		educationCategories: "CATEGORII DE ÎNVĂȚĂMÂNT",
		articleCategories: "CATEGORII DE STIRI",
		languages: "LIMBI",
		article: "ȘTIRI",
		articles: "ȘTIRI",
		surveys: "ANCHETE",
		educations: "EDUCAȚIE",
		tests: "TESTE",
		panel: "PANOU",
		signOut: "SIGN OUT",
		signIn: "CONECTARE",
		controlPanel: "PANOU DE CONTROL",
		profile: "PROFIL",
		cancel: "ANULARE",
		delete: "ȘTERGE",
		deleteQuestion: "ȘTERGEȚI ÎNTREBAREA",
		deleteWarningMessage: "Dacă ștergeți acest element, nu îl veți putea recupera!",
		yes: "DA",
		no: "NU",
		creationDate: "DATA CREARII",
		views: "VEDERE",
		reactions: "REACȚII",
		comments: "COMENTARII",
		registrationTime: "TIMPUL ÎNREGISTRĂRII",
		lastLogin: "ULTIMA LOGARE",
		active: "ACTIV",
		congratulations: "FELICITĂRI",
		completedTest: "AȚI FINALIZAT TESTUL",
		monthlyReport: "Raport lunar",
		fileSelected: "Fișier selectat",
		chooseCsvFile: "Selectați fișierul .csv",
		photo: "Fotografie",
		selectPhoto: "Selectați fotografia",
		noFileSelectedMessage: "Fișierul nu a fost selectat!",
		exportData: "EXPORT DE DATE",
		export: "EXPORT",
		importData: "DATE DE IMPORT",
		import: "IMPORT",
		confirm: "CONFIRMĂ",
		noComments: "Fara comentarii",
		loadMore: "INCARCA MAI MULT",
		author: "AUTOR",
		notFoundMessage: "Pagina pe care o căutați nu a fost găsită",
		updateData: "ACTUALIZARE DATE",
		privacyPolicy: "POLITICA DE CONFIDENȚIALITATE",
		result: "REZULTAT",
		serverErrorMessage: "Eroare de server!",
		wrongCredentialsMessage: "Nu a fost găsit niciun cont cu acreditări date!",
		noAccount: "Fără cont?",
		clickHere: "Click aici",
		forgotPassword: "Ați uitat parola?",
		backTo: "Înapoi la",
		loginPage: "pagina de conectare",
		emailAddress: "ADRESA DE E-MAIL",
		passwordReset: "RESETARE PAROLA",
		registration: "INSCRIE-TE",
		noMore: "Nu mai!",
		welcomeTo: "bun venit la",
		byUser: "DE UTILIZATOR",
		byCity: "PE ORAS",
		byQuestions: "PRIN ÎNTREBĂRI",
		loginCount: "Număr de conectări",
		articleViews: "Vizualizări știri",
		articleReactions: "Reacții la știri",
		educationViews: "Opiniile educației",
		passedTests: "Testele trecute",
		monthly: "LUNAR",
		eduAll: "TOATE",
		eduChoose: "ALEGE",
		eduChooseCompleted: "ALEGE FINALIZAT",
		eduChooseNotCompleted: "ALEGE NEFINIT",
		noEduSelectedMessage: "Educația nu este selectată!",
		deleteSlide: "ȘTERGEȚI DIAPOZITIVUL",
		cantDeleteFirst: "Nu puteți șterge primul slide!",
		agreePrivacyPolicy: "Sunt de acord cu politica de confidențialitate",
		articleAll: "TOATE",
		articleByUserReports: "PRIN RAPOARTELE UTILIZATORULUI",
		imageMissing: "Imaginea lipsește undeva!",
		monthlyPoints: "PUNCTE PENTRU ACEASTA LUNĂ:",
		annualPoints: "PUNCTE PENTRU ACEST AN:",
		notePlaceholder: "Notă...",
		wrongAppMessage: "Încercați să accesați o platformă greșită!",
		selectFile: "Selectați fișierul",
		searchPlaceholder: "Căutare...",
		repeatTest: "REPEȚI TESTUL",
		usersUpdate: "UPDATE UTILIZATORUL",
		total: "TOTAL",
		track: "TRACK",
		loginData: "LOGIN DATA",
		checkEmailMessage: "Deschideți linkul de e-mail pentru a confirma înregistrarea (verificați spam)",
		subquestion: "SUBINTREBARE",
		comment: "COMETARIU",
		reject: "RESPINGE",
		approve: "APROBA",
		tags: "ETICHETE",
		deadline: "limita",
		applicationDeadline: "termen limita",
		jobListings: "LISTELE DE POSTURI",
		apply: "Inscrie-te",
		openFormToApply: "Deschideți formularul de cerere",
		uploadYourCV: "încărcați CV-ul dvs",
		uploadFile: "Incarca fisier",
		yourCVIsUploaded: "CV-ul tău este încărcat",
		applied: "ESTI ÎNREGISTRAT",
		jobs: "LOCURI DE MUNCA",
		job: "LOC DE MUNCA",
		note: "Notă",
		newEmployees: "Angajati noi",
		newEmployee: "ANGAJAT NOU",
		updateEmploymentStatus: "Actualizați statutul de angajare",
		banner: "publicitate",
		url: "url- legătură",
		expirationDate: "DATA EXPIRĂRII",
		expired: "EXPIRAT",
		days: "ZILE",
		hours: "ORE",
		useCustomTheme: "utilizați o temă personalizată",
		chooseTheColorOfTheApplication: "alege culoarea aplicației",
		selectTheApplicationLogo: "selectați sigla aplicației",
		selectTheApplicationFont: "selectați fontul aplicației",
		currentLogo: "logo-ul actual",
		font: "font",
		black: "negru",
		background: "fundal",
		dark: "întuneric",
		medium: "mediu",
		light: "ușoară",
		red: "roșu",
		assignedToUsers: "ATRIBUIT UTILIZATORILOR",
		testAnswerQuestion: "Răspunde la întrebare",
		testMaxRepeatNumberReached: "Ai făcut testul de maximum de ori",
		selectDocument: "Selectați documentul",
		UA_LOGIN_ACTIVITY: "Activitate",
		UA_NEWS_VIEWS: "Știri",
		UA_NEWS_REACTIONS: "Reacții",
		UA_EDUCATION_VIEWS: "Educație",
		UA_TESTS: "Teste",
		UA_LOGIN_ACTIVITY_TP: "Activitatea utilizatorului în ultimele 100 de zile",
		UA_NEWS_VIEWS_TP: "Știri văzute în ultimele 100 de zile",
		UA_NEWS_REACTIONS_TP: "Numărul de reacții în ultimele 100 de zile",
		UA_EDUCATION_VIEWS_TP: "Studii absolvite cu succes în ultimele 100 de zile",
		UA_TESTS_TP: "Activitatea testelor trecute în ultimele 100 de zile",
		ACTIVITY: "Activitate",
		NEWS: "Știri",
		EDUCATIONS: "Educație",
		TESTS: "Teste",
		achievements: "Realizări",
		ACTIVITY_DAYS: "zile de conectare consecutivă",
		EDUCATIONS_FINISHED: "studii finalizate",
		NEWS_LIKED: "știri plăcute",
		NEWS_VIEWED: "știri vizualizate",
		TESTS_SUCCESS_FINISHED: "a trecut testele cu toate răspunsurile corecte",
		TESTS_FINISHED: "teste finalizate",
		numberOfAchievements: "Numărul de realizări",
		currentRank: "Rangul actual",
		numberOfUnread: "Număr de necitite",
		numberOfNotCompleted: "Numărul de nefinalizate",
		documents: "Documente",
		document: "Document",
		download: "Descarca",
		completedAllEdu: "Le-ai completat pe toate",
		readAllNews: "Ai citit tot",
		forms: "Forme",
		month: "Lună",
		year: "An",
		absenceRequests: "CERERI DE ABSENCE",
		absenceRequestType: "TIP ABSENTA",
		byAssignedUser: "DE UTILIZATORUL ATRIBUIT",
		bySubQuestions: "PRIN SUBINTREBĂRI",
		lockedEduMessage:
			"Stimate utilizator, atata timp cat esti angajat nou, trebuie sa faci training-uri pentru noii angajati, pentru a avea acces la alte training-uri.",
		mpoorj: "MPO/ORJ",
		cv: "CV",
		FORM_ZZAZ: "Cerere pentru o interdicție administrativă",
		FORM_PZDF: "Cerere de partajare a pliantelor",
		contactPhoneNumber: "NUMAR DE TELEFON DE CONTACT",
		FORM_TK: "Club de curse",
		FORM_ZPOZ: "Cerere de certificate de angajare",
		bank: "BANCĂ",
		certificationPurpose: "SCOPUL CERTIFICĂRII",
		documentType: "TIP DE DOCUMENT",
		documentVerification: "DOCUMENT PENTRU VERIFICARE",
		loanStatement: "DECLARAȚIE PENTRU RAMBURSAREA ÎMPRUMUTULUI INDEPENDENT",
		FORM_ZZO: "Cereri de plecare",
		leaveType: "TIP DE CONCEDIU",
		dateFrom: "DATA DIN",
		dateTo: "DATA LA",
		absenceDocument: "DOCUMENT PENTRU ABSENȚĂ",
		FORM_PK: "O combinație câștigătoare",
		commentNote: "COMENTARIU/NOTĂ",
		recommendation: "RECOMANDARE",
		FORM_IZ: "Inițiative ale angajaților",
		initiative: "INIȚIATIVĂ",
		choose: "Alege",
	}
}
