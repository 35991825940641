import { Button } from "@chakra-ui/button"
import { FormControl, FormLabel } from "@chakra-ui/form-control"
import { Input } from "@chakra-ui/input"
import { Box, Flex, Stack } from "@chakra-ui/layout"
import { Checkbox } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"
import useApp from "../../redux/hooks/useApp"
import useEducation from "../../redux/hooks/useEducation"
import useForms from "../../redux/hooks/useForms"
import useUser from "../../redux/hooks/useUser"
import { fixTimezoneOffset, handleToast, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedPermissionsPayload, SmartedFields } from "../../utils/smartedHelper"
import { DatePickerWrapper } from "../DatePickerComponent"
import RichTextEditor from "../RichTextEditor"
import SelectDropdown from "../SelectDropdown"
import UploadFile from "../UploadFile"

const AddEduForm = ({ selectData, handleClose }) => {
	const hook = useEducation()
	const toast = useToast()
	const hookForms = useForms()
	const config = useOutletContext()

	const hookUser = useUser()
	const hookApp = useApp()

	const [expiryDate, setExpiryDate] = useState(null)
	const [selectedFile, setSelectedFile] = useState(null)

	let defaultApp = null
	let defaultCompanies = []

	if (!hookUser.admin) {
		defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
		defaultApp = { label: hookApp.name, value: hookApp.id }
	}

	const [currentId, setCurrentId] = useState(0)
	const [images, setImages] = useState([])

	const { handleSubmit, control, register, watch, setValue } = useForm({})

	const handleNext = () => {
		const currentSlide = hookForms.eduForms.find((ele, i) => i === currentId)
		if (!currentSlide.text || !images[currentId]) {
			handleToast(toast, { text: strings.requiredTextOrImageMessage, type: "error" }, "1")
		} else {
			if (currentId + 1 === hookForms.eduForms.length) {
				hookForms.addSlide(currentId + 1)
			}
			setCurrentId(currentId + 1)
		}
	}

	const submit = (data) => {
		var formData = new FormData()

		const contentType = data.content_type.value

		if (contentType === 1 && !images[currentId]) {
			handleToast(toast, { text: strings.requiredTextOrImageMessage, type: "error" }, "1")
		} else {
			const slides = []
			let exitLoop = false
			if (images.length > 0) {
				hookForms.eduForms.map((slide, i) => {
					const oneSlide = {}
					oneSlide["serial_num"] = i + 1
					oneSlide["text"] = slide.text
					oneSlide["image"] = images[i].data
					return slides.push(oneSlide)
				})

				const stringifyProps = ["text", "serial_num"]

				slides.forEach((slide) => {
					if (slide.image !== undefined) {
						formData.append("images[]", slide.image, slide.image.name)
					} else if (slide.image === undefined) {
						exitLoop = true
					}
				})

				formData.append("slides", JSON.stringify(slides, stringifyProps))
			}

			let contentChecker = ""

			let contentTypes = ["content_pdf", "content_image", "content_video"]

			contentTypes.forEach((c) => {
				if (c in data) {
					contentChecker = c
				}
			})

			let education = {}

			if (contentChecker && !data[contentChecker]) {
				handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "8")
			} else if (!data.name || !data.app || !data.category || !data.status) {
				handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "3")
			} else if (contentType === 1 && exitLoop) {
				handleToast(toast, { text: strings.requiredImageMessage, type: "error" }, "2")
			} else {
				education["name"] = data.name
				education["app"] = data.app.value
				education["category"] = data.category.value
				education["status"] = data.status.value
				education["content_type"] = data.content_type.value

				if (contentChecker) {
					formData.append(contentChecker, data[contentChecker], data[contentChecker].name)
				}

				if (expiryDate) {
					education["expiration_date"] = fixTimezoneOffset(expiryDate)
				} else {
					education["expiration_date"] = ""
				}

				if (data.new_employee_content) {
					education["new_employee_content"] = "True"
				} else {
					education["new_employee_content"] = "False"
				}

				if (config.appId === 2) {
					handleSmartedPermissionsPayload(data, education)
				}

				if (data.companies.length > 0) {
					education["companies"] = makeIdsArray(data.companies)
				} else {
					education["companies"] = []
				}

				formData.append("education", JSON.stringify(education))

				if (selectedFile) {
					formData.append("users_file", selectedFile, selectedFile.name)
				}
				hook.create(formData).then((res) => {
					if (res !== undefined && res.status === 200) {
						handleClose()
						hook.handleRefresh()
					}
				})
			}
		}
	}

	const handleDeleteSlide = () => {
		if (currentId === 0 && hookForms.eduForms.length === 1) {
			handleToast(toast, { text: strings.cantDeleteFirst, type: "error" }, "11")
		} else {
			setImages(images.filter((item, i) => i !== currentId))
			hookForms.deleteSlide(currentId)
			if (currentId !== 0) {
				setCurrentId(currentId - 1)
			}
		}
	}

	const handleImage = (file, index) => {
		let newImages = [...images]
		if (newImages.length > 0) {
			newImages.map((data, i) => {
				//eslint-disable-line
				if (index === i) {
					newImages = newImages.filter((item, i) => i !== index)
				}
			})
		}

		if (currentId === 0) {
			newImages.unshift({ question: index, data: file })
		} else {
			newImages.push({ question: index, data: file })
		}
		setImages(newImages)
	}

	const currentContentType = watch("content_type")

	const checkFileName = () => {
		if (currentContentType) {
			if (currentContentType.value === 2) {
				return "content_video"
			} else if (currentContentType.value === 3) {
				return "content_image"
			} else if (currentContentType.value === 4) {
				return "content_pdf"
			}
		} else {
			return "content_test"
		}
	}

	const handleFile = (data) => {
		setValue(checkFileName(), data)
	}

	return (
		<form onSubmit={handleSubmit(submit)}>
			<Stack spacing={6}>
				<Flex alignItems="flex-end" gridGap="20px" direction={["column", "row"]}>
					<FormControl w={["100%", "50%"]}>
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.name} *
						</FormLabel>
						<Controller as={<Input boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)" />} control={control} name="name" />
					</FormControl>
					<FormControl w={["100%", "50%"]} minW="200px">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.category} *
						</FormLabel>
						<Controller as={<SelectDropdown />} control={control} name="category" options={selectData.categories} />
					</FormControl>
				</Flex>
				<Flex gridGap="20px" w="100%" flexWrap="wrap" display={hookUser.admin ? "flex" : "none"}>
					<FormControl w={["100%", "48%", "20%"]} minW="220px">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.application} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="app"
							options={selectData.apps}
							defaultValue={defaultApp}
						/>
					</FormControl>

					<FormControl w={["100%", "40%"]} minW="350px">
						<FormLabel fontSize="14px" color="mc_dark">
							{strings.companies}
						</FormLabel>
						<Controller
							as={<SelectDropdown isMulti />}
							control={control}
							name="companies"
							options={selectData.companies}
							defaultValue={defaultCompanies}
						/>
					</FormControl>
				</Flex>
				<Flex dir="row" gridGap={"20px"}>
					<FormControl w={["100%", "48%", "20%"]} minW="220px">
						<FormLabel color="mc_dark" textTransform="uppercase" fontSize="14px">
							{strings.status} *
						</FormLabel>
						<Controller as={<SelectDropdown />} control={control} name="status" options={selectData.statuses} />
					</FormControl>
					<Flex align="center" minW="160px">
						<Checkbox
							size="lg"
							alignItems="center"
							color="mc_dark"
							fontSize="md"
							ref={register}
							name="new_employee_content"
							defaultChecked={false}
						>
							{strings.newEmployees}
						</Checkbox>
					</Flex>
					<FormControl w={"20%"} minW="328px">
						<FormLabel color="mc_dark" textTransform="uppercase" fontSize="14px">
							{strings.expirationDate}
						</FormLabel>
						<DatePickerWrapper
							selected={expiryDate}
							showPopperArrow={true}
							timeFormat="HH:mm"
							onChange={(date) => setExpiryDate(date)}
							dateFormat="dd-MM-yyyy HH:mm"
							showTimeSelect
						/>
					</FormControl>
				</Flex>

				{config.appId === 2 && (
					<SmartedFields control={control} selectData={selectData} setSelectedFile={setSelectedFile} fileImport={true} />
				)}

				<Flex justifyItems={"center"} w={"30%"} minW="328px">
					<Box w="100%">
						<FormLabel color="mc_dark" textTransform="uppercase" fontSize="14px">
							{"CONTENT TYPE"} *
						</FormLabel>
						<Controller
							as={<SelectDropdown />}
							control={control}
							name="content_type"
							options={selectData.contentTypes}
							defaultValue={selectData.contentTypes.find((ele) => ele.value === 1)}
						/>
					</Box>
					<Box>
						{currentContentType && currentContentType.value === 2 ? (
							<Controller as={<UploadFile />} control={control} name={"content_video"} onChange={handleFile} />
						) : currentContentType && currentContentType.value === 3 ? (
							<Controller as={<UploadFile />} control={control} name={"content_image"} onChange={handleFile} />
						) : currentContentType && currentContentType.value === 4 ? (
							<Controller as={<UploadFile />} control={control} name={"content_pdf"} onChange={handleFile} />
						) : (
							<></>
						)}
					</Box>
				</Flex>

				<>
					<Stack direction="row" justify="center" flexWrap="wrap">
						{hookForms.eduForms.map((slide, i) =>
							currentId === i ? (
								<Flex
									w="18px"
									h="16px"
									bg="mc_dark"
									rounded="2"
									fontSize="11px"
									color="mc_bg"
									justify="center"
									align="center"
									pt="4px"
									mb={1}
								>
									{i + 1}
								</Flex>
							) : (
								<Flex
									w="18px"
									h="16px"
									border="1px solid"
									borderColor="mc_medium"
									rounded="2"
									fontSize="11px"
									color="mc_dark"
									justify="center"
									align="center"
									pt="4px"
									mb={1}
								>
									{i + 1}
								</Flex>
							)
						)}
					</Stack>
					<Box w="100%">
						{hookForms.eduForms.map((item, index) => (
							<Flex
								w="100%"
								gridGap="20px"
								direction={["column", "column", "column", "column", "row"]}
								display={currentId === index ? "flex" : "none"}
							>
								<FormControl w="100%">
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.slideText}
									</FormLabel>
									<Box rounded="xl" boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)">
										<RichTextEditor onChange={(data) => hookForms.updateSlideText(index, data)} />
									</Box>
								</FormControl>
								<Box>
									<FormLabel fontSize="14px" color="mc_dark">
										{strings.image} *
									</FormLabel>

									<UploadFile format="image/jpeg, image/png" onChange={(val) => handleImage(val, index)} />
								</Box>
							</Flex>
						))}
					</Box>
				</>
				<Stack spacing={[4, 4, 0]}>
					<Flex gridGap="20px" justify="center" w="100%">
						<Button
							display={currentId === 0 ? "none" : "inline-block"}
							variant="dark"
							onClick={() => setCurrentId(currentId - 1)}
						>
							{strings.back}
						</Button>
						<Button variant="red" textTransform="uppercase" onClick={handleDeleteSlide}>
							{strings.deleteSlide}
						</Button>
						<Button variant="dark" onClick={() => handleNext()}>
							{strings.forward}
						</Button>
					</Flex>

					<Flex w="100%" justify="space-between">
						<Button variant="red" onClick={() => handleClose()}>
							{strings.close}
						</Button>

						<Button variant="medium" type="submit">
							{strings.save}
						</Button>
					</Flex>
				</Stack>
			</Stack>
		</form>
	)
}

export default AddEduForm
