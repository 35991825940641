import { Button, HStack, Stack } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { useMemo, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import { strings } from "../utils/localization"
// import SamplePDF from "../assets/sample.pdf"
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PdfRender = ({ url }) => {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
	}

	function previousPage() {
		setPageNumber((prevPageNumber) => prevPageNumber - 1)
	}

	function nextPage() {
		setPageNumber((prevPageNumber) => prevPageNumber + 1)
	}

	const file = useMemo(() => ({ url }), [url])

	return (
		<Wrapper>
			<Document
				options={{
					cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
					cMapPacked: true,
				}}
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<Page className="pdf-page" pageNumber={pageNumber} scale={2.0} />
			</Document>
			<Stack w="100%" align="center" py={6}>
				<HStack>
					<Button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
						{strings.previous}
					</Button>
					<Button type="button" disabled={pageNumber >= numPages} onClick={nextPage}>
						{strings.next}
					</Button>
				</HStack>
				<p>
					{strings.page} {pageNumber || (numPages ? 1 : "--")} {strings.of} {numPages || "--"}
				</p>
			</Stack>
		</Wrapper>
	)
}
const Wrapper = styled.div`
	width: 100%;
	justify-content: center;
	.pdf-page {
	}
	.react-pdf__Document {
	}
	.react-pdf__Page {
		max-width: 860px;
		width: 90vw;
		aspect-ratio: auto;
	}
	.react-pdf__Page__canvas {
		max-width: 860px !important;
		width: 90vw !important;
		height: auto !important;
		display: block;
		aspect-ratio: auto;
	}
	canvas {
		max-width: 860px !important;
		width: 90vw;
		height: auto;
	}
	.react-pdf__Page__textContent {
		max-width: 860px !important;
		width: 90vw !important;
		min-height: 400px;
		overflow: hidden;
	}
`

export default PdfRender
