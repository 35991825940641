import { useDispatch, useSelector } from "react-redux"
import {
	createDocumentFile,
	createEmailNotification,
	createReport,
	deleteEmailNotification,
	exportData,
	fetchBanners,
	fetchDocumentFiles,
	fetchDocumentsFilesDropdown,
	fetchEmailNotifications,
	importData,
	removeDocumentFile,
	updateBanner,
	updateDocumentFile,
	updateEmailNotification,
	updateEmploymentStatus,
	uploadFile,
} from "../reducers/dataManagementReducer"

export default function useDataManagement() {
	const dispatch = useDispatch()
	const reportMessage = useSelector((state) => state.dataManagement.reportMessage)
	const importExportMessage = useSelector((state) => state.dataManagement.importExportMessage)
	const userGraphData = useSelector((state) => state.dataManagement.userGraphData)
	const banners = useSelector((state) => state.dataManagement.banners)
	const emailNotifications = useSelector((state) => state.dataManagement.emailNotifications)
	const documents = useSelector((state) => state.dataManagement.documents)
	const documentsDropdown = useSelector((state) => state.dataManagement.documentsDropdown)
	const pagination = useSelector((state) => state.dataManagement.pagination)
	const loading = useSelector((state) => state.dataManagement.loading)

	const makeReport = async (payload) => {
		return dispatch(createReport(payload))
	}

	const exportFile = async (payload) => {
		return dispatch(exportData(payload))
	}

	const importFile = async (payload) => {
		return dispatch(importData(payload))
	}
	const uploadAnyFile = async (payload) => {
		return dispatch(uploadFile(payload))
	}

	const updateEmployeeStatus = async (payload) => {
		return dispatch(updateEmploymentStatus(payload))
	}

	const createNotification = async (payload) => {
		return dispatch(createEmailNotification(payload))
	}

	const updateNotification = async (payload) => {
		return dispatch(updateEmailNotification(payload))
	}

	const deleteNotification = async (payload) => {
		return dispatch(deleteEmailNotification(payload))
	}

	const getBanners = async (payload) => {
		return dispatch(fetchBanners())
	}

	const getDocuments = async () => {
		return dispatch(fetchDocumentFiles())
	}

	const getDocumentsDropdown = async () => {
		return dispatch(fetchDocumentsFilesDropdown())
	}

	const createDocument = async (payload) => {
		return dispatch(createDocumentFile(payload))
	}

	const updateDocument = async (payload) => {
		return dispatch(updateDocumentFile(payload))
	}

	const removeDocument = async (id) => {
		return dispatch(removeDocumentFile(id))
	}

	const getEmailNotifications = async (payload) => {
		return dispatch(fetchEmailNotifications(payload))
	}

	const updateBanners = async (payload) => {
		return dispatch(updateBanner(payload))
	}

	return {
		reportMessage,
		importExportMessage,
		loading,
		userGraphData,
		banners,
		documents,
		documentsDropdown,
		makeReport,
		exportFile,
		importFile,
		uploadAnyFile,
		updateEmployeeStatus,
		getBanners,
		updateBanners,
		getEmailNotifications,
		getDocuments,
		getDocumentsDropdown,
		createDocument,
		updateDocument,
		removeDocument,
		emailNotifications,
		pagination,
		createNotification,
		updateNotification,
		deleteNotification,
	}
}
