import { Button } from "@chakra-ui/button"
import { Flex } from "@chakra-ui/layout"
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/react"
import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import styled from "styled-components"
import useUser from "../../redux/hooks/useUser"
import paths from "../../routing/paths"
import useConfig from "../../utils/config"
import { strings } from "../../utils/localization"
import AdminPanelHeading from "../admin_panel/AdminPanelHeading"
import AdminPanelItem from "../admin_panel/AdminPanelItem"
import { LeftArrow, LogOutIcon } from "../Icons"

const AdminPanelPage = () => {
	const hook = useUser()
	let navigate = useNavigate()
	const config = useConfig()

	const path = paths()

	return (
		<Flex bg="mc_bg" w="100%" h="100vh" p="20px" gridColumnGap="20px">
			<Flex
				w="270px"
				minW="240px"
				h="100%"
				boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
				rounded="xl"
				direction="column"
				justify="space-between"
				alignItems="center"
				p="10px"
				pr="0"
			>
				<Accordion defaultIndex={[0, 2]} allowMultiple w="100%" pr="8px" overflowY="auto">
					<WrapContainer>
						<AccordionItem borderTopWidth={0} p="4px">
							<AccordionButton borderRadius={"12px"}>
								<AdminPanelHeading text={strings.panel} />
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel pb={4} px={0}>
								<AdminPanelItem text={strings.users} path={path.panelUsers} />
								<AdminPanelItem text={strings.reports} path={path.panelReports} />
								{(hook.admin || hook.supervisor) && (
									<AdminPanelItem text={strings.importExport} path={path.panelExportImport} />
								)}
								{hook.admin && (
									<>
										<AdminPanelItem text={"EMAIL NOTIFICATIONS"} path={path.panelEmailNotification} />
									</>
								)}
								<AdminPanelItem text={strings.comments} path={path.panelComments} />
								{config.appId === 2 && <AdminPanelItem text={strings.absenceRequests} path={path.panelAbsenceRequests} />}
							</AccordionPanel>
						</AccordionItem>

						{hook.admin && (
							<AccordionItem p="4px">
								<AccordionButton borderRadius={"12px"}>
									<AdminPanelHeading text={strings.masterData} />
									<AccordionIcon />
								</AccordionButton>
								<AccordionPanel pb={4} px={0}>
									<AdminPanelItem text={strings.countries} path={path.panelCountries} />
									<AdminPanelItem text={strings.jobTitles} path={path.panelTitles} />
									<AdminPanelItem text={strings.positions} path={path.panelPositions} />
									<AdminPanelItem text={strings.companyTypes} path={path.panelCompanyTypes} />
									<AdminPanelItem text={strings.cities} path={path.panelCities} />
									<AdminPanelItem text={strings.companies} path={path.panelCompanies} />
									<AdminPanelItem text={strings.educationCategories} path={path.panelEducationCategory} />
									<AdminPanelItem text={strings.articleCategories} path={path.panelArticleCategory} />
									<AdminPanelItem text={strings.tags} path={path.panelTags} />
								</AccordionPanel>
							</AccordionItem>
						)}
						<AccordionItem p="4px">
							<AccordionButton borderRadius={"12px"}>
								<AdminPanelHeading text={strings.content} />
								<AccordionIcon />
							</AccordionButton>
							<AccordionPanel pb={4} px={0}>
								<AdminPanelItem text={strings.articles} path={path.panelArticles} />
								<AdminPanelItem text={strings.surveys} path={path.panelSurveys} />
								<AdminPanelItem text={strings.educations} path={path.panelEducations} />
								<AdminPanelItem text={strings.tests} path={path.panelTests} />
								<AdminPanelItem text={strings.documents} path={path.panelDocs} />

								{config.appId === 1 && hook.admin && (
									<>
										<AdminPanelItem text={strings.jobListings} path={path.panelJobs} />
										<AdminPanelItem text="BANNERS" path={path.panelBanners} />
									</>
								)}
							</AccordionPanel>
						</AccordionItem>
					</WrapContainer>
				</Accordion>

				<Flex gridGap="10px">
					{hook.admin && (
						<Button
							variant="medium"
							rounded="xl"
							px="8px"
							fontSize="14px"
							leftIcon={<LeftArrow fill="mc_bg" />}
							onClick={() => navigate(path.articles)}
						>
							{strings.back}
						</Button>
					)}
					<Button
						variant="red"
						rounded="xl"
						px="8px"
						rightIcon={<LogOutIcon fill="mc_bg" />}
						fontSize="14px"
						onClick={() => hook.signout()}
					>
						{strings.signOut}
					</Button>
				</Flex>
			</Flex>
			<Flex
				w="100%"
				h="100%"
				boxShadow="0px 2px 10px rgba(36, 180, 188, 0.25)"
				rounded="xl"
				p="20px"
				position="relative"
				direction="column"
				overflow="hidden"
			>
				<Outlet context={config} />
			</Flex>
		</Flex>
	)
}

const WrapContainer = styled.div`
	width: 100%;
	.selected {
		background: var(--mc_medium) !important;
		color: var(--mc_bg);
		border-radius: 0.75rem;
		width: 100%;
		height: 100%;
	}
`

export default AdminPanelPage
