import { useToast } from "@chakra-ui/toast"
import React, { useEffect } from "react"
import { useNavigate, useOutletContext } from "react-router-dom"
import useUser from "../redux/hooks/useUser"
import paths from "../routing/paths"
import { handleToast } from "../utils/functions"
import { Button } from "@chakra-ui/button"
import { Input, InputGroup, InputRightElement } from "@chakra-ui/input"
import { Box, Flex, Heading, Link, Stack, Text } from "@chakra-ui/layout"
import { Link as RouterLink } from "react-router-dom"
import { ReactComponent as WhiteArrow } from "../assets/icons/whiteArrow.svg"
import { useForm } from "react-hook-form"
import { strings } from "../utils/localization"

const LoginPage = () => {

    const hook = useUser()
    const toast = useToast()
    let navigate = useNavigate()
    const config = useOutletContext()
	const path = paths()
    const { register, handleSubmit } = useForm()

    useEffect(() => {
        hook.cleanToast()
    }, []) //eslint-disable-line

    useEffect(() => {
		if(hook.token) {
			if(hook.admin || hook.supervisor) {
				navigate(path.panelReports)
			}else{
				navigate(path.defaultPath)
			}
		}
	  }, [hook.token])

    useEffect(() => {
        if (hook.message) {
            handleToast(toast, hook.message, "1")
        }
    }, [hook.message]) //eslint-disable-line

    return (
        <Flex justify="center" alignItems="center" h="70vh" direction="column">
            <Box mb="10px">
                {config.styledLogo}
            </Box>

            <Heading as="h1" letterSpacing="5px" mb="55px">
                {strings.signIn}
            </Heading>
            <form onSubmit={handleSubmit(hook.signin)}>
                <Stack w="530px" spacing={3}>
                    <InputGroup>
                        <Input
                            name="username"
                            ref={register({ required: true })}
                            placeholder={strings.username}
                            type="text"
                            bg="mc_bg"
                            _placeholder={{
                                color: "mc_medium",
                            }}
                            pl="25px"
                            fontWeight="700"
                            fontSize="14px"
                            rounded="xl"
                            boxShadow="0px 2px 12px 4px rgba(34, 178, 186, 0.35)"
                            h="57px"
                            letterSpacing="1px"
                        />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            name="password"
                            ref={register({ required: true })}
                            placeholder={strings.password}
                            type="password"
                            bg="mc_bg"
                            _placeholder={{
                                color: "mc_medium",
                            }}
                            pl="25px"
                            fontWeight="700"
                            fontSize="14px"
                            rounded="xl"
                            boxShadow="0px 2px 12px 4px rgba(34, 178, 186, 0.35)"
                            h="57px"
                            letterSpacing="1px"
                        />
                        <InputRightElement h="100%" w="160px">
                            <Button
                                type="submit"
                                h="100%"
                                w="160px"
                                overflow="hidden"
                                rounded="xl"
                                variant="medium"
                                _hover={{ bg: "mc_dark" }}
                            >
                                {strings.confirm}
                                <Box ml="15px">
                                    <WhiteArrow />
                                </Box>
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                    {config.appId !== 2 && (
                        <Flex
                            color="mc_medium"
                            fontSize="16px"
                            fontWeight="700"
                            flexDirection="column"
                            alignItems="center"
                            gridRowGap="10px"
                        >
                            <Text>
                                {strings.noAccount}{" "}
                                <Link as={RouterLink} to="/registration" textDecor="underline">
                                    {strings.clickHere}
                                </Link>
                            </Text>
                            <Text>
                                {strings.forgotPassword}{" "}
                                <Link as={RouterLink} to="/reset-password" textDecor="underline">
                                    {strings.clickHere}
                                </Link>
                            </Text>
                        </Flex>
                    )}
                </Stack>
            </form>
        </Flex>
    )
}

export default LoginPage
