import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import { city } from "../../utils/constants"
import useGeneric from "../../redux/hooks/useGeneric"
import UpdateButton from "../buttons/UpdateButton"
import CityForm from "../forms/CityForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"
import { strings } from "../../utils/localization"

const CityTable = ({ data, selectData }) => {
    const hook = useGeneric()

    const [updateData, setUpdateData] = useState(null)

    const columns = [
        {
            dataField: "code",
            text: strings.code,
        },
        {
            dataField: "name",
            text: strings.name,
        },
        {
            dataField: "country_name",
            text: strings.country,
        },
    ]

    const handleUpdateModal = (row) => {
        const defaultCountry = selectData.countries.find((e) => e.value === row.country)

        const defaultValues = {
            id: row.id,
            code: row.code,
            name: row.name,
            defaultCountry: defaultCountry,
        }
        setUpdateData(defaultValues)
        hook.onOpen()
    }

    const expandRow = {
        renderer: (row) => (
            <div className="btns-container">
                <DeleteModal remove={() => hook.remove(city, row.id)} />
                <UpdateButton onClick={() => handleUpdateModal(row)} />
            </div>
        ),
        className: "expandedRow",
        parentClassName: "parentExpandedRow",
    }

    const handleSubmit = (data) => {
        const payload = {
            id: updateData.id,
            code: data.code,
            name: data.name,
            country: data.country.value,
        }

        hook.update(city, payload)
    }

    return (
        <>
            <TablesStyles>
                <BootstrapTable
                    data={data}
                    columns={columns}
                    keyField="id"
                    classes="TablesStyles"
                    expandRow={expandRow}
                    bordered
                    bootstrap4
                />
            </TablesStyles>

            <AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="5xl">
                <CityForm
                    updateData={updateData}
                    onClose={hook.onClose}
                    submit={handleSubmit}
                    selectData={selectData}
                />
            </AddUpdateModal>
        </>
    )
}

export default CityTable
