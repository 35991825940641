import { Button } from "@chakra-ui/button"

const CustomButton = ({ onClick, variant, text }) => {
    return (
        <Button
            w="120px"
            h="38px"
            variant={variant}
            rounded="xl"
            onClick={() => onClick()}
			textTransform="uppercase"
        >
			{text}
        </Button>
    )
}

export default CustomButton
