import { Box } from "@chakra-ui/react"
import { useToast } from "@chakra-ui/toast"
import React, { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import PlusButton from "../../components/buttons/PlusButton"
import ArticleForm from "../../components/forms/ArticleForm"
import AddUpdateModal from "../../components/modals/AddUpdateModal"
import ArticleTable from "../../components/tables/ArticleTable"
import Pagination from "../../components/tables/Pagination"
import useArticlePanel from "../../redux/hooks/useArticlePanel"
import useGeneric from "../../redux/hooks/useGeneric"
import useMasterdata from "../../redux/hooks/useMasterdata"
import useUsers from "../../redux/hooks/useUsers"
import {
	application,
	articleCategory,
	articleStatus,
	city,
	company,
	concept,
	contentType,
	country,
	hqRetail,
	organizationUnit,
	positionSe1,
	positionSe2,
	sector,
	survey,
} from "../../utils/constants"
import { handleToast, makeDropdownOptions, makeDropdownOptionsUsers, makeIdsArray } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedPermissionsPayload, handleSmartedSelectData } from "../../utils/smartedHelper"

const AdminArticlesPage = () => {
	const hook = useArticlePanel()
	const hookMd = useMasterdata()
	const hookG = useGeneric()
	const toast = useToast()
	const config = useOutletContext()
	const hookUsers = useUsers()

	const [surveys, setSurveys] = useState([])
	const [companies, setCompanies] = useState([])
	const [applications, setApplications] = useState([])
	const [articleCategories, setArticleCategories] = useState([])
	const [articleStatuses, setArticleStatuses] = useState([])
	const [hqRetails, setHqRetails] = useState([])
	const [concepts, setConcepts] = useState([])
	const [organizationUnits, setOrganizationUnits] = useState([])
	const [sectors, setSectors] = useState([])
	const [positionsSe1, setPositionsSe1] = useState([])
	const [positionsSe2, setPositionsSe2] = useState([])
	const [countries, setCountries] = useState([])
	const [cities, setCities] = useState([])
	const [users, setUsers] = useState([])
	const [contentTypes, setContentTypes] = useState([])
	const [selectedFile, setSelectedFile] = useState(null)

	useEffect(() => {
		hook.cleanToast()
		hook.fetchPage(hook.defaultSize, 1)
		hookG.fetch(survey)
		hookG.fetch(company)
		hookMd.fetch(application)
		hookG.fetch(articleCategory)
		hookMd.fetch(articleStatus)
		hookMd.fetch(contentType)

		if (config.appId === 2) {
			hookMd.fetch(hqRetail)
			hookMd.fetch(concept)
			hookMd.fetch(organizationUnit)
			hookMd.fetch(sector)
			hookMd.fetch(positionSe1)
			hookMd.fetch(positionSe2)
			hookG.fetch(city)
			hookMd.fetch(country)
			hookUsers.fetchDropdown()
		}
	}, []) //eslint-disable-line

	useEffect(() => {
		makeDropdownOptions(hookG.surveys, setSurveys)
		makeDropdownOptions(hookG.companies, setCompanies)
		makeDropdownOptions(hookMd.apps, setApplications)
		makeDropdownOptions(hookG.articleCategories, setArticleCategories)
		makeDropdownOptions(hookMd.articleStatuses, setArticleStatuses)
		makeDropdownOptions(hookMd.contentTypes, setContentTypes)

		if (config.appId === 2) {
			makeDropdownOptions(hookMd.hqRetails, setHqRetails)
			makeDropdownOptions(hookMd.concepts, setConcepts)
			makeDropdownOptions(hookMd.organizationUnits, setOrganizationUnits)
			makeDropdownOptions(hookMd.sectors, setSectors)
			makeDropdownOptions(hookMd.positionsSe1, setPositionsSe1)
			makeDropdownOptions(hookMd.positionsSe2, setPositionsSe2)
			makeDropdownOptions(hookG.cities, setCities)
			makeDropdownOptions(hookMd.countries, setCountries)
			makeDropdownOptionsUsers(hookUsers.data, setUsers)
		}
	}, [
		hookG.surveys,
		hookG.companies,
		hookMd.apps,
		hookG.articleCategories,
		hookMd.articleStatuses,
		hookMd.hqRetails,
		hookMd.concepts,
		hookMd.organizationUnits,
		hookMd.sectors,
		hookMd.positionsSe1,
		hookMd.positionsSe2,
		hookG.cities,
		hookMd.countries,
		hookUsers.data,
		config.appId,
		hookMd.contentTypes,
	]) //eslint-disable-line

	useEffect(() => {
		if (hook.message) {
			handleToast(toast, hook.message, "1")
		}
	}, [hook.message]) // eslint-disable-line

	const handleSubmit = (data) => {
		console.log(data)
		let formData = new FormData()

		if (data.survey) {
			formData.append("survey", data.survey.value)
		}

		if (data.companies && data.companies.length > 0) {
			formData.append("companies", JSON.stringify(makeIdsArray(data.companies)))
		}

		if (config.appId === 2) {
			handleSmartedPermissionsPayload(data, formData, "formData")
		}

		let contentChecker = ""

		let contentTypes = ["content_pdf", "content_image", "content_video", "content"]

		contentTypes.forEach((c) => {
			if (c in data) {
				contentChecker = c
			}
		})

		if (!data.image || !data.status || !data.app || !data.summary_text || !data.content_type || !data[contentChecker]) {
			handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "2")
		} else {
			formData.append("title", data.title)
			formData.append("summary_text", data.summary_text)
			formData.append("app", data.app.value)
			formData.append("status", data.status.value)
			formData.append("image", data.image, data.image.name)
			formData.append("new_employee_content", data.new_employee_content)
			formData.append("content_type", data.content_type.value)
			if (contentChecker === "content") {
				formData.append("content", data.content)
			} else {
				formData.append(contentChecker, data[contentChecker], data[contentChecker].name)
			}

			if (data.category) {
				formData.append("category", data.category.value)
			} else {
				formData.append("category", "")
			}

			if (selectedFile) {
				formData.append("users_file", selectedFile, selectedFile.name)
			}

			hook.create(formData)
		}
	}

	let selectData = {
		companies: companies,
		apps: applications,
		surveys: surveys,
		categories: articleCategories,
		statuses: articleStatuses,
		contentTypes: contentTypes,
	}

	if (config.appId === 2) {
		const allOptions = {
			hqRetails: hqRetails,
			concepts: concepts,
			organizationUnits: organizationUnits,
			sectors: sectors,
			positionsSe1: positionsSe1,
			positionsSe2: positionsSe2,
			cities: cities,
			countries: countries,
			users: users,
		}

		handleSmartedSelectData(allOptions, selectData)
	}

	const pagination = hook.pagination

	return (
		<>
			<Box
				h="95%"
				overflow="auto"
				border={"4px"}
				boxShadow={"0px 2px 10px 2px rgba(34, 178, 186, 0.25)"}
				borderColor="mc_black"
				borderRadius="4px"
				w="100%"
			>
				<ArticleTable data={hook.data} selectData={selectData} />
				<PlusButton onClick={() => hook.onOpen()} />
				<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="full">
					<ArticleForm
						handleClose={hook.onClose}
						submit={handleSubmit}
						selectData={selectData}
						setSelectedFile={setSelectedFile}
					/>
				</AddUpdateModal>
			</Box>
			{hook.pagination && pagination.total_count > 10 && (
				<Box w="80%" mx="auto">
					<Pagination
						paginationData={{
							totalCount: pagination.total_count,
							totalPages: pagination.total_pages,
							currentPage: pagination.current_page,
							pageSize: hook.defaultSize,
							pageSizesArray: [10, 25, 50, 100, 200],
						}}
						setSize={hook.changeSize}
						fetchPage={hook.fetchPage}
					></Pagination>
				</Box>
			)}
		</>
	)
}

export default AdminArticlesPage
