import { useToast } from "@chakra-ui/toast"
import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import useApp from "../../redux/hooks/useApp"
import useArticlePanel from "../../redux/hooks/useArticlePanel"
import useUser from "../../redux/hooks/useUser"
import useConfig from "../../utils/config"
import { formatDateForCell, handleToast, makeDropdownOptions, makeIdsArray, trueOrFalse } from "../../utils/functions"
import { strings } from "../../utils/localization"
import { handleSmartedDefaultValues, handleSmartedPermissionsPayload, smartedColumns } from "../../utils/smartedHelper"
import UpdateButton from "../buttons/UpdateButton"
import ArticleForm from "../forms/ArticleForm"
import AddUpdateModal from "../modals/AddUpdateModal"
import DeleteModal from "../modals/DeleteModal"
import { TablesStyles } from "./TablesStyles"

const ArticleTable = ({ data, selectData }) => {
	const hook = useArticlePanel()
	const hookUser = useUser()
	const hookApp = useApp()
	const toast = useToast()

	const config = useConfig()

	const [updateData, setUpdateData] = useState(null)
	const [selectedFile, setSelectedFile] = useState(null)

	const columns = [
		{
			dataField: "title",
			text: strings.title,
		},
		{
			dataField: "category_name",
			text: strings.category,
		},
		{
			dataField: "date_created",
			text: strings.creationDate,
			formatter: (cell) => formatDateForCell(cell),
		},
		{
			dataField: "status_name",
			text: strings.status,
		},
		{
			dataField: "views",
			text: strings.views,
		},
		{
			dataField: "reactions_count",
			text: strings.reactions,
		},
		{
			dataField: "comments_count",
			text: strings.comments,
		},
		{
			dataField: "content_type_name",
			text: "CONTENT TYPE",
		},
		{
			dataField: "unique_url",
			text: "UNIQUE URL",
		},
		{
			dataField: "new_employee_content",
			text: "NEW EMPLOYEE CONTENT",
			formatter: (cell) => trueOrFalse(cell),
		},
	]

	if (hookUser.admin) {
		columns.push(
			{
				dataField: "companies_value",
				text: strings.companies,
			},
			{
				dataField: "app_name",
				text: strings.application,
			}
		)
	}

	if (config.appId === 2) {
		smartedColumns.forEach((column) => columns.push(column))
	}

	const handleUpdateModal = (row) => {
		let defaultCompanies = []
		let defaultApp = null

		if (!hookUser.admin) {
			defaultCompanies = [{ label: hookUser.company.name, value: hookUser.company.id }]
			defaultApp = { label: hookApp.name, value: hookApp.id }
		} else {
			defaultCompanies = makeDropdownOptions(row.companies)
			defaultApp = selectData.apps.find((e) => e.value === row.app)
		}

		const defaultValues = {
			id: row.id,
			defaultCategory: selectData.categories.find((e) => e.label === row.category_name),
			defaultCompanies: defaultCompanies,
			defaultStatus: selectData.statuses.find((e) => e.label === row.status_name),
			defaultSurvey: selectData.surveys.find((e) => e.label === row.survey_name),
			defaultApp: defaultApp,
			defaultContentType: selectData.contentTypes.find((e) => e.value === row.content_type),
			content: row.content,
			imagePath: row.image,
			summaryText: row.summary_text,
			title: row.title,
			new_employee_content: row.new_employee_content,
		}

		if (config.appId === 2) {
			handleSmartedDefaultValues(defaultValues, row, selectData)
		}

		setUpdateData(defaultValues)

		hook.onOpen()
	}

	const expandRow = {
		renderer: (row) => (
			<div className="btns-container">
				<DeleteModal remove={() => hook.remove(row.id)} />
				<UpdateButton onClick={() => handleUpdateModal(row)} />
			</div>
		),
		className: "expandedRow",
		parentClassName: "parentExpandedRow",
	}

	const handleSubmit = (data) => {
		let formData = new FormData()
		formData.append("id", updateData.id)

		if (data.survey) {
			formData.append("survey", data.survey.value)
		} else {
			formData.append("survey", null)
		}
		if (data.image) {
			formData.append("image", data.image, data.image.name)
		}

		if (data.companies && data.companies.length > 0) {
			formData.append("companies", JSON.stringify(makeIdsArray(data.companies)))
		} else {
			formData.append("companies", [])
		}

		if (config.appId === 2) {
			handleSmartedPermissionsPayload(data, formData, "formData")
		}

		let contentChecker = ""

		let contentTypes = ["content_pdf", "content_image", "content_video", "content"]

		contentTypes.forEach((c) => {
			if (c in data) {
				contentChecker = c
			}
		})

		if (!data.status || !data.app || !data.summary_text) {
			handleToast(toast, { text: strings.requiredFieldsMessage, type: "error" }, "2")
		} else {
			formData.append("title", data.title)
			formData.append("summary_text", data.summary_text)
			formData.append("app", data.app.value)
			formData.append("status", data.status.value)
			formData.append("content_type", data.content_type.value)
			formData.append("new_employee_content", data.new_employee_content)

			if (data[contentChecker]) {
				if (contentChecker === "content") {
					formData.append("content", data.content)
				} else {
					formData.append(contentChecker, data[contentChecker], data[contentChecker].name)
				}
			}

			if (data.category) {
				formData.append("category", data.category.value)
			} else {
				formData.append("category", "")
			}

			if (selectedFile) {
				formData.append("users_file", selectedFile, selectedFile.name)
			}

			hook.update(formData)
		}
	}

	return (
		<>
			<TablesStyles appId={config.appId}>
				<BootstrapTable
					data={data}
					columns={columns}
					keyField="id"
					classes="TablesStyles"
					expandRow={expandRow}
					bordered
					bootstrap4
				/>
			</TablesStyles>

			<AddUpdateModal isOpen={hook.isOpen} onClose={hook.onClose} size="full">
				<ArticleForm
					updateData={updateData}
					handleClose={hook.onClose}
					submit={handleSubmit}
					selectData={selectData}
					setSelectedFile={setSelectedFile}
				/>
			</AddUpdateModal>
		</>
	)
}

export default ArticleTable
