import { Button } from "@chakra-ui/button"
import { Flex, Text } from "@chakra-ui/layout"
import { strings } from "../../utils/localization"
import { AttentionIcon, ReloadIcon, WhiteArrowIcon } from "../Icons"

const LastSlideFooter = ({ reload, startTest, userAllowed, testAttempts, noTest }) => {
	if (noTest) {
		return (
			<Flex justify="center" alignItems="center" gridColumnGap="20px" h="80px">
				{reload && (
					<Button onClick={reload} variant="medium" rounded="lg" w="55px" h="40px">
						<ReloadIcon fill="mc_bg" />
					</Button>
				)}
			</Flex>
		)
	} else {
		return (
			<Flex justify="center" alignItems="center" gridColumnGap="20px" h="80px">
				{reload && (
					<Button onClick={reload} variant="medium" rounded="lg" w="55px" h="40px">
						<ReloadIcon fill="mc_bg" />
					</Button>
				)}

				{userAllowed ? (
					<Button
						rightIcon={<WhiteArrowIcon fill="mc_bg" />}
						variant="medium"
						alignItems="center"
						rounded="lg"
						onClick={() => startTest()}
						textTransform="uppercase"
					>
						{strings.startTest}
					</Button>
				) : (
					<Flex
						alignItems="center"
						justify="center"
						gridColumnGap="10px"
						border="1px solid"
						borderColor="mc_errorRed"
						borderRadius="md"
						height="42px"
						lineHeight="110%"
						p="4px"
					>
						<AttentionIcon fill="mc_errorRed" w="24px" h="24px" />
						<Text textTransform="uppercase" color="mc_errorRed" textAlign="left" fontWeight="700">
							{strings.didTest}
							<br /> {strings.maxTimes}
						</Text>
					</Flex>
				)}
			</Flex>
		)
	}
}

export default LastSlideFooter
